const formulae = {
  C2C: value => value,
  C2F: value => (value * (9 / 5)) + 32,
  F2C: value => (value - 32) * (5 / 9),
  F2F: value => value,
  wh2kwh: value => (value / 1000).toFixed(3),
  wh2mwh: value => (value * 1000).toFixed(0),
  w2kw: value => (value / 1000).toFixed(3),
};

export default function UnitConverter() {
  this.convert = (from, to) => {
    const target = `${from}2${to}`;
    if (target in formulae) {
      return formulae[target];
    }
    throw new Error(`Unsupported conversion: ${from} to ${to}.`);
  };
}
