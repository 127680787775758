<template>
  <div class="weather-current">
    <h4 class="dashboard-header">{{ $t('weatherConditions') }}</h4>
    <div class="weather-current__deck">
      <WeatherCurrentCard v-for="card in cards" :key="`weather-current-${card.key}`" :card="card" />
    </div>
  </div>
</template>

<script>
import WeatherCurrentCard from './WeatherCurrentCard.vue';

export default {
  name: 'WeatherCurrent',
  components: { WeatherCurrentCard },
  inject: ['$busy', '$toast'],
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  computed: {
    current() {
      return this.$store.getters['weather/current'];
    },
    units() {
      return this.current.units;
    },
    cards() {
      return [
        {
          key: this.$i18n.t('currentConditions'),
          icon: this.current.icon,
        },
        {
          key: this.$i18n.t('temperature'),
          value: this.current.temp,
          symbol: this.units === 'imperial' ? 'F' : 'C',
        },
        {
          key: this.$i18n.t('precipitation'),
          value: this.current.precip,
          symbol: '%',
        },
        {
          key: this.$i18n.t('wind'),
          value: this.current.windSpeed,
          symbol: this.units === 'imperial' ? 'MPH' : 'km/h',
        },
      ];
    },
  },
  watch: {
    house() {
      this.$busy.toggle(true);
      this.fetchCurrent()
        .finally(() => {
          this.$busy.toggle(false);
        });
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$busy.toggle(true);
      this.fetchCurrent()
        .finally(() => {
          this.$busy.toggle(false);
        });
    },
    fetchCurrent() {
      const { house } = this;
      const i18n = this.$i18n;
      return this.$store.dispatch('weather/fetchCurrent', { house, i18n })
        .catch((err) => {
          this.$toast.show('fail', { title: err.name, body: err.message });
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.weather-current {
  width: 100%;
  padding: 0 0 24px;

  &__deck {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    row-gap: 8px;
  }

  @include Tablet--XLarge {
    &__deck {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      column-gap: 16px;
      row-gap: unset;
    }
  }

  @include Desktop {
    width: max-content;

    &__deck {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 16px;
    }
  }

  @include Desktop--Large {
    &__deck {
      column-gap: 16px;
      row-gap: 16.5px;
    }
  }
}
</style>
