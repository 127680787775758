export default {
  leaderboardStandingsTypes: (state) => {
    return state.leaderboard.standingsTypes;
  },
  leaderboardParticipantList: (state) => {
    return state.leaderboard.participantList;
  },
  energyGraphDateRange: (state) => {
    return state.energyGraph.dateRange;
  },
  energyGraphTimeType: (state) => {
    return state.energyGraph.timeType;
  },
  house: (state) => {
    return state.house;
  },
  marketingMessages(state) {
    return state.marketingMessages;
  },
  locale: (state) => {
    return state.locale;
  },
};
