/*
  munging.js

  All functions defined here should accept arguments and return results that
  at least comply with the following interface, except for functions that are
  intended to be called last.

  Minimum Supported Interface
  [
    {
      time: string, // ISO 8601 Date String
      value: number,
    },
    ...
  ]

  Currently, format() is meant to be called last, and returns a value that does
  not comply with this interface.

  What follows is an example of the raw data for a given device resource as
  retrieved from the server.

  Example Raw Data
  [
    {
      createdAt: '', // ISO 8601 Date String
      deviceId: 0,
      id: 0,
      platoonId: null,
      time: '', // ISO 8601 Date String
      updatedAt: '', // ISO 8601 Date String
      value: 0,
    },
    ...
  ]
*/

import _ from 'lodash';

export default {
  convert2kilo(data) {
    // Converts value scale to kilo
    return data.map((item) => {
      return {
        ...item,
        value: item.value / 1000,
      };
    });
  },

  convert2percent(data) {
    // Converts value to percent
    return data.map((item) => {
      return {
        ...item,
        value: Math.floor(item.value * 100),
      };
    });
  },
  convertTemp({
    metadata,
    tempUnit = 'F',
    localTemp = v => v,
  }) {
    // Converts temperature to given country's system of measurement
    return (data) => {
      _.merge(metadata, { tempUnit });
      return data.map((item) => {
        return {
          ...item,
          value: localTemp(item.value),
        };
      });
    };
  },
  fixEndTime(start, end) {
    // Fixes end time to comply with given time range
    const now = new Date();
    const start_ = new Date(start);
    const end_ = new Date(end);
    const today = (start_ < now) && (end_ > now);
    return (data) => {
      if (!today) {
        return [
          ...data,
          {
            value: data[data.length - 1].value,
            time: end_.toISOString(),
          },
        ];
      }
      return [
        ...data,
        {
          value: data[data.length - 1].value,
          time: now.toISOString(),
        },
      ];
    };
  },
  fixStartTime(start) {
    // Fixes start time to comply with given start time
    const start_ = new Date(start);
    return (data) => {
      if (new Date(data[0].time) < start_) {
        const [firstEntry, ...others] = data;
        return [{ ...firstEntry, time: start_.toISOString() }, ...others];
      }
      return data;
    };
  },
  format(data) {
    // Formats the data for use with VpScatterChart & Chart.js
    // Must be called last; return value does not comply with { time, value } interface
    return data.map((item) => {
      return {
        x: new Date(item.time),
        y: item.value,
      };
    });
  },
  findExtremes(metadata, { mode = 'absolute', relativeBase = 10, relativeTarget = 'both' } = {}) {
    // Finds and sets the min/max values
    return (data) => {
      const newMeta = _.merge({}, metadata);
      if (mode === 'both' || mode === 'absolute') {
        // Simple min/max
        newMeta.min = data.reduce((min, item) => {
          return item.value < min ? Math.round(item.value) - 1 : min;
        }, newMeta.min || 0);
        newMeta.max = data.reduce((max, item) => {
          return item.value > max ? Math.round(item.value) + 1 : max;
        }, newMeta.max || 0);
      }
      if (mode === 'both' || mode === 'relative') {
        // Windowed min/max
        if (relativeTarget === 'both' || relativeTarget === 'min') {
          newMeta.min = data.reduce((min, item) => {
            const floor = Math.floor(item.value / relativeBase) * relativeBase - relativeBase;
            return (min == null) || (floor < min) ? floor : min; // ==
          }, newMeta.min);
        }
        if (relativeTarget === 'both' || relativeTarget === 'max') {
          newMeta.max = data.reduce((max, item) => {
            const ceil = Math.ceil(item.value / relativeBase) * relativeBase + relativeBase;
            return (max == null) || (ceil > max) ? ceil : max; // ==
          }, newMeta.max);
        }
      }
      _.merge(metadata, newMeta);
      return data;
    };
  },
  // minMaxDownsample(window) {
  //   return (data) => {
  //     const out = [];
  //     if (window <= 1) {
  //       return data;
  //     }
  //     for (let i = 0; i < data.length; i += window) {
  //       let min = data[i];
  //       let max = min;
  //       for (let j = i; j < i + window && j < data.length; j += 1) {
  //         min = data[j].value < min.value ? data[j] : min;
  //         max = data[j].value > max.value ? data[j] : max;
  //       }
  //       if (new Date(min.time) > new Date(max.time)) {
  //         out.push(max);
  //         out.push(min);
  //       } else {
  //         out.push(min);
  //         out.push(max);
  //       }
  //     }
  //     return out;
  //   };
  // },
  movingAverage(window) {
    // Sets value to an average amount based on a given window
    return (data) => {
      const out = [];
      out.push(data[0]);
      for (let i = window; i < data.length; i += window) {
        const sample = data.slice(i - window, i);
        let avg = 0;
        for (let j = 0; j < sample.length; j += 1) {
          const item = sample[j];
          avg += item.value;
        }
        avg /= window;
        out.push({
          time: data[i].time,
          value: avg,
        });
      }
      return out;
    };
  },
  pass(data) {
    // Does nothing to the given data
    return data;
  },
  reverse(data) {
    // Reverses the order of the given data
    return [...data].reverse();
  },
  truncate(data) {
    return data.map((item) => {
      if (item.value !== 0) {
        return {
          ...item,
          value: Math.round(item.value * 10) / 10,
        };
      } else {
        return { ...item };
      }
    });
  },
  stairStep(data) {
    // Inserts data points to allow for a stair-stepped appearance on a graph
    const out = [];
    out.push(data[0]);
    for (let i = 1; i < data.length; i += 1) {
      const itemK = JSON.parse(JSON.stringify(data[i]));
      const itemKm1 = JSON.parse(JSON.stringify(data[i - 1]));
      itemKm1.time = (new Date((new Date(itemK.time)).getTime() - 1)).toISOString();
      out.push(itemKm1);
      out.push(itemK);
    }
    return out;
  },
  transitions(data) {
    // Removes duplicate data points to allow for a smooth appearance on a graph
    const out = [];
    out.push(data[0]);
    for (let i = 1; i < data.length; i += 1) {
      if (data[i].value !== out[out.length - 1].value) {
        out.push(data[i]);
      }
    }
    return out;
  },
};
