export default {
  absolute: 'Absoluto',
  accountError: 'Error al actualizar la cuenta.',
  accountProfile: 'Perfil De La Cuenta',
  accountSecurity: 'Seguridad de la cuenta',
  accountSuccessBody: 'Su cuenta actualizada correctamente.',
  accountSuccessHead: 'Éxito',
  accountTab: '{tab} de la Cuenta',
  actual: 'Actual',
  addNumber: 'Añadir número',
  addPhoneNumber: '+ Agregar un número de teléfono',
  amps: 'Amperios',
  archived: 'archivado',
  atHome: 'En casa',
  attachFile: 'Adjuntar archivo',
  authenticatePIN: 'Autenticar el PIN',
  auto: 'Automático',
  automaticSelfConsumption: 'Autoconsumo automático',
  autoSchedule: 'La función Horario automático aprende sus hábitos y crea un horario alrededor de ellos, todo al mismo tiempo que ahorra dinero. Actívelo y use su dispositivo como lo haría normalmente. Después de una semana, el Horario automático hará el trabajo.',
  autoScheduleHead: 'Ahorre Dinero y Sea Ecológico con Horario Automático',
  auxHeat: 'Solo calor auxiliar',
  available: 'Disponible',
  backup: 'Respaldo',
  backToDashboard: 'Volver a la pagina inicial',
  baseline: 'Base',
  boost: 'Incentivo',
  byPower: 'Por energía',
  byPV: 'Por fotovoltaico',
  cancel: 'Cancelar',
  capacity: 'Capacidad',
  chargeFromGridCurrent: 'Carga de la red por corriente',
  chargeFromGridPower: 'Carga de la red por energía',
  chargeFromPV: 'Carga por fotovoltaico',
  charging: 'Cargando',
  checkEmail: 'Revise su correo electrónico',
  checkPIN: 'Para iniciar sesión, revise su correo electrónico y busque un PIN de 5 dígitos.',
  cleanBackup: 'Respaldo libre',
  close: 'Cerrar',
  cloudCoverage: 'cobertura nubosa',
  codeError: 'Código incorrecto. Intente de nuevo.',
  codePlaceHolder: 'Ejemplo: XXXXXXX',
  codePlaceholder: 'Ingrese el código de verificación',
  codeSent: 'Se envió el código de verificación; revise su teléfono.',
  compLower: 'Comp e inferior',
  compressor: 'Compresor',
  confirm: 'Confirmar',
  cool: 'Frío',
  coolSetpoint: 'Punto de adjuste de enfriamiento',
  cooling: 'Enfriamiento',
  coolingSetpoint: 'Punto de adjuste de enfriamiento',
  currentConditions: 'actualmente',
  currentlyRunning: 'ACTUALMENTE EN EJECUCIÓN',
  curtailment: 'Recorte de la respuesta a la demanda',
  date: 'fecha',
  datePickerDay: 'día',
  datePickerMonth: 'mes',
  datePickerWeek: 'semana',
  days: {
    monday: {
      long: 'lunes',
      short: 'lun',
      '1la': 'L',
    },
    tuesday: {
      long: 'martes',
      short: 'mar',
      '1la': 'M',
    },
    wednesday: {
      long: 'miércoles',
      short: 'miér',
      '1la': 'X',
    },
    thursday: {
      long: 'jueves',
      short: 'jue',
      '1la': 'J',
    },
    friday: {
      long: 'viernes',
      short: 'vie',
      '1la': 'V',
    },
    saturday: {
      long: 'sábado',
      short: 'sáb',
      '1la': 'S',
    },
    sunday: {
      long: 'domingo',
      short: 'dom',
      '1la': 'D',
    },
  },
  defaultMode: 'Modo por defecto',
  defaultSetpoint: 'Punto de ajuste por defecto',
  deleteDevice: 'Borrar el dispositivo',
  deleteMessage: 'Borrar',
  deleteNumber: 'Borrar',
  deleteSchedule: 'BORRAR HORARIO',
  desiredSiteMeter: 'Lectura del medidor del sitio deseado',
  device: 'Dispositivo',
  deviceBattery: 'Batería | {n} Baterías',
  deviceBatteryCapacity: 'Capacidad',
  deviceBatteryConsumption: 'Autoconsumo',
  deviceBatteryPower: 'Alimentación de batería',
  deviceControl: 'Control del dispositivo',
  deviceDefaultMode: 'Modo por defecto',
  deviceDefaultSetpoint: 'Punto de ajuste por defecto',
  deviceEv: 'VE | {n} VEs',
  deviceEvCharger: 'Cargador de vehículo eléctrico | {n} Cargadors de vehículo eléctrico',
  deviceEVPower: 'Carga rápida',
  deviceFan: 'Modo ventilador',
  deviceGE: 'Calentador de agua o ventana ac',
  deviceInstructions: 'Use un nombre fácil de recordar para su dispositivo como "Calentador de agua principal" o "Aire acondicionado de la habitación".',
  deviceKind: 'Tipo de dispositivo',
  deviceLimitNoAccess: 'El fabricante del dispositivo protege con esmero la información de su termostato. Puede ver o controlar el dispositivo manualmente o desde la aplicación del dispositivo recomendada por el fabricante.',
  deviceLimitNoControl: 'Este fabricante de dispositivos limita su capacidad para controlar su dispositivo desde nuestra aplicación. Puede realizar ajustes manualmente o desde la aplicación del dispositivo recomendada por el fabricante.',
  deviceManufacturer: 'Fabricante del dispositivo',
  deviceMode: 'Modo',
  deviceName: 'Nombre del dispositivo',
  deviceOffline: 'Dispositivo fuera de línea',
  deviceOptOutCopy: 'Hay un evento programado de { duration } para el { date } a las { time }. Anule las marcas de las casillas junto a los dispositivos que no quiere que participen en el evento.',
  deviceOptOutHead: 'Dispositivos participantes',
  deviceOptOutModal: 'Después de hacer clic en Enviar, se actualizaran sus preferencias de exclusión. Completar el proceso de exclusión puede tomar hasta 90 segundos.',
  deviceOverCopy: 'Aún puede participar en eventos de respuesta a la demanda futuros. Aprenda más acerca de cómo le ayudan a ahorrar energía y mantener la red eléctrica funcionado a la perfección al visitar { supportEmail }.',
  deviceOverHead: 'Este evento ya terminó',
  devicePower: 'Potencia',
  devicePowerLimit: 'Límite de potencia',
  deviceReconnect: 'Vuelva a conectar su dispositivo para ver las opciones de control',
  deviceRoomAC: 'Aires acondicionado para habitaciones | {n} Aires acondicionados para habitaciones',
  deviceSensibo: 'Mini-Split o ventana ac',
  deviceSettings: 'Configuración de dispositivo',
  deviceStatus: 'Estado del dispositivo',
  deviceThermostat: 'Termostato | {n} Termostatos',
  deviceTOU: 'Dispositivo TOU',
  deviceTstatSetpoint: 'Punto de ajuste',
  deviceTstatTemp: 'Temperatura Actual',
  deviceType: 'Tipo de dispositivo',
  deviceUid: 'Número de identificación',
  deviceUnknown: 'Desconocido',
  deviceUnreachable: 'Dispositivo Inaccesible',
  deviceWaterHeater: 'Calentador de agua | {n} Calentadores de agua',
  directMessage: 'Mensajes Directos',
  directMessageTooltip: 'Tenga en cuenta que los mensajes directos se utilizarán para soporte y para proporcionar información crítica sobre su dispositivo. Le recomendamos encarecidamente que no se dé de baja de estos mensajes.',
  disableGrid: 'Desactivar la red',
  dispatchCurrent: 'Envío por corriente',
  dispatchPower: 'Envío por energía',
  dispatchPV: 'Envío por fotovoltaico',
  dispatchSiteDemand: 'Envío por demanda del sitio',
  drEventHead: 'Hola {name}, es un día de respuesta a la demanda.',
  drEventMessage: 'Hoy hay un evento que afectará los dispositivos siguientes. No se preocupe, usted no tiene que hacer nada. Solo relájese y disfrute ayudando a mantener la tasa baja. Puede salir del evento, pero se puede perder de algunos beneficios.',
  eco: 'Eco',
  efficiency: 'Rendimiento',
  electric: 'Eléctrico',
  email: 'Correo electrónico',
  emailAddress: 'Correo electrónico',
  emailDisclaimer: 'Su dirección de correo electrónico se usa en el proceso de registro, por eso debemos validar cualquier cambio. Para comenzar con el proceso de modificación, contáctese con',
  emailHelp: 'Por ayuda, comuníquese a { supportEmail }',
  emailPlaceholder: 'Ingrese su correo electrónico',
  enabled: 'Activado',
  endTime: 'Hasta {event end time}',
  energy: 'Energía',
  energyBattery: 'ENERGÍA NETA PARA BATERÍA',
  energyConsumption: 'Consumo energético',
  energyEvse: 'ENERGÍA TOTAL USADA EN UNA RECARGA DE VEHÍCULO ELECTRÓNICO',
  energyHwH: 'TOTAL DE ENERGÍA USADA POR EL CALENTADOR DE AGUA',
  energySaver: 'Ahorro de energía',
  energyUsage: 'Consumo de energía',
  enterPhoneVerificationCode: 'Ingrese el código de verificación para {phoneNumber}',
  enterPIN: 'Ingrese el PIN',
  equipmentStatus: 'Estado del equipo',
  error: 'Error',
  error404: 'No se encontró esta página. (Error 404)',
  errorGenericHead: 'Hemos experimentado un error',
  errorGenericText: 'Encontramos el siguiente error: {error}. Se tomaron las siguientes acciones: {actions}',
  errorInactive: 'Cuenta inactivada',
  errorNotFound: 'No se encontró',
  ev: 'VE',
  evAwayPower: 'Energía Afuera',
  evBatteryCapacity: 'Capacidad de la batería de VE',
  evBatteryLevel: 'Nivel de batería de VE',
  evBatteryRange: 'Autonomía de la batería de VE',
  evChargerHistory: 'Historia del cargador de VE',
  evChargingStatus: 'Estado de carga',
  evDeviceMfg: 'Fabricante',
  evDeviceName: 'Nombre',
  evDeviceSettings: 'Configuración de VE',
  evDeviceStatus: 'Estado de VE',
  evDeviceChargeStatus: 'Estado de carga del VE',
  evDeviceLocation: 'Ubicación del VE',
  evHomePower: 'Energía en Casa',
  evLocationStatus: 'Ubicado en casa',
  evPluggedInStatus: 'Cargador enchufado',
  evRemainingRange: 'Carga restante del VE',
  evRemainingRangeFooter: 'Millas que puede recorrer el vehículo',
  evStateOfCharge: 'Estado de carga del VE',
  evUpdateDevice: 'Actualizar vehículo',
  evVehicleModel: 'Modelo de vehículo',
  evVehicleYear: 'Año del modelo',
  eventCalendarEmpty: '¡Aguardamos su primer evento!',
  eventCalendarResults: 'Resultado del día del evento',
  eventDevices: 'Todos los dispositivos',
  eventDuration: 'Duración del evento:',
  eventEnergy: 'Total de energía usada en el evento',
  eventEnergyBattery: 'ENERGÍA SUMINISTRADA POR LA BATERÍA DURANTE EL EVENTO',
  eventEnergyEvse: 'ENERGÍA USADA EN UNA RECARGA DE VEHÍCULO ELECTRÓNICO DURANTE EL EVENTO',
  eventEnergyHwh: 'ENERGÍA UTILIZADA POR EL CALENTADOR DE AGUA PARA EL EVENTO',
  eventEnergySavings: 'Ahorro de energía del evento',
  eventHead: 'Evento de gestión de energía en progreso',
  eventOptOut: 'Exclusiones',
  eventOptout: 'Salir del evento',
  eventParticipation: 'Participación',
  eventRuntimeTstat: 'TIEMPO DE EJECUCIÓN DURANTE EL EVENTO',
  events: 'Eventos',
  eventsResults: 'De {total} eventos llamados',
  eventsSuccess: 'EVENTOS EXITOSOS',
  eventStartTime: 'Hora inicial del evento:',
  eventText: '¡Cambiar los controles o salir de este evento puede inhabilitarlo para los ahorros!',
  eventToastActiveBody: 'es un día de respuesta a la demanda. Un día de respuesta a la demanda significa que controlaremos su dispositivo desde',
  eventToastActiveTitle: '¡Tiene un evento hoy!',
  eventToastOptout: 'Puede excluirse voluntariamente del evento haciendo <a class="event-optout">clic aquí</a>.',
  eventToastUpcomingBody: 'se ha programado un evento para su dispositivo. Controlaremos su dispositivo el',
  eventToastUpcomingTitle: '¡Tiene un evento programado!',
  eventUnresponsive: 'Incluye dispositivos sin restablecer',
  expiredLink: 'Parece que intentó ingresar con un enlace caducado. Para que le enviemos un nuevo enlace, ingrese su correo electrónico nuevamente.',
  extension: 'Extensión',
  failedToOptoutOfEvent: 'No se ha podido excluir del evento',
  fanOnly: 'Solo ventilador',
  favoriteMessage: 'Favorito',
  favorites: 'favoritos',
  firstName: 'Nombre',
  forecastDay: 'día',
  forecastEvening: 'noche',
  forecastToday: 'hoy',
  from: 'De',
  fullyCharged: 'Completamente cargada',
  generalNotification: 'Anuncios y notificaciones generales',
  getStarted: 'Para comenzar, seleccione un mensaje.',
  gridTie: 'Conexión a red',
  heat: 'Calor',
  heating: 'Calefacción',
  heatingSetpoint: 'Punto de ajuste de calentamiento',
  heatPump: 'Bomba de calor',
  high: 'Alto',
  highDemand: 'Alta demanda',
  holding: 'Holding',
  holdSetpoint: 'Hold Setpoint',
  homeownerSignIn: 'Inicio de sesión del propietario',
  hour: 'hora',
  housePower: 'Energía de la casa',
  hwhDefaultHead: 'Ingrese los detalles siguientes sobre el calentador de agua:',
  hwhDefaultMode: 'Modo por defecto del calentador de agua',
  hwhDefaultSetpoint: 'Punto de ajuste por defecto del calentador de agua',
  hybrid: 'Híbrido',
  idNumber: 'Número de identificación',
  inactiveMessage: 'Su cuenta está inactivada. Por favor, comuníquese con Apoyo al cliente, si piensa que esto es un error.',
  inbox: 'recibidos',
  intelligence: 'Inteligencia',
  lastName: 'Apellido',
  latestActivity: 'Última actividad',
  leaderboard: 'tabla de clasificación',
  learning: 'Aprendizaje',
  learningMessage: '{deviceId} aún está aprendiendo',
  learningMode: 'Puede tomar un par de semanas para que aprenda la mejor manera de ayudarle a ahorrar dinero. Use su dispositivo de forma normal y nosotros haremos el resto.',
  learningModeHead: 'Su dispositivo está en modo de aprendizaje',
  limitDispatch: 'Envío límite',
  linkError: 'Error al enviar el enlace',
  loaded: 'Cargado',
  loadEvents: 'Cargue más',
  loading: 'Cargando...',
  loadingMessage: 'Mejorando el CI de Smart Grid…',
  loadingMessage2: 'Reduciendo las emisiones de carbono con capacidad informática.',
  loadshifting: 'Inversión de carga de la respuesta a la demanda',
  lockedOut: 'Hemos bloqueado el ingreso porque ha intentado ingresar muchas veces. Puede intentar ingresar nuevamente en 15 minutos.',
  loginErrorWithContact: 'Hubo un error al intentar ingresar. Si necesita ayuda, comuníquese con nosotros a {supportEmail}',
  loginError: 'Error al ingresar',
  loginFailure: 'Su intento de ingresar ha fallado.',
  logout: 'Cerrar sesión',
  low: 'Bajo',
  magicLinkError: 'Hubo un error al enviarle un enlace mágico. Por favor, ingrese su correo electrónico en la siguiente página.',
  magicLinkMessage: 'Su enlace mágico debería llegar a la bandeja de entrada de su correo electrónico en breve. Ahora puede cerrar la ventana de navegación.',
  manual: 'Manual',
  manufacturer: 'Fabricante',
  maxCapacity: 'Máxima Capacidad',
  maxTempDisclaimer: 'La mayoría de los calentadores puede ajustarse a un máximo de 140 ºF, pero si hay una válvula mezcladora termostática puede alcanzar hasta 160 ºF o más.',
  maxTemperature: 'Temperatura máxima',
  medium: 'Medio',
  message: 'Mensaje',
  messageCenter: 'Centro de Mensajes',
  messageCenterWelcome: '¡Bienvenido a su centro de mensajes!',
  messageInstructions: 'Escriba el mensaje aquí',
  minTempDisclaimer: 'Esto define la temperatura mínima a la que su calentador de agua se puede ajustar durante los eventos de optimización. Si usted siente que la temperatura de salida de agua caliente está muy baja durante ciertos momentos del día, ajústela a un número más alto.',
  minTemperature: 'Temperatura mínima',
  mixingValve: 'Válvula mezcladora',
  mixingValveDisclaimer: 'Este dispositivo tiene una válvula mezcladora, y confirmo que entiendo las consideraciones de seguridad descritas en los Términos y condiciones de mis servicios públicos.',
  mixingValveWarning: 'Advertencia de válvula mezcladora',
  mode: 'Modo',
  monthlyResults: 'Sus resultados de su {month}',
  months: {
    january: {
      long: 'enero',
      short: 'ene',
    },
    february: {
      long: 'febrero',
      short: 'feb',
    },
    march: {
      long: 'marzo',
      short: 'mar',
    },
    april: {
      long: 'abril',
      short: 'abr',
    },
    may: {
      long: 'mayo',
      short: 'may',
    },
    june: {
      long: 'junio',
      short: 'jun',
    },
    july: {
      long: 'julio',
      short: 'jul',
    },

    august: {
      long: 'agosto',
      short: 'ago',
    },
    september: {
      long: 'septiembre',
      short: 'sep',
    },
    october: {
      long: 'octubre',
      short: 'oct',
    },
    november: {
      long: 'noviembre',
      short: 'nov',
    },
    december: {
      long: 'diciembre',
      short: 'dic',
    },
  },
  newPassword: 'Nueva Contraseña',
  newPasswordConfirm: 'Confirmar Nueva Contraseña',
  newPasswordInstructions: 'Ingrese una nueva contraseña. Debe tener al menos 12 caracteres e incluir lo siguiente: al menos una letra mayúscula, al menos una letra minúscula y al menos un número.',
  newPasswordPlaceholder: 'Ingrese nueva contraseña',
  newSchedule: 'Añadir un Horario Nuevo',
  nickname: 'Apodo',
  noDevicesToOptout: 'No hay dispositivos para excluir',
  none: 'Ninguno',
  noSubject: 'Sin asunto',
  notCharging: 'No cargando',
  notificationPreferences: 'Preferencias de notificación',
  notifications: 'Notificaciones',
  notify: 'Notify me…',
  oauthSuccess: '¡Lo logró!',
  oauthSuccessCopy: 'Gracias por completar la inscripción de su dispositivo',
  off: 'APAGADO',
  offline: 'Desconectado',
  offlineDeviceNotification: 'Si uno de mis dispositivos se desconecta',
  offset: 'Compensar',
  oldPassword: 'Contraseña Anterior',
  oldPasswordPlaceholder: 'Ingrese contrasena anterior.',
  on: 'ENCENDIDO',
  online: 'En línea',
  optedOut: 'Ya salí',
  optimizationBody: 'Ya que usted cambió su dispositivo a modo de {vacationSetting}, se ha desactivado la Optimización de tiempo de uso. Para activar la Optimización de tiempo de uso, actualice el Modo.',
  optimizationHead: 'Optimización desactivada',
  optoutWithApp: 'Salir de la aplicación originaria',
  outOfHome: 'Fuera de casa',
  overallStandings: 'Posiciones generales',
  participants: 'Participantes del programa',
  password: 'Contraseña',
  passwordErrorEmail: 'La contraseña no puede ser igual al correo electrónico.',
  passwordErrorMismatch: 'La contraseña anterior no es igual. Intente de nuevo.',
  passwordErrorName: 'La contraseña no puede ser igual al nombre.',
  passwordErrorRequirements: 'La contraseña debe cumplir con los requisitos antes mencionados. Intente de nuevo.',
  passwordHeader: 'Use la contraseña',
  passwordInstructions: 'Si esta es la primera vez que va a iniciar sesión, use la información de ingreso de Secure Link.',
  passwordPlaceholder: 'Ingrese su contraseña',
  passwordReset: 'RESTABLECER CONTRASEÑA',
  passwordResetLink: 'Has olvidado tu contraseña?',
  passwordResetMessage: 'Ingrese su correo electrónico y le enviaremos un enlace para restablecer su contraseña. Para más ayuda, envíe un correo electrónico a { supportEmail }.',
  passwordSignIn: 'Ingrese con una contraseña',
  passwordSuccess: 'La contraseña se actualizó con éxito.',
  peakDemandNotification: 'Antes de los eventos de demanda pico',
  peakShaving: 'Cargas punta',
  percent: 'Porcentaje',
  percentCharged: 'Porcentaje cargado',
  performance: 'Actuación',
  phoneError: 'Ocurrió un error. Vuelva a ingresar el número de teléfono.',
  phoneErrorDuplicate: '​Número telefónico duplicado. Regreso a la lista de números telefónicos.',
  phoneInstructions: 'Ingrese su número de teléfono y le enviaremos un código de verificación por mensaje de texto. Una vez verificado, lo registraremos a fin de que pueda recibir alertas recurrentes por SMS acerca de su cuenta y de sus dispositivos.',
  phoneNumber: 'Número de Teléfono',
  phoneNumbers: 'Números de Teléfono',
  phoneOptOut: 'Usted ha renunciado a recibir mensajes SMS. Para que se lo vuelva a incluir, comuníquese a {supportEmail}.',
  phonePlaceholder: {
    america: 'Ejemplo: (555) 555-5555',
    australia: 'Ejemplo: 0555 555 555',
  },
  phoneToolTip: 'Para recibir mensajes de texto, necesita tener un número de teléfono en su expediente. Haga clic en el enlace de arriba.',
  phoneVerifyHead: 'Verifique el número telefónico',
  phoneVerifyIntro: 'Al verificar los números telefónicos nos permite enviar de manera segura información importante de próximos eventos, rendimiento de la participación e instrucciones para reclamar cualquier incentivo del programa. Por favor, verifique el número telefónico que proporcionó durante el proceso de inscripción.',
  phoneVerifyTooltip: 'Este número telefónico fue ingresado durante el proceso de inscripción. Puede añadir mas números telefónicos en la página de Perfil de la cuenta.',
  power: 'Energía',
  precipitation: 'precipitación',
  preferenceInstructions: 'Elija cómo le gustaría que lo contacten.',
  preferences: 'Preferencias',
  presentCapacity: 'Capacidad Actual',
  priorityBackup: 'Respaldo prioritario',
  profile: 'Perfil',
  programImpact: 'Impacto del programa',
  providerWelcome: 'Bienvenido a {providerName}',
  pvPower: 'Energía fotovoltaico',
  pvSmoothing: 'Regulador fotovoltaico',
  pvSmoothingRate: 'Tasa de regulador fotovoltaico',
  ratePercent: 'Tasa (%)',
  relative: 'Relativo',
  remoteArbitrage: 'Arbitraje remoto',
  reply: 'RESPONDER',
  resendCode: 'Reenviar código',
  resetPassword: 'Restablecer contraseña',
  reviseButton: 'REVISAR',
  right: 'derecha',
  run: 'Ejecutar',
  runtime: 'Tiempo(s) de ejecución',
  runtimeTstat: 'TIEMPO DE EJECUCIÓN TOTAL',
  safetyShutdown: 'Apagado de seguridad',
  save: 'Guardar',
  saved: 'Guardado',
  saving: 'Guardando',
  schedule: 'Horario',
  scheduleDays: 'Días del plan',
  scheduleDeviceMode: 'Modo del dispositivo',
  scheduleError: 'Este horario no tiene suficiente información para ejecutar.',
  scheduleHeader: 'Horarios',
  scheduleName: 'Nombre del plan',
  scheduleSetpoint: 'Ajuste de temperatura',
  scheduleStartTime: 'Hora de comienzo',
  scheduleTabTitle: 'Plan del dispositivo',
  search: 'buscar mensajes',
  secureLinkHeader: 'Use Secure Link',
  secureLinkSignIn: 'Inicie la sesión con un enlace especial que se envió al correo electrónico ingresado a continuación.',
  security: 'Seguridad',
  selectHWH: '¿Qué calentador de agua le gustaría que ejecute este horario?',
  selfSupply: 'Autoabastecimiento',
  sell: 'Vender',
  send: 'Enviar',
  sendCode: 'Enviar código',
  sending: 'Volviendo a enviar…',
  sendPIN: 'Enviarme un PIN',
  sendSecureLink: 'Enviar Secure Link',
  sent: 'Enviado',
  setFavorite: 'Configurar como mensaje favorito',
  setPassword: 'Configurar contraseña',
  setpoint: 'Punto de ajuste',
  setpointControl: 'Control de punto de ajuste',
  setTemp: 'Configurar la temperatura',
  setTemperature: 'Configurar la temperatura',
  settings: 'Configuración',
  setUpProfile: 'Configure su perfil',
  signIn: 'Iniciar sesión',
  simulatedPV: 'Fotovoltaico simulado automático',
  sleep: 'Suspensión',
  sms: 'SMS',
  standard: 'Estándar',
  standby: 'Espera',
  status: 'Estado',
  subject: 'Asunto',
  submitButton: 'Enviar',
  successfulOptout: 'Exclusión exitosa',
  switchDevice: 'Dispositivo de conmutación',
  tankTemp: 'Temperatura del depósito',
  tankTemperature: 'Temperatura del depósito',
  temperature: 'temperatura',
  time: 'Tiempo',
  to: 'Para',
  totalEnergy: 'Total de energía ahorrada',
  totalOptouts: 'TOTAL DE EVENTOS EXCLUIDOS',
  totalParticipation: 'Participación total',
  tryAgain: 'Intente de nuevo',
  twoFactorAuth: 'Autenticación de dos factores',
  twoFactorInstructions: 'Verifique su identificación al ingresar el código de 6 dígitos de la aplicación de autenticación en su dispositivo.',
  unableToSend: 'Unable to Send Code',
  unit: 'Unidad',
  unknown: 'Desconocido',
  unreachable: 'Inaccesible',
  unsetFavorite: 'Deshabilitar como favorito',
  until: 'hasta',
  unverified: 'Sin verificar',
  updated: 'actualizado',
  updateDevice: 'Actualizar el dispositivo',
  updateProfile: 'Actualizar perfil',
  userDefined: 'Definido por el usuario',
  userParticipation: 'Su participación en el evento',
  userResults: 'xx de xx',
  userStandings: 'Sus posiciones',
  vacation: 'Vacaciones',
  vehicleData: 'Datos del vehículo',
  vehicleDetails: 'Detalles del vehículo',
  verificationCode: 'Código de verificación',
  verified: 'Verificado',
  verifiedToast: {
    title: 'Su telefono esta verificado',
    body: 'Se ha verificado el número telefónico y se utilizará para comunicar información importante de su Servicio público. Puede añadir más números telefónicos en la página de Perfil de la cuenta.',
  },
  verifyFailToast: {
    title: '¡Código de verificación incorrecto!',
    body: 'Ha ingresado un código de verificación incorrecto. Puede volver a intentar ingresar el código de verificación a continuación o pedir que se envíe un código nuevo a su teléfono haciendo clic en el botón "Volver a enviar código".',
  },
  verifyNumber: 'Verificar',
  voice: 'Vox',
  voltVar: 'Control volt/var',
  warning: 'Advertencia',
  watts: 'Vatios',
  weatherConditions: 'Condiciones climáticas actuales',
  weatherForecast: 'Pronóstico del clima',
  welcome: 'Bienvenido a casa',
  welcomeMessage: 'Nos complace que usted esté aquí. Ya puede empezar a ahorrar energía (¡y dinero!) cada mes al conectar los dispositivos inteligentes de su casa.',
  wind: 'viento',
  writeReply: 'Responder',
  wrongContactInfo: 'Si esta no es su información de contacto, por favor ignore esta página ya que, probablemente, el mensaje asociado con esta página se le reenvió a usted.',
  yourDevicesHaveBeenOptedOut: 'Sus dispositivos han sido excluidos del evento',
};
