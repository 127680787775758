import Vue from 'vue';
import store from './store';
import App from './App.vue';
import router from './router';
import Components from '@/components';
import VueComponentLibrary from '@virtual-peaker/vue-component-library';
import VueGtag from 'vue-gtag';
import i18n from './i18n';

// Generated by Vue-Cli
Vue.config.productionTip = false;

// Bind vue-component-library#1.0.0+ to global Vue components;
const vclConfig = {
  store,
  config: {
    app: 'home',
    apiHost: window.gConfig.host,
    pid: window.cConfig ? window.cConfig.id : 'default',
  },
  i18n,
};
Vue.use(VueComponentLibrary, vclConfig);

// Configure Vue (Google) Analytics
if (window.gConfig.gaCode) {
  Vue.use(VueGtag, {
    config: { id: window.gConfig.gaCode },
  }, router);
}

// Bind components/ to global Vue components;
Vue.use(Components);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
