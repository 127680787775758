<template>
  <div v-if="!singleFigure" class="figure-panel" :style="style">
    <div class="figure-panel__upper">
      <div class="figure-panel__label">{{ upper.label }}</div>
      <div class="figure-panel__value">{{ upper.value }} <span class="figure-panel__symbol">{{ upper.symbol }}</span></div>
    </div>
    <div class="figure-panel__lower">
      <div class="figure-panel__label">{{ lower.label }}</div>
      <div class="figure-panel__value">{{ lower.value }} <span class="figure-panel__symbol">{{ lower.symbol }}</span></div>
    </div>
  </div>
  <div v-else class="figure-panel" :style="style">
    <div  class="figure-panel__single">
      <div class="figure-panel__label">{{ upper.label }}</div>
      <div class="figure-panel__value">{{ upper.value }} <span class="figure-panel__symbol">{{ upper.symbol }}</span></div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'FigurePanel',
  props: {
    args: {
      type: Object,
      required: true,
    },
    brand: {
      type: Object,
      default: () => ({
        color: '#000000',
      }),
    },
  },
  computed: {
    lower() {
      return this.args.lower;
    },
    upper() {
      return this.args.upper;
    },
    style() {
      return {
        '--provider-color': this.brand.color,
      };
    },
    singleFigure() {
      return !this.args.lower;
    },
  },
};

</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.figure-panel {
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column wrap;
  row-gap: 10px;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-style: normal;

  line-height: 1;

  color: #4F4F4F;

  &__label {
    font-size: 10px;
  }
  &__value {
    font-size: 24px;
    color: var(--provider-color);
  }
  &__symbol {
    font-size: 16px;
    color: #4F4F4F;
  }

  &__single &__symbol {
    color: #AFAFAF;
  }

  &__single &__label {
    padding-bottom: 3px;
  }

  @include Tablet--Large {
    row-gap: 24px;

    &__label {
      font-size: 16px;
      line-height: 24px;
    }
    &__value {
      font-size: 32px;
      line-height: 40.16px;
    }
    &__symbol {
      font-size: 32px;
      line-height: 40.16px;
    }
    &__single &__value {
    font-size: 40px;
  }
  }
}
</style>
