import api from '@/common/api';

export default {
  fetchHouseDetails: (store, houseIds) => {
    const promises = houseIds.map(id => api.get(`/house/${id}`));
    Promise.all(promises).then((houseDetails) => {
      houseDetails.sort((a, b) => (a.address > b.address) ? 1 : -1);
      store.commit('setHouseList', houseDetails, { root: true });

      const currentSelectedHouseId = JSON.parse(localStorage.getItem('houseId'));

      // check to make sure that the selectedHouseId set in localStorage is still in the
      // logged in users roles. if not we will select the first house
      const selectedHouse = houseDetails.find((house) => (house.id === currentSelectedHouseId));
      if (!selectedHouse) {
        localStorage.setItem('houseId', houseDetails[0].id);
        return store.commit('setSelectedHouseId', houseDetails[0].id, { root: true });
      } else {
        return store.commit('setSelectedHouseId', currentSelectedHouseId, { root: true });
      }
    });
  },
  fetchPhoneNumbers: (store, accountId) => {
    api.get(`/account/${accountId}/phonenumber`).then((response) => {
      store.commit('setPhoneNumbers', response, { root: true });
    });
  },
  updateAuth: (store, isAuthenticated) => store.commit('setAuth', isAuthenticated),
  logout: () => {
    return api.post('/auth/logout')
      .then(() => {
        localStorage.setItem('location', null);
        localStorage.setItem('pid', null);
        localStorage.setItem('pinUid', null);
        localStorage.setItem('token', null);
        localStorage.setItem('uid', null);
        localStorage.setItem('proByodConfig', null);
        localStorage.setItem('proSettings', null);
        localStorage.setItem('houseId', null);
        return window.location.assign(window.cConfig.homePath || '/');
      });
  },
};
