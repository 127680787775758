<template>
  <div id="app" class="vue-app-container">
    <VpBusy ref="busy" />
    <VpSvgSprite />
    <VpToast ref="fail" mode="fail" />
    <VpToast ref="success" mode="success" />
    <VpToast ref="warning" mode="warning" />
    <VpToast ref="info" mode="info" />
    <VpModalRoot ref="modal" />
    <Shell />
  </div>
</template>

<script>
import { $filter } from '@/common/filter';
import Shell from '@/modules/shell/Shell.vue';
import logonRoutes from '@/modules/authentication/routes';
import { log as $log } from '@/common/log';

let $byodConfig = {};
if ('proByodConfig' in localStorage) {
  if (localStorage.getItem('proByodConfig') !== 'undefined') {
    $byodConfig = JSON.parse(localStorage.getItem('proByodConfig'));
  }
}

export default {
  name: 'App',
  components: { Shell },
  provide() {
    const root = this;
    return {
      $modal: {
        open: (component, props = {}) => this.$refs.modal.open(component, props),
        close: () => this.$refs.modal.close(),
      },
      $toast: {
        show: (name, { title, body, duration, clickEvents }) => {
          // Catch error if component is destroyed before toast is thrown
          if (this.$refs[name]) {
            this.$refs[name].open({
              title: title || `${name}!`,
              body: body || ' ',
              duration,
              clickEvents,
            });
          }
        },
        hide(name) {
          root.$refs[name].close();
        },
      },
      $busy: {
        // set global busy via $busy.toggle(Boolean)
        toggle(value) { root.busy = value; },
        // fetch global busy via $busy.status()
        status: () => root.busy,
      },
      $error: {
        compile(err) {
          const body = [err.message];
          const { data } = err.response;
          if (typeof data === 'string' && data) body.push(`Response: ${data}`);
          else if (typeof data === 'object' && data.msg) body.push(`Response: ${data.msg}`);
          return body.join('<br>');
        },
      },
      $filter,
      $byodConfig,
      $log,
    };
  },
  data() {
    return {
      busy: false,
    };
  },
  created() {
    if (!this.localization) {
      this.$store.commit('updateLocalization', $byodConfig.localization);
    }
    if ('houseId' in localStorage) {
      this.$store.commit('setSelectedHouseId', localStorage.getItem('houseId'));
    }
    // initialize windowWidth and windowHeight, add resize listener
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
  },
  watch: {
    busy(value) { return this.$refs.busy.toggle(value); },
    selectedLanguage(selectedLanguage) {
      document.getElementsByTagName('html')[0].lang = selectedLanguage || 'en';
    },
    isAuth: {
      immediate: true,
      handler(bool) {
        const isLogonRoute = logonRoutes.some(({ path }) => path === this.$route.path);
        // if User authenticated and accessing AppAuthentication, redirect to home
        if (bool && isLogonRoute) return this.$router.push({ name: 'user-home' }).catch(err => $log.error(err.message));

        // handle de-auth / logout
        if (bool && !bool.authenticated) {
          this.$refs.modal.close();
        }
      },
    },
  },
  computed: {
    isAuth() { return this.$store.getters['shell/isAuth']; },
    localization() { return this.$store.getters.localization; },
    selectedLanguage() { return this.$store.getters.selectedLanguage; },
    windowWidth: {
      get() {
        return this.$store.getters.windowWidth;
      },
      set(newWidth) {
        this.$store.commit('updateWindowWidth', newWidth);
      },
    },
    windowHeight: {
      get() {
        return this.$store.getters.windowHeight;
      },
      set(newHeight) {
        this.$store.commit('updateWindowHeight', newHeight);
      },
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles';

#app {
  height: 100%;
}
</style>
