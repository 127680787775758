<template>
  <div v-show="selectedMessageId !== ''" id="messageBodyContainer">
    <div id="messageHeader">
      <div id="messageHeaderInner">
        <vp-kebab-menu v-if="messageCenterMode != 'archived'" id="message-header-kebab" :menu-options="headerKebabOptions" open-by="click" :menu-position="'down-left'" @click="kebabOptionClicked" />
        <div class="messageHeaderSubject">{{ message.subject }}</div>
        <div>
          <div id="messageHeaderDetails">
            {{ $t('from') }}: <div class="messageHeaderSender">{{ $byodConfig.name }}</div>&nbsp; &nbsp;
            {{ $t('to') }}: <span id="messageHeaderRecipient">{{ userFullName }}</span>
          </div>
        </div>

        <div class="messageHeaderDateAndFav">
          <div id="messageHeaderDateTime">{{ messageCreatedAtTime }}</div>
          <div id="messageHeaderFavorite" class="messageHeaderFavorite messageFavorite"
               role="img" :alt="(messageFavorited) ? $t('unsetFavorite') : $t('setFavorite')"
               :class="(messageFavorited) ? 'messageFavorited' : ''"
               @click="favoriteMessage(message)"
          />
        </div>
      </div>
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div id="messageBodyOuter" v-html="message.htmlBody" />
  </div>
</template>
<script>
export default {
  name: 'MessageBody',
  inject: ['$byodConfig'],
  props: {},
  data: function data() {
    return {
      userEmail: localStorage.getItem('email'),
    };
  },
  computed: {
    userFullName() {
      const user = JSON.parse(localStorage.getItem('user')) || {};
      const { firstName, lastName } = user;
      let fullName = '';
      if (firstName !== undefined) { fullName = firstName; }
      if (lastName !== undefined) {
        if (fullName !== '') { fullName += ' '; }
        fullName += lastName;
      }
      return fullName;
    },
    headerKebabOptions() {
      if (this.messageCenterMode === 'inbox') {
        return [{ label: this.$i18n.t('favoriteMessage'), value: 'favorite' }, { label: this.$i18n.t('deleteMessage'), value: 'delete' }];
      }
      if (this.messageCenterMode === 'favorites') {
        return [{ label: this.$i18n.t('deleteMessage'), value: 'delete' }];
      }
      if (this.messageCenterMode === 'archived') {
        return [];
      }
      return [];
    },
    message: {
      get() {
        if (this.$store.getters['messageCenter/selectedMessage'] !== null) {
          return this.$store.getters['messageCenter/selectedMessage'];
        }

        return { subject: '' };
      },
    },
    messageFavorited: {
      get() {
        const msg = this.$store.getters['messageCenter/selectedMessage'];
        if (msg !== null) { return msg.favorite; }
        return 0;
      },
    },
    messages: {
      get() {
        return this.$store.getters['messageCenter/messages'];
      },
    },
    messageCenterMode: {
      get() {
        return this.$store.getters['messageCenter/messageCenterMode'];
      },
    },
    messageCreatedAtTime: {
      get() {
        const created = new Date(this.message.createdAt);
        const month = created.toLocaleString(this.$i18n.locale, { month: 'long' });
        const hours = created.getHours();
        const minutes = (created.getMinutes() < 10 ? '0' : '') + created.getMinutes();
        if (this.$i18n.locale === 'es') {
          return `${created.getDate()} de ${month} de ${created.getFullYear()} ${
            hours > 12 ? (hours - 12) : hours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
        }
        return `${month} ${created.getDate()}, ${created.getFullYear()} ${
          hours > 12 ? (hours - 12) : hours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
      },
    },
    selectedMessageId: {
      get() {
        return this.$store.getters['messageCenter/selectedMessageId'];
      },
      set(newMsgId) {
        this.$store.commit('messageCenter/updateSelectedMessageId', newMsgId);
      },
    },
    selectedMessage: {
      get() {
        return this.$store.getters['messageCenter/selectedMessage'];
      },
      set(newMsg) {
        this.$store.commit('messageCenter/updateSelectedMessage', newMsg);
      },
    },
  },
  methods: {
    favoriteMessage: function favoriteMessage(msg) {
      this.$store.dispatch('messageCenter/setFavorite', { id: msg.uuid, value: !msg.favorite });
    },
    kebabOptionClicked: function kebabOptionClicked(option) {
      if (option.value === 'favorite') {
        this.$store.dispatch('messageCenter/setFavorite', { id: this.selectedMessageId, value: 1 }).then(() => {
        });
      } else if (option.value === 'delete') {
        this.$store.dispatch('messageCenter/deleteMessage', this.selectedMessageId).then(() => {
        });
      }
    },
    messageInfoClicked: function messageInfoClicked(evt) {
      const messageActions = document.getElementById('messageActions');
      if ((window.messageActionsDisplayed === 0) || (window.messageActionsDisplayed === undefined)) {
        messageActions.style.display = 'block';
        window.messageActionsDisplayed = 1;
        evt.stopPropagation();
      } else {
        messageActions.style.display = 'none';
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles';

div#messageBodyContainer {
 display:flex;
 flex-direction:column;
 height:100%;
}

div#messageHeader {
 background-color: $white;
 border-bottom:1px solid $lightgray;
 flex: 0 1 auto;
 padding-left:24px;
 padding-top:18px;
 padding-bottom:16px;
 user-select:none;
}

div.messageHeaderSender {
 background-color:$offwhite;
 border:1px solid $lightgray;
 border-radius:4px;
 color:$darkblue;
 display:inline-block;
 font-size:14px;
 padding:3px 8px 3px 8px;
}

div.messageHeaderSubject {
 color: $charcoal;
 font-size:24px;
 line-height:32px;
 padding-right:36px;
}

div.messageHeaderDateAndFav {
 color:$charcoal;
 float:right;
 font-size:14px;
 margin-right:20px;
 margin-top:-25px;
}

@media (max-width:1225px) {
  div.messageHeaderDateAndFav {
    float:none;
    margin-top:6px;
  }
}

div#messageHeaderDateTime {
 display:inline-block;
 vertical-align: text-bottom;
}

div#messageHeaderFavorite {
 display:inline-block;
 height:15px;
 margin-left:8px;
 width:15px;
}

div#messageBodyOuter {
 background-color: $white;
 box-shadow:0px 2px 4px rgba(0,0,0,0.1);
 color: $charcoal;
 flex: 1 1 auto;
 overflow-y: scroll;
 padding:32px;
}

div#message-header-kebab {
 float:right;
 margin-right:16px;
}

div#messageHeaderDetails {
 color:$charcoal;
 margin-top:8px;
}

#messageHeaderRecipient {
 background-color:$lightblue;
 border:1px solid $borderblue;
 border-radius:4px;
 color:$darkblue;
 font-size:14px;
 margin-left:4px;
 padding:3px 8px 3px 8px;
}

@media (max-width:992px) {
 div#messageHeader {
  padding:16px;
  height:100px;
 }

 div#messageHeaderInner {
  margin-top:16px;
 }

 div#messageBodyOuter {
  margin-top: 16px;
  background-color: $white;
  margin-left:24px;
  margin-right:24px;
  overflow-y: auto;
 }

 div#messageBodyInner {
  border-radius:6px;
 }

 span.messageHeaderSender { display:none; }
 div#messageActions { margin-left:-100px; }
}
</style>
