import Account from './Account.vue';

export default [
  {
    path: '/account',
    redirect: () => ({
      name: 'account',
    }),
  },
  {
    name: 'account-profile',
    path: '/account/profile',
    component: Account,
    props: { tab: 'profile' },
  },
  {
    name: 'account-security',
    path: '/account/security',
    component: Account,
    props: { tab: 'security' },
  },
  {
    name: 'account-preferences',
    path: '/account/preferences',
    component: Account,
    props: { tab: 'preferences' },
  },
];
