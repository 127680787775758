<template>
  <div class="vp-busy" :style="style">
    <div v-if="busy" class="vp-busy__spinner">
      <i class="fas fa-circle-notch fa-spin" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VpBusy',
  inject: ['$byodConfig'],
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    providerColor() {
      return this.$byodConfig.color;
    },
    style() {
      return {
        '--provider-color': this.providerColor,
      };
    },
  },
  methods: {
    toggle(value) {
      this.busy = value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .vp-busy{
    z-index: 9999;
    position: fixed !important;
    top: 50%;
    left: 55%;

    color: var(--provider-color);

  &__spinner{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    i{
      position: absolute;
      right: calc(50% - 50px);
      top: calc(50% - 50px);
      font-size: 100px;
    }
  }
}

.vp-busy__top{
  .vp-busy{
    &__spinner{
      min-height: 70px;
      i{
        top: 20px;
      }
    }
  }
}
</style>
