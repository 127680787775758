<template>
  <Widget class="device-plot" :title="chartTitle">
    <template #controller>
      <VpSelect
        v-model="timeType"
        :options="[{ name: $t('datePickerDay'), value: 'day' }, { name: $t('datePickerWeek'), value: 'week' }, { name: $t('datePickerMonth'), value: 'month' }]"
        aria-label="Timeframe"
        :styles="timeFrameSelectStyle"
      />
      <div @keypress.space.prevent @keyup="keyupHandler">
        <VpChartDatePicker
          ref="picker"
          tabindex="0"
          :type="timeType"
          :locale="locale"
          aria-label="Date Picker"
          @update="updateDates($event, { plotType: type, resources })"
        />
      </div>
    </template>
    <VpScatterChart
      :data="datasets"
      :options="chartOptions"
      :styles="{ height: '350px' }"
      @update="updatePlot($event, { plotType: type, resources: resources })"
    />
  </Widget>
</template>

<script>
import moment from 'moment/moment';
import _ from 'lodash';
import { Widget } from '@/components';

export default {
  name: 'DevicePlot',
  components: { Widget },
  props: {
    context: {
      type: Object,
      default: () => ({
        deviceKind: '',
        deviceMfg: '',
        plotType: '',
        resources: [],
        title: '',
        unit: '',
      }),
    },
    datasets: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    resources: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    enableLongDescription: {
      type: Boolean,
      default: true,
    },
    updatePlot: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      timeType: 'day',
      startTime: null,
      endTime: null,
    };
  },
  watch: {
    locale(newLocale) {
      moment.locale(newLocale);
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.breakpoint;
    },
    chartOptions() {
      let unit;
      let labelString;
      let stepSize;
      let tooltipFormat;
      switch (this.timeType) {
        case 'month':
          unit = 'week';
          labelString = this.$i18n.t('date').charAt(0).toUpperCase() + this.$i18n.t('date').slice(1);
          stepSize = 1;
          tooltipFormat = 'MMM d h:mm a';
          break;
        case 'week':
          unit = 'day';
          labelString = this.$i18n.t('date').charAt(0).toUpperCase() + this.$i18n.t('date').slice(1);
          stepSize = 1;
          tooltipFormat = 'MMM d h:mm a';
          break;
        case 'day':
        default:
          unit = 'hour';
          labelString = this.$i18n.t('time');
          stepSize = 2;
          tooltipFormat = 'h:mm a';
          break;
      }

      return _.merge({}, this.options, {
        title: this.chartTitle,
        start: new Date(this.startTime),
        end: new Date(this.endTime),
        timeRange: this.timeType,
        locale: this.locale,
        xAxis: {
          time: {
            stepSize,
            tooltipFormat,
            unit,
          },
          title: {
            display: true,
            text: labelString,
          },
        },
        graphDescription: {
          display: this.enableLongDescription,
          bottom: '0',
          right: '0',
        },
      });
    },
    chartTitle() {
      if (this.title) {
        return this.title;
      }
      return this.type;
    },
    locale() {
      return this.$i18n.locale;
    },
    timeFrameSelectStyle() {
      const styles = {
        fontSize: '16px',
        width: '100px',
        menu: {
          itemHeight: '45px',
        },
        icon: {
          fontSize: '12px',
        },
      };
      if ((this.breakpoint.substring(0, 6) === 'tablet')) {
        styles.fontSize = '12px';
        styles.menu.itemHeight = '36px';
        styles.icon.fontSize = '10px';
      } else if ((this.breakpoint.substring(0, 6) === 'mobile')) {
        styles.fontSize = '10px';
        styles.menu.itemHeight = '30px';
        styles.icon.fontSize = '8px';
      }
      return styles;
    },
  },
  methods: {
    updateDates({ start, end }, { plotType, resources }) {
      const end_ = moment(end).isValid() ? end : moment(start).endOf().toDate();
      this.startTime = start;
      this.endTime = end_;
      this.updatePlot({ start, end: end_ }, { plotType, resources });
    },
    keyupHandler(event) {
      const { code } = event;
      const target = this.$refs.picker;
      if (code === 'ArrowLeft') {
        target.modifyOffset(-1);
      } else if (code === 'ArrowRight') {
        target.modifyOffset(1);
      } else if (code === 'Space' && this.timeType === 'day') {
        target.$refs['date-picker'].togglePopover();
        event.stopPropagation();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.device-plot {
  max-width: 100%;
  max-height: max-content;

  & > .vp-overlay > .widget__inner {
    height: max-content;
  }

  .widget__header-controller {
    display: flex;
    column-gap: 8px;
    @include Tablet--Large {
      column-gap: 16px;
    }
  }

  .vcl-select {
    width: 100%;
    max-width: 80px;
    max-height: 24px;
    @include Tablet--Large {
      width: 100px !important;
      max-width: unset !important;
      max-height: 35px !important;
    }
  }

  .vcl-input {
    width: 100%;
    max-width: 80px;
    max-height: 24px;
    @include Tablet--Large {
      width: 100px !important;
      max-width: unset !important;
      max-height: 35px !important;
    }
  }

  .vcl-date-picker {
    width: 100%;
    min-width: 135px;
    max-width: 180px;
    max-height: 24px;
    @include Mobile--Only {
      padding: 0 35px 0 27px !important;
    }
    @include Tablet--Large {
      width: 300px !important;
      max-width: unset !important;
      max-height: 35px !important;
    }
  }

  .vcl-scatter-chart__scatter-chart {
    @include Mobile--Only {
      margin-bottom: 10px;
    }
  }
}

p.vcl-date-picker__display {
  @include Mobile--Only {
    font-size: 10px;
    white-space: nowrap;
  }

  @include Tablet {
    font-size: 12px;
    white-space: nowrap;
  }

  @include Desktop {
    font-size: 16px;
  }
}

</style>
