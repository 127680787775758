import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  name: 'device',
  state() {
    return {
      activeDevice: null,
      activeSchedules: [],
      changesPendingReconciliation: false,
      deviceData: [],
      deviceSchedules: [],
      deviceScheduleDetails: [],
      devices: [],
      deviceSettings: [],
      devicesRealtime: [],
      energySavedBySeason: {},
      eventLog: [],
      events: [],
      plotData: {},
    };
  },
  actions,
  getters,
  mutations,
};
