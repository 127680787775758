<template>
  <div class="info-block">
    <div class="info-block-content">
      <div class="info-block-header">
        <div class="info-block-header-inner">{{ header }}</div>
      </div>
      <div class="info-block-info">
        <span class="info-block-info-value">{{ info }}</span>
        <span v-if="unit !== ''" class="info-block-info-unit">{{ unit }}</span>
      </div>
      <div v-if="showFooter" class="info-block-footer">
        <PercentBar v-if="footer === '#percent#'" :value="Number(info)" />
        <div v-else class="info-block-footer-inner">{{ footer }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import PercentBar from '@/components/PercentBar';

export default {
  name: 'InfoBlock',
  components: { PercentBar },
  props: {
    header: {
      default: () => '',
      type: String,
    },
    info: {
      default: () => '',
      type: String,
    },
    unit: {
      default: () => '',
      type: String,
    },
    footer: {
      default: () => '',
      type: String,
    },
  },
  computed: {
    showFooter() {
      if (this.footer !== '') {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/mixins.scss";
.info-block {
  width: 100%;
  height: 75px;

  padding: 6px 0;

  border: 1px solid #AFAFAF;
  box-sizing: border-box;
  border-radius: 6px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;

  &-header {
    display: flex;
    justify-content: center;

    &-inner {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.2rem;
      color: #E3E3E3;
      padding-left:4px;
      padding-right:4px;
      text-transform: uppercase;
    }
  }

  &-info {
    color: #FFFFFF;

    &-unit {
      font-weight: bold;
      font-size: 14px;
      line-height: 32px;
      color: #E3E3E3;
    }

    &-value {
      font-size: 30px;
      line-height: 30px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;

    &-inner {
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: #E3E3E3;
    }
  }

  @include Tablet--XLarge {
    height: 142px;
    line-height: 1;

    &-header {
      min-height: 28px;
      align-items: center;

      &-inner {
        height: max-content;
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
    &-content {
      height: 100%;
      padding: 8px 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
    &-info {
      &-value {
        font-size: 32px;
        line-height: 40.16px;
      }
      &-unit {
        font-size: 16px;
        line-height: 32px;
      }
    }
    &-footer {
      min-height: 28px;
      align-items: center;

      &-inner {
        font-size: 14px;
        line-height: 12px;
      }
    }
  }

  @include Desktop {
    width: 132px;
    height: 95px;

    padding: 8px 2px;

    &-header {
      min-height: 20px;
      &-inner {
        font-size: 0.75rem;
        line-height: 0.9rem;
        padding-left:4px;
        padding-right:4px;
      }
    }

    &-content {
      padding: 0;
    }

    &-info {
      font-size: 24px;

      &-unit {
        font-size: 12px;
        line-height: 32px;
      }

      &-value {
        font-size: 24px;
        line-height: 30.12px;
      }
    }

    &-footer {
      min-height: 20px;
      align-items: center;

      &-inner {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  @include Desktop--Large {
    width: 210px;
    height: 150px;
    padding: 6px 0;

    &-header {
      min-height: 28px;
      &-inner {
        font-size: 1.1rem;
        line-height: 1.3rem;
        padding-left:8px;
        padding-right:8px;
      }
    }

    &-content {
      padding: 8 0;
    }

    &-info {
      font-size: 40px;

      &-unit {
        font-size: 18px;
        line-height: 32px;
      }

      &-value {
        font-size: 40px;
        line-height: 50px;
      }
    }

    &-footer {
      min-height: 28px;
      align-items: center;

      &-inner {
        font-size: 14px;
        line-height: 12px;
      }
    }
  }
}
</style>
