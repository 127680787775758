<template>
  <div class="temperature-panel" :style="style">
    <div class="temperature-panel__reading upper-area">
      <div class="temperature-panel__reading__amount branded" aria-live="polite">{{ actualTemp.value }}°</div>
      <div class="temperature-panel__reading__label">{{ translatedLabel(actualTemp) }}</div>
    </div>
    <div class="temperature-panel__reading lower-area">
      <div class="temperature-panel__reading__amount" aria-live="polite">{{ Number.isNaN(setpoint.value) ? '--' : setpoint.value }}{{ setpoint.degree }}</div>
      <div class="temperature-panel__reading__label">{{ translatedLabel(setpoint) }}</div>
    </div>
    <div class="temperature-panel__controls">
      <div class="temperature-panel__controls__control" :class="disableInput" tabindex="0" role="button" aria-label="Increase setpoint temperature" @click="incrementSetpoint" @keypress.space.prevent @keyup.space="incrementSetpoint">
        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99463 0.629191C7.25307 0.370753 7.51151 0.370753 7.76995 0.629191L14.2309 7.09015C14.3601 7.21937 14.3601 7.60703 14.2309 7.86546L12.8095 9.28687C12.5511 9.41609 12.1634 9.41609 12.0342 9.28687L7.38229 4.63499L2.7304 9.28687C2.60118 9.41609 2.21352 9.41609 1.95508 9.28687L0.533675 7.86546C0.275237 7.60703 0.275237 7.34859 0.533675 7.09015L6.99463 0.629191Z" :fill="providerColor" />
        </svg>
      </div>
      <div class="temperature-panel__controls__control" :class="disableInput" tabindex="0" role="button" aria-label="Decrease setpoint temperature" @click="decrementSetpoint" @keypress.space.prevent @keyup.space="decrementSetpoint">
        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.46087 8.8752C7.20243 9.13364 6.944 9.13364 6.68556 8.8752L0.2246 2.41425C0.0953808 2.28503 0.0953808 1.89737 0.2246 1.63893L1.64601 0.21752C1.90445 0.0883007 2.29211 0.0883007 2.42133 0.21752L7.07322 4.86941L11.7251 0.21752C11.8543 0.0883007 12.242 0.0883007 12.5004 0.21752L13.9218 1.63893C14.1803 1.89737 14.1803 2.15581 13.9218 2.41425L7.46087 8.8752Z" :fill="providerColor" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemperaturePanel',
  inject: ['$filter', '$toast', '$log'],
  props: {
    args: {
      type: Object,
      required: true,
    },
    brand: {
      type: Object,
      default: () => ({
        color: '#000000',
      }),
    },
  },
  computed: {
    actualTemp() {
      const { value, ...other } = this.args.actualTemp;
      return { value: Math.round(value), ...other };
    },
    deviceId() {
      return this.args.deviceId;
    },
    disableInput() {
      return this.args.disableInput ? 'input-disabled' : '';
    },
    providerColor() {
      return this.brand.color;
    },
    setpoint() {
      const { value, ...other } = this.args.setpoint;
      const numericValue = Math.round(value);
      const degree = Number.isNaN(numericValue) ? '' : '°';
      return { degree, value: Number.isNaN(numericValue) ? value : numericValue, ...other };
    },
    style() {
      return {
        '--provider-color': this.providerColor,
      };
    },
  },
  methods: {
    decrementSetpoint() {
      if (!this.disableInput) {
        this.enqueueCommand(this.setpoint.key, (this.setpoint.value - 1));
      }
    },
    enqueueCommand(key, value) {
      return this.$store.dispatch('device/enqueueCommand', { deviceId: this.deviceId, key, value })
        .catch((err) => {
          this.$log.error(err);
          this.$toast.show('fail', {
            title: 'Failed to make change to device',
            body: err.message,
          });
        });
    },
    incrementSetpoint() {
      if (!this.disableInput) {
        this.enqueueCommand(this.setpoint.key, (this.setpoint.value + 1));
      }
    },
    translatedLabel(actualTemp) {
      return this.$i18n.t(this.$filter.camelize(actualTemp.label));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
  .temperature-panel {
    width: 100%;
    height: 100%;

    padding: 3px 8px;

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "upper controls"
      "lower controls";

    .branded {
      color: var(--provider-color) !important;
    }
    .upper-area {
      grid-area: upper;
    }
    .lower-area {
      grid-area: lower;
    }

    &__reading {
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      align-self: center;
      justify-self: center;

      &__amount {
        font-size: 28px;
        color: #4F4F4F;
      }
      &__label {
        font-weight: bold;
        font-size: 8px;
        color: #4F4F4F;
        text-transform: uppercase;
      }
    }
    &__controls {
      grid-area: controls;
      align-self: center;
      justify-self: center;

      .input-disabled {
        filter: grayscale(1);
        svg {
          path {
            fill: #AFAFAF;
          }
        }
      }

      &__control:first-of-type {
        margin-bottom: 12.84px;
      }
      &__control {
        cursor: pointer;
        width: 26.75px;
        height: 26.75px;

        background: #FFFFFF;
        border: 1px solid #DBDBDB;
        box-sizing: border-box;
        border-radius: 4px;
      }
      &__control:focus {
        outline: 2px solid #016699;
      }
    }

    @include Tablet--Large {
      .upper-area {
        .temperature-panel__reading__amount {
          font-size: 48px;
        }
      }
      &__reading {
        &__amount {
          font-size: 32px;
        }
        &__label {
          font-weight: 700;
          font-size: 12px;
        }
      }
      &__controls {
        &__control:first-of-type {
          margin-bottom: 24px;
        }
        &__control {
          width: 50px;
          height: 50px;
          padding: 10px;
        }

        svg {
          transform: scale(1.87);
        }
      }
    }
  }
</style>
