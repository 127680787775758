import mutations from './store/mutations';
import actions from './store/actions';
import getters from './store/getters';

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

function loadStores() {
  const context = require.context('@/modules/', true, /store\.js$/i);
  return context.keys()
    .map(context)
    .map(m => m.default);
}

const modules = {};

const moduleStores = loadStores();
moduleStores.forEach((store) => {
  modules[store.name] = store;
});

export default new Vuex.Store({
  strict: debug,
  modules,
  state: {
    breakpoint: '',
    hasTouchScreen: false,
    houseList: [],
    linkedAccounts: [],
    localizedFormat: {},
    phoneNumbers: [],
    proSettings: {},
    selectedHouseId: null,
    user: {},
    selectedLanguage: '',
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  },
  actions,
  getters,
  mutations,
});
