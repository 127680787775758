import api from '@/common/api';

export default {
  deletePhoneNumber: (store, params) => {
    const { accountId, phone, countryCode } = params;
    api.delete(`/account/${accountId}/phonenumber/${phone}?countryCode=${countryCode}`).then((response) => {
      if (response) {
        store.commit('removePhoneNumber', { phone, countryCode });
      }
    });
  },
  getProviderSettingsForHouse(store, houseId) {
    const url = `/house/${houseId}/provider-settings`;
    return api.get(url)
      .then(results => {
        if (results !== null) {
          store.commit('setProSettings', results);
          localStorage.setItem('proSettings', JSON.stringify(results));
        }
      })
      .catch(error => Promise.reject(error));
  },
};
