<template>
  <div class="progress-bar">
    <div class="progress-bar__title">
      <p v-if="title">{{ title }}</p>
      <span v-if="subTitle">({{ subTitle }})</span>
    </div>
    <div class="progress-bar__container">
      <div class="progress-bar__line" :style="{ backgroundColor: color, width: progress }" />
      <span v-if="label !== null" class="progress-bar__label">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    color: { type: String, default: null },
    label: { type: [String, Number], default: null },
    progress: { type: [String, Number], default: '0%' },
    title: { type: String, default: null },
    subTitle: { type: String, default: null },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
.progress-bar {
  &__title {
    display: flex;
    flex-direction: column;
    @include Tablet--Large {
      gap: 0.25rem;
      flex-direction: row;
      margin-bottom: 0.25rem;
    }
    span {
      font-size: 6px;
      @include Tablet--Large {
        font-size: unset;
      }
    }
  }
  &__container {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
  &__line {
    height: 0.5rem;
    border-radius: 2px;
    @include Tablet--Large {
      height: 0.75rem;
    }
  }
  &__label {
    flex: 1 1 100%;
    font-size: 0.5rem;
    line-height: 100%;
    font-weight: bold;
    @include Tablet--Large {
      font-size: 0.75rem;
    }
  }
}
</style>
