export default {
  breakpoint: (state) => {
    return state.breakpoint;
  },
  hasTouchScreen: (state) => {
    return state.hasTouchScreen;
  },
  houseList: (state) => {
    return state.houseList;
  },
  localization: (state) => {
    return state.localizedFormat.localization;
  },
  phoneNumbers: (state) => {
    return state.phoneNumbers;
  },
  proSettings: (state) => {
    return state.proSettings;
  },
  selectedHouseId: (state) => {
    return state.selectedHouseId;
  },
  selectedLanguage: (state) => {
    return state.selectedLanguage;
  },
  user: (state) => {
    return state.user;
  },
  windowWidth: (state) => {
    return state.windowWidth;
  },
  windowHeight: (state) => {
    return state.windowHeight;
  },
};
