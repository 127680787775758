<template>
  <div v-if="isReady" class="dashboard">
    <UpperDashboard />
    <LowerDashboard />
  </div>
</template>

<script>
import WelcomeModal from './modals/WelcomeModal.vue';
import moment from 'moment/moment';
import momentTimezone from 'moment-timezone'; // eslint-disable-line no-unused-vars
import UpperDashboard from './UpperDashboard.vue';
import LowerDashboard from './LowerDashboard.vue';

const $filter = {
  Date: (value, string) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone) {
      return moment(value).tz(timeZone).format(string);
    }
    return moment(value).format(string);
  },
};

export default {
  name: 'Dashboard',
  components: { UpperDashboard, LowerDashboard },
  inject: ['$byodConfig', '$modal', '$toast', '$busy', '$log'],
  data() {
    return {
      eventToastLog: {},
      ready: false,
      refreshTimeout: {
        id: null,
        delay: 10000, // ms
        enable: true,
      },
      activeEvents: [],
      upcomingEvents: [],
    };
  },
  computed: {
    $filter() {
      return $filter;
    },
    allEvents() {
      return [].concat(this.activeEvents, this.upcomingEvents)
        .sort((a, b) => a.startTime - b.startTime);
    },
    firstName() {
      const user = JSON.parse(localStorage.getItem('user')) || {};
      return user.firstName;
    },
    isReady() {
      return this.ready;
    },
    selectedHouseId() {
      return this.$store.getters.selectedHouseId;
    },
  },
  watch: {
    selectedHouseId: {
      handler(newId, oldId) {
        if (newId !== oldId) {
          this.$busy.toggle(true);
          this.fetchData({ repeat: false })
            .finally(() => {
              this.$busy.toggle(false);
            });
        }
      },
    },
  },
  mounted() {
    this.init();
  },
  updated() {
    this.$store.commit('dashboard/locale', this.$i18n.locale);
  },
  destroyed() {
    clearTimeout(this.refreshTimeout.id);
  },
  methods: {
    init() {
      const user = JSON.parse(localStorage.getItem('user')) || {};

      const welcomeModalDisplayed = localStorage.getItem('welcomeModalDisplayed') || 0;
      if ((user.sessionCount !== undefined) &&
          (user.sessionCount === 0) &&
          this.$byodConfig.homeAppConfig &&
          this.$byodConfig.homeAppConfig.welcomeModalEnabled &&
          (welcomeModalDisplayed === 0)) {
        this.$modal.open(WelcomeModal);
        localStorage.setItem('welcomeModalDisplayed', 1);
      }
      this.$busy.toggle(true);
      this.fetchData({ repeat: true })
        .finally(() => {
          this.ready = true;
          this.$busy.toggle(false);
        });
    },
    fetchData(options) {
      const { repeat } = options;
      const promises = [
        this.$store.dispatch('event/fetchActiveEvents')
          .then((activeEvents) => {
            this.activeEvents = activeEvents;
            return this.$store.dispatch('event/fetchUpcomingEvents');
          })
          .then((upcomingEvents) => {
            this.upcomingEvents = upcomingEvents;
            if (this.allEvents && this.allEvents.length) {
              // TODO: Multiple event support
              const targetEvent = this.allEvents[0];
              const { id: eventId, deviceEventLogs: { length: showOptout } = {} } = targetEvent;
              if (!(eventId in this.eventToastLog)) {
                this.showEventToast(this.allEvents[0], { showOptout });
                this.eventToastLog[eventId] = new Date();
              }
            }
          }),
        this.$store.dispatch('device/fetchAllDevices')
          .then(() => this.$store.dispatch('device/fetchAllDeviceSchedules')),
      ];
      return Promise.all(promises)
        .catch((err) => {
          this.$log.error(err);
        })
        .finally(() => {
          if (repeat && this.refreshTimeout.enable) {
            this.refreshTimeout.id = setTimeout(this.fetchData, this.refreshTimeout.delay, { repeat });
          }
        });
    },
    formatDate(value, type) {
      const locale = this.$i18n.locale;
      if (type === 'time') {
        return this.$filter.Date(value, 'hh:mm A zz', locale);
      }
      if (type === 'date') {
        return this.$filter.Date(value, 'MMMM DD', locale);
      }
    },
    showEventToast(event, options) {
      const { startTime, endTime, id: eventId } = event;
      const { showOptout } = options;
      let body, title;

      if (this.activeEvents.findIndex(event => event.id === eventId) !== -1) {
        body = `${this.firstName}, ${this.$t('eventToastActiveBody')} ${this.formatDate(startTime, 'time')} ${this.$t('until')} ${this.formatDate(endTime, 'time')}.`;
        title = this.$t('eventToastActiveTitle');
      } else if (this.upcomingEvents.findIndex(event => event.id === eventId) !== -1) {
        body = `${this.firstName}, ${this.$t('eventToastUpcomingBody')} ${this.formatDate(startTime, 'date')} ${this.$t('from')} ${this.formatDate(startTime, 'time')} ${this.$t('until')} ${this.formatDate(endTime, 'time')}.`;
        title = this.$t('eventToastUpcomingTitle');
      }
      if (showOptout) {
        body = body.concat(' ', this.$t('eventToastOptout'));
      }

      this.$toast.show('info', {
        title,
        body,
        duration: 10000, // ms
        clickEvents: {
          '.event-optout': () => {
            this.$store.dispatch('event/optoutAllDevices', { event })
              .then(() => {
                this.$toast.show('success', {
                  title: this.$t('successfulOptout'),
                  body: this.$t('yourDevicesHaveBeenOptedOut'),
                });
                this.$store.dispatch('event/fetchActiveEvents');
              })
              .catch((err) => {
                this.$log.error(err);
                this.$toast.show('fail', {
                  title: this.$t('failedToOptoutOfEvent'),
                  body: err.toString(),
                });
              })
              .finally(() => {
                this.$toast.hide('info');
              });
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
</style>
