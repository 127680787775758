<template>
  <div class="device-energy-graph">
    <VpSvgSprite />
    <div class="device-energy-header">
      <div class="cell dashboard-header">{{ titleString }}</div>
    </div>
    <div class="graph--container">
      <VpScatterChart
        :data="chartData"
        :options="chartOptions"
        :styles="chartStyles"
        @update="update($event)"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeviceEnergyGraph',
  // inject: ['$busy', '$toast', '$filter'],
  props: {
    devices: {
      type: Array,
      default: () => ([]),
    },
    screenSize: {
      type: String,
      default: () => 'small',
    },
  },
  data() {
    return {
      deviceDataById: {},
      boundaries: { yAxis1: { min: 0, max: 1 }, yAxis2: { min: 0, max: 1 } },
      minDataPoints: { yAxis1: new Set(), yAxis2: new Set() },
      maxDataPoints: { yAxis1: new Set(), yAxis2: new Set() },
    };
  },
  computed: {
    deviceKinds() {
      const deviceKind = [];
      const { devices = [] } = this;
      devices.forEach(({ kind }) => {
        if (!(deviceKind.includes(kind))) deviceKind.push(kind);
      });
      return deviceKind;
    },
    chartContext() {
      const { titleString, dateRange, deviceKinds, chartData } = this;

      const graphUnit = [];
      chartData.forEach(({ data }) => {
        if (data && Array.isArray(data)) {
          data.forEach(({ unitOfMeasure }) => {
            if (!(graphUnit.includes(unitOfMeasure))) graphUnit.push(unitOfMeasure);
          });
        }
      });

      const chartContext = {
        title: titleString,
        plotType: 'energy',
        plotStartTime: dateRange.start,
        plotEndTime: dateRange.end,
        deviceKinds,
        graphUnit,
      };

      return chartContext;
    },
    chartData() {
      const { devices, timeType } = this;
      // reset scaling when chart data is updated
      this.minDataPoints.yAxis1.clear();
      this.maxDataPoints.yAxis1.clear();
      this.minDataPoints.yAxis2.clear();
      this.maxDataPoints.yAxis2.clear();

      const groupedData = [];
      this.deviceKinds.forEach((deviceKind) => {
        let yAxisID = 'yAxis1';

        const devicesOfKind = devices.filter(device => {
          if (device.kind === deviceKind) {
            // Set yAxisID
            if (device.kind === 'tstat' && device.mfg !== 'mysa') {
              yAxisID = 'yAxis2';
            }
            return true;
          }
          return false;
        });
        groupedData.push({ deviceKind, yAxisID, numberOfDevices: devicesOfKind.length, data: this.aggregateDeviceData(devicesOfKind) });
      });
      const chartData = groupedData.map((itemToPlot, index) => {
        const { deviceKind, yAxisID, numberOfDevices, data } = itemToPlot;

        const { label, pointRadius, color } = this.generateCommonChartDataConfig({ numberOfDevices, deviceKind, timeType, index });

        return {
          label,
          data,
          backgroundColor: color,
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointRadius,
          legend: { inactiveFontColor: color, activeFontColor: '#444' },
          unit: (data[0] || {}).unitOfMeasure,
          yAxisID,
        };
      });

      this.setScales();

      return chartData;
    },
    chartOptions() {
      const { locale, dateRange, scales, layout } = this;
      const { xAxis, yAxes: [yAxis1 = {}, yAxis2 = {}] = [] } = scales;
      const { start, end } = dateRange;
      const chartOptions = {
        title: this.titleString,
        start,
        end,
        timeRange: this.timeType,
        locale,
        xAxis,
        yAxis1,
        yAxis2,
        layout,
        crosshair: {
          line: {
            color: '#FFFFFF',
            width: 2,
          },
        },
        graphDescription: {
          display: true,
        },
        includeExport: false,
        updateInterval: 30000,
      };

      return chartOptions;
    },
    chartStyles() {
      return {
        legends: {
          position: 'center',
          margin: '10px 0 2px',
        },
        tooltip: {
          body: {
            squareBorder: 'solid #444 1px',
          },
        },
        graphDescription: {
          fontColor: '#fff',
          right: '8px',
          bottom: '10px',
          purpose: 'energy',
          fontSize: '0.61em',
        },
        emptyState: {
          backgroundColor: '#444',
          fontColor: '#fff',
          border: 'solid #fff 1px',
          fontSize: '24px',
        },
      };
    },
    layout() {
      const { screenSize } = this;
      const layout = {
        padding: {},
      };

      switch (screenSize) {
        case 'large':
          layout.padding = {
            top: 6,
            bottom: 16,
            left: 8,
          };
          break;
        case 'mediumLarge':
          layout.padding = {
            top: 6,
            bottom: 16,
            left: 8,
          };
          break;
        case 'medium':
          layout.padding = {
            top: 6,
            left: 8,
          };
          break;
        default:
          layout.padding = {
            top: 6,
          };
          break;
      }

      return layout;
    },
    scales() {
      const { devices, timeType } = this;

      const displayPowerScale = !!devices.find(({ kind, mfg }) => kind !== 'tstat' || mfg === 'mysa'); // y
      const displayRuntimeScale = !!devices.find(({ kind, mfg }) => kind === 'tstat' && mfg !== 'mysa'); // y

      /*
        scale configuration
      */

      const genericScale = {
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)',
          borderWidth: 1,
          tickLength: 5,
          borderColor: '#FFFFFF',
        },
      };
      const genericXScale = {
        // A scale intended for, but not strictly limited to, use on the X-axis
        ...genericScale,
        ticks: {
          source: 'auto',
          autoSkip: true,
          maxTicksLimit: 4,
          minRotation: 0,
          maxRotation: 0,
          color: '#FFFFFF',
          font: {
            size: 12,
          },
        },
        title: {
          display: false,
          text: this.$i18n.t('time'),
          color: '#FFFFFF',
          font: {
            size: 16,
          },
        },
      };
      const genericYScale = {
        // A scale intended for, but not strictly limited to, use on the Y-axis
        ...genericScale,
        title: {
          display: true,
          color: '#FFFFFF',
          text: '',
        },
        ticks: {
          source: 'auto',
          autoSkip: true,
          maxTicksLimit: 12,
          minRotation: 0,
          color: '#FFFFFF',
          font: { size: 12 },
        },
      };

      // Timeline Scale
      const timelineScale = {
        ...genericXScale,
        id: 'timeline',
        display: true,
        type: 'time',
        distribution: 'series',
        time: {
        },
      };
      switch (timeType) {
        case 'month':
          timelineScale.time.unit = 'week';
          timelineScale.time.tooltipFormat = this.locale === 'es' ? 'd MMM h:mm: a' : 'MMM d h:mm a';
          timelineScale.title.text = this.$i18n.t('date');
          break;
        case 'week':
          timelineScale.time.unit = 'day';
          timelineScale.time.tooltipFormat = this.locale === 'es' ? 'd MMM h:mm: a' : 'MMM d h:mm a';
          timelineScale.title.text = this.$i18n.t('date');
          break;
        case 'day':
        default:
          timelineScale.time.unit = 'hour';
          timelineScale.title.text = this.$i18n.t('time');
          break;
      }

      // Power Scale
      const powerScale = {
        ...genericYScale,
        id: 'power',
        position: 'left',
        display: displayPowerScale,
        min: this.boundaries.yAxis1.min,
        max: this.boundaries.yAxis1.max,
        title: {
          ...genericYScale.title,
          text: `${this.$i18n.t('power')} (kW)`,
        },
      };

      // Runtime Scale
      const runtimeScalePosition = (() => {
        let runtimeScalePosition = 'left';

        const moreThanOneDevice = devices.length > 1;
        const nonTstatKindPresent = !!devices.find(({ kind, mfg }) => kind !== 'tstat' || mfg === 'mysa');

        if (moreThanOneDevice && nonTstatKindPresent) {
          runtimeScalePosition = 'right';
        }

        return runtimeScalePosition;
      })();
      const runtimeScale = {
        ...genericYScale,
        id: 'runtime',
        position: runtimeScalePosition,
        display: displayRuntimeScale,
        min: this.boundaries.yAxis2.min,
        max: this.boundaries.yAxis2.max,
        title: {
          ...genericYScale.title,
          text: this.$i18n.t('runtime'),
        },
      };

      /*
        axis configuration
      */
      const yAxes = [];
      if (displayPowerScale) yAxes.push(powerScale);
      if (displayRuntimeScale) yAxes.push(runtimeScale);

      return {
        xAxis: timelineScale,
        yAxes,
      };
    },
    titleString() {
      return this.$i18n.t('energyConsumption');
    },
    dateRange() {
      return this.$store.getters['dashboard/energyGraphDateRange'];
    },
    timeType() {
      return this.$store.getters['dashboard/energyGraphTimeType'];
    },
    locale() {
      return this.$store.getters['dashboard/locale'];
    },
  },
  watch: {
    devices() {
      this.update(this.dateRange);
    },
    dateRange(newDateRange) {
      this.update(newDateRange);
    },
    locale() {
      this.update(this.dateRange);
    },
  },
  mounted() {
    this.update(this.dateRange);
  },
  methods: {
    update({ start, end }) {
      const promises = [];

      this.devices.forEach((device) => {
        promises.push(
          this.$store.dispatch('dashboard/getDeviceEnergy', {
            start,
            end,
            deviceId: device.id,
            kind: device.kind,
            mfg: device.mfg,
            hourly: true,
          })
            .then((deviceData = []) => {
              const data = (
                (Array.isArray(deviceData) ? deviceData : [deviceData])
                  .filter(({ unitOfMeasure }) => {
                    if (device.mfg === 'mysa' && unitOfMeasure === 's') return false; // Use only energy data for Mysa devices
                    return true;
                  })
                  .map(({ time, value, unitOfMeasure }) => {
                    const y = Number.parseFloat(value);
                    let yAxisID = device.kind === 'tstat' ? 'yAxis2' : 'yAxis1';
                    if (device.mfg === 'mysa') {
                      yAxisID = 'yAxis1';
                    }
                    this.boundaries[yAxisID].min = this.boundaries[yAxisID].min > y ? y : this.boundaries[yAxisID].min;
                    this.boundaries[yAxisID].max = this.boundaries[yAxisID].max < y ? y : this.boundaries[yAxisID].max;

                    return { x: new Date(time), y, unitOfMeasure };
                  })
              );

              this.$set(this.deviceDataById, device.id, data);
            }),
        );
      });

      return Promise.all(promises);
    },
    aggregateDeviceData(devices) {
      const uniqueYDataValues = new Set();

      if (devices.length <= 1) {
        const device = devices[0];
        // Initialize reactive array property in state for device specific data
        if (!(device.id in this.deviceDataById)) this.$set(this.deviceDataById, device.id, []);
        // get max and min and add for scales
        return this.deviceDataById[devices[0].id].map((deviceData) => ({ ...deviceData, deviceMfg: device.mfg }));
      };
      return devices.reduce((combinedData, currentDevice) => {
        const { id: deviceId, mfg: deviceMfg, kind } = currentDevice;

        // Initialize reactive array property in state for device specific data
        if (!(deviceId in this.deviceDataById)) this.$set(this.deviceDataById, deviceId, []);

        this.deviceDataById[deviceId].forEach((dataPoint) => {
          let { x, y, unitOfMeasure } = dataPoint;
          y = Number.parseFloat(y);
          const dataIndex = combinedData.findIndex((data) => {
            const isTimeMatch = new Date(data.x).getTime() === new Date(x).getTime(); // prevents unnecesary plotting along the x-axis
            const isUnitMatch = data.unitOfMeasure === unitOfMeasure; // prevents unintentional collision between units of measure
            return isTimeMatch && isUnitMatch;
          });
          if (dataIndex === -1) {
            combinedData.push({ x, y, unitOfMeasure, deviceMfg });
            uniqueYDataValues.add(y);
          } else {
            combinedData[dataIndex].y = Number(Number.parseFloat(combinedData[dataIndex].y) + Number.parseFloat(y)).toFixed(3);
            uniqueYDataValues.add(Number(combinedData[dataIndex].y));
          }
        });

        // Get y min/max values to set scales
        // Mysa devices plot energy on yAxis1, we do not use the runtime data for this graph
        if (kind !== 'tstat' || deviceMfg === 'mysa') {
          this.minDataPoints.yAxis1.add(this.getMinDataPoint(Array.from(uniqueYDataValues)));
          this.maxDataPoints.yAxis1.add(this.getMaxDataPoint(Array.from(uniqueYDataValues)));
        } else {
          // tstats
          this.minDataPoints.yAxis2.add(this.getMinDataPoint(Array.from(uniqueYDataValues)));
          this.maxDataPoints.yAxis2.add(this.getMaxDataPoint(Array.from(uniqueYDataValues)));
        }

        return combinedData;
      }, []);
    },
    generateCommonChartDataConfig({
      numberOfDevices,
      deviceKind,
      timeType,
      index,
    }) {
      // Set label used in legend
      let label = '';
      const count = numberOfDevices;
      switch (deviceKind) {
        case 'evse':
          label = this.$i18n.tc('deviceEvCharger', count);
          break;
        case 'battery':
          label = this.$i18n.tc('deviceBattery', count);
          break;
        case 'rac':
          label = this.$i18n.tc('deviceRoomAC', count);
          break;
        case 'hwh':
          label = this.$i18n.tc('deviceWaterHeater', count);
          break;
        case 'tstat':
          label = this.$i18n.tc('deviceThermostat', count);
          break;
        case 'ev':
          label = this.$i18n.tc('deviceEv', count);
          break;
        default:
          label = this.$i18n.t('deviceUnknown');
          break;
      }

      // Set point radius
      const pointRadius = timeType === 'day' ? 4 : 1;

      const color = !(index % 2) ? '#FFFFFF' : '#777777';

      return {
        label,
        pointRadius,
        color,
      };
    },
    getMaxDataPoint(deviceData = []) {
      // Scale should have at least 1 unit for max point
      if (deviceData.length === 0) return 1;
      const maxDataPoint = deviceData.reduce((a, b) => a > b ? a : b);
      return maxDataPoint === 0 ? 1 : maxDataPoint;
    },
    getMinDataPoint(deviceData = []) {
      if (deviceData.length === 0) return 0;
      return deviceData.reduce((a, b) => a < b ? a : b);
    },
    setScales() {
      if (this.minDataPoints.yAxis1.size || this.maxDataPoints.yAxis1.size) {
        this.boundaries.yAxis1.min = Math.min(...this.minDataPoints.yAxis1);
        this.boundaries.yAxis1.max = Math.max(...this.maxDataPoints.yAxis1);
      }
      if (this.minDataPoints.yAxis2.size || this.maxDataPoints.yAxis2.size) {
        this.boundaries.yAxis2.min = Math.min(...this.minDataPoints.yAxis2);
        this.boundaries.yAxis2.max = Math.max(...this.maxDataPoints.yAxis2);
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/mixins.scss';

.device-energy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include Tablet--XLarge {
    justify-content: right;
    // margin-top: -34px; // Vertically recess external chart label
    margin-bottom: 12px;
  }
  @include Desktop {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    width: 576px;
  }
  @include Desktop--Large {
    width: 780px;
  }
}
.device-energy-graph {
  /***** BEGIN: SCATTER CHART STYLES *****/
  .graph--container {
    height: 218px !important;
    border: 1px solid #AFAFAF;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 5px 5px 0;
    margin: 0 0 16px;

    @include Tablet--XLarge {
      height: 142px !important;
      padding: 0 16px 5px 0;
    }

    @include Desktop {
      width: 576px;
      height: 206px !important;
      padding: 0 8px 3px 0;
    }
    @include Desktop--Large {
      width: 780px;
      height: 319px !important;
    }
  }
  /***** END: SCATTER CHART STYLES *****/
}

figure {
 margin: 0 !important;
}
</style>
