<template>
  <div>
    <div class="dashboard-header">{{ $t('monthlyResults', { month: month }) }}</div>
    <div id="info-dashboard">
      <InfoBlock v-for="(block, i) in blocksToDisplay" :key="i" :header="block.header" :info="block.info" :unit="block.unit" :footer="block.footer" />
    </div>
  </div>
</template>

<script>
import InfoBlock from './InfoBlock.vue';

export default {
  name: 'InfoDashboard',
  components: { InfoBlock },
  inject: ['$byodConfig'],
  data() {
    return {
      currentMonthEvents: [],
      devices: [],
      deviceEnergy: [],
      eventRuntimes: [],
      ev: {
        batteryLevel: null,
        batteryRange: null,
      },
    };
  },
  computed: {
    month() { return new Date().toLocaleString(this.$i18n.locale, { month: 'long' }); },
    eventsFooter() { return this.$i18n.t('eventsResults', { total: this.currentMonthEvents.length }); },
    eventsInfo() { return this.currentMonthEvents.filter(event => !event.deviceEventLogs.find(deviceEventLog => deviceEventLog.optout)).length.toString(); },
    eventsOptedOutInfo() { return this.currentMonthEvents.filter(event => event.deviceEventLogs.find(deviceEventLog => deviceEventLog.optout)).length.toString(); },
    evRangeUnitOfMeasurement() {
      if (this.$byodConfig.localization === 'CA') {
        return 'Km';
      } return 'Miles';
    },
    energyEvse() {
      const devices = this.deviceEnergy.filter(item => item.kind === 'evse');
      const energy = {
        value: (devices.reduce((prev, item) => prev + item.energy, 0) / 1000),
        unitOfMeasure: 'kWh',
      };
      energy.value = Math.round(energy.value).toString();
      return energy;
    },
    energyHwh() {
      const devices = this.deviceEnergy.filter(item => item.kind === 'hwh');
      const energy = {
        value: (devices.reduce((prev, item) => prev + item.energy, 0) / 1000),
        unitOfMeasure: 'kWh',
      };
      energy.value = Math.round(energy.value).toString();
      return energy;
    },
    energyBattery() {
      const batteries = this.deviceEnergy.filter(item => item.kind === 'battery');
      const energy = {
        value: (batteries.reduce((prev, item) => prev + item.energy, 0) / 1000),
        unitOfMeasure: 'kWh',
      };
      energy.value = Math.round(energy.value).toString();
      return energy;
    },
    runtimeTstat() {
      const runtimeSeconds = this.deviceEnergy.filter(item => item.kind === 'tstat').reduce((prev, item) => prev + item.runtime, 0);
      return new Date(runtimeSeconds * 1000).toISOString().substr(11, 8);
    },
    eventRuntimeTstat() {
      const runtimeSeconds = this.eventRuntimes.reduce((prev, item) => prev + item.runtime, 0);
      return new Date(runtimeSeconds * 1000).toISOString().substr(11, 8);
    },
    eventEnergyHwh() {
      const events = this.currentMonthEvents.filter(event => event.type === 'hwh');
      const energy = {
        value: (events.reduce((prevEventSum, event) => prevEventSum + event.deviceEventLogs.reduce((prevDeviceSum, deviceEvent) => prevDeviceSum + deviceEvent.eventEnergy, 0), 0) / 1000),
        unitOfMeasure: 'kWh',
      };
      energy.value = Math.round(energy.value).toString();
      return energy;
    },
    eventEnergyEvse() {
      const events = this.currentMonthEvents.filter(event => event.type === 'evse');
      const energy = {
        value: (events.reduce((prevEventSum, event) => prevEventSum + event.deviceEventLogs.reduce((prevDeviceSum, deviceEvent) => prevDeviceSum + deviceEvent.eventEnergy, 0), 0) / 1000),
        unitOfMeasure: 'kWh',
      };
      energy.value = Math.round(energy.value).toString();
      return energy;
    },
    eventEnergyBattery() {
      const events = this.currentMonthEvents.filter(event => event.type === 'battery');
      const energy = {
        value: (events.reduce((prevEventSum, event) => prevEventSum + event.deviceEventLogs.reduce((prevDeviceSum, deviceEvent) => prevDeviceSum + deviceEvent.eventEnergy, 0), 0) / 1000),
        unitOfMeasure: 'kWh',
      };
      energy.value = Math.round(energy.value).toString();
      return energy;
    },
    evBatteryLevel() {
      let evBatteryLevel = '';
      if (this.ev.batteryLevel != null) {
        const { batteryLevel } = this.ev;
        const percentage = Math.round(batteryLevel * 100);
        evBatteryLevel = String(percentage);
      }
      return evBatteryLevel || '0';
    },
    evBatteryRange() {
      let evBatteryRange = '';
      if (this.ev.batteryRange != null) {
        const { batteryRange } = this.ev;
        const rounded = Math.round(this.getLocalizedRange(batteryRange));
        evBatteryRange = String(rounded);
      }
      return evBatteryRange || '0';
    },
    deviceKinds() {
      const deviceKinds = new Set(this.devices.map(device => device.kind));
      return Array.from(deviceKinds);
    },
    availableBlocks() {
      // Universal blocks
      const availableBlocks = {
        eventsInfo: {
          header: this.$i18n.t('eventsSuccess'),
          info: this.eventsInfo,
          unit: '',
          footer: this.eventsFooter,
        },
        eventsOptedOutInfo: {
          header: this.$i18n.t('totalOptouts'),
          info: this.eventsOptedOutInfo,
          unit: '',
          footer: '',
        },
      };

      // Conditional blocks
      if (this.deviceKinds.includes('battery')) {
        availableBlocks.battery = [
          {
            header: this.$i18n.t('eventEnergyBattery'),
            info: this.eventEnergyBattery.value,
            unit: this.eventEnergyBattery.unitOfMeasure,
            footer: '',
          },
          {
            header: this.$i18n.t('energyBattery'),
            info: this.energyBattery.value,
            unit: this.energyBattery.unitOfMeasure,
            footer: '',
          },
        ];
      }
      if (this.deviceKinds.includes('evse')) {
        availableBlocks.evse = [
          {
            header: this.$i18n.t('eventEnergyEvse'),
            info: this.eventEnergyEvse.value,
            unit: this.eventEnergyEvse.unitOfMeasure,
            footer: '',
          },
          {
            header: this.$i18n.t('energyEvse'),
            info: this.energyEvse.value,
            unit: this.energyEvse.unitOfMeasure,
            footer: '',
          },
        ];
      }
      if (this.deviceKinds.includes('hwh')) {
        availableBlocks.hwh = [
          {
            header: this.$i18n.t('eventEnergyHwh'),
            info: this.eventEnergyHwh.value,
            unit: this.eventEnergyHwh.unitOfMeasure,
            footer: '',
          },
          {
            header: this.$i18n.t('energyHwH'),
            info: this.energyHwh.value,
            unit: this.energyHwh.unitOfMeasure,
            footer: '',
          },
        ];
      }
      if (this.deviceKinds.includes('tstat')) {
        availableBlocks.tstat = [
          {
            header: this.$i18n.t('eventRuntimeTstat'),
            info: this.eventRuntimeTstat,
            unit: '',
            footer: '',
          },
          {
            header: this.$i18n.t('runtimeTstat'),
            info: this.runtimeTstat,
            unit: '',
            footer: '',
          },
        ];
      }
      if (this.deviceKinds.includes('ev')) {
        availableBlocks.ev = [
          {
            header: this.$i18n.t('evStateOfCharge'),
            info: this.evBatteryLevel,
            unit: '%',
            footer: '#percent#',
          },
          {
            header: this.$i18n.t('evRemainingRange'),
            info: this.evBatteryRange,
            unit: '',
            footer: this.$i18n.t('evRemainingRangeFooter', { evRangeUnit: this.evRangeUnitOfMeasurement }),
          },
        ];
      }
      return availableBlocks;
    },
    blocksToDisplay() {
      let blocksToDisplay = [];

      const { deviceKinds, availableBlocks } = this;

      switch (deviceKinds.length) {
        case 1:
          if (deviceKinds.includes('ev')) {
            // Float EV blocks to the top
            blocksToDisplay = [
              ...availableBlocks.ev,
              availableBlocks.eventsInfo,
              availableBlocks.eventsOptedOutInfo,
            ];
          } else {
            // Mimic default behavior
            blocksToDisplay = [
              availableBlocks.eventsInfo,
              availableBlocks.eventsOptedOutInfo,
            ];
            deviceKinds.forEach((kind) => {
              if (kind in availableBlocks) {
                blocksToDisplay = blocksToDisplay.concat(availableBlocks[kind]);
              }
            });
          }
          break;
        case 2:
          if (deviceKinds.includes('ev') && deviceKinds.includes('evse')) {
            // Display both EVSE and EV related blocks
            blocksToDisplay = [
              ...availableBlocks.evse,
              ...availableBlocks.ev,
            ];
          } else if (deviceKinds.includes('ev')) {
            // Ensure display of EV related blocks, default for the rest
            blocksToDisplay = [
              ...availableBlocks.ev,
            ];
            deviceKinds.forEach((kind) => {
              if (kind !== 'ev') {
                blocksToDisplay = blocksToDisplay.concat(availableBlocks[kind]);
              }
            });
          } else {
            // Mimic default behavior
            blocksToDisplay = [
              availableBlocks.eventsInfo,
              availableBlocks.eventsOptedOutInfo,
            ];
            deviceKinds.forEach((kind) => {
              if (kind in availableBlocks) {
                blocksToDisplay = blocksToDisplay.concat(availableBlocks[kind]);
              }
            });
          }
          break;
        default:
          // Default behavior
          blocksToDisplay = [
            availableBlocks.eventsInfo,
            availableBlocks.eventsOptedOutInfo,
          ];
          deviceKinds.forEach((kind) => {
            if (kind in availableBlocks) {
              blocksToDisplay = blocksToDisplay.concat(availableBlocks[kind]);
            }
          });
          break;
      }

      return blocksToDisplay.slice(0, 4);
    },
    house() {
      return this.$store.getters['dashboard/house'];
    },
  },
  watch: {
    house(newHouse) {
      if ('id' in newHouse && newHouse.id) {
        this.init();
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // Get current month date range
      const date = new Date();
      date.setDate(1);
      date.setHours(0, 0, 0, 0);
      const start = date.toISOString();
      date.setMonth(date.getMonth() + 1);
      const end = date.toISOString();

      this.$store.dispatch('dashboard/getHouseEvents', { start, end, includeAllPlatoonEvents: true })
        .then((results) => {
          this.currentMonthEvents = results.filter(event => event.category === 'event');
          return this.$store.getters['device/devices'];
        })
        .then((results) => {
          this.devices = results;
          return Promise.all(this.devices.map(device => this.$store.dispatch('dashboard/getDeviceEnergy', {
            kind: device.kind, deviceId: device.id, mfg: device.mfg, start, end,
          })));
        })
        .then((results) => {
          this.deviceEnergy = results;
          const tstatEventRuntimeOpts = this.currentMonthEvents
            .filter(event => event.type === 'tstat')
            .reduce((prev, event) => {
              return prev.concat(event.deviceEventLogs.map(({ deviceId }) => {
                return {
                  deviceId, kind: 'tstat', start: event.startTime, end: event.endTime,
                };
              }));
            }, []);
          return Promise.all(tstatEventRuntimeOpts.map(opts => this.$store.dispatch('dashboard/getDeviceEnergy', opts)));
        })
        .then((results) => {
          this.eventRuntimes = results;
        })
        .then(() => {
          if (this.deviceKinds.includes('ev')) {
            // Find one ev device
            const { id: deviceId = null } = this.devices.find(device => device.kind === 'ev');
            if (deviceId) {
              // Retrieve relevent device data
              return Promise.allSettled([
                this.$store.dispatch('device/fetchDeviceDataByType', { deviceId, dataType: 'ev-battery-level' })
                  .then((levelRes) => {
                    this.ev.batteryLevel = levelRes.value;
                  }),
                this.$store.dispatch('device/fetchDeviceDataByType', { deviceId, dataType: 'ev-battery-range' })
                  .then((rangeRes) => {
                    this.ev.batteryRange = rangeRes.value;
                  }),
              ]);
            }
            return Promise.resolve();
          }
        });
    },
    getLocalizedRange(initialBatteryRange) {
      if (this.$byodConfig.localization === 'CA') {
        const batteryRange = initialBatteryRange * 1.60934;
        return batteryRange;
      }
      return initialBatteryRange;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/mixins.scss";
#info-dashboard {
  width: 100%;
  padding: 0 0 24px;

  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;

  @include Tablet--XLarge {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 16px;
  }

  @include Desktop {
    width: max-content;
    padding: 0;

    place-content: center stretch;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
  }

  @include Desktop--Large {
    row-gap: 19.09px;
    column-gap: 16px;
  }
}
</style>
