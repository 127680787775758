import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  name: 'weather',
  state() {
    return {
      current: {
        temp: 0,
        icon: 'wi wi-fw wi-na',
        windSpeed: 0,
        precip: 0,
        units: '',
      },
      forecast: [],
    };
  },
  actions,
  getters,
  mutations,
};
