<template>
  <Widget class="device-activity" :title="title">
    <div class="device-activity__list">
      <div v-for="item in deviceActivity" :key="item.id" class="device-activity__list__row">
        <span class="device-activity__list__row__action">{{ $t('updated') }}</span>
        <span class="device-activity__list__row__resource">{{ resourceTranslation(item) }}</span>
        <div class="device-activity__list__row__time">{{ item.time }}</div>
      </div>
    </div>
  </Widget>
</template>

<script>
import moment from 'moment/moment';
import { Widget } from '@/components';

export default {
  name: 'DeviceActivity',
  components: { Widget },
  props: {
    deviceData: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      deviceActivity: [],
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    title() {
      return this.$i18n.t('latestActivity');
    },
  },
  methods: {
    calculateDeviceActivity() {
      const results = Array.from(this.deviceData);
      results.sort((a, b) => {
        // Sort in reverse
        return moment(b.time).diff(moment(a.time));
      });
      this.deviceActivity = results.map((item) => {
        // Convert timestamp to description
        const { time, ...others } = item;
        return { time: moment(time).fromNow(), ...others };
      });
    },
    resourceTranslation(item) {
      return this.$i18n.t(item.resource);
    },
  },
  created() {
    moment.locale(this.$i18n.locale);
    this.calculateDeviceActivity();
  },
  watch: {
    locale(newLocale) {
      moment.locale(newLocale);
      this.calculateDeviceActivity();
    },
  },
};
</script>

<style lang="scss">
.device-activity {
  @import '@/assets/styles/mixins.scss';

  width: 434px;
  height: 300px;

  & > .widget__inner {
    background: #FBFBFB;
  }

  &__list {
    height: 100%;
    overflow: scroll;
    padding-right: 16px; // Allow for scroll bar

    &__row {
      margin: 0 0 10px;
      font-size: 16px;
      color: #4F4F4F;
      border-bottom: 1px solid #DBDBDB;
      text-transform: capitalize;
      text-align: left;

      &__action {
        font-size: 12px;
        line-height: 12px;
      }

      &__resource {
        font-size: 12px;
        line-height: 12px;
        margin-left:6px;
      }

      &__time {
        color: #4F4F4F;
        font-size: 8px;
        line-height: 12px;
      }

      @include Tablet--Large {
        &__action {
          font-size: 18px;
          line-height: 20px;
        }

        &__resource {
          font-size: 18px;
          line-height: 20px;
        }

        &__time {
          color: #4F4F4F;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
