<template>
  <div class="battery-panel" :style="style">
    <div class="battery-panel__battery">
      <svg class="battery-panel__battery__icon" width="31" height="52" viewBox="0 0 31 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.35856 3.79534L4.35364 3.82338L4.35032 3.85167L1.76334 25.9248C1.62604 26.8944 1.93288 27.6895 2.41571 28.3778C2.991 29.198 3.77938 29.5689 4.67243 29.5689H13.1253L9.64129 46.2778C9.39971 47.2611 9.50858 48.2203 10.0853 49.0426L10.904 48.4683L10.0853 49.0426C10.6023 49.7795 11.3574 50.343 12.4379 50.343C12.9672 50.343 13.4938 50.2283 13.9701 49.9341C14.4452 49.6406 14.7991 49.2125 15.0561 48.7007C15.0566 48.6997 15.0571 48.6986 15.0577 48.6976L29.4232 20.5181C29.9849 19.5357 29.9459 18.4192 29.4382 17.4059C29.1811 16.8928 28.8267 16.4635 28.3507 16.1694C27.8744 15.8752 27.3478 15.7605 26.8185 15.7605H18.5849L21.076 4.98422C21.3363 4.06338 21.2009 3.10194 20.6386 2.30043C20.1217 1.56349 19.3666 1 18.286 1H7.26094C6.43619 1 5.82665 1.39155 5.36461 1.78676C4.7082 2.34822 4.46506 3.18802 4.35856 3.79534Z" stroke="white" :fill="providerColor" stroke-width="2" />
      </svg>
      <div class="battery-panel__battery__outer">
        <div class="battery-panel__battery__inner" />
        <div class="battery-panel__battery__inner" />
        <div class="battery-panel__battery__inner--00" />
        <div class="battery-panel__battery__inner--50" />
        <div class="battery-panel__battery__inner--50" />
      </div>
      <div class="battery-panel__battery__tip" />
    </div>
    <div class="battery-panel__mode">{{ mode }}</div>
  </div>
</template>

<script>
export default {
  name: 'BatteryPanel',
  props: {
    args: {
      type: Object,
      required: true,
    },
    brand: {
      type: Object,
      default: () => ({
        color: '#000000',
      }),
    },
  },
  computed: {
    mode() {
      return this.args.mode;
    },
    providerColor() {
      return this.brand.color;
    },
    style() {
      return {
        '--provider-color': this.providerColor,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.battery-panel {
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;

  padding: 10px 0;

  text-align: center;
  line-height: 1;

  &__battery {
    position: relative;
    width: 100px;
    margin: 10px 0;
    display: inline-block;
    align-self: center;

    &__outer {
      width: 90.07px;
      height: 35.99px;

      padding: 3px 4px;

      display: inline-grid;
      grid-template-columns: repeat(5, 14px);
      grid-template-rows: 26px;
      column-gap: 2px;

      border: 2px solid var(--provider-color);
      border-radius: 6px;
    }

    &__inner {
      width: 100%;
      height: 100%;

      background: var(--provider-color);

      &--00 {
        background: var(--provider-color);
        opacity: 0;
      }
      &--50 {
        background: var(--provider-color);
        opacity: 0.5;
      }
    }

    &__tip {
      width: 9.24px;
      height: 15.43px;

      margin-left: -6px;
      margin-bottom: 5px;

      display: inline-block;

      border: 2px solid var(--provider-color);
      border-left-width: 0;
      border-radius: 0px 6px 6px 0px;

    }

    &__icon {
      position: absolute;
      left: 32px;
      bottom: -10px;
      z-index: 5;
    }
  }

  &__mode {
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 19px;

    color: #4F4F4F;
  }

  @include Tablet--Large {
    &__battery {
      width: 210px;
      margin: 15px 0;

      &__outer {
        width: 190.24px;
        height: 76.83px;

        padding: 4.5px 5.5px;

        grid-template-columns: repeat(5, 30.49px);
        grid-template-rows: 59.76px;
        column-gap: 5px;

      border: 4px solid var(--provider-color);
      }
      &__tip {
        width: 19.51px;
        height: 32.93px;

        border: 4px solid var(--provider-color);
        border-left-width: 0;

        margin-bottom: 13px;
      }
      &__icon {
        transform: scale(2.11);
        left: 82px;
        bottom: 12px;
      }
    }
    &__mode {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
