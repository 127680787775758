<template>
  <Widget v-if="ready" :title="deviceStatusTitle" class="device-status">
    <template #controller>
      <DeviceStatusIndicator class="device-status__indicator" :brand="{ color: providerColor }" :status="deviceStatus" />
    </template>
    <DeviceControl v-if="device" class="device-status__control" :device="device" :brand="{ color: providerColor }" :enable-header-link="false" :enable-status-indicator="false" @deviceStatusUpdate="setDeviceStatus" />
    <DeviceActivity class="device-status__activity" :device-data="deviceData" :styles="{ innerBackground: '#FBFBFB' }" />
  </Widget>
</template>

<script>
import { Widget } from '@/components';
import DeviceStatusIndicator from '../DeviceStatusIndicator.vue';
import DeviceControl from '../DeviceControl.vue';
import DeviceActivity from './DeviceActivity.vue';

export default {
  name: 'DeviceStatus',
  components: { Widget, DeviceStatusIndicator, DeviceControl, DeviceActivity },
  inject: ['$byodConfig', '$log'],
  data() {
    return {
      deviceStatus: '',
      ready: false,
      reconcileTimeout: {
        id: null,
        delay: 3000, // ms
        enable: true,
      },
      refreshTimeout: {
        id: null,
        delay: 10000, // ms
        enable: true,
      },
      title: 'Device Status',
    };
  },
  computed: {
    device() {
      return this.$store.getters['device/activeDevice'];
    },
    deviceData() {
      const deviceData = this.$store.getters['device/deviceData'];
      if (this.deviceKind === 'hwh') return deviceData.filter(({ resource }) => resource !== 'power');
      return deviceData;
    },
    deviceId() {
      return this.device.id;
    },
    deviceKind() {
      return this.device.kind;
    },
    deviceStatusTitle() {
      if (this.deviceKind === 'ev') {
        return this.$i18n.t('evDeviceStatus');
      }
      return this.$i18n.t('deviceStatus');
    },
    providerColor() {
      return this.$byodConfig.color;
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    // When upgrading to Vue 3, convert this to unmounted.
    clearTimeout(this.refreshTimeout.id);
    clearTimeout(this.reconcileTimeout.id);
  },
  methods: {
    init() {
      this.fetchData({ repeat: true })
        .finally(() => {
          this.ready = true;
        });
      this.reconcilePending({ repeat: true });
    },
    fetchData(options) {
      const { repeat } = options;
      const promises = [
        this.$store.dispatch('device/fetchDeviceData', { deviceId: this.deviceId, deviceKind: this.deviceKind }),
        this.$store.dispatch('event/fetchActiveEvents'),
        this.$store.dispatch('device/fetchAllDevices')
          .then(() => this.$store.dispatch('device/fetchAllDeviceSchedules')),
      ];
      return Promise.all(promises)
        .catch(err => this.$log.error(err))
        .finally(() => {
          if (repeat && this.refreshTimeout.enable) {
            this.refreshTimeout.id = setTimeout(this.fetchData, this.refreshTimeout.delay, { repeat });
          }
        });
    },
    reconcilePending(options) {
      const { repeat } = options;
      return this.$store.dispatch('device/reconcilePending')
        .catch(err => this.$log.error(err))
        .finally(() => {
          if (repeat && this.reconcileTimeout.enable) {
            this.reconcileTimeout.id = setTimeout(this.reconcilePending, this.reconcileTimeout.delay, { repeat });
          }
        })
        .catch(err => this.$log.error(err));
    },
    setDeviceStatus(status) {
      this.deviceStatus = status;
    },
  },
};
</script>

<style lang="scss">
.device-status {
  @import '@/assets/styles/mixins.scss';

  max-width: 100%;
  max-height: unset;
  @media (min-width: 1360px) {
    max-height: 395px;
  }

  & > .widget__header {
    border-bottom: unset;
  }

  & > .vp-overlay > .widget__inner {
    padding-top: 0;
    height: max-content;
    @media (min-width: 1360px) {
      max-height: 324px;
    }
    overflow: visible;

    & > .widget__inner-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 12px;
      @media (min-width: 1360px) {
        width: 1070px;
        flex-flow: row nowrap;
        gap: 24px;
      }
    }
  }

  &__activity {
    min-width: unset;
    max-width: 338px;
    max-height: 161px;
    @include Tablet--Large {
      max-width: 612px;
      max-height: 300px;
    }
    @media (min-width: 1360px) {
      max-width: unset;
      max-height: unset;
    }
  }
}
</style>
