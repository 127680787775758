import DatePickerCustom from '@/components/DatePickerCustom.vue';
import EventCalendar from '@/components/EventCalendar/EventCalendar.vue';
import LanguageButton from '@/components/LanguageButton.vue';
import LeaderBoard from '@/components/LeaderBoard.vue';
import LeaderBoardList from '@/components/LeaderBoardList.vue';
import MarketingMessage from '@/components/MarketingMessage.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import VpBusy from '@/components/VpBusy.vue';
import VpOverlay from '@/components/VpOverlay.vue';
import Widget from '@/components/Widget.vue';

const components = {
  install(Vue) {
    Vue.component('DatePickerCustom', DatePickerCustom);
    Vue.component('EventCalendar', EventCalendar);
    Vue.component('LanguageButton', LanguageButton);
    Vue.component('LeaderBoard', LeaderBoard);
    Vue.component('LeaderBoardList', LeaderBoardList);
    Vue.component('MarketingMessage', MarketingMessage);
    Vue.component('ProgressBar', ProgressBar);
    Vue.component('VpBusy', VpBusy);
    Vue.component('VpOverlay', VpOverlay);
    Vue.component('Widget', Widget);
  },
};

export {
  components as default,
  DatePickerCustom,
  EventCalendar,
  LanguageButton,
  LeaderBoard,
  LeaderBoardList,
  MarketingMessage,
  ProgressBar,
  VpBusy,
  VpOverlay,
  Widget,
};
