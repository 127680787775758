<template>
  <div v-if="ready" class="upper-dashboard">
    <div class="upper-dashboard__deck">
      <div class="upper-dashboard__greeting">
        <h2>{{ $t('welcome') }}{{ firstName ? `, ${firstName}` : '' }}</h2>
        <DeviceEnergyDatepicker v-if="showMetrics && (screenSize == 'large' || screenSize == 'mediumLarge')" />
      </div>
      <div v-if="showMetrics" class="upper-dashboard__metrics-results">
        <hr>
        <VpAccordion :override="accordionOverride">
          <template #header="{ toggle, visible }">
            <div class="accordion__header" :class="{ 'is-open': visible }" @click="toggle">
              <div class="accordion__left">
                <span>{{ $t('monthlyResults', { month: month }) }}</span>
                <vp-icon class="accordion__icon" :icon="visible ? 'chevron-up' : 'chevron-down'" />
              </div>
            </div>
          </template>
          <template #content><InfoDashboard /></template>
        </VpAccordion>
      </div>
      <div v-if="showMetrics" class="upper-dashboard__metrics-consumed">
        <hr>
        <VpAccordion :override="accordionOverride">
          <template #header="{ toggle, visible }">
            <div class="accordion__header" :class="{ 'is-open': visible }" @click="toggle">
              <div class="accordion__left">
                <span>{{ $t('energyConsumption') }}</span>
                <vp-icon class="accordion__icon" :icon="visible ? 'chevron-up' : 'chevron-down'" />
              </div>
            </div>
          </template>
          <template #content>
            <DeviceEnergyDatepicker v-if="showDatePickerInAccordion" />
            <br v-if="showDatePickerInAccordion" />
            <DeviceEnergyGraph :devices="deviceList" :screen-size="screenSize" />
          </template>
        </VpAccordion>
      </div>
      <div v-if="showWeather" class="upper-dashboard__weather-current">
        <hr>
        <VpAccordion :override="accordionOverride">
          <template #header="{ toggle, visible }">
            <div class="accordion__header" :class="{ 'is-open': visible }" @click="toggle">
              <div class="accordion__left">
                <span>{{ $t('weatherConditions') }}</span>
                <vp-icon class="accordion__icon" :icon="visible ? 'chevron-up' : 'chevron-down'" />
              </div>
            </div>
          </template>
          <template #content><WeatherCurrent :house="house" /></template>
        </VpAccordion>
      </div>
      <div v-if="showWeather" class="upper-dashboard__weather-forecast">
        <hr>
        <VpAccordion :override="accordionOverride">
          <template #header="{ toggle, visible }">
            <div class="accordion__header" :class="{ 'is-open': visible }" @click="toggle">
              <div class="accordion__left">
                <span>{{ $t('weatherForecast') }}</span>
                <vp-icon class="accordion__icon" :icon="visible ? 'chevron-up' : 'chevron-down'" />
              </div>
            </div>
          </template>
          <template #content>
            <WeatherForecast :house="house" :screen-size="screenSize" />
          </template>
        </VpAccordion>
      </div>
    </div>
  </div>
</template>

<script>
import InfoDashboard from './InfoDashboard.vue';
import DeviceEnergyDatepicker from './DeviceEnergyDatepicker.vue';
import DeviceEnergyGraph from './DeviceEnergyGraph.vue';
import WeatherCurrent from '../weather/WeatherCurrent.vue';
import WeatherForecast from '../weather/WeatherForecast.vue';

export default {
  name: 'UpperDashboard',
  components: {
    InfoDashboard, DeviceEnergyDatepicker, DeviceEnergyGraph, WeatherCurrent, WeatherForecast,
  },
  inject: ['$busy', '$proSettings', '$log'],
  data() {
    return {
      accordionOverride: null,
      screenSize: null,
      mediaQueries: {
        medium: window.matchMedia('(min-width: 834px) and (max-width: 1023px)'),
        mediumLarge: window.matchMedia('(min-width: 1024px) and (max-width: 1439px)'),
        large: window.matchMedia('(min-width: 1440px)'),
      },
      ready: false,
    };
  },
  computed: {
    deviceList() {
      return this.$store.getters['device/devices'];
    },
    enableWeather() {
      return this.$proSettings.settings.enableHomeWeather;
    },
    firstName() {
      const user = JSON.parse(localStorage.getItem('user')) || {};
      return user.firstName;
    },
    house() {
      return this.$store.getters['dashboard/house'];
    },
    month() {
      return new Date().toLocaleString(this.$i18n.locale, { month: 'long' });
    },
    showDatePickerInAccordion() {
      if (this.showMetrics && (this.screenSize === 'small' || this.screenSize === 'medium')) {
        return true;
      } return false;
    },
    showMetrics() {
      const nonNestDevices = this.deviceList.some(item => item.mfg !== 'nest');
      return this.deviceList.length > 0 && nonNestDevices;
    },
    showWeather() {
      const usingLatLong = typeof this.house.latitude === 'number' && typeof this.house.longitude === 'number';
      const usingZip = this.house.zipcode && this.house.country;

      return (this.enableWeather &&
        Object.keys(this.house).length > 0 &&
        (usingLatLong || usingZip)
      );
    },
    selectedHouseId() {
      return this.$store.getters.selectedHouseId;
    },
  },
  watch: {
    screenSize(val) {
      this.setOverride(val);
    },
    selectedHouseId() {
      this.$store.dispatch('dashboard/getCurrentHouse');
    },
  },
  mounted() {
    this.init();
  },
  updated() {
    this.$store.commit('dashboard/locale', this.$i18n.locale);
  },
  destroyed() {
  // Change to unmounted() once upgrade to Vue 3 is completed
    this.teardown();
  },
  methods: {
    init() {
      this.$busy.toggle(true);

      // Watch for change in window size
      window.addEventListener('resize', this.setScreenSize);
      this.setScreenSize();
      this.setOverride(this.screenSize);

      // Non-repeating Promises
      const onetimePromises = [];
      if (this.enableWeather) {
        onetimePromises.push(this.$store.dispatch('dashboard/getCurrentHouse')
          .catch((err) => {
            this.$log.error(`Failed to Fetch Current House  - ${err.name}:  ${err.message}`);
          }));
      }
      Promise.all(onetimePromises)
        .finally(() => {
          this.ready = true;
          this.$busy.toggle(false);
        });
    },
    teardown() {
      window.removeEventListener('resize', this.setScreenSize);
    },
    setOverride(status) {
      if (['mediumLarge', 'large'].includes(status)) {
        this.accordionOverride = {
          header: 'hide',
          content: 'open',
          animation: false,
        };
      } else {
        this.accordionOverride = {
          header: 'show',
          content: 'close',
          animation: true,
        };
      }
    },
    setScreenSize() {
      const { medium, mediumLarge, large } = this.mediaQueries;
      if (large.matches) {
        this.screenSize = 'large';
      } else if (mediumLarge.matches) {
        this.screenSize = 'mediumLarge';
      } else if (medium.matches) {
        this.screenSize = 'medium';
      } else {
        this.screenSize = 'small';
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/weather-icons.scss';

.dashboard-header {
  display: none;
  margin: 0;

  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #FFFFFF;

  @include Desktop {
    display: block;
    font-size: 20px;
    line-height: 32px;
  }

  @include Desktop--Large {
    font-size: 24px;
    line-height: 40px;
  }
}
.upper-dashboard {
  padding: 24px 24px 0px;
  background-color: #454545;

  &__deck {
    width: 100%;
    display: block;
  }

  h2 {
    font-size: 20px;
    line-height: 26px;
    padding: 0;
    margin: 0 0 14px;
    color: #FFFFFF;
    text-align: left;
  }

  hr {
    margin: 0 -24px 0;
    width: 100vw;
    height: 0px;
    border-top: 0.5px solid #7E7E7E;
  }

  &__greeting {
    grid-area: greeting;
    max-width: 1246px;
    padding-bottom: 16px;
    user-select:none;
  }

  &__metrics-results {
    grid-area: metrics-results;
  }
  &__metrics-consumed {
    grid-area: metrics-consumed;
  }
  &__weather-current {
    grid-area: weather-current;
  }
  &__weather-forecast {
    grid-area: weather-forecast;
  }

  @include Tablet--XLarge {
    &__greeting {
      padding: 0;

      display: grid;
      grid-template-columns: auto 384px;
    }

    h2 {
      font-size: 24px;
      line-height: 40px;
    }
  }

  @include Desktop {
    padding: 24px;

    hr {
      display: none;
    }

    &__deck {
      width: max-content;

      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(3, auto);
      grid-template-areas:
        "greeting greeting"
        "metrics-results metrics-consumed"
        "weather-current weather-forecast";
      gap: 24px;
    }

    &__greeting {
      max-width: 880px;

      h2 {
        margin: 0;
        font-size: 32px;
        line-height: 40.16px;
      }
    }
  }

  @include Desktop--Large {
    &__deck {
      gap: 32px;
    }
    &__greeting {
      max-width: unset;
      h2 {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
}

.accordion__left {
 cursor:pointer;
 user-select:none;
}
</style>
