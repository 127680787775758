<template>
  <div ref="leaderBoardList" class="leader-board-list">
    <div v-for="(item, index) in participantList" :key="`${index}-${item.houseId}`" :ref="`leaderRow${item.houseId}`" class="leader-board-list__item">
      <div class="leader-board-list__rank">
        <div :data-rank="index + 1">
          <span>{{ index + 1 }}</span>
        </div>
      </div>
      <div class="leader-board-list__person">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5102 16.747H3.48974C1.85913 16.747 0.714233 15.7409 0.714233 14.0755C0.714233 11.7164 1.26934 8.14289 4.2877 8.14289C4.59995 8.14289 5.95301 9.56534 7.99995 9.56534C10.0469 9.56534 11.3653 8.14289 11.7122 8.14289C14.7306 8.14289 15.2857 11.7164 15.2857 14.0755C15.2857 15.7409 14.1755 16.747 12.5102 16.747ZM7.99995 8.80208C5.81423 8.80208 4.04485 7.03269 4.04485 4.84697C4.04485 2.66126 5.81423 0.857178 7.99995 0.857178C10.1857 0.857178 11.955 2.62657 11.955 4.81228C11.955 6.99799 10.1857 8.80208 7.99995 8.80208Z" :fill="providerColor" />
        </svg>
        <span>{{ formatName(item.houseId, item.alias) }}</span>
      </div>
      <div class="leader-board-list__score">
        <span>{{ item.points }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const houseId = localStorage.getItem('houseId');
export default {
  name: 'LeaderBoardList',
  props: {},
  inject: ['$byodConfig'],
  computed: {
    bodyCSS() {
      return { '--provider-color': this.providerColor };
    },
    providerColor() {
      return this.$byodConfig.color;
    },
    participantList() {
      return this.$store.getters['dashboard/leaderboardParticipantList'];
    },
    standingsTypes: {
      get() {
        return this.$store.getters['dashboard/leaderboardStandingsTypes'];
      },
      set(newType) {
        this.$store.commit('dashboard/leaderboardStandingsTypes', newType);
      },
    },
  },
  watch: {
    standingsTypes(type) {
      if (type === 'homeowner') {
        this.$refs.leaderBoardList.scrollTop = this.$refs[`leaderRow${houseId}`][0].offsetTop - 150;
      } else {
        this.$refs.leaderBoardList.scrollTop = 0;
      }
    },
  },
  methods: {
    formatName(id, alias) {
      if (Number(id) === Number(houseId)) {
        return 'You';
      }
      if (alias) {
        return alias;
      }
      const providerId = localStorage.getItem('pid');
      return `${providerId}-${id}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";

.leader-board-list {
  gap: 0.25rem;
  height: 100%;
  display: flex;
  overflow: scroll;
  padding: 0.25rem 0;
  flex-direction: column;
  @include Tablet--Large {
    gap: 0.5rem;
    padding: 1.5rem 0;
  }
  &__item {
    height: 26px;
    gap: 0.625rem;
    display: flex;
    background: #fff;
    border-radius: 6px;
    align-items: center;
    padding: 0.25rem 0.5rem;
    justify-content: space-between;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    @include Tablet--Large {
      height: 50px;
      padding: 0.75rem 1rem;
    }
  }
  &__rank {
    & > div {
      font-size: 10px;
      border-radius: 50%;
      aspect-ratio: 1 / 1;
      padding: 0 0.25rem;
      box-sizing: border-box;
      @include Tablet--Large {
        font-size: 13px;
        padding: 0.25rem 0.5rem;
      }
    }
    div[data-rank="1"] {
      color: #c19600;
      background: #fff2c7;
      border: 2px solid #f5d873;
    }
    div[data-rank="2"] {
      color: #4f4f4f;
      background: #f1f1f1;
      border: 2px solid #afafaf;
    }
    div[data-rank="3"] {
      color: #9b6600;
      background: #f5dbc2;
      border: 2px solid #9b6600;
    }
  }
  &__person {
    gap: 0.25rem;
    flex-grow: 1;
    height: 100%;
    display: flex;
    text-align: left;
    font-size: 0.875rem;
    align-items: baseline;
    @include Tablet--Large {
      font-size: 1rem;
      align-items: center;
    }
    svg {
      display: none;
      @include Tablet--Large {
        display: unset;
      }
    }
  }
  &__score {
    padding: 0 0.5rem;
    line-height: 1rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    background: #f3f9fb;
    color: var(--provider-color);
    border: 1px solid var(--provider-color);
    @include Tablet--Large {
      font-size: 1rem;
      padding: 0.375rem 0.5rem;
    }
  }
}
</style>
