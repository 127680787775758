<template>
  <div class="schedule-event-display">
    <figure>
      <div class="schedule-event-display__event-number">
        {{ eventNumber }}
      </div>
    </figure>
    <figure>
      <figcaption>{{ $t('scheduleDays') }}</figcaption>
      <div class="schedule-event-display__schedule-week-container">
        <ScheduleWeekDisplay :days="eventDays" :brand="{ color: providerColor }" />
        <span v-if="isActive"><VpIcon icon="active" :color="providerColor" title="Active Schedule" /></span>
      </div>
    </figure>
    <figure>
      <figcaption>{{ $t('scheduleStartTime') }}</figcaption>
      <span>{{ eventStartTime }}</span>
    </figure>
    <figure>
      <figcaption>{{ $t('scheduleDeviceMode') }}</figcaption>
      <span>{{ eventDeviceMode }}</span>
    </figure>
    <figure>
      <figcaption>{{ $t('scheduleSetpoint') }}</figcaption>
      <span>{{ eventSetpoint }}</span>
    </figure>
  </div>
</template>

<script>
import ScheduleWeekDisplay from './ScheduleWeekDisplay.vue';

export default {
  name: 'ScheduleEventDisplay',
  components: { ScheduleWeekDisplay },
  inject: ['$byodConfig', 'formatDecimalTime', 'formatConvertSetpoint', 'deviceModes'],
  props: {
    event: {
      type: Object,
      required: true,
    },
    eventIndex: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    eventNumber() {
      return this.eventIndex + 1;
    },
    eventDays() {
      return this.event.DayOfWeek.map(item => item.day);
    },
    eventStartTime() {
      return this.formatDecimalTime(this.event.timeOfDay);
    },
    eventDeviceMode() {
      const mode = this.deviceModes.find(item => item.setting === this.event.command.mode);
      return mode ? mode.name : this.$filter.humanReadableCommandMode(this.event.command.mode);
    },
    eventSetpoint() {
      return this.formatConvertSetpoint(this.event.command.setpoint);
    },
    providerColor() {
      return this.$byodConfig.color;
    },
  },
};
</script>

<style lang="scss" scoped>
figure {
  margin: 0;

  figcaption {
    font-size: 10px;
    line-height: 10px;
    color: #AFAFAF;
    text-transform: uppercase;
  }

  .schedule-week-display {
    padding-top: 4px;
  }
}
</style>

<style lang="scss">
.schedule-event-display {
  padding: 8px;

  display: grid;
  grid-template-columns: repeat(1, max-content) 304px repeat(3, 1fr);
  gap: 0 16px;
  justify-content: left;
  align-items: center;

  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &__event-number {
    width: 34px;
    height: 34px;
    background: #D5EAF6;
    border: 1px solid #A7CBE1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__schedule-week-container {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 16px;
  }
}
</style>
