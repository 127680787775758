<template>
  <div class="device-plot-list">
    <DevicePlot
      v-for="plot in plots"
      :key="plot.type"
      :datasets="plot.datasets"
      :options="plot.options"
      :resources="plot.resources"
      :title="plot.title"
      :type="plot.type"
      :enableLongDescription="plot.enableLongDescription"
      :update-plot="updatePlot"
      :context="{ deviceKind, deviceMfg, plotType: plot.type, resources: plot.resources, title: plot.title, unit: plot.unit }"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import CommandDefinition from '../command-definition';
import DevicePlot from './DevicePlot.vue';

export default {
  name: 'DevicePlotList',
  components: { DevicePlot },
  computed: {
    commandDefinition() {
      return new CommandDefinition(this.$i18n);
    },
    device() {
      return this.$store.getters['device/activeDevice'];
    },
    deviceId() {
      return this.device.id;
    },
    deviceKind() {
      return this.device.kind;
    },
    deviceMfg() {
      return this.device.mfg;
    },
    plots() {
      const plotList = [];
      const defaultOptions = {
        xAxis: {
          type: 'time',
          distribution: 'linear',
          time: {
            displayFormats: {
              hour: 'h:mm a',
              week: 'MMM D',
              month: 'MMM D',
            },
          },
          ticks: {
            maxTicksLimit: 12,
          },
        },
        updateInterval: 30000, // ms
      };

      // Configure mode chart specific behavior
      let modeList = this.commandDefinition.getModeList(this.device);
      if (Array.isArray(modeList)) {
        modeList = modeList.map(item => item.name);
        modeList.reverse();
      }

      // Configure tstat device specific behavior
      const includeHvacElementsPlot = this.deviceMfg === 'ecobee';
      const tstatTemperatureResources = [{ name: 'thermostat-actual-temperature', label: this.$i18n.t('deviceTstatTemp') }];
      if (this.deviceMfg === 'sensibo' || this.deviceMfg === 'ge') {
        tstatTemperatureResources.unshift({ name: 'thermostat-heat-setpoint', label: this.$i18n.t('setpoint'), tension: 0 });
      } else if (this.deviceMfg === 'mysa') {
        tstatTemperatureResources.unshift({ name: 'thermostat-heat-setpoint', label: this.$i18n.t('heatingSetpoint'), tension: 0 });
      } else {
        tstatTemperatureResources.unshift(
          { name: 'thermostat-heat-setpoint', label: this.$i18n.t('heatingSetpoint'), tension: 0 },
          { name: 'thermostat-cool-setpoint', label: this.$i18n.t('coolSetpoint'), tension: 0 },
        );
      }
      // Configure CTA hwh device specific behavior
      const isCtaDevice = this.deviceMfg === 'eradio';

      // Generate list of charts to render
      switch (this.deviceKind) {
        case 'battery':
          plotList.push({
            type: 'power',
            title: this.$i18n.t('power'),
            yAxisLabel: `${this.$i18n.t('power')} (kW)`,
            resources: [
              { name: 'battery-power', label: this.$i18n.t('deviceBatteryPower'), unit: 'kW' },
              { name: 'pv-power', label: this.$i18n.t('pvPower'), unit: 'kW' },
              { name: 'battery-house-power', label: this.$i18n.t('housePower'), unit: 'kW' },
            ],
            unit: 'kW',
          });
          plotList.push({
            type: 'capacity',
            title: this.$i18n.t('capacity'),
            yAxisLabel: `${this.$i18n.t('capacity')} (kWh)`,
            resources: [
              { name: 'battery-max-capacity', label: this.$i18n.t('maxCapacity'), unit: 'kWh' },
              { name: 'battery-present-capacity', label: this.$i18n.t('presentCapacity'), unit: 'kWh' },
            ],
            unit: 'kWh',
          });
          break;
        case 'evse':
          plotList.push({
            type: 'evChargerHistory',
            title: this.$i18n.t('evChargerHistory'),
            yAxisLabel: `${this.$i18n.t('power')} (kW)`,
            resources: [
              { name: 'evse-power', label: this.$i18n.t('power') },
              { name: 'evse-power-limit', label: this.$i18n.t('devicePowerLimit') },
            ],
          });
          break;
        case 'hwh':
          plotList.push({
            type: 'power',
            title: this.$i18n.t('power'),
            yAxisLabel: `${this.$i18n.t('power')} (W)`,
            resources: [
              { name: 'power', label: this.$i18n.t('power'), unit: 'W' },
            ],
            unit: 'W',
          });
          if (isCtaDevice) break;
          plotList.push({
            type: 'temperature',
            title: this.$i18n.t('temperature'),
            yAxisLabel: this.$i18n.t('temperature'),
            resources: [
              { name: 'hwh-set-temperature', label: this.$i18n.t('setpoint'), tension: 0 },
              { name: 'hwh-tank-temperature', label: this.$i18n.t('actual') },
            ],
          });
          if (this.deviceMfg === 'aosmith') break;
          plotList.push({
            type: 'mode',
            title: this.$i18n.t('mode'),
            yAxisLabel: '',
            resources: [
              { name: 'hwh-set-mode', label: this.$i18n.t('mode'), tension: 0 },
            ],

            categoryLabels: modeList,
          });
          break;
        case 'ev':
          plotList.push({
            type: 'power',
            title: this.$i18n.t('devicePower'),
            yAxisLabel: [
              `${this.$i18n.t('power')} (kW)`,
              `${this.$i18n.t('percentCharged')} (%)`,
            ],
            resources: [
              { name: 'ev-home-power', label: `${this.$i18n.t('evHomePower')}`, yAxisID: 'yAxis1', unit: 'kW' },
              { name: 'ev-away-power', label: `${this.$i18n.t('evAwayPower')}`, yAxisID: 'yAxis1', unit: 'kW' },
              { name: 'ev-battery-level', label: `${this.$i18n.t('percentCharged')}`, unit: 'kW', yAxisID: 'yAxis2', ticks: { min: 0, max: 100 } },
            ],
            unit: 'kW',
            enableLongDescription: false,
          });
          break;
        case 'rac':
          break;
        case 'tstat':
          plotList.push({
            type: 'temperature',
            title: this.$i18n.t('temperature'),
            yAxisLabel: this.$i18n.t('temperature'),
            resources: tstatTemperatureResources,
          });
          if (includeHvacElementsPlot) {
            plotList.push({
              type: 'mode',
              title: this.$i18n.t('hvacElements'),
              yAxisLabel: '',
              resources: [
                { name: 'hvac-equipment-status', label: '', tension: 0 },
              ],
              categoryLabels: modeList,
            });
          }
          break;
        default:
          break;
      }

      // Convert to VpScatterChart compatible format
      return plotList.map((plot) => {
        let { unit, enableLongDescription } = plot;

        // Localize labelString
        let labelString;
        let labelStringRight;

        // Checks if there is more than one label for the y axis
        if (Array.isArray(plot.yAxisLabel)) {
          labelString = plot.yAxisLabel[0];
          labelStringRight = plot.yAxisLabel[1];
        } else { labelString = plot.yAxisLabel; }

        const plotData = this.$store.getters['device/plotData'](this.deviceKind, plot.type);

        if (plot.type === 'temperature') {
          const { tempUnit } = plotData.metadata;
          if (tempUnit) {
            unit = tempUnit;
            labelString = `${this.$i18n.t('temperature')} (${tempUnit})`;
          }
        }

        let yAxis1Min, yAxis1Max, yAxis2Min, yAxis2Max;
        // default left y-axis min/max ticks if there's datasets
        if (plotData.datasets && plotData.datasets.length > 0) {
          yAxis1Min = plotData.metadata.min || null;
          yAxis1Max = plotData.metadata.max || null;

          // Allows setting custom ticks for left/right y-axis
          yAxis2Min = plotData.metadata.min || null;
          yAxis2Max = plotData.metadata.max || null;
          if (this.deviceKind === 'ev') {
            yAxis1Min = 0;
            yAxis1Max = 0;
            yAxis2Min = plotList[0].resources[2].ticks.min;
            yAxis2Max = plotList[0].resources[2].ticks.max;

            // set up data
            const powerHome = plotData.datasets[0].data;
            const powerAway = plotData.datasets[1].data;

            // find max y value
            const combinedDataPoints = powerHome.concat(powerAway);
            const yValues = combinedDataPoints.map((obj) => { return obj.y; });
            yAxis1Max = Math.ceil(Math.max(...yValues));

            if (yAxis1Max === 0) {
              yAxis1Max = 10;
            }
          }
        }

        // Return VpScatterChart compatible plot config object
        const config = {
          type: plot.type,
          title: plot.title,
          options: _.merge({}, defaultOptions, {
            title: { display: false, text: plot.title },
            yAxis1: {
              type: plot.type === 'mode' ? 'category' : 'linear',
              labels: plot.categoryLabels,
              title: { display: true, text: labelString },
              ticks: {
                min: yAxis1Min,
                max: yAxis1Max,
              },
            },
          }),
          datasets: plotData.datasets || [],
          resources: plot.resources,
          unit,
          enableLongDescription,
        };

        // Adds right axis to the config object if it exists
        if (labelStringRight) {
          config.options = {
            ...config.options,
            yAxis2: {
              // Right y-axis
              type: plot.type === 'mode' ? 'category' : 'linear',
              display: true,
              text: { display: true, text: labelStringRight },
              grid: { display: false },
              ticks: {
                min: yAxis2Min,
                max: yAxis2Max,
              },
            },
          };
        }
        return config;
      });
    },
  },
  methods: {
    updatePlot({ start, end }, { plotType, resources }) {
      const { deviceId, deviceKind } = this;
      const pointRadius = this.$children[0].timeType === 'day' ? 4 : 1;
      if (plotType && resources.length) {
        this.$store.dispatch('device/fetchDevicePlotData', {
          deviceId,
          deviceKind,
          plotType,
          resources,
          start,
          end,
          pointRadius,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.device-plot-list {
  display: flex;
  flex-flow: column;
  row-gap: 24px;
}
</style>
