<template>
  <main id="account__profile">
    <form id="accountProfileForm" ref="form" name="accountProfileForm">
      <div class="vp-list">
        <vp-input id="firstName" v-model="account.firstName" height="50px">
          <template #label>{{ $t('firstName') }}</template>
        </vp-input>
        <vp-input id="lastName" v-model="account.lastName" height="50px">
          <template #label>{{ $t('lastName') }}</template>
        </vp-input>
        <vp-input v-if="showNickname" id="alias" :value="nickname" height="50px" disabled="true" :styles="{ disabledColor: '#4f4f4f' }">
          <template #label>{{ $t('nickname') }} </template>
        </vp-input>
        <vp-input id="email" :value="account.email" height="50px" disabled="true" :styles="{ disabledColor: '#4f4f4f' }">
          <template #label>{{ $t('emailAddress') }}</template>
        </vp-input>
        <p>
          {{ $t('emailDisclaimer') }} <a id="account__email-link" :href="`mailto:${supportEmail}`">{{ supportEmail }}.</a>
        </p>
        <vp-button id="account__update-button" :style="buttonCSS" class="medium" @click="updateProfile()">{{ $t('updateProfile') }}</vp-button>
      </div>
    </form>
    <AccountSettings />
  </main>
</template>

<script>
import AccountSettings from './AccountSettings.vue';
const providerId = localStorage.getItem('pid');
const houseId = localStorage.getItem('houseId');

export default {
  name: 'AccountProfile',
  components: {
    AccountSettings,
  },
  inject: ['$byodConfig', '$proSettings', '$toast'],
  data: () => ({
    account: {},
  }),
  computed: {
    buttonCSS() {
      return {
        'background-color': this.providerColor,
        border: `solid 2px ${this.providerColor}`,
      };
    },
    nickname() { return this.user.alias || `${providerId}-${houseId}`; },
    providerColor() { return this.$byodConfig.color; },
    showNickname() { return this.$proSettings.settings.enableHomeLeaderboard; },
    supportEmail() { return this.$byodConfig.supportEmail; },
    user: {
      get() { return this.$store.getters.user; },
      set(newUser) { this.$store.commit('setUser', newUser); },
    },
  },
  created() {
    return this.init();
  },
  methods: {
    init() {
      // we maintain a copy of the user for editting purposes. We don't want to connect the user form to the vuex user
      // beacuse you have to click Update Profile to actually save the changes
      this.account = JSON.parse(JSON.stringify(this.user));
    },
    updateProfile() {
      const opts = {
        accountId: this.user.id,
        firstName: this.account.firstName,
        lastName: this.account.lastName,
      };
      return this.$store.dispatch('account/edit', opts)
        .then((account) => {
          this.account = account;
          const user = JSON.parse(localStorage.getItem('user'));
          user.firstName = account.firstName;
          user.lastName = account.lastName;
          localStorage.setItem('user', JSON.stringify(user));
          this.user = account;
        })
        .finally(() => this.$toast.show('success', { title: this.$i18n.t('accountSuccessHead'), body: this.$i18n.t('accountSuccessBody') }))
        .catch(err => this.$toast.show('fail', { title: this.$i18n.t('accountError'), body: err.message }));
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

#account__profile {
  padding: 0px 24px 90px;
  .vcl-input{
    &__label {
      font-size: 1rem;
    }
    &__input {
      &.has-focus {
        border: 2px solid var(--provider-color);
      }
    }
  }
  .vp-list {
    display:block;
  }
}
.vp-house p {
  margin: 0;
}
#accountProfileForm {
  border-bottom: 1px solid #bec3cc;
  padding-bottom: 24px;
}
#account__phoneNumbers {
  padding-top: 24px;
}
#account__email-link {
  color: var(--provider-color);
}
#account__email-link:focus {
  border:1px dotted var(--provider-color);
}

@include Desktop {
  #account__profile {
    display: flex;
    flex-flow: row nowrap;
    background: #FBFBFB;
    padding: 24px 24px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  }
  #accountProfileForm {
    border-right: 1px solid #bec3cc;
    border-bottom: none;
    padding-right: 24px;
  }
  #account__profile > * {
    flex: 1;
  }
  #account__phoneNumbers {
    padding-left: 24px;
    padding-top: 0px;
  }
}

#lastName, #alias, #email {
 margin-top:24px;
 width:100%;
}

#email {
 margin-bottom:24px;
}

#account__update-button {
 margin-top:16px;
}
</style>
