<template>
  <div class="cta-panel" :style="style">
    <div class="cta-panel__hwh">
      <svg class="cta-panel__hwh__icon" viewBox="0 0 50 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 79H45C45 81.7614 42.7614 84 40 84H10C7.23858 84 5 81.7614 5 79Z" fill="#016699" />
        <rect width="50" height="76" rx="6" fill="#016699" />
        <rect x="21" y="62" width="8" height="8" rx="4" fill="white" />
        <path
          d="M17.5 53.8839C15.1506 52.5039 13.3434 50.6639 11.988 48.2718C10.6325 45.9718 10 43.3957 10 40.6357C10 38.4276 10.5422 36.2195 11.5361 34.0115C12.1687 32.7235 13.3434 30.7914 15.1506 28.2154C16.9578 25.6393 18.2229 23.6153 19.1265 22.0512C20.5723 19.4752 21.747 16.7151 22.4699 13.863C22.741 13.035 23.2831 12.483 23.9157 12.207C24.6386 11.931 25.3614 11.931 26.0843 12.207C26.8072 12.483 27.259 13.035 27.5301 13.863C28.3434 16.7151 29.5181 19.4752 30.8735 22.1432C31.7771 23.7073 33.1325 25.7313 34.8494 28.3074C36.5663 30.8834 37.8313 32.8155 38.4639 34.1035C39.4578 36.2195 40 38.4276 40 40.7277C40 43.4877 39.3675 46.0638 38.012 48.3638C36.6566 50.7559 34.8494 52.5959 32.5 53.976C30.1506 55.356 27.7108 56 25 56C22.2892 56 19.8494 55.264 17.5 53.8839Z"
          fill="white" />
        <path d="M25 50C20.0294 50 16 45.9706 16 41" stroke="#016699" stroke-width="3" stroke-linecap="round" />
      </svg>
      <div class="cta-panel__label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTAPanel',
  props: {
    args: {
      type: Object,
      required: true,
    },
    brand: {
      type: Object,
      default: () => ({
        color: '#000000',
      }),
    },
  },
  computed: {
    label() {
      return this.args.label;
    },
    providerColor() {
      return this.brand.color;
    },
    style() {
      return {
        '--provider-color': this.providerColor,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.cta-panel {
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;

  padding: 10px 0;

  text-align: center;
  line-height: 1;
  color: #4F4F4F;

  &__hwh__icon {
    height: 52px;
  }

  &__label {
    margin-top: 1em;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 19px;

    color: #4F4F4F;
  }

  @include Tablet--Large {

    &__label {
      font-size: 18px;
      line-height: 24px;
    }

    &__hwh__icon {
      height: 84px;
    }
  }
}
</style>
