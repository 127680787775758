import AppAuthentication from './AppAuthentication.vue';

export default [{
  name: 'logon',
  path: '/logon',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}, {
  name: 'logon-success',
  path: '/logon/success',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}, {
  name: 'logon-error',
  path: '/logon/error',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}, {
  name: 'password-reset',
  path: '/logon/password-reset',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}, {
  name: 'totp',
  path: '/totp',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}, {
  name: 'auth',
  path: '/auth',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}, {
  name: 'auth-proxy',
  path: '/auth-proxy',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}, {
  name: 'link-house',
  path: '/link-house',
  component: AppAuthentication,
  meta: { requiresAuth: false },
}];
