import UnitConverter from '@/common/unit-converter';
import Vue from 'vue';
import DataMap from '../data-map';
import Munging from './munging';

const { convert } = new UnitConverter();

const pipe = (...fns) => {
  // Take functions, then take data and apply functions to produce output
  return value => fns.reduce((v, fn) => fn(v), value);
};

export default {
  addEventLog(state, payload) {
    state.eventLog = payload;
  },
  addEvent(state, payload) {
    const exist = state.events.find(({ eventDay }) => eventDay === payload.eventDay);
    if (!exist) state.events.push(payload);
  },
  setPlotData(state, payload) {
    const { reverse, fixStartTime, movingAverage, transitions, stairStep, convert2kilo, convertTemp, fixEndTime, findExtremes, format, pass, convert2percent, truncate } = Munging;
    const { deviceKind, plotType, rawDatasets, start, end, tempUnit = null, localTemp } = payload;

    const datasets = [];
    const metadata = {};

    // Configure resource-specific data wrangling
    const resourceSpecificWranglers = {
      // Battery Power
      'battery-power': pipe(movingAverage(15), convert2kilo),
      'pv-power': pipe(movingAverage(15), convert2kilo),
      'battery-house-power': pipe(movingAverage(15), convert2kilo),
      // Battery Capacity
      'battery-max-capacity': pipe(transitions, stairStep, convert2kilo),
      'battery-present-capacity': pipe(convert2kilo),
      // EV
      'ev-battery-level': pipe(convert2percent, transitions),
      'ev-home-power': pipe(convert2kilo, truncate),
      'ev-away-power': pipe(convert2kilo, truncate),
      // EVSE EV Charger History
      'evse-power': pipe(stairStep, convert2kilo),
      'evse-power-limit': pipe(stairStep, convert2kilo),
      // HWH Temperature
      'hwh-set-mode': pipe(stairStep),
      'hwh-set-temperature': pipe(convertTemp({ metadata, tempUnit, localTemp }), stairStep),
      'hwh-tank-temperature': pipe(convertTemp({ metadata, tempUnit, localTemp })),
      // TSTAT
      'hvac-equipment-status': pipe(stairStep),
      'thermostat-actual-temperature': pipe(convertTemp({ metadata, tempUnit, localTemp })),
      'thermostat-cool-setpoint': pipe(convertTemp({ metadata, tempUnit, localTemp }), transitions, stairStep),
      'thermostat-heat-setpoint': pipe(convertTemp({ metadata, tempUnit, localTemp }), transitions, stairStep),
    };

    // Configure findExtremes
    let mode;
    let relativeBase;
    let relativeTarget;
    if (['hwh', 'tstat'].includes(deviceKind)) {
      mode = 'relative';
      if (deviceKind === 'hwh' && plotType === 'power') {
        mode = 'both';
        relativeBase = 100;
        relativeTarget = 'max';
      }
    }

    // Perform data wrangling
    if (rawDatasets.length) {
      rawDatasets.forEach((rawDataset) => {
        const { resource, label, data, tension, pointRadius, yAxisID, unit } = rawDataset;
        let resourceSpecificWrangling = pipe(pass); // default
        if (resource in resourceSpecificWranglers) {
          resourceSpecificWrangling = resourceSpecificWranglers[resource];
        }
        const wrangler = pipe(reverse, fixStartTime(start), resourceSpecificWrangling, fixEndTime(start, end), findExtremes(metadata, { mode, relativeBase, relativeTarget }), format);

        datasets.push({
          label,
          data: wrangler(data),
          tension,
          pointRadius,
          unit,
          yAxisID, // When there's a right y-axis
        });
      });
    }

    // Update state
    if (deviceKind in state.plotData) {
      if (plotType in state.plotData[deviceKind]) {
        state.plotData[deviceKind][plotType] = { datasets, metadata };
      }
      Vue.set(state.plotData[deviceKind], plotType, { datasets, metadata });
    } else {
      Vue.set(state.plotData, deviceKind, {
        [plotType]: { datasets, metadata },
      });
    }
  },
  setChangesPendingReconciliation: (state, payload) => {
    state.changesPendingReconciliation = (payload === true);
  },
  setActiveDevice(state, payload) {
    const { device } = payload;
    state.activeDevice = device;
  },
  setDeviceActiveSchedule(state, payload) {
    const { activeSchedule } = payload;
    const { deviceId } = activeSchedule;
    const index = state.activeSchedules.findIndex(item => item.deviceId === deviceId);
    if (index === -1) {
      state.activeSchedules.push(activeSchedule);
    } else {
      Vue.set(state.activeSchedules, index, activeSchedule);
    }
  },
  setDeviceData(state, payload) {
    const { deviceData } = payload;
    state.deviceData = deviceData;
  },
  setDeviceRealtime(state, payload) {
    const { deviceId, kind, realtime, resetPending, tempUnit = 'F' } = payload;
    const keyMap = new DataMap(kind).byKey;
    // Extract key/value pairs from object array
    const extraction = {};
    realtime.forEach((obj) => {
      const setting = keyMap(obj.key);
      let convertTemperature = false;
      if (setting && setting.property) {
        convertTemperature = [
          'setpoint',
          'coolSetpoint',
          'heatSetpoint',
          'temperature',
          'actualTemperature',
        ].includes(setting.property);
      }
      if (obj.key === 'status') {
        extraction[obj.key] = obj.status;
      } else if (convertTemperature) {
        extraction[obj.key] = convert('F', tempUnit)(obj.value);
      } else {
        extraction[obj.key] = obj.value;
      }
    });
    // Store extracted data by device ID
    const index = state.devicesRealtime.findIndex(obj => obj.deviceId === deviceId);
    if (index === -1) {
      // Create the device entry
      state.devicesRealtime.push({ deviceId, current: { ...extraction }, pending: { ...extraction } });
    } else {
      // Update the device entry
      state.devicesRealtime[index].current = { ...extraction };
      if (resetPending) {
        // Reset pending to match current
        state.devicesRealtime[index].pending = { ...extraction };
      }
    }
  },
  setDeviceRealtimePending(state, payload) {
    const { deviceId, key, value } = payload;
    const index = state.devicesRealtime.findIndex(obj => obj.deviceId === deviceId);
    state.devicesRealtime[index].pending[key] = value;
  },
  setDevices(state, payload) {
    state.devices = payload;
  },
  setDeviceSetting(state, payload) {
    const { deviceSetting, localTemp } = payload;
    const targetSettingIndex = state.deviceSettings.findIndex(setting => setting.key === deviceSetting.key);
    // Localize values as appropriate
    if (['hwh-max-setpoint', 'hwh-min-set-temperature', 'hwh-mixing-valve'].includes(deviceSetting.key)) {
      deviceSetting.value = localTemp(deviceSetting.value);
    }
    // Mutate state
    if (targetSettingIndex === -1) {
      // Create the setting
      state.deviceSettings.push(deviceSetting);
    } else {
      // Update the setting
      Vue.set(state.deviceSettings, targetSettingIndex, { ...deviceSetting });
    }
  },
  setDeviceSchedules(state, payload) {
    const { deviceSchedules, deviceScheduleDetails } = payload;
    if (deviceScheduleDetails != null && deviceScheduleDetails.length) {
      state.deviceScheduleDetails = deviceScheduleDetails;
    }
    state.deviceSchedules = deviceSchedules;
  },
  setEventPerformance(state, [event, { performance, weather }]) {
    if (event) {
      event.performance = performance || {};
      event.weather = weather || {};
    }
  },
  setSavings(state, payload) {
    state.energySavedBySeason = payload;
  },
  sortEvents: state => state.events.sort((a, b) => new Date(a.startTime) - new Date(b.startTime)),
};
