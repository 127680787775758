<template>
  <div v-if="ready" class="device-tabs">
    <h3>{{ headerContent }}</h3>
    <a id="backToDashboard" @click="returnToDashboard" v-show="!isDesktop">
      <vp-icon icon="chevron-left" :tabindex=0 />{{ $t('backToDashboard') }}
    </a>
    <div class="device-tabs-content">
      <VpTabMenu v-model="selectedTab" :tabs="availableTabs" :styles="{ activeColor: providerColor, inactiveColor: '#4f4f4f', backgroundColor: '#EFF1F2', activeUnderlineColor: providerColor }" @tab-updated="changeTab">
        <template #status>{{ $t('status') }}</template>
        <template #schedule>{{ $t('schedule') }}</template>
        <template #settings>{{ $t('settings') }}</template>
      </VpTabMenu>
      <keep-alive>
        <component :is="selectedTabContent" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import StatusTab from './tabs/StatusTab.vue';
import ScheduleTab from './tabs/ScheduleTab';
import SettingsTab from './tabs/SettingsTab.vue';

export default {
  name: 'DeviceTabs',
  components: { StatusTab, ScheduleTab, SettingsTab },
  inject: ['$byodConfig', '$filter', '$proSettings'],
  props: {
    tab: {
      type: String,
      default: 'status',
    },
  },
  data() {
    return {
      ready: false,
      selectedTab: '',
    };
  },
  computed: {
    availableTabs() {
      const tabs = [
        { value: 'status', path: `/device/${this.deviceId}` },
        { value: 'settings', path: `/device/${this.deviceId}/settings` },
      ];
      if (this.deviceKind === 'hwh' && (this.$proSettings.settings.enableAutoSchedule || this.$proSettings.settings.enableCustomHwhSchedule)) {
        tabs.splice(1, 0, { value: 'schedule', path: `/device/${this.deviceId}/schedule` });
      }
      return tabs;
    },
    breakpoint() {
      return this.$store.getters.breakpoint;
    },
    device() {
      return this.$store.getters['device/activeDevice'];
    },
    deviceId() {
      return Number(this.$route.params.deviceId);
    },
    deviceKind() {
      return this.device.kind;
    },
    headerContent() {
      const { name, mfg, id, deviceSettings } = this.device;
      if (name) {
        return name;
      }

      if (mfg) {
        const subBrand = deviceSettings.find(setting => (setting.key === 'sub-brand'));
        if (subBrand) { return this.$filter.deviceSubBrand(subBrand.value); }
        if (mfg === 'smartcar') {
          return this.$filter.vehicleMake(this.device.type);
        }
        return this.$filter.deviceMfg(mfg);
      }
      return `Device #${id}`;
    },
    isDesktop() {
      if ((this.breakpoint.substring(0, 7) === 'desktop')) { return true; }
      return false;
    },
    selectedTabContent() {
      switch (this.selectedTab) {
        case 'schedule':
          return 'ScheduleTab';
        case 'settings':
          return 'SettingsTab';
        default:
          return 'StatusTab';
      }
    },
    providerColor() {
      return this.$byodConfig.color;
    },
  },
  mounted() {
    this.selectedTab = this.tab;
    this.init();
  },
  methods: {
    init() {
      const { deviceId } = this;
      this.$store.dispatch('device/fetchDevice', { deviceId })
        .finally(() => {
          this.ready = true;
        });
    },
    changeTab(newTab) {
      this.$router.push({ path: newTab.path });
    },
    returnToDashboard() {
      this.$router.push({ path: '/#/' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixins.scss';

#backToDashboard {
 color:#016699;
 display:block;
 margin-left:18px;
 margin-top:18px;
 text-decoration:underline;
}

.device-tabs {
  width: 100%;
  padding: 0px;
}

.device-tabs > h3 {
 background-color:#4f4f4f;
 color:#fff;
 font-size:1.625rem;
 font-weight:600;
 height:70px;
 line-height:70px;
 margin-top:0px;
 margin-bottom:0px;
 padding-left:76px;
}

.device-tabs-content {
  max-width: 1264px;
  padding: 8px 72px 32px;

  color: #4f4f4f;

  .vcl-tab-menu {
    margin-bottom: 32px;

    &__group {
      -webkit-box-shadow: unset !important;
      box-shadow: unset !important;
    }
  }

  @include Mobile--Only {
    padding: 8px;
  }

  @include Tablet {
    padding: 8px 16px 16px;
  }

  @include Desktop {
    padding: 8px 72px 32px;
  }
}
</style>
