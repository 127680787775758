import api from '@/common/api';
import UnitConverter from '@/common/unit-converter.js';

function getPoints(data) {
  const starterBonus = 500;
  // const firstEventBonus = data.eventCount > 0 ? 50 : 0;
  const successPoints = 1000 * (data.responseCount - data.optOutCount);
  // const optOutDeductions = -2 * data.optOutCount;
  return starterBonus + (successPoints > 0 ? successPoints : 0);
  // return starterBonus + firstEventBonus + successPoints + optOutDeductions;
}

export default {
  getProgramLeaderboard(store, opts) {
    const houseId = localStorage.getItem('houseId');
    return api.get(`/house/${houseId}/program-leaderboard`)
      .then(result => result.map(house => Object.assign({ points: getPoints(house) }, house)).sort((a, b) => b.points - a.points))
      .then(result => store.commit('leaderboardParticipantList', result));
  },
  getHouseEvents(store, opts) {
    const houseId = localStorage.getItem('houseId');
    const { start, end, includeAllPlatoonEvents } = opts;
    const query = new URLSearchParams();
    if (start) {
      query.append('start', start instanceof Date ? start.toISOString() : new Date(start).toISOString());
      if (end) {
        query.append('end', end instanceof Date ? end.toISOString() : new Date(end).toISOString());
      }
    }
    if (includeAllPlatoonEvents) {
      query.append('includeAllPlatoonEvents', includeAllPlatoonEvents);
    }
    return api.get(`/house/${houseId}/event?${String(query)}`);
  },
  getDeviceEnergy(store, opts) {
    const { convert } = new UnitConverter();

    function combineAndFillZeros(start, end, input) {
      const values = input.reduce((prev, item) => {
        const time = new Date(Date.parse(item.time));
        time.setMinutes(0);
        const timeString = time.toISOString();
        if (!prev[timeString]) {
          prev[timeString] = Object.assign({}, item);
        } else {
          prev[timeString].value += item.value;
        }
        prev[timeString].unitOfMeasure = item.unitOfMeasure;
        return prev;
      }, []);
      const data = [];
      const startDate = new Date(Date.parse(start));
      const endDate = new Date(Date.parse(end));

      // eslint-disable-next-line no-unmodified-loop-condition
      while (startDate < endDate) {
        data.push({ time: startDate.toISOString(), value: (values[startDate.toISOString()] || { value: 0 }).value });
        startDate.setHours(startDate.getHours() + 1);
      }
      return data;
    }

    const houseId = localStorage.getItem('houseId');
    const {
      kind, deviceId, start, end, hourly = false, mfg,
    } = opts;
    const startStr = (new Date(Date.parse(start))).toISOString();
    const endStr = (new Date(Date.parse(end))).toISOString();
    if (['hwh', 'evse', 'battery', 'ev'].includes(kind)) {
      const url = `/house/${houseId}/device/${deviceId}/energy?start=${startStr}&end=${endStr}&${hourly ? 'hourly' : 'total'}=true`;
      return api.get(url)
        .then((result) => {
          if (hourly) {
            const hourlyResult = combineAndFillZeros(start, end, result);
            hourlyResult.forEach((item) => {
              item.value = convert('w', 'kw')(item.value);
              item.unitOfMeasure = 'kW';
            });
            return hourlyResult;
          }
          result.unitOfMeasure = 'W';
          return result;
        });
    }
    if (kind === 'tstat' && mfg !== 'mysa') {
      const coolUrl = `/house/${houseId}/device/${deviceId}/data/thermostat-runtime-cool?start=${startStr}&end=${endStr}&hourly=${hourly}`;
      const heatUrl = `/house/${houseId}/device/${deviceId}/data/thermostat-runtime-heat?start=${startStr}&end=${endStr}&hourly=${hourly}`;
      return Promise.all([
        api.get(coolUrl).catch(() => []),
        api.get(heatUrl).catch(() => []),
      ])
        .then((result) => {
          if (hourly) {
            return combineAndFillZeros(start, end, [].concat(...result)).map((item) => ({ ...item, unitOfMeasure: 's' }));
          }
          return { deviceId, kind, runtime: [].concat(...result).reduce((prev, item) => prev + item.value, 0) };
        });
    }
    if (kind === 'tstat' && mfg === 'mysa') {
      const heatUrl = `/house/${houseId}/device/${deviceId}/data/thermostat-runtime-heat?start=${startStr}&end=${endStr}&hourly=${hourly}`;
      const energyUrl = `/house/${houseId}/device/${deviceId}/energy?start=${startStr}&end=${endStr}&${hourly ? 'hourly' : 'total'}=true`;

      return Promise.all([
        api.get(heatUrl).catch(() => []),
        api.get(energyUrl).catch(() => {}),
      ])
        .then((result) => {
          if (hourly) {
            const hourlyRuntimeData = combineAndFillZeros(start, end, result[0]).map((item) => ({ ...item, unitOfMeasure: 's' }));
            const hourlyEnergyData = combineAndFillZeros(start, end, result[1]).map((item) => ({ ...item, unitOfMeasure: 'kW', value: convert('w', 'kw')(item.value) }));

            return Promise.resolve([...hourlyRuntimeData, ...hourlyEnergyData]);
          }
          const runtimeData = { deviceId, kind, runtime: [].concat(...result[0]).reduce((prev, item) => prev + item.value, 0) };
          return runtimeData;
        });
    }
    return Promise.resolve({ deviceId, kind, energy: 0 });
  },
  getDeviceData(store, opts) {
    const houseId = localStorage.getItem('houseId');
    const { keys, deviceId } = opts;
    return Promise.all(
      keys.map(key => api.get(`/house/${houseId}/device/${deviceId}/data/${key}`)
        .catch(() => { return {}; })
        .then(result => Object.assign({ key }, result))),
    );
  },
  setEnergyGraphDateRange(context, opts) {
    const { start, end } = opts;
    return context.commit('energyGraphDateRange', { start, end });
  },
  setEnergyGraphTimeType(context, opts) {
    const { timeType } = opts;
    return context.commit('energyGraphTimeType', timeType);
  },
  getCurrentHouse(context) {
    const houseId = localStorage.getItem('houseId');
    return api.get(`/house/${houseId}`)
      .then(res => context.commit('house', res));
  },
  fetchMarketingMessages(context) {
    const houseId = localStorage.getItem('houseId');
    return api.get(`/house/${houseId}/marketing/in-app`)
      .then(res => context.commit('marketingMessages', res));
  },
  setLocale(context, opts) {
    const { locale } = opts;
    return context.commit('locale', locale);
  },
};
