<template>
  <div class="vp-overlay">
    <div v-if="showOverlay" class="vp-overlay__overlay">
      <div v-if="message.text" class="vp-overlay__overlay__message">{{ message.text }}</div>
      <a v-if="message.linkUrl && message.linkText" class="vp-overlay__overlay__link" :href="message.linkUrl">{{ message.linkText }}</a>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VpOverlay',
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => ({
        text: '',
        linkUrl: '',
        linkText: '',
      }),
    },
  },
};
</script>

<style lang="scss">
.vp-overlay {
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;

    width: 100%;
    height: 100%;

    padding: 8px;

    border-radius: 0 0 6px 6px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;

    color: white;
    font-size: 1rem;

    background-color: rgba(black, 0.61);
    backdrop-filter: blur(1px);

    &__link {
      width: max-content;
      height: max-content;

      padding: 8px;
      border-radius: 6px;

      background-color: var(--provider-color);
      color: white;
    }
  }
}
</style>
