<template>
  <div class="phone-settings number">
    <div v-for="(phoneNumber, index) in list" :key="phoneNumber.phone+index" class="phoneNumberRow">
      <div class="phoneNumber">
        <vp-icon v-if="!phoneNumber.verified" icon="plus-circle" style="color: #4F4F4F; transform: rotate(45deg)" />
        <vp-icon v-if="phoneNumber.verified" icon="check-circle" style="color: #113050" />
        {{ phoneNumber.maskedPhone }}
      </div>
      <div class="phoneNumberControls">
        <vp-button v-if="!phoneNumber.verified" class="primary small" :style="verifyButtonCSS" @click="verifyNumber(phoneNumber.phone, phoneNumber.countryCode)">{{ $t('verifyNumber') }}</vp-button>&nbsp;
        <vp-button class="primary small red" :style="deleteButtonCSS" @click="deleteNumber(phoneNumber.phone, phoneNumber.countryCode)">{{ $t('deleteNumber') }}</vp-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhoneList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  inject: ['$byodConfig'],
  emits: ['delete-number', 'verify-number'],
  computed: {
    providerColor() { return this.$byodConfig.color; },
    deleteButtonCSS() {
      return {
        'min-width': '70px',
        'min-height': '25px',
        height: '25px',
        padding: '5px',
      };
    },
    verifyButtonCSS() {
      return {
        'background-color': this.providerColor,
        border: `solid 2px ${this.providerColor}`,
        'min-width': '70px',
        'min-height': '25px',
        height: '25px',
        padding: '5px',
      };
    },
    buttonCSS() {
      return {
        'background-color': this.providerColor,
        border: `solid 2px ${this.providerColor}`,
      };
    },
  },
  methods: {

    deleteNumber(phone, countryCode) {
      this.$emit('delete-number', phone, countryCode);
    },

    verifyNumber(phone, countryCode) {
      this.$emit('verify-number', phone, countryCode);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
 margin-bottom:8px;
}

.phoneNumberRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 5px 5px 24px;
}

.phoneNumber {
  height: 25px;
  padding: 0px 5px;
  background: rgba(17, 48, 80, 0.1);
  border: 1px solid #113050;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
}

</style>
