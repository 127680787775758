import { map, pipe, evolveCurrent, evolveForecast } from './munging';

export default {
  current(state, payload) {
    const { current: rawCurrentObj, hourlyForecast: rawHourlyForecast, units } = payload;
    const { pop } = rawHourlyForecast.list[0];
    let current = {
      temp: 0,
      icon: 'wi wi-fw wi-na',
      windSpeed: 0,
      precip: 0,
      units: '',
    }; // default

    let precision = 0;
    if (units === 'metric') {
      precision = 1;
    }

    const currentWrangler = pipe(evolveCurrent({ pop, precision, units }));
    current = currentWrangler(rawCurrentObj);

    state.current = current;
  },
  forecast(state, payload) {
    const { res, units } = payload;
    const { list: rawForecastArr } = res;
    let forecast = []; // default

    let precision = 0;
    if (units === 'metric') {
      precision = 1;
    }

    const forecastWrangler = map(pipe(evolveForecast({ precision, units })));
    forecast = forecastWrangler(rawForecastArr);

    state.forecast = forecast;
  },
};
