<template>
  <div id="shell">
    <div v-show="!authenticated && !isAuth" v-cloak id="shell__unauthorized">
      <AppAuthentication @auth="onAuthenticated" />
    </div>
    <div v-if="authenticated && isAuth && proSettingsLoaded" v-cloak id="shell__ready">
      <div id="menuContainer" class="sliding-shell show-menubar" :style="bodyCSS">
        <LeftMenu />
        <MobileMenu />
        <TopMenu />
      </div>
      <div v-cloak id="shell__authorized">
        <div id="overflowWrapper">
          <router-view />
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppAuthentication from '@/modules/authentication/AppAuthentication.vue';
import Footer from './Footer.vue';
import LeftMenu from './LeftMenu.vue';
import MobileMenu from './MobileMenu.vue';
import PhoneVerificationModal from '@/modules/account/modals/PhoneVerificationModal.vue';
import TopMenu from './TopMenu.vue';

export default {
  name: 'Shell',
  components: {
    AppAuthentication, Footer, LeftMenu, MobileMenu, TopMenu,
  },
  provide() {
    const providedProSettings = {};
    // TODO: See if appProSettings can be replaced with vuex getter
    Object.defineProperty(providedProSettings, 'settings', {
      enumerable: true,
      get: () => this.appProSettings,
    });
    return {
      $proSettings: providedProSettings,
    };
  },
  inject: ['$busy', '$byodConfig', '$modal'],
  data: () => ({
    appProSettings: {},
    proSettingsLoaded: false,
    authenticated: false,
    toggledNavigation: false,
  }),
  computed: {
    bodyCSS() {
      return {
        '--provider-color': this.$byodConfig.color,
      };
    },
    busy() {
      return this.$busy.status();
    },
    isAuth() {
      const auth = this.$store.getters['shell/isAuth'];
      return (typeof auth === 'object' && auth !== null) ? auth.authenticated : auth;
    },
    phoneNumbers() {
      return this.$store.getters.phoneNumbers;
    },
    roles() {
      return this.user.roles || [];
    },
    selectedHouseId() {
      return this.$store.getters.selectedHouseId;
    },
    user: {
      get() {
        return this.$store.getters.user;
      },
      set(newUser) {
        this.$store.commit('setUser', newUser);
      },
    },
    vuexProSettings() { return this.$store.getters.proSettings; },
  },
  onUnmounted() {
    return this.setBodyLock(false);
  },
  methods: {
    toggleNav(value) {
      const hasOverride = value !== undefined;
      if (!hasOverride) {
        this.toggledNavigation = !this.toggledNavigation;
      }
      if (hasOverride && this.toggledNavigation !== value) {
        this.toggledNavigation = value;
      }
    },
    setBodyLock(bool) {
      const [body] = document.getElementsByTagName('body');
      if (body) {
        body.setAttribute('data-shell-lock', bool);
      }
    },
    onAuthenticated(isAuthenticated) {
      this.authenticated = isAuthenticated;
      this.setBodyLock(isAuthenticated);
      this.$store.dispatch('shell/updateAuth', { authenticated: Boolean(isAuthenticated) });
      if (isAuthenticated) {
        if ('user' in localStorage) {
          const user = JSON.parse(localStorage.getItem('user') || {});
          this.$store.commit('setUser', user);
          this.$store.dispatch('shell/fetchPhoneNumbers', user.id);
          this.preselectUserPreferredLanguage({ user });
        }
      }
    },
    preselectUserPreferredLanguage({ user }) {
      const {
        value: allowMultipleLanguages = false,
        languages: supportedLanguages = [],
      } = this.$byodConfig.commonSettings.find(({ key }) => key === 'byodMultipleLanguages') || {};

      if (allowMultipleLanguages) {
        if ('language' in user) {
          const preferredLanguageSupported = supportedLanguages.find(({ key }) => key === user.language);
          if (preferredLanguageSupported && (localStorage.getItem('selectedLanguage') === null)) {
            localStorage.setItem('selectedLanguage', user.language);
          }
        }
      }
    },
    openPhoneVerificationModal(phoneNumbers) {
      if (this.appProSettings.enablePhoneNumberVerificationModal) {
        for (let i = 0; i < phoneNumbers.length; i++) {
          if (!phoneNumbers[i].verified) {
            // If phone verification modal is dismissed don't display again for 3 days
            if (!('phoneVerificationModalLastDisplayed' in localStorage) ||
                (localStorage.getItem('phoneVerificationModalLastDisplayed') < (Date.now() - (1000 * 60 * 60 * 72)))) {
              this.$modal.open(PhoneVerificationModal);
            }
            break;
          }
        }
      }
    },
  },
  watch: {
    'appProSettings.enablePhoneNumberVerificationModal': {
      immediate: true,
      handler() {
        this.openPhoneVerificationModal(this.phoneNumbers);
      },
    },
    phoneNumbers(newNumbers) {
      return this.openPhoneVerificationModal(newNumbers);
    },
    selectedHouseId: {
      immediate: true,
      async handler(newHouseId) {
        if (newHouseId == null) return;
        if ((!('proSettings' in localStorage) || (localStorage.getItem('proSettings') === 'null'))) {
          await this.$store.dispatch('getProviderSettingsForHouse', newHouseId);
          this.proSettingsLoaded = true;
        } else {
          this.appProSettings = JSON.parse(localStorage.getItem('proSettings'));
          this.proSettingsLoaded = true;
        }
      },
    },
    user(newUser) {
      // Not entirely sure about the conditional below. Can an admin role have a houseId?
      const houseRoles = newUser.roles.filter(role => ((role.houseId !== null) && (role.role === 'house' || role.role === 'admin')));
      const houseIds = houseRoles.map(role => role.houseId);

      // Load Data for stored houseId via admin proxy login
      const adminRole = !!newUser.roles.filter(role => role.role === 'admin').length;
      const storedHouseId = localStorage.getItem('houseId') || null;
      if (adminRole && storedHouseId) {
        houseIds.push(storedHouseId);
      }

      this.$store.dispatch('shell/fetchHouseDetails', houseIds);
    },
    vuexProSettings(newSettings) {
      this.appProSettings = newSettings;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

div#shell {
 background-color: #f1f1f1;
}

#shell, #shell__ready {
  height: 100%;
}

// If #shell-ready is rendered, Lock Scrolling on Body element
body[data-shell-lock="true"] {
  overflow: hidden;
}

#shell__authorized {
/*
  display: flex;
  flex-wrap: wrap;
*/
  height:100%;

 @include Mobile--Only {
  padding-left:0px;
  padding-top:100px;
 }

 @include Tablet {
  padding-left:0px;
  padding-top:100px;
 }

 @include Desktop {
   padding-left:80px;
   padding-top:60px;
 }

  /** Header */
  #shell__header {
    flex: 1 1 100%;
  }

  #shell__navigation {
    flex: 0 0 125px;
  }

  /** Content */
  #shell__body {
    flex: 1 1 70%;
    padding: 1rem;
    background-color: $lightgray;
    &[data-hide="true"] {
      overflow: hidden;
    }

    & > * {
      display: flex;
      flex-direction: column;
      background-color: $white;
    }
  }

  #shell__navigation, #shell__body {
    overflow-y: scroll;
    // calculate view height - header height
    height: calc(100vh - 50px);
  }

  #overflowWrapper {
    height: calc(100% - 80px); // less height of mobile menu
    overflow-x: hidden; // ff, safari
    overflow-y: auto;
    position: relative;

    @include Desktop {
      height: 100%;
    }
  }
}
</style>
