<template>
  <VpModal ref="modal" class="mixing-valve-modal">
    <template #header>{{ $t('mixingValveWarning') }}</template>
    <div>{{ $t('mixingValveDisclaimer') }}</div>
    <template #footer="{ close }">
      <VpButton id="mvm-cancel" class="medium no-fill" @click="close">{{ $t('cancel') }}</VpButton>
      <VpButton id="mvm-confirm" class="medium" @click="close">{{ $t('confirm') }}</VpButton>
    </template>
  </VpModal>
</template>

<script>
export default {
  name: 'MixingValveModal',
  inject: ['$modal'],
  provide() {
    const self = this;
    return {
      close(event) {
        if (event.target.id !== 'mvm-confirm') {
          self.target.setMixingValveSetting(false);
        }
        self.$modal.close();
      },
    };
  },
  props: {
    target: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>
