import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  name: 'account',
  state() {
    return {
      accountId: null,
      providerId: null,
      linkedAccounts: [],
    };
  },
  mutations,
  actions,
  getters,
};
