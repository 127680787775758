<template>
  <widget class="event-calendar" :styles="widgetCSS" :busy="busy">
    <template #title>{{ $t('eventCalendarResults') }}</template>
    <template #controller>
      <DatePickerCustom ref="picker" v-bind="datePickerProps" @update="handleUpdate" />
    </template>
    <div class="event-calendar__body">
      <div ref="swiper" class="swiper-container">
        <div v-if="dataExist" class="event-calendar__tiles swiper-wrapper">
          <EventCalendarCard v-if="hasMoreToRender" :busy="busy" :opts="{ isLoader: true, rendered: events.length, max: availableDates.length, load: handleLoad }" />
          <template v-for="event in events">
            <EventCalendarCard :key="event.id" ref="cards" v-bind="{ busy, event, localization}" class="event-calendar__tile swiper-slide" />
          </template>
        </div>
        <div v-else class="event-calendar__empty">
          <p>{{ $t('eventCalendarEmpty') }}</p>
        </div>
      </div>
    </div>
  </widget>
</template>

<script>
import Swiper, { Mousewheel } from 'swiper';
import moment from 'moment/moment';
import EventCalendarCard from './EventCalendarCard.vue';
import DatePickerCustom from '../DatePickerCustom.vue';
import 'swiper/swiper-bundle.css';
import Widget from '../Widget.vue';

Swiper.use([Mousewheel]);

// const defaultTheme = {};
const maxCallCount = 12;

const $filter = {
  Date: (value, string) => moment(value).format(string),
};

export default {
  name: 'EventCalendar',
  components: {
    EventCalendarCard,
    DatePickerCustom,
    Widget,
  },
  inject: ['$byodConfig', '$log'],
  data: () => ({
    calls: 0,
    busy: false,
    swiper: null,
    pendingDates: [],
    date: new Date(),
  }),
  computed: {
    $filter: () => $filter,
    attributes() {
      return [
        {
          // Attribute type definitions
          dot: true,
          // We also need some dates to know where to display the attribute
          dates: this.availableDates,
        },
      ];
    },
    availableDates() {
      const dates = [];
      this.eventLog.forEach((event) => {
        const date = moment(new Date(event.startTime)).startOf('day').toISOString();
        // Denylisting events with no endTime, && haven't been added yet
        if (!dates.includes(date) && event.endTime) dates.push(date);
      });
      return dates.sort((a, b) => new Date(b) - new Date(a))
        .filter(a => Date.parse(a) < Date.parse(moment().startOf('day').toISOString()));
    },
    availableYears() {
      const dates = [];
      this.availableDates.forEach((date) => {
        const { year, month } = this.extractDate(date);
        const exist = dates.find(([y, m]) => y === year && m === month);
        if (!dates.length || !exist) dates.push([year, month]);
      });
      return dates;
    },
    dataExist() { return Boolean(this.events && this.events.length); },
    datePickerProps() {
      return {
        busy: this.busy,
        date: this.date,
        loadedDates: this.events,
        attributes: this.attributes,
        handleLoad: this.handleLoad,
        hasMore: this.hasMoreToRender,
        totalLoaded: this.events.length,
        availableDates: this.availableDates,
      };
    },
    events() {
      return this.$store.getters['device/events']
        .filter(({ endTime }) => Date.parse(endTime) < Date.now());
    },
    eventLog() { return this.$store.getters['device/eventLog']; },
    hasMoreToRender() { return this.availableDates.length > this.events.length; },
    houseId: () => Number.parseInt(localStorage.getItem('houseId'), 10),
    localization() { return this.$store.getters.localization; },
    leaderboardStart() {
      const { leaderboardStart } = this.proSettings;
      if (leaderboardStart) {
        return leaderboardStart;
      }
      return moment().startOf('year').format('YYYY-MM-DD');
    },
    payload() { return { houseId: this.houseId, start: this.range.start, end: this.range.end }; },
    providerId: () => Number.parseInt(localStorage.getItem('pid'), 10),
    proSettings: () => JSON.parse(localStorage.getItem('proSettings')),
    range() { return (!this.date) ? { start: '', end: '' } : this.getQuery(this.extractDate(this.date)); },
    widgetCSS: () => ({
      headerBorder: 'none',
      bodyBackground: '#fbfbfb',
      innerPadding: '0',
      innerBackground: '#fbfbfb',
      innerBoxShadow: 'inset 0 2px 3px rgba(0, 0, 0, 0.1)',
    }),
  },
  mounted() { return this.init(); },
  methods: {
    init() {
      this.calls = 0;
      this.busy = true;
      // Fallback if localization isn't initialized
      if (!this.localization) {
        this.$store.commit('updateLocalization', this.$byodConfig.localization);
      }
      return this.$store.dispatch('device/fetchAllEvents', this.payload)
        .then(() => {
          this.pendingDates = this.availableYears;
          return this.fetchEvents();
        })
        .then(() => this.$store.commit('device/sortEvents'))
        .then(() => this.createSwiper())
        .then(() => {
          const index = (this.events.length && this.events.length > 0) ? this.events.length - 1 : 0;
          this.date = new Date(this.events[index].startTime);
          this.$refs.picker.setIndex(index);
        })
        .catch(err => this.$log.error(err.message))
        .finally(() => { this.busy = false; });
    },
    createSwiper() {
      if (!this.dataExist) return null;
      this.swiper = new Swiper(this.$refs.swiper, {
        // Optional parameters
        loop: false,
        spaceBetween: 8,
        watchOverflow: true,
        slidesPerView: 1.5,
        centeredSlides: false,
        direction: 'horizontal',
        initialSlide: this.events ? this.events.length : 0,
        breakpoints: {
          475: {
            slidesPerView: 1.9,
          },
          580: {
            slidesPerView: 2.5,
          },
          630: {
            slidesPerView: 2.9,
          },
          768: {
            spaceBetween: 16,
            slidesPerView: 2.18,
          },
        },
        mousewheel: {
          eventsTarget: this.$refs.swiper,
          invert: true,
          releaseOnEdges: true,
        },
      });
      return this.swiper;
    },
    extractDate(d) {
      const date = new Date(d);
      const obj = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
      obj.lastDay = new Date(obj.year, obj.month, 0).getDate();
      return Object.assign({}, obj);
    },
    fetchEvents(eventDate, forced) {
      if (!eventDate && !this.pendingDates.length) return null;

      const date = this.extractDate(eventDate || ((yearMonthArr) => {
        const [year, month] = yearMonthArr;
        return `${year}-${String(month).padStart(2, '0')}-15`; // Pad month for Webkit compatibility
      })(this.pendingDates[0]));
      return this.$store.dispatch('device/fetchEventDays', Object.assign({}, this.payload, this.getQuery(date)))
        .then((events) => {
          this.calls += 1;
          this.pendingDates = this.pendingDates.filter(([y, m]) => !(y === date.year && m === date.month));
          const [seasonStartYear, seasonStartMonth] = this.leaderboardStart.split('-').slice(0, 2).map(part => Number(part));
          const leftOfSeason = this.pendingDates.filter(([y, m]) => y >= seasonStartYear && m >= seasonStartMonth);
          const hasExceededCalls = this.calls < maxCallCount && this.calls < this.availableYears.length;
          if (forced) return events;
          // If list doesn't contain all events from current calendar year (Program Season), fetch more events
          return (leftOfSeason.length && hasExceededCalls && this.hasMoreToRender && !forced) ? this.fetchEvents() : null;
        });
    },
    formatDate(value) {
      if (this.$i18n.locale === 'es') {
        return this.$filter.Date(value, 'DD MMM YYYY');
      }
      return this.$filter.Date(value, 'MMM DD, YYYY');
    },
    getQuery: ({ year, month, lastDay }) => ({ start: `${year}-${month}-1`, end: `${year}-${month}-${lastDay}` }),
    handleLoad(eventDate) {
      this.busy = true;
      let cap = 0;
      return Promise.resolve(this.fetchEvents(eventDate, true))
        .then((events) => {
          cap = events.length > 0 ? events.length - 1 : 0;
          return this.$store.commit('device/sortEvents');
        })
        .then(() => this.swiper.update())
        .then(() => this.$refs.picker.modifyOffset(cap, true))
        .catch(err => this.$log.error(err.message))
        .finally(() => { this.busy = false; });
    },
    handleUpdate(range) {
      const event = this.events.find((e) => {
        return e.startTime === range;
      });
      if (event) {
        this.date = range;
        this.swiper.slideTo(this.events.indexOf(event));
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
.event-calendar {
  .swiper-container {
    padding: 0 1.5rem;
  }
  &__body {
    height: 100%;
  }
  div.widget__inner {
    padding: 0;
  }
  .vcl-date-picker {
    max-height: 18px !important;
    @include Tablet--Large {
      max-height: 35px !important;
    }
  }
  .vcl-tab-menu__tab {
    line-height: 19px !important;
  }
}
</style>
