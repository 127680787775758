<template>
  <div v-if="opts.isLoader" class="event-calendar__card swiper-slide event-calendar__card--controls">
    <i>{{ $t('loaded')}} {{ opts.rendered }}/ {{ opts.max }} {{ $t('events')}}</i>
    <vp-button id="event-calendar__controller" :disabled="busy" @click="opts.load()">{{ $t('loadEvents') }}</vp-button>
  </div>
  <div v-else :data-id="`event-${event.id}`" class="event-calendar__card swiper-slide">
    <div class="event-calendar__card--header">
      <div class="event-calendar__card--date">
        <span>{{ date }}</span>
      </div>
      <div class="event-calendar__card--temp">
        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg" :title="event.weather.description">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8269 6.66923C17.8269 6.99615 17.7616 7.25769 17.6962 7.51923C17.0423 6.66923 16.0616 6.08077 14.8846 6.08077C14.5577 6.08077 14.1654 6.14615 13.8385 6.21154C13.3154 5.49231 12.6616 4.90385 11.8769 4.44615C12.4654 3.72692 13.3154 3.26923 14.3616 3.26923H14.6231C16.3885 3.26923 17.7616 4.64231 17.7616 6.40769V6.66923H17.8269ZM3.50771 10.3962C3.50771 10.2654 3.50771 10.0692 3.50771 9.93846C3.50771 6.99615 5.86156 4.64231 8.80387 4.64231C10.8962 4.64231 12.6616 5.81923 13.5116 7.51923C13.9039 7.25769 14.3616 7.06154 14.8846 7.06154C16.2577 7.06154 17.3039 8.17308 17.3039 9.54615C17.3039 9.87308 17.2385 10.2 17.1077 10.4615C17.1731 10.4615 17.2385 10.4615 17.2385 10.4615C19.0693 10.4615 20.5077 11.9654 20.5077 13.7308C20.5077 15.5615 19.0039 17 17.2385 17H3.76925C3.70387 17 3.70387 17 3.63848 17C3.5731 17 3.50771 17 3.44233 17C1.61156 17 0.1731 15.4962 0.1731 13.7308C0.238485 11.9 1.74233 10.3962 3.50771 10.3962ZM14.6231 2.61538C14.2308 2.61538 13.9693 2.35385 13.9693 1.96154V0.653846C13.9693 0.261538 14.2308 0 14.6231 0C15.0154 0 15.2769 0.261538 15.2769 0.653846V1.96154C15.2769 2.35385 14.95 2.61538 14.6231 2.61538ZM19.2 7.19231C18.8077 7.19231 18.5462 6.93077 18.5462 6.53846C18.5462 6.14615 18.8077 5.88462 19.2 5.88462H20.5077C20.9 5.88462 21.1616 6.14615 21.1616 6.53846C21.1616 6.93077 20.9 7.19231 20.5077 7.19231H19.2ZM10.8962 3.79231L9.98079 2.87692C9.71925 2.61538 9.71925 2.22308 9.98079 1.96154C10.2423 1.7 10.6346 1.7 10.8962 1.96154L11.8116 2.87692C12.0731 3.13846 12.0731 3.53077 11.8116 3.79231C11.6154 3.98846 11.1577 3.98846 10.8962 3.79231ZM18.6769 4.05385C18.4154 4.31538 18.0231 4.31538 17.7616 4.05385C17.5 3.79231 17.5 3.4 17.7616 3.13846L18.6769 2.22308C18.9385 1.96154 19.3308 1.96154 19.5923 2.22308C19.8539 2.48462 19.8539 2.87692 19.5923 3.13846L18.6769 4.05385Z" :fill="providerColor" />
        </svg>
        <span>{{ temp }}&deg;{{ localization.tempUnit}}</span>
      </div>
    </div>
    <vp-tab-menu v-model="tab" :tabs="eventTabs" style="width: 100%" :styles="{ activeColor: providerColor, activeUnderlineColor: providerColor, backgroundColor: '#ffffff', menuHeight: '20px', tabMargin: '0 6px', fontSize: '9px', align: 'space-around' }" @input="update">
      <template #usage-content>
        <div class="event-calendar__card--body">
          <div class="event-calendar__card--body-inner">
            <ProgressBar :title="usage.total.title" :sub-title="usage.total.subTitle" :color="usage.total.color" :progress="usage.total.progress" :label="usage.total.label" />
            <ProgressBar :title="usage.saved.title" :color="usage.saved.color" :progress="usage.saved.progress" :label="usage.saved.label" />
          </div>
        </div>
      </template>
      <template #participation-content>
        <div class="event-calendar__card--body">
          <div class="event-calendar__card--body-inner">
            <ProgressBar :title="participation.total.title" :color="participation.total.color" :progress="participation.total.progress" :label="participation.total.label" />
            <ProgressBar :title="participation.devices.title" :sub-title="participation.devices.subTitle" :color="participation.devices.color" :progress="participation.devices.progress" :label="participation.devices.label" />
          </div>
        </div>
      </template>
    </vp-tab-menu>

    <div class="event-calendar__card--footer">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.98113 14C3.13208 14 0 10.8679 0 6.98113C0 3.09434 3.13208 0 6.98113 0C10.8302 0 13.9623 3.13208 13.9623 6.98113C13.9623 10.8302 10.8679 14 6.98113 14ZM6.98113 2.03774C4.26415 2.03774 2.03774 4.26415 2.03774 6.98113C2.03774 9.69811 4.26415 11.9245 6.98113 11.9245C9.69811 11.9245 11.9245 9.69811 11.9245 6.98113C11.9245 4.26415 9.73585 2.03774 6.98113 2.03774ZM8.15094 7.84906C8.15094 8 8.03774 8.15094 7.84906 8.15094H4.9434C4.79245 8.15094 4.64151 8.03774 4.64151 7.84906V7.28302C4.64151 7.13208 4.75472 6.98113 4.9434 6.98113H6.98113V3.77358C6.98113 3.62264 7.09434 3.4717 7.28302 3.4717H7.84906C8 3.4717 8.15094 3.58491 8.15094 3.77358V7.84906Z" :fill="providerColor" />
      </svg>
      <div>
        <p>{{ $t('eventStartTime') }} {{ startTime }}</p>
        <p>{{ $t('eventDuration') }} {{ duration }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment/moment';
import ProgressBar from '../ProgressBar.vue';

const $filter = {
  Date: (value, string) => Moment(value).format(string),
};

const defaultColor = '#C4C4C4';

export default {
  name: 'EventCalendarCard',
  inject: ['$byodConfig'],
  components: { ProgressBar },
  props: {
    opts: {
      type: Object,
      default: () => {
        return { isLoader: false };
      },
    },
    busy: { type: Boolean, default: false },
    event: { type: Object, default: () => {} },
    localization: { type: Object, default: () => {} },
  },
  data() {
    return {
      tab: 'usage',
    };
  },
  computed: {
    providerColor() {
      return this.$byodConfig.color;
    },
    date() {
      return this.$filter.Date(new Date(this.event.startTime), this.format.date);
    },
    startTime() {
      const startTime = this.event.events
        .map(({ startTime }) => Date.parse(startTime))
        .sort((a, b) => a - b)[0];

      return this.$filter.Date(new Date(startTime), this.format.time);
    },
    temp() { // Convert Between Faren <> Celsius here
      const { avgTemperature, eventTemperature } = this.event.weather;
      let temp;
      if (this.localization) {
        temp = this.localization.localTemp(eventTemperature || avgTemperature);
        // localTemp for farenheit often returns too many digits after decimal
        if (String(temp).length > 2) temp = Number.parseInt(temp, 10);
      } else temp = Number.parseInt(eventTemperature || avgTemperature, 10);
      return temp || '--';
    },
    totalParticipants() {
      const contacted = this.event.events
        .map(({ devicesContactedCount }) => devicesContactedCount);
      return Number.parseInt(Math.max(...contacted), 10);
    },
    optOuts() {
      const notParticipating = this.event.events
        .map(({ noResponseCount, optOutCount }) => noResponseCount + optOutCount);
      return Number.parseInt(Math.max(...notParticipating), 10);
    },
    eventEnergy() {
      return Number.parseFloat((this.event.performance.eventEnergy || 0).toFixed(2));
    },
    savedEnergy() {
      const reductionEnergy = Number.parseFloat(this.event.performance.reductionEnergy);
      let savedEnergy = 0;

      if (reductionEnergy > savedEnergy) {
        savedEnergy = reductionEnergy;
      }
      return savedEnergy.toFixed(2);
    },
    usage() {
      const wasActive = this.eventEnergy > 0;
      const hasSavings = this.savedEnergy > this.eventEnergy && this.savedEnergy > 0;
      let totalProgress = (wasActive) ? 100 : 0;
      let savingsProgress = (hasSavings) ? 100 : 0;

      if (wasActive && this.savedEnergy > 0 && this.savedEnergy <= this.eventEnergy) {
        savingsProgress = ((this.savedEnergy * 100) / this.eventEnergy).toFixed(2);
      } if (wasActive && hasSavings) {
        totalProgress = ((this.eventEnergy * 100) / this.savedEnergy).toFixed(2);
      }
      return {
        total: {
          title: this.$i18n.t('eventEnergy'),
          subTitle: this.$i18n.t('eventDevices'),
          progress: `${totalProgress}%`,
          color: defaultColor,
          label: `${this.eventEnergy} KWH`,
        },
        saved: {
          title: this.$i18n.t('eventEnergySavings'),
          progress: `${savingsProgress}%`,
          color: this.providerColor,
          label: `${this.savedEnergy} KWH`,
        },
      };
    },
    participation() {
      const hasParticipants = this.totalParticipants > 0;
      let totalProgress = (hasParticipants) ? 100 : 0;
      let deviceProgress = (this.optOuts > 0) ? 100 : 0;

      if (hasParticipants && this.optOuts <= this.totalParticipants) {
        deviceProgress = ((this.optOuts * 100) / this.totalParticipants).toFixed(2);
      } else if (hasParticipants && this.optOuts > this.totalParticipants) {
        totalProgress = ((this.totalParticipants * 100) / this.optOuts).toFixed(2);
      }

      return {
        total: {
          title: this.$i18n.t('totalParticipation'),
          progress: `${totalProgress}%`,
          color: this.providerColor,
          label: this.totalParticipants,
        },
        devices: {
          title: this.$i18n.t('eventOptOut'),
          subTitle: this.$i18n.t('eventUnresponsive'),
          progress: `${deviceProgress}%`,
          color: defaultColor,
          label: this.optOuts,
        },
      };
    },
    duration() {
      const startTime = this.event.events
        .map(({ startTime }) => Date.parse(startTime))
        .sort((a, b) => a - b)[0];
      const endTime = this.event.events
        .map(({ endTime }) => Date.parse(endTime))
        .sort((a, b) => b - a)[0];
      let ms = Math.abs((new Date(endTime) - new Date(startTime))) / 1000;
      // calculate days
      const days = Math.floor(ms / 86400);
      ms -= days * 86400;
      // calculate hours
      let hours = Math.floor(ms / 3600) % 24;
      ms -= hours * 3600;
      if (days > 0) hours += (days * 24);
      // calculate minutes
      let minutes = Math.floor(ms / 60) % 60;
      ms -= minutes * 60;

      // set seconds from remainder
      const seconds = ms;

      minutes = Math.round(minutes + seconds / 60);
      if (minutes < 10) minutes = `0${minutes}`;

      return `${hours}:${minutes}`;
    },
    type() {
      const { type } = this.event.events[0] || { type: '' };
      return type;
    },
    eventTabs() {
      let tabLabel = this.$i18n.t('energyUsage');

      const deviceTypes = new Set();
      this.event.events.map((event) => {
        return deviceTypes.add(event.type);
      });

      if (deviceTypes.has('ev') || deviceTypes.has('battery')) {
        tabLabel = this.$i18n.t('programImpact');
      }

      return [{ value: 'usage', label: tabLabel }, { value: 'participation', label: this.$i18n.t('eventParticipation') }];
    },
    tabMenuStyles() {
      if (this.$i18n.locale === 'es') {
        return { fontSize: '13px' };
      }
      return { fontSize: '16px' };
    },
    format() {
      if (this.$i18n.locale === 'es') {
        return ({ date: 'DD MMM YYYY', time: 'hh:mm A' });
      } return ({ date: 'MMM DD, YYYY', time: 'hh:mm A' });
    },
    $filter: () => $filter,
  },
  methods: {
    update(tab) {
      this.tab = tab;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
div.event-calendar__card {
  display: flex;
  margin: 0.5rem 0;
  padding-top: 2px;
  border-radius: 6px;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  @include Tablet--Large {
    margin: 0.875rem 0;
    padding-top: 0.5rem;
  }
  &--controls {
    gap: 0.5rem;
    display: flex;
    font-size: 9px;
    padding: 0.5rem;
    flex-direction: column;
    @include Tablet--Large {
      gap: $GAP;
      font-size: 1rem;
    }
    button#event-calendar__controller {
      height: 100%;
      font-size: 9px;
      min-width: 50px;
      padding: 0.25rem;
      @include Tablet--Large {
        padding: 0.5rem;
        font-size: 1rem;
      }
    }
  }
  &--temp {
    svg {
      width: 11px;
      height: 9px;
      @include Tablet--Large {
        width: 22px;
        height: 17px;
      }
    }
  }
  &--header {
    height: 14px;
    display: flex;
    font-size: 8px;
    color: #4f4f4f;
    line-height: 100%;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    background-color: #fbfbfb;
    border: 1px solid #dbdbdb;
    @include Tablet--Large {
      height: 25px;
      font-size: 14px;
    }
    & > div {
      height: 14px;
      display: flex;
      padding: 3px 6px;
      border-radius: 4px;
      align-items: baseline;
      box-sizing: border-box;
      @include Tablet--Large {
        height: 25px;
        padding: 0.5rem;
        align-items: center;
      }
    }
  }
  &--date {
    // negative margin based on borderSize
    margin: -1px;
    border: 1px solid #dbdbdb;
    background-color: #ffffff;
    p {
      margin: 0;
    }
  }
  &--body,
  &--footer {
    width: 100%;
    text-align: left;
    p,
    span {
      line-height: 100%;
      @include Tablet--Large {
        font-size: 0.75rem;
      }
    }
  }
  &--body p {
    font-size: 0.562rem;
    @include Tablet--Large {
      font-size: 0.75rem;
    }
  }
  &--body-inner {
    // retracting card padding for styling
    padding: 2px 4px;
    border: 1px solid #f3f3f3;
    background-color: #fbfbfb;
    @include Tablet--Large {
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
      padding: 0.75rem 0 0.75rem 1rem;
    }
  }
  &--footer {
    padding: 2px;
    gap: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include Tablet--Large {
      gap: 0.5rem;
      padding: 0.375rem 0 0.375rem 1rem;
    }
    p {
      font-size: 0.5rem;
      @include Tablet--Large {
        font-size: 0.75rem;
      }
    }
    svg {
      width: 10px;
      @include Tablet--Large {
        width: 14px;
      }
    }
  }
}
</style>
