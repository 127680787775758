import api from '@/common/api';

export default {
  fetchCurrent(context, { house, i18n }) {
    const { tempUnit } = context.rootGetters.localization;
    const units = tempUnit === 'F' ? 'imperial' : 'metric';

    const currentUrl = `/data/2.5/weather?units=${units}`; // Current Weather Data
    const hourlyForecastUrl = `/data/2.5/forecast?units=${units}`; // 5 Day, 3 Hour Forecast
    const hourlyForecastCount = 1; // 3 hour period

    return context.dispatch('proxyOpenWeather', { house, url: currentUrl, i18n })
      .then((current) => {
        return context.dispatch('proxyOpenWeather', { house, url: hourlyForecastUrl, count: hourlyForecastCount, i18n })
          .then((hourlyForecast) => {
            return { current, hourlyForecast };
          });
      })
      .then(({ current, hourlyForecast }) => {
        return context.commit('current', { current, hourlyForecast, units });
      });
  },
  fetchForecast(context, { house, i18n }) {
    const { tempUnit } = context.rootGetters.localization;
    const units = tempUnit === 'F' ? 'imperial' : 'metric';

    const url = `/data/2.5/forecast/daily?units=${units}`; // Daily Forecast 16 days
    const count = 3; // days

    return context.dispatch('proxyOpenWeather', { house, url, count, i18n })
      .then((res) => {
        return context.commit('forecast', { res, units });
      });
  },
  async proxyOpenWeather(context, { house, url, count = null, i18n: { locale } = {} }) {
    let { id: houseId, latitude: lat, longitude: lon, zipcode, country } = house;

    if (lat == null || lon == null) {
      const formattedZip = zipcode.replaceAll(' ', '');
      const proxyUrl = `/house/${houseId}/api-proxy/openweather?url=/geo/1.0/zip?zip=${formattedZip},${country}`;
      ({ lat, lon } = await api.get(proxyUrl));
    }

    let proxyUrl = `/house/${houseId}/api-proxy/openweather?url=${url}&lat=${lat}&lon=${lon}`;
    if (count !== null) {
      proxyUrl = proxyUrl.concat(`&cnt=${count}`);
    }
    if (locale !== null) {
      proxyUrl = proxyUrl.concat(`&lang=${locale}`);
    }
    return api.get(proxyUrl);
  },
};
