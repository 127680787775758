<template>
  <div id="homeAppFooter" v-if="showFooter" v-html="footerContents" />
</template>
<script>
export default {
  name: 'Footer',
  inject: ['$byodConfig'],
  computed: {
    footerContents() {
      if (this.$byodConfig.homeAppFooter && this.$byodConfig.homeAppFooter.text) {
        return this.$byodConfig.homeAppFooter.text;
      }
      return '';
    },
    showFooter() {
      if (this.$byodConfig.homeAppFooter && this.$byodConfig.homeAppFooter.text) {
        return true;
      } return false;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/mixins.scss';

#homeAppFooter {
 @include Mobile--Only {
  padding:24px 24px 100px 24px;
 }

 @include Tablet {
  padding:24px 24px 100px 24px;
 }

 @include Desktop {
  padding:24px;
 }
}

</style>
