import api from '@/common/api';

export default {
  get: (context) => {
    return api.get(`/account/${context.rootGetters.user.id}?includeTotp=true&includeHasPassword=true`);
  },
  edit: (context, opts) => api.put(`/account/${opts.accountId}`, opts),
  updatePassword: (context, { oldPassword, newPassword }) => {
    return api.post(`/account/${context.rootGetters.user.id}/password`, { oldPassword, newPassword });
  },
  getMessageSettings: (context) => {
    return api.get(`/account/${context.rootGetters.user.id}/house/${context.rootGetters.selectedHouseId}/messageSettings`);
  },
  updateMessageSettings: (context, {
    key, data,
  }) => {
    return api.put(`/account/${context.rootGetters.user.id}/house/${context.rootGetters.selectedHouseId}/messageSettings/${key}`, { data });
  },
  addPhoneNumber: (context, actionParams) => {
    const {
      phone, countryCode, providerId,
    } = actionParams;
    return api.post(`/account/${context.rootGetters.user.id}/phonenumber`, { phone, countryCode, providerId });
  },
  resendPhoneCode: (context, actionParams) => {
    const {
      phone, countryCode, providerId,
    } = actionParams;
    return api.post(`/account/${context.rootGetters.user.id}/phonenumber/${phone}/verify?countryCode=${countryCode}`, { providerId });
  },
  sendPhoneVerificationCode(store, actionParams) {
    const { number, countryCode } = actionParams;
    const user = JSON.parse(localStorage.getItem('user') || {});
    const uid = user.id || null;
    const providerId = localStorage.getItem('pid');
    const url = `/account/${uid}/phonenumber/${number}/verify?countryCode=${countryCode}`;
    const apiParams = { providerId };
    return api.post(url, apiParams).then(() => {});
  },
  confirmPhoneVerificationCode(store, actionParams) {
    const {
      phone, countryCode, code, providerId,
    } = actionParams;
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const uid = user.id || null;
    const houseId = localStorage.getItem('houseId');
    const url = `/account/${uid}/phonenumber/${phone}/verify?countryCode=${countryCode}`;
    const apiParams = { code, houseId, providerId };
    return api.put(url, apiParams).then((response) => {
      if (response.verified) {
        store.commit('addPhoneNumber', response, { root: true });
        return response;
      } else {
        return false;
      }
    });
  },
};
