import axios from 'axios';
import { log } from '@/common/log';

const environment = process.env.NODE_ENV;

/**
 * Testing
 */

// Jest fails to mock window.gConfig,
// the following returns a fallback, instead of undefined
if (!window.gConfig) window.gConfig = {};

axios.defaults.baseURL = window.gConfig.host;

/**
 * Interceptors
 */

const HttpErrorMap = new Map();

const trackErrors = (errorMap) => (error) => {
  const {
    config: { method, baseURL, url } = {},
    response: { status } = {},
  } = error || {};

  // Track number of occurances of HTTP errors per endpoint
  if (method && baseURL && url) {
    const endpoint = `${method.toUpperCase()} ${baseURL}${url}`;
    const tracker = errorMap.get(endpoint);
    if (tracker) {
      if (status in tracker) {
        tracker[status] = tracker[status] + 1;
      } else {
        tracker[status] = 1;
      }
    } else {
      errorMap.set(endpoint, { [status]: 1 });
    }
  }

  return Promise.reject(error);
};
const checkErrors = (errorMap, { environment }) => (config) => {
  const { method, baseURL, url } = config || {};

  const maxAttemptsPerCode = {
    401: 2,
    403: 2,
  };

  if (method && baseURL && url) {
    const endpoint = `${method.toUpperCase()} ${baseURL}${url}`;
    const tracker = errorMap.get(endpoint);
    if (tracker) {
      // Prevent attempts beyond set maximum for a given code
      Object.keys(maxAttemptsPerCode).forEach((code) => {
        if (code in tracker) {
          const maxAttempts = maxAttemptsPerCode[code];
          const actualAttempts = tracker[code];
          if (actualAttempts >= maxAttempts) {
            if (environment !== 'production') {
              log.warn(`Preventing call to ${endpoint}; maximum number (${maxAttempts}) of status code ${code} received.`);
            }
            throw new Error(`Request failed with status code ${code}`);
          };
        }
      });
    }
  }

  // Proceed with request
  return config;
};

// reference: https://axios-http.com/docs/interceptors
axios.interceptors.request.use(checkErrors(HttpErrorMap, { environment }));
axios.interceptors.response.use((response) => response, trackErrors(HttpErrorMap));

/**
 * Export
 */

export default {

  get(url) {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return axios.get(url, config)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  put(url, data) {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return axios.put(url, data, config)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  post(url, data) {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return axios.post(url, data, config)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  delete(url) {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return axios.delete(url, config)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
