<template>
  <div class="welcome-modal" :style="style">
    <VpModal ref="modal">
      <template #header>{{ modalTitle }}</template>
      {{ modalBody }}
      <template #footer>
        <VpButton class="primary medium" :style="buttonStyle['cancel']" @click="cancel">CANCEL</VpButton>
        <VpButton class="primary medium" :style="buttonStyle['proceed']" @click="proceed">SETUP PROFILE</VpButton>
      </template>
    </VpModal>
  </div>
</template>

<script>
export default {
  name: 'WelcomeModal',
  inject: ['$byodConfig'],
  computed: {
    buttonStyle() {
      return {
        cancel: {
          backgroundColor: '#FFFFFF',
          color: this.providerColor,
          border: `${this.providerColor} solid 2px`,
        },
        proceed: {
          backgroundColor: this.providerColor,
        },
      };
    },
    modalBody() {
      if (this.$byodConfig.homeAppConfig.welcomeModalBody) {
        return this.$byodConfig.homeAppConfig.welcomeModalBody;
      }
      return `Welcome to ${this.providerName}'s ${this.programName} Homeowner's App! The Homeowner's App allows you to monitor all connected devices in ${this.providerName}'s ${this.programName} Program. To get started, click the "Setup Profile" button below.`;
    },
    modalTitle() {
      if (this.$byodConfig.homeAppConfig.welcomeModalTitle) {
        return this.$byodConfig.homeAppConfig.welcomeModalTitle;
      } return 'Setup Your Profile';
    },
    providerColor() {
      return this.$byodConfig.color;
    },
    providerName() {
      return this.$byodConfig.shortName;
    },
    programName() {
      // TODO - Update needed for multi-program support
      if (this.$byodConfig.programs) {
        return this.$byodConfig.programs[0].programLongName;
      }
      return '';
    },
    style() {
      return {
        '--provider-color': this.providerColor || '#000000',
      };
    },
  },
  methods: {
    cancel() {
      this.$refs.modal.close();
    },
    proceed() {
      this.$router.push('/account/profile');
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss">
.welcome-modal {
  .vcl-modal {
    &__title {
      color: var(--provider-color) !important;
    }
  }
}
</style>
