export default {
  leaderboardStandingsTypes(state, payload) {
    state.leaderboard.standingsTypes = payload;
  },
  leaderboardParticipantList(state, payload) {
    state.leaderboard.participantList = payload;
  },
  energyGraphDateRange(state, payload) {
    state.energyGraph.dateRange = payload;
  },
  energyGraphTimeType(state, payload) {
    state.energyGraph.timeType = payload;
  },
  house(state, payload) {
    state.house = payload;
  },
  marketingMessages(state, payload) {
    state.marketingMessages = payload;
  },
  locale(state, payload) {
    state.locale = payload;
  },
};
