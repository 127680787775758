const { camelize, truncateString } = require('@virtual-peaker/vp-common').vpFilters.strings;
const { deviceKind, deviceMfg, deviceSubBrand } = require('@virtual-peaker/vp-common').vpFilters.device;
const { localizedDate: date } = require('@virtual-peaker/vp-common').vpFilters.datetime;
const { vehicleMake } = require('@virtual-peaker/vp-common').vpFilters.print;
const { humanReadableCommandMode } = require('@virtual-peaker/vp-common').vpFilters.commands;

export const $filter = {
  camelize,
  date,
  deviceKind,
  deviceMfg,
  deviceSubBrand,
  truncateString,
  vehicleMake,
  humanReadableCommandMode,
};
