import LocalizedService from '@/common/localization-formatting';

export default {
  addPhoneNumber(state, phoneNumber) {
    state.phoneNumbers.push(phoneNumber);
  },
  removePhoneNumber: (state, params) => {
    state.phoneNumbers = state.phoneNumbers.filter((num) => ((num.phone !== params.phone && (num.countryCode !== params.countryCode))));
  },
  setHouseList: (state, houseList) => {
    state.houseList = houseList;
  },
  setPhoneNumbers: (state, phoneNumbers) => {
    state.phoneNumbers = phoneNumbers;
  },
  setPhoneNumberVerified: (state, params) => {
    for (let i = 0; i < state.phoneNumbers.length; i++) {
      if ((state.phoneNumbers[i].phone === params.phone) &&
          (state.phoneNumbers[i].countryCode === params.countryCode)) {
        state.phoneNumbers[i].verified = true;
      }
    }
  },
  setProSettings: (state, newSettings) => {
    state.proSettings = newSettings;
  },
  setSelectedHouseId: (state, houseId) => {
    state.selectedHouseId = parseInt(houseId, 10);
  },
  setUser: (state, newUser) => {
    state.user = newUser;
  },
  updateLocalization: (state, countryCode) => {
    if (state.localization instanceof LocalizedService) {
      state.localizedFormat.setLocalization(state, countryCode);
    } else {
      state.localizedFormat = new LocalizedService(state, countryCode);
    }
  },
  updateSelectedLanguage: (state, newLanguage) => {
    state.selectedLanguage = newLanguage;
  },
  updateHasTouchScreen: (state, newValue) => {
    state.hasTouchScreen = newValue;
  },
  updateWindowWidth: (state, newWidth) => {
    state.windowWidth = newWidth;
    if (newWidth < 480) {
      state.breakpoint = 'mobile';
    } else if ((newWidth >= 480) && (newWidth < 768)) {
      state.breakpoint = 'tablet';
    } else if ((newWidth >= 768) && (newWidth < 834)) {
      state.breakpoint = 'tablet--large';
    } else if ((newWidth >= 834) && (newWidth < 1024)) {
      state.breakpoint = 'tablet--xlarge';
    } else if ((newWidth >= 1024) && (newWidth < 1440)) {
      state.breakpoint = 'desktop';
    } else if (newWidth >= 1440) {
      state.breakpoint = 'desktop--large';
    }
  },
  updateWindowHeight: (state, newHeight) => {
    state.windowHeight = newHeight;
  },
};
