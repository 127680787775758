import api from '@/common/api';
import i18n from '@/i18n';

function detectEventOptout(eventItem, deviceId) {
  const { deviceEventLogs } = eventItem;
  if (deviceEventLogs && deviceEventLogs.length) {
    if (deviceId) {
      if (deviceEventLogs && deviceEventLogs.length) {
        const target = deviceEventLogs.find(logItem => logItem.deviceId === deviceId);
        if (target && 'optout' in target) {
          return target.optout;
        }
        return false;
      }
    }
    const optoutAvailability = deviceEventLogs.findIndex(logItem => logItem.optout !== true);
    if (optoutAvailability === -1) {
      return true;
    }
    return false;
  }
  return false;
}

export default {
  namespaced: true,
  name: 'event',
  state() {
    return {
      activeEvents: [],
      upcomingEvents: [],
    };
  },
  actions: {
    fetchActiveEvents(context) {
      const houseId = localStorage.getItem('houseId');
      return api.get(`/house/${houseId}/event?active=true`)
        .then((activeEvents) => {
          context.commit('setActiveEvents', { activeEvents });
          return context.getters.activeEvents();
        });
    },
    fetchUpcomingEvents({ commit, getters }) {
      const houseId = localStorage.getItem('houseId');
      const now = new Date();
      // Only returns scheduled events where preview window has already started
      return api.get(`/house/${houseId}/event?active=false&start=${now.toISOString()}`)
        .then((upcomingEvents) => {
          commit('setUpcomingEvents', { upcomingEvents });
          return getters.upcomingEvents;
        });
    },
    optoutAllDevices(context, payload) {
      const { event: { id: eventId, deviceEventLogs } = {} } = payload;
      if (deviceEventLogs && deviceEventLogs.length) {
        const promises = [];
        const deviceIdList = [];
        deviceEventLogs.forEach((deviceEventLogItem) => {
          if (deviceEventLogItem.optout !== true) {
            deviceIdList.push(deviceEventLogItem.deviceId);
          }
        });
        if (deviceIdList.length) {
          deviceIdList.forEach((deviceId) => {
            promises.push(context.dispatch('optoutDevice', { eventId, deviceId }));
          });
        }
        return Promise.all(promises);
      }
      return Promise.reject(new Error(i18n.t('noDevicesToOptout')));
    },
    optoutDevice(context, payload) {
      const houseId = localStorage.getItem('houseId');
      const { eventId, deviceId } = payload;
      return api.post(`/house/${houseId}/event/${eventId}/device/${deviceId}/optout`);
    },
  },
  mutations: {
    setActiveEvents(state, payload) {
      const { activeEvents } = payload;
      state.activeEvents = activeEvents;
    },
    setUpcomingEvents(state, payload) {
      const { upcomingEvents } = payload;
      state.upcomingEvents = upcomingEvents;
    },
  },
  getters: {
    activeEvents: state => (deviceId) => {
      if (deviceId) {
        const results = [];
        state.activeEvents.forEach((eventItem) => {
          if (!detectEventOptout(eventItem, deviceId)) {
            const deviceIndex = eventItem.deviceEventLogs.findIndex(logItem => logItem.deviceId === deviceId);
            if (deviceIndex !== -1) {
              results.push(eventItem);
            }
          }
        });
        return results;
      }
      return state.activeEvents.filter(eventItem => !detectEventOptout(eventItem));
    },
    upcomingEvents: state => {
      return state.upcomingEvents.filter(eventItem => !detectEventOptout(eventItem) && eventItem.category === 'event');
    },
  },
};
