<template>
  <div class="schedule-week-display" :style="style">
    <div
      v-for="dayOfWeek of daysOfWeek"
      :key="`day-of-week-${dayOfWeek.index}`"
      class="day-of-week"
      :class="{ 'day-of-week--selected': days.includes(dayOfWeek.index) }"
      :title="`${dayOfWeek.fullName}: ${days.includes(dayOfWeek.index) ? 'Selected' : 'Not Selected'}`"
    >
      <span aria-hidden="true">{{ dayOfWeek.abbreviation }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleWeekDisplay',
  props: {
    brand: {
      type: Object,
      default: () => ({
        color: '#000000',
      }),
    },
    days: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    providerColor() {
      return this.brand.color;
    },
    style() {
      return {
        '--provider-color': this.providerColor,
      };
    },
    daysOfWeek() {
      return [
        {
          index: 1,
          abbreviation: this.$i18n.t('days.monday.1la'),
          fullName: this.$i18n.t('days.monday.long'),
        },
        {
          index: 2,
          abbreviation: this.$i18n.t('days.tuesday.1la'),
          fullName: this.$i18n.t('days.tuesday.long'),
        },
        {
          index: 3,
          abbreviation: this.$i18n.t('days.wednesday.1la'),
          fullName: this.$i18n.t('days.wednesday.long'),
        },
        {
          index: 4,
          abbreviation: this.$i18n.t('days.thursday.1la'),
          fullName: this.$i18n.t('days.thursday.long'),
        },
        {
          index: 5,
          abbreviation: this.$i18n.t('days.friday.1la'),
          fullName: this.$i18n.t('days.friday.long'),
        },
        {
          index: 6,
          abbreviation: this.$i18n.t('days.saturday.1la'),
          fullName: this.$i18n.t('days.saturday.long'),
        },
        {
          index: 0,
          abbreviation: this.$i18n.t('days.sunday.1la'),
          fullName: this.$i18n.t('days.sunday.long'),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.schedule-week-display {
  display: flex;
  column-gap: 4px;

  .day-of-week {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 30px;
    height: 20px;

    font-size: 14px;
    font-weight: 700;

    color: var(--provider-color);

    border: 1px solid var(--provider-color);
    border-radius: 20px;

      &--selected {
        color: #FFFFFF;
        background-color: var(--provider-color);
      }
  }
}
</style>
