import MessageCenter from './MessageCenter.vue';

export default [
  {
    path: '/message-center',
    redirect: () => ({
      name: 'message-center-inbox',
    }),
  },
  {
    name: 'message-center-inbox',
    path: '/message-center/inbox',
    component: MessageCenter,
    props: { mode: 'inbox' },
    children: [
      {
        path: 'message/:messageId',
        props: { mode: 'inbox' },
      },
    ],
  },
  {
    name: 'message-center-favorites',
    path: '/message-center/favorites',
    component: MessageCenter,
    props: { mode: 'favorites' },
    children: [
      {
        path: 'message/:messageId',
        props: { mode: 'favorites' },
      },
    ],
  },
  {
    name: 'message-center-archived',
    path: '/message-center/archived',
    component: MessageCenter,
    props: { mode: 'archived' },
    children: [
      {
        path: 'message/:messageId',
        props: { mode: 'archived' },
      },
    ],
  },
];
