<template>
  <form id="security">
    <div v-if="hasPassword" class="old-password">
      <vp-input v-model="oldPassword" tabindex="0" :placeholder="$t('oldPasswordPlaceholder')" type="password" label="Password" height="50px" :styles="{ placeholderColor: '#4f4f4f' }" required>
        <template #label>{{ $t('oldPassword') }}</template>
      </vp-input>
    </div>

    <div id="password-requirements">
      {{ $t('newPasswordInstructions') }}
    </div>
    <div class="new-password">
      <vp-input v-model="newPassword1" :placeholder="$t('newPasswordPlaceholder')" type="password" label="Password" height="50px" :styles="{ placeholderColor: '#4f4f4f' }" required @change="isPasswordValid(newPassword1)">
        <template #label>{{ $t('newPassword') }}</template>
      </vp-input>
    </div>

    <div class="password-alert">{{ validityMessage }}</div>

    <div class="new-password-2">
      <vp-input v-model="newPassword2" :placeholder="$t('newPasswordConfirm')" type="password" label="Password" :styles="{ placeholderColor: '#4f4f4f' }" :disabled="error" height="50px" required>
        <template #label>{{ $t('newPasswordConfirm') }}</template>
      </vp-input>
    </div>

    <vp-button id="submit-button"
               :disabled="!passwordFormValid()"
               :style="buttonCSS"
               class="medium" @click="resetPassword(oldPassword, newPassword1)"
    >
      {{ hasPassword ? $t('resetPassword') : $t('setPassword') }}
    </vp-button>
    <span>{{ passwordMessage }}</span>
  </form>
</template>

<script>
import { mapState } from 'vuex';

const PasswordValidator = require('password-validator');

export default {
  name: 'AccountSecurity',
  inject: ['$byodConfig'],
  data: () => ({
    name: localStorage.getItem('firstName'),
    emailAddress: localStorage.getItem('email'),
    item: {},
    message: '',
    validityMessage: '',
    busy: true,
    error: false,
    oldPassword: null,
    newPassword1: null,
    newPassword2: null,
    hasPassword: true,
    passwordMessage: '',
  }),
  computed: {
    ...mapState('account', {
      accountId: 'accountId',
    }),
    schema() {
      const validator = new PasswordValidator();
      validator
        .is().min(12)
        .has().uppercase()
        .has()
        .lowercase()
        .has()
        .digits();
      return validator;
    },
    buttonCSS() {
      return {
        'background-color': this.providerColor,
        border: `solid 2px ${this.providerColor}`,
      };
    },
    providerColor() { return this.$byodConfig.color; },
  },
  created() {
    this.$store.dispatch('account/get')
      .then((data) => {
        this.item = data;
        this.hasPassword = data.hasPassword;
      })
      .finally(() => {
        this.busy = false;
      });
  },
  methods: {
    isPasswordValid(password) {
      if (!this.schema.validate(password)) {
        this.validityMessage = this.$i18n.t('passwordErrorRequirements');
        this.error = true;
        return false;
      }
      if (password === this.emailAddress) {
        this.validityMessage = this.$i18n.t('passwordErrorEmail');
        this.error = true;
        return false;
      }
      if (password === this.name) {
        this.validityMessage = this.$i18n.t('passwordErrorName');
        this.error = true;
        return false;
      }
      this.validityMessage = '';
      this.error = false;
      return true;
    },
    passwordFormValid() {
      return (this.item.hasPassword ? this.oldPassword : true) && (this.newPassword1 && this.newPassword1 === this.newPassword2);
    },
    resetPassword(oldPassword = null, newPassword) {
      return this.$store.dispatch('account/updatePassword', { oldPassword, newPassword })
        .then(() => {
          return this.$store.dispatch('account/get');
        })
        .then((data) => {
          this.item = data;
          this.hasPassword = true;
          this.passwordMessage = this.$i18n.t('passwordSuccess');
          this.oldPassword = null;
          this.newPassword1 = null;
          this.newPassword2 = null;
          setTimeout(() => {
            this.passwordMessage = '';
          }, 4000);
        })
        .catch((error) => {
          if (error.response.data === 'The old password does not match') {
            this.passwordMessage = this.$i18n.t('passwordErrorMismatch');
          } else {
            this.passwordMessage = this.$i18n.t('passwordErrorRequirements');
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

#security {
  padding: 0px 24px 90px 24px;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0.5rem;
  .vcl-input{
    &__label {
      font-size: 1rem;
    }
    &__input {
      &.has-focus {
        border: 2px solid var(--provider-color);
      }
    }
  }
}

#password-requirements {
  font-size: .875rem;
  line-height: 1.5rem;
  margin: 16px 0px 14px 0px;
}

#submit-button {
  max-width: 250px;
  margin-top: 16px;
}

@include Desktop {
  #security {
    background: #FBFBFB;
    padding: 24px 24px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  }
}
</style>
