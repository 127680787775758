<template>
  <div id="topBar" :style="bodyCSS">
    <div id="navInner">
      <div id="utilityLogoContainer">
        <img id="utilityLogo" :src="logoLightSmall" :alt="providerName" @click="goHome">
      </div>
      <ul id="topbarHouseInfo">
        <li id="singleHouseLabel" v-if="houseList.length == 1">{{ selectedHouse.address }}</li>
        <li id="topbarHouseDropdown" v-if="houseList.length > 1" @click="houseMenuClicked" tabindex="0" @keydown="houseMenuKeydown" @blur="houseMenuFocusLost" aria-haspopup="menu" :aria-expanded="houseMenuVisible">
          <a id="topBarHouseLabel">{{ selectedHouse.address}}</a>
          <ul id="topBarHouseDropdown" class="dropdown" ref="houseMenuDropdown" v-show="houseMenuVisible">
            <li v-for="house in houseList" :key="house.id" class="title" @click="switchHouse(house.id)">
              <a @click="switchHouse(house.id)">{{ house.address }}</a>
            </li>
          </ul>
        </li>
      </ul>

      <div id="mobileHeader">
        <div id="topBarProviderLogo">
          <img id="topBarProviderLogoImg" :src="logoLight" :alt="providerName">
          <ul id="mobileHouseInfo">
            <li class="mobileHouseItem" v-if="houseList.length == 1">
              <span class="mobileLabel">{{ $filter.truncateString(selectedHouse.address, { maxChars: 32 }) }}</span>
            </li>
            <li class="mobileHouseItem" ref="houseMenu" v-if="houseList.length > 1" @click="mobileLabelClicked">
              <a id="topBarHouseLabel" class="mobileLabel">{{ selectedHouse.address }}</a>
              <ul id="mobileBarHouseDropdown" class="dropdown" v-show="mobileHouseMenuVisible">
                <li v-for="choice in houseList" :key="choice.id" class="title" @click="switchHouse(choice.id)">
                  <a @click="switchHouse(choice.id)">{{ choice.nickname || choice.address }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="mobileLanguageButton" v-if="multipleLanguages">
          <LanguageButton />
        </div>
      </div><!-- end mobileHeader -->

      <div id="topBarLeftDiv">&nbsp;</div>
      <div id="topBarCenterDiv">&nbsp;</div>
      <div id="topBarRightDiv"></div>

      <ul id="topbarRightContent">
        <li id="topbarUserDropdown" ref="userMenu" @click="userMenuClicked" tabindex="0" @keydown="userMenuKeydown" @blur="userMenuFocusLost" aria-haspopup="menu" :aria-expanded="userMenuVisible">
          <a id="topbarUsername">
            <i class="fa fa-user fa-lg" aria-hidden="true"></i>
            &nbsp; {{ user.firstName || 'You'}}
          </a>
        </li>
        <ul id="userNameDropdown" ref="userMenuDropdown" class="dropdown" v-show="userMenuVisible">
          <li>
            <router-link :to="{ name: 'account-profile' }">
              {{ $t('accountTab', { tab: 'profile' }) }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'account-preferences' }">
              {{ $t('notificationPreferences') }}
            </router-link>
          </li>
          <li id="logoutMenuItem">
            <a @click="logout()">
              {{ $t('logout') }}
            </a>
          </li>
        </ul>
        <li id="topbarLanguageButton" v-if="multipleLanguages">
          <LanguageButton />
        </li>
      </ul>
    </div><!-- end navInner -->
  </div><!-- end topBar -->
</template>
<script>
export default {
  name: 'TopMenu',
  inject: ['$byodConfig', '$filter'],
  data() {
    return {
      houseMenuSelectionIndex: -1,
      houseMenuVisible: false,
      mobileHouseMenuVisible: false,
      userMenuSelectionIndex: -1,
      userMenuVisible: false,
    };
  },
  computed: {
    bodyCSS() {
      return {
        '--provider-color': this.$byodConfig.color,
      };
    },
    houseList: {
      get() {
        return this.$store.getters.houseList;
      },
      set(newHouseList) {
        this.$store.commit('setHouseList', newHouseList);
      },
    },
    logoLight() {
      return this.$byodConfig.logoLight;
    },
    logoLightSmall() {
      return this.$byodConfig.logoLightSmall;
    },
    multipleLanguages() {
      const multiLanguageConfig = this.$byodConfig.commonSettings.find(({ key }) => key === 'byodMultipleLanguages');
      if (multiLanguageConfig !== undefined) {
        return multiLanguageConfig.value;
      } else {
        return false;
      }
    },
    providerName() {
      return this.$byodConfig.name;
    },
    selectedHouse() {
      if (this.selectedHouseId) {
        const selectedHouse = this.houseList.find((house) => (house.id === this.selectedHouseId));
        if (selectedHouse) {
          return selectedHouse;
        } else { return {}; }
      } return {};
    },
    selectedHouseId: {
      get() {
        return this.$store.getters.selectedHouseId;
      },
      set(houseId) {
        this.$store.commit('setSelectedHouseId', houseId);
      },
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' }).catch(() => {});
    },
    hideHouseMenu() {
      this.houseMenuVisible = false;
      this.houseMenuSelectionIndex = -1;
    },
    hideUserMenu() {
      this.userMenuVisible = false;
      this.userMenuSelectionIndex = -1;
    },
    houseMenuClicked() {
      this.houseMenuVisible = !this.houseMenuVisible;
    },
    houseMenuKeydown(event) {
      const menuItems = this.$refs.houseMenuDropdown.children;
      if ((event.code === 'Space' || event.code === 'Enter')) {
        if (!this.houseMenuVisible) {
          this.showHouseMenu();
        } else {
          if (this.houseMenuSelectionIndex === -1) {
            this.hideHouseMenu();
          } else {
            menuItems[this.houseMenuSelectionIndex].children[0].click();
            this.hideHouseMenu();
          }
        }
      } else if (event.code === 'Escape') {
        this.hideHouseMenu();
      } else if (event.code === 'ArrowDown') {
        if (this.houseMenuSelectionIndex === -1) {
          this.houseMenuSelectionIndex = 0;
        } else {
          this.houseMenuSelectionIndex++;
          if (this.houseMenuSelectionIndex === menuItems.length) {
            this.houseMenuSelectionIndex = 0;
          }
        }
      } else if (event.code === 'ArrowUp') {
        if (this.houseMenuSelectionIndex === -1) {
          this.houseMenuSelectionIndex = (menuItems.length - 1);
        } else {
          this.houseMenuSelectionIndex--;
          if (this.houseMenuSelectionIndex === -1) {
            this.houseMenuSelectionIndex = (menuItems.length - 1);
          }
        }
      }
    },
    houseMenuFocusLost(event) {
      this.hideHouseMenu();
    },
    logout() {
      this.$store.dispatch('shell/logout');
    },
    mobileLabelClicked() {
      this.mobileHouseMenuVisible = !this.mobileHouseMenuVisible;
    },
    switchHouse(houseId) {
      localStorage.setItem('houseId', houseId);
      this.selectedHouseId = houseId;
      this.$router.push({ path: '/' }).catch(() => {});
    },
    showHouseMenu() {
      this.houseMenuVisible = true;
    },
    showUserMenu() {
      this.userMenuVisible = true;
    },
    userMenuClicked(event) {
      this.userMenuVisible = !this.userMenuVisible;
    },
    userMenuFocusLost(event) {
      setTimeout(this.hideUserMenu, 250);
    },
    userMenuKeydown(event) {
      const menuItems = this.$refs.userMenuDropdown.children;

      if ((event.code === 'Space' || event.code === 'Enter')) {
        if (!this.userMenuVisible) {
          this.showUserMenu();
        } else {
          if (this.userMenuSelectionIndex === -1) {
            this.hideUserMenu();
          } else {
            menuItems[this.userMenuSelectionIndex].children[0].click();
            this.hideUserMenu();
          }
        }
      } else if (event.code === 'Escape') {
        this.hideUserMenu();
      } else if (event.code === 'ArrowDown') {
        if (this.userMenuSelectionIndex === -1) {
          this.userMenuSelectionIndex = 0;
        } else {
          this.userMenuSelectionIndex++;
          if (this.userMenuSelectionIndex === menuItems.length) {
            this.userMenuSelectionIndex = 0;
          }
        }
      } else if (event.code === 'ArrowUp') {
        if (this.userMenuSelectionIndex === -1) {
          this.userMenuSelectionIndex = (menuItems.length - 1);
        } else {
          this.userMenuSelectionIndex--;
          if (this.userMenuSelectionIndex === -1) {
            this.userMenuSelectionIndex = (menuItems.length - 1);
          }
        }
      }
    },
  },
  watch: {
    houseMenuSelectionIndex() {
      const menuItems = this.$refs.houseMenuDropdown.children;
      for (let i = 0; i < menuItems.length; i++) {
        menuItems[i].className = '';
      }
      if ((this.houseMenuSelectionIndex !== -1) && menuItems[this.houseMenuSelectionIndex]) {
        menuItems[this.houseMenuSelectionIndex].className = 'selected';
        menuItems[this.houseMenuSelectionIndex].scrollTo();
      }
    },
    userMenuSelectionIndex() {
      const menuItems = this.$refs.userMenuDropdown.children;
      for (let i = 0; i < menuItems.length; i++) {
        menuItems[i].className = '';
      }
      if ((this.userMenuSelectionIndex !== -1) && menuItems[this.userMenuSelectionIndex]) {
        menuItems[this.userMenuSelectionIndex].className = 'selected';
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/mixins.scss';

div#topBar {
 @include Mobile--Only {
   height:100px;
 }

 @include Tablet {
   height:100px;
 }

 @include Desktop {
   height: 60px;
 }

 background-color: var(--provider-color);
 box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
 height: 60px;
 position:fixed;
 width: 100%;
 z-index: 901;
}

#navInner {
 height: 100%;

 @include Mobile--Only {
   width:100%;
 }

 @include Tablet {
   width:100%;
 }

 @include Desktop {
   width:auto;
 }
}

div#navInner ul li {
  list-style-type: none;
}

div#utilityLogoContainer {
 float:left;
 height: 60px;
 line-height: 60px;
 padding-left:10px;

 @include Mobile--Only {
  display:none;
 }

 @include Tablet {
  display:none;
 }

 @include Desktop {
  display:inline-block;
 }
}

img#utilityLogo {
 margin: auto;
 margin-top:-6px;
 height: 50px;
 width: auto;
}

#topBarProviderLogo {
 @include Mobile--Only {
   height:88px;
   padding-top:10px;
   width:250px;
 }

 @include Tablet {
   height:88px;
   padding-top:10px;
   width:250px;
 }
}

#topBarProviderLogoImg {
 height: 64px;
}

#mobileHeader {
 display: -ms-flexbox;
 display: flex;
 -ms-flex-pack: justify;
 justify-content: space-between;
 -ms-flex-line-pack: space-evenly;
 align-content: space-evenly;
 width: 100%;
 height: 100%;
 padding: 0px 20px;

 @include Desktop {
   display:none;
 }

 #mobileHouseInfo {
   margin-top: 18px;
 }
}

a.mobileLabel {
  color: #F4F7F8;
  font-size: 13px;
}

span.mobileLabel {
  color: #F4F7F8;
  font-size: 13px;
}

#topBarLeftDiv {
  display: none;
}

#topBarCenterDiv {
  display: none;
}

#topBarRightDiv {
  display: none;
  float: right;
  margin-right: 32px;
  margin-top: 50px;
}

#topbarRightContent {
 float: right;
 margin-top:0px;
 padding-right: 32px;

  display: flex;
  align-items: center;
  height: 60px;

 @include Mobile--Only {
   display:none;
 }

 @include Tablet {
   display:none;
 }

 @include Desktop {
   display: -ms-flexbox;
   display: flex;
 }
}

#singleHouseLabel, #topBarHouseLabel, #topbarUserDropdown {
 color: #F4F7F8;
 display: inline-block;
}

#topbarUserDropdown {
 padding-right:20px;
}

#topbarUsername {
  color: #F4F7F8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#topbarUsername::after, #topBarHouseLabel::after {
 color: #F4F7F8;
 content: "\25be";
 display: inline-block;
 margin-left:6px;
 top: 0;
}

#topbarHouseInfo {
 margin-top:0px;
 margin-bottom:0px;
 padding-left:32px;
 line-height: 60px;

 @include Mobile--Only {
   display:none;
 }

 @include Tablet {
   display:none;
 }

 @include Desktop {
   display:inline-block;
 }

}

#topbarHouseDropdown:focus-visible, #topbarUserDropdown:focus-visible {
 border:2px solid white;
}

#topbarUserDropdown li {
 color: #F4F7F8;
}

#userNameDropdown, #topBarHouseDropdown, #mobileBarHouseDropdown {
 background-color: #fff;
 border: 1px solid #DBDBDB;
 border-radius: 6px;
 box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
 margin-right: 8px;
 margin-top: 8px;
 padding: 8px 0px 8px 0px !important;
 position: absolute;
}

#topBarHouseDropdown, #mobileBarHouseDropdown {
 display:block;
 margin-left: -20px;
 max-height:320px;
 overflow-y:auto;
 scroll-snap-type: y mandatory;
 scroll-padding-top:60px;
 scroll-padding-bottom:0px;
 width: 280px;
}

#userNameDropdown {
  margin-top: 208px;
  width: 225px;
  position: absolute;
  right: 24px;
}

#userNameDropdown li, #topBarHouseDropdown li, #mobileBarHouseDropdown li {
  background-color: #white;
  border-radius:0px !important;
  border-top: 1px solid #DBDBDB;
  color: #30619C;
  cursor: pointer;
  font-size: 16px;
  line-height: 42px !important;
  text-align: left;
}

#userNameDropdown li#logoutMenuItem {
  border-bottom: 1px solid #DBDBDB;
}

#topBarHouseDropdown li a, #mobileBarHouseDropdown li a, #userNameDropdown li a {
 color: var(--provider-color) !important;
 display: inline-block;
 padding: 0px 20px !important;
 text-decoration:none;
 width: 100%;
}

#topBarHouseDropdown li:hover, #topBarHouseDropdown li.selected, #mobileBarHouseDropdown li:hover, #mobileBarHouseDropdown li.selected, #userNameDropdown li:hover, #userNameDropdown li.selected {
 background-color: var(--provider-color) !important;
}
#topBarHouseDropdown li:hover a, #topBarHouseDropdown li.selected a, #mobileBarHouseDropdown li:hover a, #mobileBarHouseDropdown li.selected a, #userNameDropdown li:hover a, #userNameDropdown li.selected a {
 color:#fff !important;
}

#userNameDropdown li a, #topBarHouseDropdown li a, #mobileBarHouseDropdown li a {
  color: #F4F7F8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#mobileLanguageButton {
 height: 40px;
 margin-top: 40px;

 @include Mobile--Only {
  display: inline-block;
 }

 @include Tablet {
  display: inline-block;
 }

 @include Desktop {
   display:none;
 }
}

#mobileBarHouseDropdown {
  background-color: #fff !important;
  border: 1px solid #DBDBDB;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height:40vh;
  margin-left: -20px;
  margin-right: 8px;
  overflow-x:clip;
  overflow-y:scroll;
  padding:0px;
  position: absolute;
  width: 275px;
}

#mobileBarHouseDropdown li.title a {
 color:#4f4f4f;
 cursor:pointer;
}

.mobileHouseItem {
 margin-left:16px;
 margin-top:-20px;
 white-space: nowrap;
 overflow-x: clip;
}
</style>
