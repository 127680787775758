export default {
  methods: {
    applyPhoneNumberLocalization(locale, number) {
      if (locale === 'US') {
        return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`;
      } else if (locale === 'AU') {
        return `0${number.substring(0, 3)} ${number.substring(3, 6)} ${number.substring(6, 9)}`;
      }
    },
  },
};
