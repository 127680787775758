<template>
  <div class="percent-bar" :style="style">
    <div class="percent-bar__bg">
      <div class="percent-bar__fg"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PercentBar',
  // inject: ['$byodConfig'],
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // providerColor() {
    //   return this.$byodConfig.color || '';
    // },
    percent() {
      let pct = this.value;
      pct = Math.min(pct, 100);
      pct = Math.max(pct, 0);
      return pct ? `${pct}%` : '0%';
    },
    style() {
      return {
        '--bg-color': '#393939',
        '--fg-color': '#5BBFBD',
        '--percent': this.percent,
      };
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/styles/mixins';

  .percent-bar {
    width: 89.04px;
    height: 6.67px;

    &__bg {
      width: 100%;
      height: 100%;

      border-radius: 50px;

      background: var(--bg-color);
    }

    &__fg {
      width: var(--percent);
      height: 100%;

      border-radius: 50px;

      background: var(--fg-color);
    }

    @include Tablet--Large {
      width: 106.02px;
      height: 10px;
    }

    @include Desktop {
      width: 100px;
      height: 10px;
    }

    @include Desktop--Large {
      width: 124px;
      height: 10px;
    }
  }
</style>
