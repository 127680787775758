export default {
  absolute: 'Absolute',
  accountError: 'There was an error updating the account.',
  accountProfile: 'Account Profile',
  accountSecurity: 'Account Security',
  accountSuccessBody: 'Your account has been updated.',
  accountSuccessHead: 'Success',
  accountTab: 'Account {tab}',
  actual: 'Actual',
  addNumber: 'Add Number',
  addPhoneNumber: '+ Add a phone number',
  amps: 'Amps',
  archived: 'Archived',
  atHome: 'At Home',
  attachFile: 'Attach File',
  authenticatePIN: 'Authenticate PIN',
  auto: 'Auto',
  automaticSelfConsumption: 'Automatic Self-Consumption',
  autoSchedule: 'The Auto Schedule feature learns your habits and builds a schedule around them – all while saving money. Turn it on and use your device like you normally would. After one week Auto Schedule will do all the work.',
  autoScheduleHead: 'Save Green and Be Green With Auto Schedule',
  auxHeat: 'Aux Heat Only',
  available: 'Available',
  backup: 'Backup',
  backToDashboard: 'Back to Dashboard',
  baseline: 'Baseline',
  boost: 'Boost',
  byPower: 'By Power',
  byPV: 'By PV',
  cancel: 'Cancel',
  capacity: 'Capacity',
  chargeFromGridCurrent: 'Charge From Grid by Current',
  chargeFromGridPower: 'Charge From Grid by Power',
  chargeFromPV: 'Charge From PV',
  charging: 'Charging',
  checkEmail: 'Check Your Email',
  checkPIN: 'Check your email for a 5-digit PIN number to sign in.',
  cleanBackup: 'Clean Backup',
  close: 'Close',
  cloudCoverage: 'Cloud Coverage',
  codeError: 'Incorrect code. Please try again.',
  codePlaceholder: 'Enter Verification Code',
  codePlaceHolder: 'Example: XXXXXXX',
  codeSent: 'Verification Code sent; please check your phone.',
  compLower: 'Comp and Lower',
  compressor: 'Compressor',
  confirm: 'Confirm',
  cool: 'Cool',
  coolSetpoint: 'Cool Setpoint',
  cooling: 'Cooling',
  coolingSetpoint: 'Cooling Setpoint',
  currentConditions: 'Currently',
  currentlyRunning: 'Currently Running',
  curtailment: 'DR Curtailment',
  date: 'Date',
  datePickerDay: 'Day',
  datePickerMonth: 'Month',
  datePickerWeek: 'Week',
  days: {
    monday: {
      long: 'Monday',
      short: 'Mon',
      '1la': 'M',
    },
    tuesday: {
      long: 'Tuesday',
      short: 'Tue',
      '1la': 'T',
    },
    wednesday: {
      long: 'Wednesday',
      short: 'Wed',
      '1la': 'W',
    },
    thursday: {
      long: 'Thursday',
      short: 'Thu',
      '1la': 'T',
    },
    friday: {
      long: 'Friday',
      short: 'Fri',
      '1la': 'F',
    },
    saturday: {
      long: 'Saturday',
      short: 'Sat',
      '1la': 'S',
    },
    sunday: {
      long: 'Sunday',
      short: 'Sun',
      '1la': 'S',
    },
  },
  defaultMode: 'Default Mode',
  defaultSetpoint: 'Default Setpoint',
  deleteDevice: 'Delete Device',
  deleteMessage: 'Delete',
  deleteNumber: 'Delete',
  deleteSchedule: 'DELETE SCHEDULE',
  desiredSiteMeter: 'Desired Site Meter Reading',
  device: 'Device',
  deviceBattery: 'Battery | {n} Batteries',
  deviceBatteryCapacity: 'Capacity',
  deviceBatteryConsumption: 'Self-consumption',
  deviceBatteryPower: 'Battery Power',
  deviceControl: 'Device Control',
  deviceDefaultMode: 'Default Mode',
  deviceDefaultSetpoint: 'Default Set Point',
  deviceEv: 'EV | {n} EVs',
  deviceEvCharger: 'EV Charger | {n} EV Chargers',
  deviceEVPower: 'Charge Power',
  deviceFan: 'Fan Mode',
  deviceGE: 'Water Heater or Window AC',
  deviceInstructions: 'Give your device an easy name to remember like "Main Waterheater" or "Bedroom Air Conditioner."',
  deviceKind: 'Device Kind',
  deviceLimitNoAccess: 'This device manufacturer carefully protects your thermostat\'s information. You can view or control your device manually or from the manufacturer\'s recommended device application.',
  deviceLimitNoControl: 'This device manufacturer limits your ability to control your device from our application. You can make adjustments manually or from the manufacturer\'s recommended device application.',
  deviceManufacturer: 'Device Manufacturer',
  deviceMode: 'Mode',
  deviceName: 'Device Name',
  deviceOffline: 'Device Offline',
  deviceOptOutCopy: 'There is a { duration } event scheduled on { date } at { time }. Uncheck the boxes next to the devices that you do not want to participate in this event.',
  deviceOptOutHead: 'Participating Devices',
  deviceOptOutModal: 'After clicking submit, your opt-out preferences will be updated. It could take up to 90 seconds to complete the opt-out process.',
  deviceOverCopy: 'You can still participate in future demand response events. Learn more about how they help save energy and keep the electric grid running smoothly by visiting { supportEmail }.',
  deviceOverHead: 'This Event is Already Over',
  devicePower: 'Power',
  devicePowerLimit: 'Power Limit',
  deviceReconnect: 'Reconnect your device to see control options.',
  deviceRoomAC: 'Room Air Conditioner | {n} Room Air Conditioners',
  deviceSensibo: 'Mini-Split o Window AC',
  deviceSettings: 'Device Settings',
  deviceStatus: 'Device Status',
  deviceThermostat: 'Thermostat | {n} Thermostats',
  deviceTOU: 'Device TOU',
  deviceTstatSetpoint: 'Set Point',
  deviceTstatTemp: 'Actual Temperature',
  deviceType: 'Device Type',
  deviceUid: 'ID Number',
  deviceUnknown: 'Unknown',
  deviceUnreachable: 'Device Unreachable',
  deviceWaterHeater: 'Water Heater | {n} Water Heaters',
  directMessage: 'Direct Messages',
  directMessageTooltip: 'Please note that Direct Messages will be used for support and to provide critical information about your device. We strongly advise against unsubscribing from these messages.',
  disableGrid: 'Disable Grid',
  dispatchCurrent: 'Dispatch by Current',
  dispatchPower: 'Dispatch by Power',
  dispatchPV: 'Dispatch by PV',
  dispatchSiteDemand: 'Dispatch by Site Demand',
  drEventHead: 'Hi {name}, today is a demand response day!',
  drEventMessage: 'There is an event today that will affect the following devices. Don’t worry, you don’t have to do a thing. Just relax and enjoy helping keep your rates low. You can opt out of the event, but you might miss out on some of the benefits!',
  eco: 'Eco',
  efficiency: 'Efficiency',
  electric: 'Electric',
  email: 'Email',
  emailAddress: 'Email Address',
  emailDisclaimer: 'Since your email address is used in the login process, we need to validate any changes. To start the process of changing it, please contact',
  emailHelp: 'For help, contact { supportEmail }.',
  emailPlaceholder: 'Enter your email address',
  enabled: 'Enabled',
  endTime: 'Until {event end time}',
  energy: 'Energy',
  energyBattery: 'NET ENERGY FOR BATTERY',
  energyConsumption: 'Energy Consumption',
  energyEvse: 'TOTAL ENERGY USED ON EV CHARGING',
  energyHwH: 'TOTAL ENERGY USED BY WATER HEATER',
  energySaver: 'Energy Saver',
  energyUsage: 'Energy Usage',
  enterPhoneVerificationCode: 'Enter verification code for {phoneNumber}',
  enterPIN: 'Enter PIN',
  equipmentStatus: 'Equipment Status',
  error: 'Error',
  error404: 'This page was not found. (Error 404)',
  errorGenericHead: 'We Experienced an Error',
  errorGenericText: 'The following error was encountered: {error}. The following actions were taken: {actions}',
  errorInactive: 'Account Inactive',
  errorNotFound: 'Not Found',
  ev: 'EV',
  evAwayPower: 'Away Power',
  evBatteryCapacity: 'EV Battery Capacity',
  evBatteryLevel: 'EV Battery Level',
  evBatteryRange: 'EV Battery Range',
  evChargerHistory: 'EV Charger History',
  evChargingStatus: 'Charging Status',
  evDeviceMfg: 'Manufacturer',
  evDeviceName: 'Name',
  evDeviceSettings: 'EV Settings',
  evDeviceStatus: 'EV Status',
  evDeviceChargeStatus: 'EV Charge Status',
  evDeviceLocation: 'EV Location',
  evHomePower: 'Home Power',
  evLocationStatus: 'Located at Home',
  evPluggedInStatus: 'Charger Plugged In',
  evRemainingRange: 'EV Remaining Range',
  evRemainingRangeFooter: '{evRangeUnit} the vehicle can travel',
  evStateOfCharge: 'EV State of Charge',
  evUpdateDevice: 'Update Vehicle',
  evVehicleModel: 'Model of Vehicle',
  evVehicleYear: 'Model Year',
  eventCalendarEmpty: 'Looking Forward to Your First Event!',
  eventCalendarResults: 'Event Day Results',
  eventDevices: 'All Devices',
  eventDuration: 'Event Duration:',
  eventEnergy: 'Total Event Energy Used',
  eventEnergyBattery: 'ENERGY DELIVERED BY BATTERY DURING EVENT',
  eventEnergyEvse: 'ENERGY USED ON EV CHARGING DURING EVENT',
  eventEnergyHwh: 'ENERGY USED BY WATER HEATER FOR EVENT',
  eventEnergySavings: 'Event Energy Savings',
  eventHead: 'Energy Management Event in Progress',
  eventOptout: 'Opt out of event',
  eventOptOut: 'Opt-Outs',
  eventParticipation: 'Participation',
  eventRuntimeTstat: 'RUNTIME DURING EVENT',
  events: 'Events',
  eventsResults: 'Out of {total} events called',
  eventsSuccess: 'SUCCESSFUL EVENTS',
  eventStartTime: 'Event Start Time:',
  eventText: 'Changing the controls or opting out of this event could make you inelligible for savings!',
  eventToastActiveBody: 'today is a demand response day. A Demand Response Day means that we will be controlling your device from',
  eventToastActiveTitle: 'You have an event today!',
  eventToastOptout: 'You can opt out of this event by <a class="event-optout">clicking here</a>.',
  eventToastUpcomingBody: 'an event has been scheduled for your device. We will be controlling your device on',
  eventToastUpcomingTitle: 'You have a scheduled event!',
  eventUnresponsive: 'Includes unresponsive devices',
  expiredLink: 'It looks like you tried to login with an expired link. Submit your email address, again, to have a new link sent.',
  extension: 'Extension',
  failedToOptoutOfEvent: 'Failed to opt out of event.',
  fanOnly: 'Fan Only',
  favoriteMessage: 'Favorite',
  favorites: 'Favorites',
  firstName: 'First Name',
  forecastDay: 'DAY',
  forecastEvening: 'EVENING',
  forecastToday: 'Today',
  from: 'From',
  fullyCharged: 'Fully Charged',
  generalNotification: 'General announcements and notifications',
  getStarted: 'Please select a message to get started.',
  gridTie: 'Grid Tie',
  heat: 'Heat',
  heating: 'Heating',
  heatingSetpoint: 'Heating Setpoint',
  heatPump: 'Heat Pump',
  high: 'High',
  highDemand: 'High Demand',
  holding: 'Holding',
  holdSetpoint: 'Hold Setpoint',
  homeownerSignIn: 'Homeowner Sign In',
  hour: 'hour',
  housePower: 'House Power',
  hwhDefaultHead: 'Please enter the following details about your water heater:',
  hwhDefaultMode: 'Default Water Heater Mode',
  hwhDefaultSetpoint: 'Default Water Heater Set Point',
  hybrid: 'Hybrid',
  idNumber: 'ID Number',
  inactiveMessage: 'Your account is inactive. Please contact support, if you think this is in error.',
  inbox: 'Inbox',
  intelligence: 'Intelligence',
  lastName: 'Last Name',
  latestActivity: 'Latest Activity',
  leaderboard: 'Leaderboard',
  learning: 'Learning',
  learningMessage: '{deviceId} is still learning',
  learningMode: 'It can take a couple of weeks to learn the best way to help you save money. Use your device normally, and we’ll do the rest.',
  learningModeHead: 'Your device is in learning mode',
  limitDispatch: 'Limit Dispatch',
  linkError: 'Error Sending Link',
  loaded: 'Loaded',
  loadEvents: 'Load More',
  loading: 'Loading...',
  loadingMessage: 'Improving the IQ of the Smart Grid…',
  loadingMessage2: 'Reducing carbon output with compute capacity.',
  loadshifting: 'DR Loadshifting',
  lockedOut: 'You have been locked out because of too many failed attempts. You can try again in 15 minutes.',
  loginError: 'Error Logging In',
  loginErrorWithContact: 'There was an error when you were trying to log in. If you need help, please contact us at {supportEmail}.',
  loginFailure: 'Your login attempt failed.',
  logout: 'Logout',
  low: 'Low',
  magicLinkError: 'There was an error sending you a Magic Link. Please, enter your email in the following page.',
  magicLinkMessage: 'The magic link should arrive in your email inbox shortly. You can close this browser window now.',
  manual: 'Manual',
  manufacturer: 'Manufacturer',
  maxCapacity: 'Max Capacity',
  maxTemperature: 'Max Temperature',
  maxTempDisclaimer: 'Most water heaters can be set to a maximum of 140F, but if there is a thermostatic mixing valve, it may be capable of 160F or more.',
  medium: 'Medium',
  message: 'Message',
  messageCenter: 'Message Center',
  messageCenterWelcome: 'Welcome to your Message Center!',
  messageInstructions: 'Type message here',
  minTempDisclaimer: 'This defines the lowest temperature that your water heaters can be set to during optimization events. If you feel that the hot water outlet temperature is too low during certain times of the day, set this to a higher a number.',
  minTemperature: 'Min Temperature',
  mixingValve: 'Mixing Valve',
  mixingValveDisclaimer: 'This device has a mixing valve, and I confirm that I understand the safety considerations laid out in my utility\'s Terms and Conditions.',
  mixingValveWarning: 'Mixing Valve Warning',
  mode: 'Mode',
  months: {
    january: {
      long: 'January',
      short: 'Jan',
    },
    february: {
      long: 'February',
      short: 'Feb',
    },
    march: {
      long: 'March',
      short: 'Mar',
    },
    april: {
      long: 'April',
      short: 'Apr',
    },
    may: {
      long: 'May',
      short: 'May',
    },
    june: {
      long: 'June',
      short: 'Jun',
    },
    july: {
      long: 'July',
      short: 'Jul',
    },

    august: {
      long: 'August',
      short: 'Aug',
    },
    september: {
      long: 'September',
      short: 'Sept',
    },
    october: {
      long: 'October',
      short: 'Oct',
    },
    november: {
      long: 'November',
      short: 'Nov',
    },
    december: {
      long: 'December',
      short: 'Dec',
    },
  },
  monthlyResults: 'Your {month} Results',
  newPassword: 'New Password',
  newPasswordConfirm: 'Confirm New Password',
  newPasswordInstructions: 'Please enter a new password. It must be 12 characters or longer and include the following items: At least one uppercase letter, at least one lowercase letter and at least one number.',
  newPasswordPlaceholder: 'Enter New Password',
  newSchedule: 'Add New Schedule',
  nickname: 'Nickname',
  noDevicesToOptout: 'No devices to optout.',
  none: 'None',
  noSubject: 'No Subject',
  notCharging: 'Not Charging',
  notificationPreferences: 'Notification Preferences',
  notifications: 'Notifications',
  notify: 'Notify me…',
  oauthSuccess: 'Success!',
  oauthSuccessCopy: 'Thanks for completing your device enrollment',
  off: 'OFF',
  offline: 'Offline',
  offlineDeviceNotification: 'If one of my devices goes offline',
  offset: 'Offset',
  oldPassword: 'Old Password',
  oldPasswordPlaceholder: 'Enter Old password.',
  on: 'ON',
  online: 'Online',
  optedOut: 'Already opted out',
  optimizationBody: 'Because you have changed your device\'s mode to {vacationSetting}, Time of Use Optimization has been disabled. To enable Time of Use Optimization, update the Mode.',
  optimizationHead: 'Optimization Disabled',
  optoutWithApp: 'Opt out with native app',
  outOfHome: 'Out of Home',
  overallStandings: 'Overall Rankings',
  participants: 'Program Participants',
  password: 'Password',
  passwordErrorEmail: 'Password cannot match email address.',
  passwordErrorMismatch: 'The old password does not match. Please try again.',
  passwordErrorName: 'Password cannot match name.',
  passwordErrorRequirements: 'Password must meet requirements listed above. Please try again.',
  passwordHeader: 'Use Password',
  passwordInstructions: 'If this is your first time logging in, please use the Secure Link login',
  passwordPlaceholder: 'Enter your password',
  passwordReset: 'RESET PASSWORD',
  passwordResetLink: 'Forgot password?',
  passwordResetMessage: 'Please enter your email address, and we will send you a link to reset your password. For additional help, email {supportEmail}.',
  passwordSignIn: 'Sign in with a password',
  passwordSuccess: 'Password successfully updated.',
  peakDemandNotification: 'Before peak demand events',
  peakShaving: 'Peak Shaving',
  percent: 'Percent',
  percentCharged: 'Percent Charged',
  performance: 'Performance',
  phoneError: 'An error occurred. Please re-enter phone number.',
  phoneErrorDuplicate: 'Duplicate phone number. Returning to phone number list.',
  phoneInstructions: 'Enter your phone number and we\'ll send you a text message verification code. Once verified, you will be registered to receive recurring SMS alerts regarding your account and devices.',
  phoneNumber: 'Phone Number',
  phoneNumbers: 'Phone Numbers',
  phoneOptOut: 'You have opted out of receiving SMS messages. To opt back in, contact {supportEmail}.',
  phonePlaceholder: {
    america: 'Example: (555) 555-5555',
    australia: 'Example: 0555 555 555',
  },
  phoneToolTip: 'To receive text messages you need a phone number on file. Click link above.',
  phoneVerifyHead: 'Verify Phone Number',
  phoneVerifyIntro: 'Verifying phone numbers allows us to securely send you important information regarding upcoming events, participation performance, and instructions on claiming any program incentives. Please verify the phone number that you provided during the enrollment process.',
  phoneVerifyTooltip: 'This phone number was entered during the enrollment process. Additional numbers can be added from the Account Profile page.',
  power: 'Power',
  precipitation: 'Precipitation',
  preferenceInstructions: 'Choose how you\'d like to be contacted.',
  preferences: 'Preferences',
  presentCapacity: 'Present Capacity',
  priorityBackup: 'Priority Backup',
  profile: 'Profile',
  programImpact: 'Program Impact',
  providerWelcome: 'Welcome to {providerName}',
  pvPower: 'PV Power',
  pvSmoothing: 'PV Smoothing',
  pvSmoothingRage: 'PV Smoothing Rate',
  ratePercent: 'Rate (%)',
  relative: 'Relative',
  remoteArbitrage: 'Remote Arbitrage',
  reply: 'REPLY',
  resendCode: 'Resend code',
  resetPassword: 'Password Reset',
  reviseButton: 'REVISE',
  right: 'right',
  run: 'Run',
  runtime: 'Runtime (s)',
  runtimeTstat: 'RUNTIME TOTAL',
  safetyShutdown: 'Safety Shutdown',
  save: 'Save',
  saved: 'Saved',
  saving: 'Saving',
  schedule: 'Schedule',
  scheduleDays: 'Days of Schedule',
  scheduleDeviceMode: 'Device Mode',
  scheduleError: 'This schedule doesn\'t have enough information to run.',
  scheduleHeader: 'Schedules',
  scheduleName: 'Schedule Name',
  scheduleSetpoint: 'Temperature Setting',
  scheduleStartTime: 'Start Time',
  scheduleTabTitle: 'Device Schedule',
  search: 'Search Messages',
  secureLinkHeader: 'Use Secure Link',
  secureLinkSignIn: 'Sign in with a special link sent to the email address entered below.',
  security: 'Security',
  selectHWH: 'Which water heater(s) would you like to run this schedule?',
  selfSupply: 'Self Supply',
  sell: 'Sell',
  send: 'Send',
  sendCode: 'Send Code',
  sending: 'Sending...',
  sendPIN: 'Send me a PIN',
  sendSecureLink: 'Send Secure Link',
  sent: 'Sent',
  setFavorite: 'Set as favorite message',
  setPassword: 'Set Password',
  setpoint: 'Setpoint',
  setpointControl: 'Setpoint Control',
  setTemp: 'Set Temperature',
  setTemperature: 'Set Temperature',
  settings: 'Settings',
  setUpProfile: 'Set Up Profile',
  signIn: 'Sign In',
  simulatedPV: 'Automatic Simulated PV',
  sleep: 'Sleep',
  sms: 'SMS',
  standard: 'Standard',
  standby: 'Standby',
  status: 'Status',
  subject: 'Subject',
  submitButton: 'Submit',
  successfulOptout: 'Successful Optout',
  switchDevice: 'Switch Device',
  tankTemp: 'Tank Temperature',
  tankTemperature: 'Tank Temperature',
  temperature: 'Temperature',
  time: 'Time',
  to: 'To',
  totalEnergy: 'Total Energy Saved',
  totalOptouts: 'TOTAL EVENT OPT-OUTS',
  totalParticipation: 'Total Participation',
  tryAgain: 'Try Again',
  twoFactorAuth: 'Two Factor Authentication',
  twoFactorInstructions: 'Verify your identity by entering the 6 digit code from the authenticator app on your device.',
  unableToSend: 'Unable to Send Code',
  unit: 'Unit',
  unknown: 'Unknown',
  unreachable: 'Unreachable',
  unsetFavorite: 'Unset as favorite',
  until: 'until',
  unverified: 'Unverified',
  updated: 'updated',
  updateDevice: 'Update Device',
  updateProfile: 'Update Profile',
  userDefined: 'User Defined',
  userParticipation: 'Your Event Participation',
  userResults: 'xx of xx',
  userStandings: 'Your Rankings',
  vacation: 'Vacation',
  vehicleData: 'Vehicle Data',
  vehicleDetails: 'Vehicle Details',
  verificationCode: 'Verification Code',
  verified: 'Verified',
  verifiedToast: {
    title: 'You have successfully verified your Phone Number!',
    body: 'Phone number has been verified and will be used to communicate important information from your Utility. Additional numbers can be added from your Account Profile.',
  },
  verifyFailToast: {
    title: 'Incorrect Verification Code!',
    body: 'You have entered an incorrect Verification Code. You can try to reenter the Verification Code below or have a new code sent to your phone by clicking "Resend Code" button below.',
  },
  verifyNumber: 'Verify',
  voice: 'Voice',
  voltVar: 'Volt/Var Control',
  warning: 'Warning',
  watts: 'Watts',
  weatherConditions: 'Current Weather Conditions',
  weatherForecast: 'Weather Forecast',
  welcome: 'Welcome Home',
  welcomeMessage: 'We are so glad that you are here. You can start saving energy (and money!) every month by connecting your home’s smart devices.',
  wind: 'wind',
  writeReply: 'Write Reply',
  wrongContactInfo: 'If this is not your contact information, please ignore this page because the message associated with this page was probably forwarded to you.',
  yourDevicesHaveBeenOptedOut: 'Your devices have been opted out of the event',
};
