<template>
  <Widget class="device-control" :title="title" :overlay="overlay" :style="style">
    <template v-if="deviceLimit !== 'noAccess'" #controller>
      <DeviceStatusIndicator v-if="enableStatusIndicator" :status="deviceStatus" :brand="brand" />
      <a v-if="enableHeaderLink" class="settings-link" :href="`#/${headerLink}`" aria-label="Device Settings" title="Device Settings" tabindex="0">
        <VpIcon icon="gear" />
      </a>
    </template>
    <div class="device-control__body">
      <template v-if="deviceLimit === 'noAccess'">
        <DeviceLimitNotice :limit="deviceLimit" mode="body" :brand="brand" />
      </template>
      <template v-else-if="isOnline || deviceMfg === 'mysa'">
        <div class="device-control__panel">
          <component :is="panels.left.component" :brand="brand" :args="panels.left.args" />
        </div>
        <div class="device-control__panel">
          <component :is="panels.right.component" :brand="brand" :args="panels.right.args" />
        </div>
        <div v-if="deviceLimit === 'noControl'" class="device-control__subpanel">
          <DeviceLimitNotice :limit="deviceLimit" mode="subpanel" :brand="brand" />
        </div>
      </template>
      <template v-else>
        <div class="device-control__offline-notice">
          <i class="fa fa-frown-o"></i>
          <h3>{{ $t('deviceOffline') }}</h3>
          <p>{{ $t('deviceReconnect') }}.</p>
        </div>
      </template>
    </div>
  </Widget>
</template>

<script>
import { Widget } from '@/components';
import CommandDefinition from './command-definition';
import DataMap from './data-map';
import DeviceLimitNotice from './DeviceLimitNotice.vue';
import DeviceStatusIndicator from './DeviceStatusIndicator.vue';
import BatteryPanel from './panels/BatteryPanel.vue';
import FigurePanel from './panels/FigurePanel.vue';
import SettingPanel from './panels/SettingPanel.vue';
import TemperaturePanel from './panels/TemperaturePanel.vue';
import ChargePanel from './panels/ChargePanel.vue';
import LocationPanel from './panels/LocationPanel.vue';
import CTAPanel from './panels/CTAPanel.vue';

export default {
  name: 'DeviceControl',
  components: {
    BatteryPanel,
    CTAPanel,
    DeviceLimitNotice,
    DeviceStatusIndicator,
    FigurePanel,
    SettingPanel,
    TemperaturePanel,
    Widget,
    ChargePanel,
    LocationPanel,
  },
  inject: ['$byodConfig', '$toast', '$filter', '$log', '$proSettings'],
  props: {
    brand: {
      type: Object,
      default: () => ({ color: '#000000' }),
    },
    device: {
      type: Object,
      required: true,
    },
    enableHeaderLink: {
      type: Boolean,
      default: true,
    },
    enableStatusIndicator: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['deviceStatusUpdate'],
  data() {
    return {
      busy: false,
      overlay: {
        show: false,
        priority: 0,
        message: { text: '', linkText: '', linkUrl: '' },
      },
      refreshTimeout: {
        id: null,
        delay: 10000, // ms
        enable: true,
      },
    };
  },
  computed: {
    activeEvents() {
      return this.$store.getters['event/activeEvents'](this.deviceId);
    },
    activeSchedule() {
      return this.$store.getters['device/activeSchedule'](this.deviceId);
    },
    commandDefinition() {
      return new CommandDefinition(this.$i18n);
    },
    commandModes() {
      // Prefer command modes from the database
      if (this.device.commandModes != null && Array.isArray(this.device.commandModes)) {
        return this.device.commandModes
          .map((rawCommandMode) => {
            if (!(typeof rawCommandMode === 'string')) return rawCommandMode;
            return {
              name: this.$filter.humanReadableCommandMode(rawCommandMode),
              setting: rawCommandMode,
            };
          });
      }

      // Fallback to the old command definitions file
      return this.commandDefinition.getModeList(this.device);
    },
    dataMap() {
      return new DataMap(this.device.kind).byProp;
    },
    deviceId() {
      return this.device.id;
    },
    deviceLimit() {
      switch (this.deviceMfg) {
        case 'amazon':
        case 'mysa':
          return 'noControl';
        case 'ecobee':
        case 'nest':
          return 'noAccess';
        default:
          return null;
      }
    },
    deviceMfg() {
      return this.device.mfg;
    },
    deviceSettings() {
      return this.device.deviceSettings || [];
    },
    subBrand() {
      const subBrand = this.deviceSettings.find(setting => (setting.key === 'sub-brand'));
      if (subBrand == null) return '';
      if (typeof subBrand !== 'object' || !('value' in subBrand)) return '';

      switch (this.deviceMfg) {
        case 'aosmith':
        case 'rheem':
          return subBrand.value;
        default:
          return '';
      }
    },
    deviceName() {
      return this.device.name;
    },
    deviceStatus() {
      return this.realtime.status ? String(this.realtime.status).toUpperCase() : null;
    },
    headerLink() {
      if (this.enableHeaderLink) {
        return `device/${this.deviceId}`;
      }
      return '';
    },
    overlayActive() {
      return this.overlay.show;
    },
    pending() {
      return this.$store.getters['device/devicePending'](this.deviceId);
    },
    readOnly() {
      return this.deviceLimit === 'noControl';
    },
    realtime() {
      return this.$store.getters['device/deviceRealtime'](this.deviceId);
    },
    scheduleList() {
      const { deviceId } = this;
      let scheduleList = [{ name: 'No Schedules Available', uid: 'default' }];
      const deviceSchedules = this.$store.getters['device/deviceSchedules']({ deviceId });
      if (deviceSchedules && deviceSchedules.length > 0) {
        scheduleList = [{ name: 'Please Select One', uid: 'default' }];
        scheduleList = scheduleList.concat().concat(deviceSchedules);
      }
      return scheduleList;
    },
    style() {
      return {
        '--provider-color': this.brand.color,
      };
    },
    title() {
      if (this.deviceName) {
        return this.deviceName;
      }

      if (this.device.kind === 'ev' && this.device.type) {
        return `${this.$filter.vehicleMake(this.device.type)}`;
      }
      const deviceControl = this.$i18n.t('deviceControl');
      const deviceStatus = this.$i18n.t('deviceStatus');

      if (this.deviceMfg && this.deviceMfg === 'eradio') return `${this.$filter.deviceMfg(this.deviceMfg)} ${deviceStatus}`;
      if (this.subBrand) return `${this.$filter.deviceSubBrand(this.subBrand)} ${deviceControl}`;
      if (this.deviceMfg) return `${this.$filter.deviceMfg(this.deviceMfg)} ${deviceControl}`;
      return deviceControl;
    },
    panels() {
      try {
        const { deviceId } = this;
        const modeList = this.commandModes;
        const fanModeList = this.commandDefinition.getFanModeList(this.device);
        const vacationSetting = this.commandDefinition.getVacationSetting(this.device);
        const aosmithDisabled = this.deviceMfg === 'aosmith';

        let vacationEnabled;
        const mode = this.dataMap('mode');
        if (mode && 'key' in mode) {
          vacationEnabled = this.pending[this.dataMap('mode').key] === vacationSetting;
        }
        let targetSetpoint;
        const panelsConfig = { left: {}, right: {} };
        switch (this.device.kind) {
          case 'battery':
            panelsConfig.left = {
              component: 'BatteryPanel',
              args: {
                mode: this.prettifyMode(modeList, this.realtime[this.dataMap('mode').key]),
              },
            };
            panelsConfig.right = {
              component: 'FigurePanel',
              args: {
                upper: {
                  label: this.dataMap('power').name,
                  value: this.convert2kilo(this.realtime[this.dataMap('power').key]),
                  symbol: 'kW',
                },
                lower: {
                  label: 'Capacity',
                  value: this.percentage(this.realtime[this.dataMap('presentCapacity').key], this.realtime[this.dataMap('maxCapacity').key]),
                  symbol: '%',
                },
              },
            };
            break;
          case 'evse':
            panelsConfig.left = {
              component: 'BatteryPanel',
              args: {
                mode: this.prettifyMode(modeList, this.realtime[this.dataMap('mode').key]),
              },
            };
            panelsConfig.right = {
              component: 'FigurePanel',
              args: {
                upper: {
                  label: this.dataMap('power').name,
                  value: this.convert2kilo(this.realtime[this.dataMap('power').key]),
                  symbol: 'kW',
                },
                lower: {
                  label: this.dataMap('powerLimit').name,
                  value: this.convert2kilo(this.realtime[this.dataMap('powerLimit').key]),
                  symbol: 'kW',
                },
              },
            };
            break;
          case 'hwh':
            if (this.device.mfg === 'eradio') {
              panelsConfig.left = {
                component: 'CTAPanel',
                args: {
                  label: this.$tc('deviceWaterHeater', 1),
                },
              };
              panelsConfig.right = {
                component: 'FigurePanel',
                args: {
                  upper: {
                    label: this.dataMap('power').name,
                    value: this.convert2kilo(this.realtime[this.dataMap('power').key]),
                    symbol: 'kW',
                  },
                  lower: null,
                },
              };
              break;
            }
            if (this.device.mfg === 'aosmith') {
              panelsConfig.left = {
                component: 'CTAPanel',
                args: {
                  label: this.$tc('deviceWaterHeater', 1),
                },
              };
              panelsConfig.right = {
                component: 'TemperaturePanel',
                args: {
                  deviceId,
                  actualTemp: {
                    label: this.dataMap('temperature').name,
                    value: this.realtime[this.dataMap('temperature').key],
                  },
                  setpoint: {
                    key: this.dataMap('setpoint').key,
                    label: this.dataMap('setpoint').name,
                    value: this.pending[this.dataMap('setpoint').key],
                  },
                  disableInput: this.readOnly || this.overlayActive,
                },
              };
              break;
            }
            if (this.device.mfg === 'rheem') {
              panelsConfig.left = {
                component: 'CTAPanel',
                args: {
                  label: this.$tc('deviceWaterHeater', 1),
                },
              };
            } else {
              panelsConfig.left = {
                component: 'TemperaturePanel',
                args: {
                  deviceId,
                  actualTemp: {
                    label: this.dataMap('temperature').name,
                    value: this.realtime[this.dataMap('temperature').key],
                  },
                  setpoint: {
                    key: this.dataMap('setpoint').key,
                    label: this.dataMap('setpoint').name,
                    value: this.pending[this.dataMap('setpoint').key],
                  },
                  disableInput: this.readOnly || this.overlayActive,
                },
              };
            }
            panelsConfig.right = {
              component: 'SettingPanel',
              args: {
                deviceId,
                upper: {
                  key: this.dataMap('mode').key,
                  value: this.pending[this.dataMap('mode').key],
                  label: 'Mode',
                  list: modeList ? modeList.map(obj => ({ name: obj.name, value: obj.setting })) : [],
                  disabled: aosmithDisabled || this.readOnly || this.overlayActive,
                },
                lower: {
                  key: 'device-active-schedule',
                  label: 'Schedule',
                  value: vacationEnabled ? 'vacation' : this.activeSchedule.uid,
                  list: (() => {
                    if (vacationEnabled) {
                      return [{ name: 'Paused', value: 'vacation' }];
                    }
                    if (this.scheduleList) {
                      return this.scheduleList.map(obj => ({ name: obj.name, value: String(obj.uid) }));
                    }
                    return [];
                  })(),
                  // renders schedule drop down menu, but disables access to change value
                  disabled: aosmithDisabled || this.readOnly || this.overlayActive || vacationEnabled,
                  // does not render schedule option if scheduling specific proSettings are not enabled
                  render: this.$proSettings.settings.enableAutoSchedule || this.$proSettings.settings.enableCustomHwhSchedule,
                },
              },
            };
            break;
          case 'rac':
            targetSetpoint = (this.pending['thermostat-mode'] === 'heat' ? 'heatSetpoint' : 'coolSetpoint');
            panelsConfig.left = {
              component: 'TemperaturePanel',
              args: {
                deviceId,
                actualTemp: {
                  label: this.dataMap('actualTemperature').name,
                  value: this.realtime[this.dataMap('actualTemperature').key],
                },
                setpoint: {
                  key: this.dataMap(targetSetpoint).key,
                  label: this.dataMap(targetSetpoint).name,
                  value: this.pending[this.dataMap(targetSetpoint).key],
                },
                disableInput: this.readOnly || this.overlayActive,
              },
            };
            panelsConfig.right = {
              component: 'SettingPanel',
              args: {
                deviceId,
                upper: {
                  key: this.dataMap('mode').key,
                  value: this.pending[this.dataMap('mode').key],
                  label: this.dataMap('mode').name,
                  list: modeList ? modeList.map(obj => ({ name: obj.name, value: obj.setting })) : [],
                  disabled: this.readOnly || this.overlayActive,
                },
                lower: {
                  key: this.dataMap('fanMode').key,
                  value: this.pending[this.dataMap('fanMode').key],
                  label: this.dataMap('fanMode').name,
                  list: fanModeList ? fanModeList.map(obj => ({ name: obj.name, value: obj.setting })) : [],
                  disabled: this.readOnly || this.overlayActive,
                },
              },
            };
            break;
          case 'tstat':
            if (this.deviceMfg === 'mysa') {
              targetSetpoint = 'heatSetpoint';
              panelsConfig.left = {
                component: 'FigurePanel',
                args: {
                  upper: {
                    label: this.dataMap('actualTemperature').name,
                    value: Math.round(this.realtime[this.dataMap('actualTemperature').key]) + '°',
                  },
                  lower: null,
                },
              };
              panelsConfig.right = {
                component: 'FigurePanel',
                args: {
                  upper: {
                    label: this.dataMap(targetSetpoint).name,
                    value: Math.round(this.pending[this.dataMap(targetSetpoint).key]) + '°',
                  },
                  lower: null,
                },
              };
            } else {
              switch (this.pending['thermostat-mode']) {
                case 'heat':
                  targetSetpoint = 'heatSetpoint';
                  break;
                case 'fan':
                  targetSetpoint = 'fanMode';
                  break;
                default:
                  targetSetpoint = 'coolSetpoint';
                  break;
              }
              panelsConfig.left = {
                component: 'TemperaturePanel',
                args: {
                  deviceId,
                  deviceMfg: this.deviceMfg,
                  actualTemp: {
                    label: this.dataMap('actualTemperature').name,
                    value: this.realtime[this.dataMap('actualTemperature').key],
                  },
                  setpoint: {
                    key: this.dataMap(targetSetpoint).key,
                    label: this.dataMap(targetSetpoint).name,
                    value: targetSetpoint === 'fanMode'
                      ? fanModeList.find(item => item.setting === this.pending[this.dataMap(targetSetpoint).key]).name
                      : this.pending[this.dataMap(targetSetpoint).key],
                  },
                  disableInput: this.readOnly || this.overlayActive || targetSetpoint === 'fanMode',
                },
              };
              panelsConfig.right = {
                component: 'SettingPanel',
                args: {
                  deviceId,
                  upper: {
                    key: this.dataMap('mode').key,
                    value: this.pending[this.dataMap('mode').key],
                    label: this.dataMap('mode').name,
                    list: modeList ? modeList.map(obj => ({ name: obj.name, value: obj.setting })) : [],
                    disabled: this.readOnly || this.overlayActive,
                  },
                  lower: {
                    key: this.dataMap('fanMode').key,
                    value: this.pending[this.dataMap('fanMode').key],
                    label: this.dataMap('fanMode').name,
                    list: fanModeList ? fanModeList.map(obj => ({ name: obj.name, value: obj.setting })) : [],
                    disabled: this.readOnly || this.overlayActive,
                  },
                },
              };
            };
            break;
          case 'ev':
            panelsConfig.left = {
              component: 'ChargePanel',
              args: {
                evChargingStatus: this.realtime[this.dataMap('evChargingStatus').key],
              },
            };
            panelsConfig.right = {
              component: 'LocationPanel',
              args: {
                evLocationStatus: this.realtime[this.dataMap('evLocationStatus').key],
              },
            };
            break;
          default:
            break;
        }
        return panelsConfig;
      } catch (error) {
        this.$log.error(error);

        this.setOverlay(200, true, { text: 'Failed to render device controls.' });
        return { left: {}, right: {} };
      }
    },
    isOnline() {
      return String(this.deviceStatus).toUpperCase() === 'ONLINE';
    },
  },
  watch: {
    activeEvents: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.setOverlay(50, true, { text: 'Opt-out to make changes.' });
        } else {
          this.setOverlay(50, false);
        }
      },
      immediate: true,
    },
    deviceStatus: {
      handler(newValue) {
        this.$emit('deviceStatusUpdate', newValue);
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    clearTimeout(this.refreshTimeout.id);
  },
  methods: {
    init() {
      this.busy = true;
      const { deviceId } = this;

      this.fetchDeviceData(deviceId, { repeat: true })
        .catch((err) => {
          this.$toast.show('fail', {
            title: 'Failed to fetch device data',
            body: err.message,
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },
    clearOverlay() {
      this.overlay.show = false;
      this.overlay.priority = 0;
      this.overlay.message = { text: '', linkText: '', linkUrl: '' };
    },
    convert2kilo(n) {
      return n / 1000;
    },
    fetchDeviceData(deviceId, options) {
      const { repeat } = options;
      const promises = [];
      promises.push(this.$store.dispatch('device/fetchDeviceRealtime', { deviceId, resetPending: true }));
      if (this.device.kind === 'hwh' && this.deviceMfg !== 'eradio') {
        promises.push(this.$store.dispatch('device/fetchDeviceActiveSchedule', { deviceId }));
      }
      return Promise.all(promises)
        .then(() => {
          if (this.overlayActive && (this.overlay.priority === 100)) {
            this.clearOverlay();
          }
        })
        .catch((err) => {
          this.$log.error(err);
          this.setOverlay(100, true, { text: this.$i18n.t('deviceUnreachable') });
        })
        .finally(() => {
          if (repeat && this.refreshTimeout.enable) {
            this.refreshTimeout.id = setTimeout(this.fetchDeviceData, this.refreshTimeout.delay, deviceId, { repeat });
          }
        });
    },
    percentage(a, b) {
      return ((a / b) * 100).toFixed(0);
    },
    prettifyMode(modeList, setting) {
      if (modeList && setting) {
        return modeList.find(item => item.setting === setting).name;
      }
      return setting;
    },
    setOverlay(priority, status, message) {
      if (priority >= this.overlay.priority) {
        this.clearOverlay();
        this.overlay.message = message;
        this.overlay.priority = priority;
        this.overlay.show = status;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.device-control {
  .widget__header-controller {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 16px;
    a.settings-link {
      width: 28px;
      height: 28px;
      border: 1px solid #DBDBDB;
      border-radius: 4px;
      color: var(--provider-color);
      &:focus {
        border: 2px solid var(--provider-color);
      }
    }
  }
  .widget__inner {
    overflow: visible; // Allow open VpSelect to overflow the widget itself
  }
    &__body {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    justify-content: center;
    column-gap: 10.56px;
  }

  &__panel {
    flex: 1 1;
    width: 50%;
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 6px;
  }

  &__offline-notice {
    align-self: center;

    i {
      font-size: 16px;
    }
    h3 {
      font-size: 16px;
      }
    p {
      font-size: 12px;
    }
  }

  @include Tablet--Large {
    &__body {
      column-gap: 24px;
    }
    &__offline-notice {
    i {
      font-size: 24px;
    }
    h3 {
      font-size: 24px;
      }
    p {
      font-size: 16px;
    }
    }
  }
}
</style>
