<template>
  <div v-if="ready" class="schedule-tab">
    <div id="device-schedule-list-label" class="schedule-tab__header">{{ $t('scheduleTabTitle') }}</div>
    <DeviceScheduleList
      aria-labelledby="device-schedule-list-label"
      :device-schedules="deviceSchedules"
      :active-schedule-uid="activeScheduleUid"
    />
  </div>
</template>

<script>
import DeviceScheduleList from './DeviceScheduleList.vue';
import CommandDefinition from '../../../command-definition';

export default {
  name: 'ScheduleTab',
  components: { DeviceScheduleList },
  inject: ['$busy'],
  data() {
    return {
      ready: false,
    };
  },
  provide() {
    const { deviceModes } = this;
    return { deviceModes };
  },
  computed: {
    deviceId() {
      return Number(this.$route.params.deviceId);
    },
    device() {
      return this.$store.getters['device/activeDevice'];
    },
    activeScheduleUid() {
      let activeScheduleUid = null;

      const activeSchedule = this.$store.getters['device/activeSchedule'](this.deviceId);
      if (activeSchedule && 'uid' in activeSchedule) {
        activeScheduleUid = activeSchedule.uid;
      }

      return activeScheduleUid;
    },
    deviceSchedules() {
      const { deviceId } = this;
      return this.$store.getters['device/deviceSchedules']({ deviceId, getDetails: true });
    },
    commandDefinition() {
      return new CommandDefinition(this.$i18n);
    },
    deviceModes() {
      const { device } = this;
      return this.commandDefinition.getModeList(device);
    },
  },
  mounted() {
    this.$busy.toggle(true);
    this.init();
  },
  methods: {
    init() {
      const { deviceId } = this;
      Promise.all([
        this.$store.dispatch('device/fetchAllDeviceSchedules', { getDetails: true }),
        this.$store.dispatch('device/fetchDeviceActiveSchedule', { deviceId }),
      ])
        .catch((err) => {
          this.$toast.show('fail', {
            title: err.name,
            body: err.message,
          });
        })
        .finally(() => {
          this.$busy.toggle(false);
          this.ready = true;
        });
    },
  },
};
</script>

<style lang="scss">
.schedule-tab {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  padding: 24px 0 32px;

  &__header {
    padding-left: 24px;
    margin-bottom: 32px;
    font-size: 24px;
  }
}
</style>
