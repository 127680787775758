import api from '@/common/api';
import UnitConverter from '@/common/unit-converter';
import DataMap from '../data-map';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default {
  buildCommands(context, payload) {
    // Convert changes into commands
    const { convert } = new UnitConverter();
    const { devices } = payload;
    const { tempUnit = 'F' } = context.rootGetters.localization;
    const commands = [];

    devices.forEach((device) => {
      let { changes } = device;
      const { deviceId } = device;
      const { kind, mfg } = context.getters.device(deviceId);
      const dataMap = new DataMap(kind);
      const pending = context.getters.devicePending(deviceId);
      const command = {
        type: 'OPERATIONAL',
        kind,
        body: {},
      };
      const includeAllSettings = mfg === 'honeywell';

      if (includeAllSettings) {
        // Include all possible settings
        changes = ['mode'];
        changes = changes.concat(kind === 'tstat' ? ['fanMode', 'coolSetpoint', 'heatSetpoint'] : ['setpoint']);
        changes = changes.reduce((obj, prop) => {
          obj[prop] = pending[dataMap.byProp(prop).key];
          return obj;
        }, {});
      } else {
        // Include only changed settings
        changes = changes.reduce((obj, key) => {
          obj[dataMap.byKey(key).property] = pending[key];
          return obj;
        }, {});
      }

      // Build command body
      if ('mode' in changes) {
        command.body.mode = changes.mode;
      }
      if ('fanMode' in changes) {
        command.body.fanMode = changes.fanMode;
      }
      if ('setpoint' in changes) {
        command.body.setpoint = changes.setpoint;
      }
      if ('coolSetpoint' in changes || 'heatSetpoint' in changes) {
        if (mfg === 'sensibo') {
          // Both cooling and heating setpoints must exist and match
          const targetMode = changes.mode || pending[dataMap.byProp('mode').key] || 'cool';
          if (targetMode === 'heat') {
            command.body.heatSetpoint = changes.heatSetpoint;
            command.body.coolSetpoint = changes.heatSetpoint;
          } else {
            command.body.heatSetpoint = changes.coolSetpoint;
            command.body.coolSetpoint = changes.coolSetpoint;
          }
        } else {
          if ('coolSetpoint' in changes) {
            command.body.coolSetpoint = changes.coolSetpoint;
          }
          if ('heatSetpoint' in changes) {
            command.body.heatSetpoint = changes.heatSetpoint;
          }
        }
      }

      // Convert temperatures
      Object.keys(command.body).forEach((setting) => {
        if (['setpoint', 'heatSetpoint', 'coolSetpoint'].includes(setting)) {
          command.body[setting] = convert(tempUnit, 'F')(command.body[setting]);
        }
      });

      // Add command to queue
      if (Object.keys(command).length) {
        commands.push({ deviceId, command });
      }
    });

    // Forward commands for submission
    if (commands.length) {
      return context.dispatch('issueCommands', { commands });
    }
    return null;
  },
  extractDate(store, d) {
    const date = new Date(d);
    const obj = { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() };
    obj.lastDay = new Date(obj.year, obj.month, 0).getDate();
    return obj;
  },
  editEvent: (store, payload) => Promise.resolve(store.getters.events.find(e => e.eventDay === payload.eventDay))
    .then(event => ((event) ? store.commit('setEventPerformance', [event, payload]) : null))
    .then(() => payload),
  enqueueCommand(context, payload) {
    const { deviceId, key, value } = payload;
    if (key === 'device-active-schedule') {
      // Forward changes to active schedule
      return context.dispatch('changeDeviceActiveSchedule', { deviceId, uid: value });
    }
    // Update pending
    context.commit('setChangesPendingReconciliation', true);
    return context.commit('setDeviceRealtimePending', payload);
  },
  fetchAllEvents: (store, { houseId }) => api.get(`/house/${houseId}/event?includeAllPlatoonEvents=true`).then(events => store.commit('addEventLog', events)),
  fetchAllDevices(context) {
    const houseId = localStorage.getItem('houseId');
    return api.get(`/house/${houseId}/device?includeSettings=true&includeCommandModes=true`)
      .then((devices) => {
        context.commit('setDevices', devices);
        return context.getters.devices;
      });
  },
  fetchDevicePlotData(context, payload) {
    const { deviceId, deviceKind, plotType, resources, start, end, pointRadius } = payload;
    const promises = [];
    const rawDatasets = [];
    const start_ = new Date(start).toISOString();
    const end_ = new Date(end).toISOString();

    if (resources.length) {
      resources.forEach((resource) => {
        promises.push(context.dispatch('fetchDeviceDataByType', { deviceId, dataType: resource.name, start: start_, end: end_ })
          .then((data) => {
            return {
              resource: resource.name,
              label: resource.label,
              data: Array.isArray(data) ? data : [data],
              tension: (resource.tension !== undefined) ? resource.tension : 0,
              pointRadius,
              yAxisID: (resource.yAxisID !== undefined) ? resource.yAxisID : 'yAxis1',
              unit: resource.unit,
            };
          })
          .catch(() => {
            return {
              resource: resource.name,
              label: resource.label,
              data: [],
            };
          }));
      });
    }
    return Promise.allSettled(promises)
      .then((data) => {
        data.forEach((item) => {
          if (item.value.data.length) {
            rawDatasets.push(item.value);
          }
        });
      })
      .then(() => {
        const { tempUnit = 'F', localTemp } = context.rootGetters.localization;
        context.commit('setPlotData', { deviceKind, plotType, rawDatasets, start: start_, end: end_, tempUnit, localTemp });
        return context.getters.plotData(deviceKind, plotType);
      });
  },
  changeDeviceActiveSchedule(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId, uid } = payload;
    if (uid === 'default') {
      api.delete(`/house/${houseId}/device/${deviceId}/active-schedule`)
        .then(() => {
          return context.commit('setDeviceActiveSchedule', { activeSchedule: { name: '--', uid: 'default', deviceId } });
        });
    } else {
      api.post(`/house/${houseId}/device/${deviceId}/active-schedule`, { uid })
        .then((activeSchedule) => {
          return context.commit('setDeviceActiveSchedule', { activeSchedule });
        });
    }
  },
  fetchDeviceScheduleDetails(context, { uid }) {
    const houseId = localStorage.getItem('houseId');
    return api.get(`/house/${houseId}/device-schedule/${uid}`);
  },
  fetchAllDeviceSchedules(context, { getDetails = false } = {}) {
    const houseId = localStorage.getItem('houseId');
    return api.get(`/house/${houseId}/device-schedule`)
      .then((deviceSchedules) => {
        if (getDetails) {
          let promises = [];

          // Accumulate schedule UIDs
          const deviceScheduleUids = new Set();
          deviceSchedules.forEach(deviceSchedule => deviceScheduleUids.add(deviceSchedule.uid));

          // Generate promises
          deviceScheduleUids.forEach((uid) => {
            promises = promises.concat(context.dispatch('fetchDeviceScheduleDetails', { uid }));
          });

          return Promise.all(promises)
            .then((deviceScheduleDetails) => {
              return context.commit('setDeviceSchedules', { deviceSchedules, deviceScheduleDetails });
            });
        }
        return context.commit('setDeviceSchedules', { deviceSchedules });
      });
  },
  fetchDevice(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId } = payload;
    return api.get(`/house/${houseId}/device/${deviceId}`)
      .then((device) => {
        return api.get(`/house/${houseId}/device/${deviceId}/setting`)
          .then((settings) => {
            device.deviceSettings = settings;
            context.commit('setActiveDevice', { device });
            return context.getters.activeDevice;
          });
      });
  },
  fetchDeviceActiveSchedule(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId } = payload;
    return api.get(`/house/${houseId}/device/${deviceId}/active-schedule`)
      .catch(() => {
        return { name: '--', uid: 'default', deviceId };
      })
      .then((activeSchedule) => {
        return context.commit('setDeviceActiveSchedule', { activeSchedule });
      });
  },
  fetchDeviceData(context, payload) {
    const { deviceId, deviceKind } = payload;
    let dataTypes = new DataMap(deviceKind).list;
    const { mfg } = context.getters.device(deviceId) || {};
    if (deviceKind === 'hwh' && mfg !== 'eradio') dataTypes = dataTypes.filter(({ key }) => key !== 'power');
    if (deviceKind === 'tstat' && mfg !== 'mysa') dataTypes = dataTypes.filter(({ key }) => key !== 'thermostat-cool-setpoint');
    const promises = [];
    const deviceData = [];
    dataTypes.forEach((type) => {
      promises.push(context.dispatch('fetchDeviceDataByType', { deviceId, dataType: type.key })
        .then((data) => {
          const { id, time } = data;
          deviceData.push({ id, resource: type.property, time });
        }));
    });
    return Promise.allSettled(promises)
      .then(() => {
        context.commit('setDeviceData', { deviceData });
        return context.getters.deviceData;
      });
  },
  fetchDeviceDataByType(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId, dataType, start = '', end = '' } = payload;
    let params = '';
    if (start) {
      params = `?before=${start instanceof Date ? start.toISOString() : start}`;
      if (end) {
        params = params.concat(`&end=${end instanceof Date ? end.toISOString() : end}`);
      }
    }
    return api.get(`/house/${houseId}/device/${deviceId}/data/${dataType}${params}`);
  },
  fetchDeviceRealtime(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId, resetPending } = payload;
    const { kind } = context.getters.device(deviceId);
    const { tempUnit = 'F' } = context.rootGetters.localization;
    return api.get(`/house/${houseId}/device/${deviceId}/realtime`)
      .then((realtime) => {
        const { changesPendingReconciliation } = context.getters;
        return context.commit('setDeviceRealtime', {
          deviceId,
          kind,
          realtime,
          resetPending: changesPendingReconciliation ? false : resetPending,
          tempUnit,
        });
      });
  },
  fetchDeviceSetting(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId, resource: { key } = {} } = payload;
    const { localTemp } = context.rootGetters.localization;
    return api.get(`house/${houseId}/device/${deviceId}/setting/${key}`)
      .then((deviceSetting) => {
        context.commit('setDeviceSetting', { deviceSetting, localTemp });
        return context.getters.deviceSettings(key);
      });
  },
  fetchEventDays: (store, { houseId, start, end }) => {
    const params = (start && end) ? `?start=${new Date(start).toISOString()}&end=${new Date(end).toISOString()}` : '';
    return api.get(`/house/${houseId}/event-day/${params}`)
      .then(events => Promise.resolve(events.forEach(event => store.commit('addEvent', Object.assign({ performance: {}, weather: {} }, event))))
        .then(() => store.dispatch('fetchProgress', { events, houseId })));
  },
  fetchProgress(store, { events, houseId }) {
    return Promise.all(events.map((event, index) => {
      // TODO: The type should be a program setting to properly support the event widget.
      // 1. take the first event type
      let { type } = event.events[0];
      // 2. filter out non-behavioral events
      const nonBehavioralEvents = event.events.filter(e => e.type !== 'behavioral');
      // 3. if more than 0 non-behavioral events, take the first type.
      if (nonBehavioralEvents.length > 0) {
        ([{ type }] = nonBehavioralEvents);
      }
      return api.get(`/house/${houseId}/event-day/${event.eventDay}/performance?type=${type}`)
        .then(({ performance, weather }) => {
          return store.dispatch('editEvent', Object.assign({}, events[index], { performance, weather }));
        })
        .then(item => item);
    })).then(collection => store.dispatch('sumTotalEnergy', collection));
  },
  issueCommands(context, payload) {
    // Manage the submission of commands to the API
    const { commands } = payload;
    const deviceIds = new Set();

    // Submit commands
    return Promise.all(commands.map(command => context.dispatch('sendCommand', command)
      .catch((err) => {
        // Refresh realtime data for device that produces error
        const { deviceId } = err.response.data;
        if (deviceId) {
          const { changesPendingReconciliation } = context.getters;
          context.dispatch('fetchDeviceRealtime', { deviceId, resetPending: !changesPendingReconciliation });
        }
        return Promise.reject(err);
      })))
      .then((replies) => {
        // Accumulate deviceIds
        replies.forEach((reply) => {
          deviceIds.add(reply.deviceId);
        });

        // Refresh affected devices' realtime data
        const { changesPendingReconciliation } = context.getters;
        return Promise.all([...deviceIds].map(deviceId => context.dispatch('fetchDeviceRealtime', { deviceId, resetPending: !changesPendingReconciliation })));
      });
  },
  reconcilePending(context) {
    // Detect pending changes to device settings
    const devices = [];

    // Compare current keys with pending values
    context.getters.devicesRealtime.forEach((item) => {
      const { deviceId, current, pending } = item;
      const keys = Object.keys(current);
      const changes = [];

      // Build list of changed keys
      keys.forEach((key) => {
        if (!Object.is(current[key], pending[key])) {
          changes.push(key);
        }
      });
      // Build list of changed devices
      if (changes.length) {
        devices.push({ deviceId, changes });
      }
    });
    // Forward changes to be converted into commands
    if (devices.length) {
      context.commit('setChangesPendingReconciliation', false);
      return context.dispatch('buildCommands', { devices });
    }
    return null;
  },
  sendCommand(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId, command } = payload;
    return api.post(`/house/${houseId}/device/${deviceId}/command`, command);
  },
  sumTotalEnergy: (store, payload) => {
    const savings = {};
    return Promise.all(payload.map((event) => {
      if (!event.performance || !Object.keys(event.performance).length) return null;
      return store.dispatch('extractDate', event.eventDay)
        .then(({ year, month }) => {
          if (!savings[year]) savings[year] = {};
          if (!savings[year][months[month]]) savings[year][months[month]] = 0;
          savings[year][months[month]] += event.performance.reductionEnergy || 0;
          return savings;
        });
    }))
      .then(() => store.commit('setSavings', savings))
      .then(() => payload);
  },
  updateDevice(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceObject, deviceId } = payload;
    return api.put(`/house/${houseId}/device/${deviceId}`, deviceObject)
      .then((device) => {
        context.commit('setActiveDevice', { device });
        return context.getters.device;
      });
  },
  updateDeviceSetting(context, payload) {
    const houseId = localStorage.getItem('houseId');
    const { deviceId, resource: { key, value } = {} } = payload;
    const { tempToF } = context.rootGetters.localization;
    return api.put(`house/${houseId}/device/${deviceId}/setting/${key}`, { key, value: tempToF(value) })
      .then(() => {
        return context.dispatch('fetchDeviceSetting', { deviceId, resource: { key } });
      });
  },
};
