const DATA_MAP = {
  battery: [
    {
      key: 'sis-set-mode',
      property: 'sisMode',
    },
    {
      key: 'battery-mode',
      property: 'mode',
    },
    {
      key: 'battery-max-capacity',
      property: 'maxCapacity',
    },
    {
      key: 'battery-present-capacity',
      property: 'presentCapacity',
    },
    {
      key: 'battery-power',
      property: 'power',
      name: 'Battery Power',
    },
    {
      key: 'pv-power',
      property: 'powerPv',
    },
    {
      key: 'battery-house-power',
      property: 'powerHouse',
    },
    {
      key: 'status',
      property: 'status',
      name: 'Status',
    },
  ],
  evse: [
    {
      key: 'evse-mode',
      property: 'mode',
    },
    {
      key: 'evse-max-power',
      property: 'maxPower',
    },
    {
      key: 'evse-power',
      property: 'power',
      name: 'Charge Power',
    },
    {
      key: 'evse-power-limit',
      property: 'powerLimit',
      name: 'Power Limit',
    },
    {
      key: 'status',
      property: 'status',
      name: 'Status',
    },
  ],
  hwh: [
    {
      key: 'hwh-set-mode',
      property: 'mode',
      name: 'Mode',
    },
    {
      key: 'hwh-set-temperature',
      property: 'setpoint',
      name: 'Set Temperature',
    },
    {
      key: 'hwh-tank-temperature',
      property: 'temperature',
      name: 'Tank Temperature',
    },
    {
      key: 'status',
      property: 'status',
      name: 'Status',
    },
    {
      key: 'power',
      property: 'power',
      name: 'Power',
    },
  ],
  rac: [
    {
      key: 'rac-mode',
      property: 'mode',
    },
    {
      key: 'rac-fan-mode',
      property: 'fanMode',
    },
    {
      key: 'rac-cool-setpoint',
      property: 'coolSetpoint',
    },
    {
      key: 'rac-actual-temperature',
      property: 'actualTemperature',
    },
    {
      key: 'status',
      property: 'status',
      name: 'Status',
    },
  ],
  tstat: [
    {
      key: 'thermostat-mode',
      property: 'mode',
      name: 'Mode',
    },
    {
      key: 'thermostat-fan-mode',
      property: 'fanMode',
      name: 'Fan Mode',
    },
    {
      key: 'thermostat-cool-setpoint',
      property: 'coolSetpoint',
      name: 'Cooling Setpoint',
    },
    {
      key: 'thermostat-heat-setpoint',
      property: 'heatSetpoint',
      name: 'Heating Setpoint',
    },
    {
      key: 'thermostat-actual-temperature',
      property: 'actualTemperature',
      name: 'Temperature',
    },
    {
      key: 'thermostat-hold',
      property: 'holding',
      name: 'Hold Setpoint',
    },
    {
      key: 'thermostat-temperature-unit',
      property: 'thermostatTemperatureUnit',
      name: 'Unit',
    },
    {
      key: 'hvac-equipment-status',
      property: 'hvacEquipmentStatus',
      name: 'Equipment Status',
    },
    {
      key: 'status',
      property: 'status',
      name: 'Status',
    },
  ],
  ev: [
    // {
    //   key: '',
    //   property: '',
    //   name: '',
    // },
    {
      key: 'ev-away-power',
      property: 'evAwayPower',
      name: 'Away Power',
    },
    {
      key: 'ev-battery-capacity',
      property: 'evBatteryCapacity',
      name: 'Battery Capacity',
    },
    {
      key: 'ev-battery-level',
      property: 'evBatteryLevel',
      name: 'Battery Level',
    },
    {
      key: 'ev-battery-range',
      property: 'evBatteryRange',
      name: 'Battery Range',
    },
    {
      key: 'ev-charging-status',
      property: 'evChargingStatus',
      name: 'Charging Status',
    },
    {
      key: 'ev-home-power',
      property: 'evHomePower',
      name: 'Home Power',
    },
    {
      key: 'ev-location-status',
      property: 'evLocationStatus',
      name: 'Location Status',
    },
    {
      key: 'ev-plugged-in-status',
      property: 'evPluggedInStatus',
      name: 'Plugged-In Status',
    },
    {
      key: 'status',
      property: 'status',
      name: 'Status',
    },
  ],
};

export default function DataMap(deviceKind) {
  const target = DATA_MAP[deviceKind];
  if (deviceKind && target) {
    this.byProp = property => target.find(item => item.property === property);
    this.byKey = key => target.find(item => item.key === key);
    this.list = target;
  } else {
    throw new Error(`Unknown deviceKind: ${deviceKind}`);
  }
}
