<template>
  <div class="widget" :style="bodyCSS">
    <VpSvgSprite />
    <VpBusy ref="busy" :busy="busy" />
    <div v-if="hasHeader" class="widget__header" @click="clickHeader">
      <div class="widget__header-container">
        <slot name="header">
          <div v-if="hasTitle" class="widget__header-title">
            <slot name="title">
              <p>{{ title }}</p>
            </slot>
          </div>
          <div v-if="hasController" class="widget__header-controller">
            <slot name="controller" />
          </div>
        </slot>
      </div>
    </div>
    <VpOverlay :show-overlay="overlay.show" :message="overlay.message">
      <div class="widget__inner">
        <div class="widget__inner-container">
          <slot />
        </div>
      </div>
    </VpOverlay>
  </div>
</template>

<script>
import VpBusy from './VpBusy.vue';
import VpOverlay from './VpOverlay.vue';

export default {
  name: 'Widget',
  components: { VpBusy, VpOverlay },
  props: {
    busy: { type: Boolean, default: false },
    headerLink: { type: String, default: '' },
    overlay: { type: Object, default: () => ({}) },
    styles: { type: Object, default: () => ({}) },
    title: { type: String, default: null },
  },
  computed: {
    bodyCSS() {
      const defaultBg = '#fff';
      return {
        // Body
        '--body-background':
          this.styles.bodyBackground || this.styles.background || defaultBg,
        // Header
        '--header-borderBottom':
          this.styles.headerBorder || '1px solid #DBDBDB',
        '--header-background':
          this.styles.headerBackground || this.styles.background || defaultBg,
        '--header-cursor':
          this.headerLink ? 'pointer' : 'auto',
        // Inner
        '--inner-height': !this.hasHeader ? '300px' : '233px',
        '--inner-boxShadow': this.styles.headerBorder || !this.styles.headerBorder === 'none' || this.styles.innerBoxShadow ? this.styles.innerBoxShadow || 'inset 0 1px 3px rgba(0, 0, 0, 0.1)' : 'none',
        '--inner-background': this.styles.innerBackground || this.styles.background || defaultBg,
        '--inner-padding': this.styles.innerPadding || '1.5rem',
      };
    },
    hasHeader() {
      return this.$slots.header || this.hasTitle || this.hasController;
    },
    hasTitle() {
      return this.$slots.title || this.title;
    },
    hasController() {
      return this.$slots.controller;
    },
  },
  watch: {
    busy(bool) {
      // disabled for future implementation
      // return this.$refs.busy.toggle(bool);
      return bool;
    },
  },
  methods: {
    clickHeader() {
      if (this.headerLink) {
        // Temporarily use window.location for Angular/Vue router cross-compatibility
        window.location.assign(`#${this.headerLink}`);

        // TODO: Switch to using Vue router directly once DeviceTabs is converted
        // this.$router.push(this.headerLink);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
.widget {
  color: #4f4f4f;
  width: 100%;
  max-width: 612px;
  min-height: 100%;
  max-height: 350px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--body-background);
  @include Tablet--Large {
    min-width: 612px;
  }
  p {
    margin: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    border-radius: 6px 6px 0 0;
    border-bottom: var(--header-borderBottom);
    background-color: var(--header-background);
    cursor: var(--header-cursor);
    @include Tablet--Large {
      min-height: 67px;
      padding: 0.75rem 1rem;
    }
    &-container {
      gap: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-title {
      flex: 1 1 50%;
      text-align: left;
      text-transform: capitalize;
      &,
      & > * {
        font-size: 0.875rem;
        @include Tablet--Large {
          font-size: 1.25rem;
        }
      }
    }
    &-controller {
      font-size: 1rem;
    }
  }
  &__inner {
    height: 125px;
    padding: 0.5rem;
    overflow: hidden;
    border-radius: 0 0 6px 6px;
    box-shadow: var(--inner-boxShadow);
    background-color: var(--inner-background);
    @include Tablet--Large {
      min-height: var(--inner-height);
      padding: var(--inner-padding);
    }
  }
  &__inner-container,
  &__header-container {
    width: 100%;
    height: 100%;
  }
}
</style>
