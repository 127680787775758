<template>
  <div class="language-button" :style="bodyCSS">
    <vp-button
      class="no-fill"
      id="language-button__btn"
      @click="updateLanguage(toggleLanguage)"
      :styles="buttonStyles"
    >
      <VpIcon icon="globe"/> {{ toggleLanguage }}
  </vp-button>
  </div>
</template>

<script>
export default {
  name: 'LanguageButton',
  inject: ['$byodConfig'],
  components: {},
  props: {},
  data() {
    return {
      toggleLanguage: '',
      multipleLanguages: null,
    };
  },
  mounted() {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    this.multipleLanguages = this.$byodConfig.commonSettings.find(({ key }) => key === 'byodMultipleLanguages');
    this.toggleLanguage = this.multipleLanguages.languages.find(item => item.key !== this.locale).value;
    if (selectedLanguage) {
      return this.updateLanguage(this.multipleLanguages.languages.find(({ key }) => key === selectedLanguage).value);
    }
    return this.updateLanguage(this.multipleLanguages.languages.find(({ key }) => key === this.locale).value);
  },
  watch: {
    locale(newVal) {
      // Watch for unexpected changes to locale and update accordingly
      const { languages } = this.multipleLanguages;
      if (languages) {
        const language = languages.find(({ value }) => value === newVal);
        if (language) {
          this.updateLanguage(language);
        }
      }
    },
  },
  computed: {
    bodyCSS() {
      return { '--provider-color': this.$byodConfig.color };
    },
    buttonStyles() {
      return { border: 'none' };
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    updateLanguage(language) {
      const { languages } = this.multipleLanguages;
      const newLanguage = languages.find(item => item.value === language);
      this.toggleLanguage = languages.find(item => item.value !== language).value;
      this.$i18n.locale = `${newLanguage.key}`;
      localStorage.setItem('selectedLanguage', this.locale);
      this.$store.commit('updateSelectedLanguage', this.locale);
      return this.$store.dispatch('dashboard/setLocale', this.locale)
        .then(() => {
        // Remove "lang" query param if present
          if ('lang' in this.$route.query) {
            const { lang, ...rest } = this.$route.query;
            const newQuery = { ...rest };
            this.$router.replace({
              query: newQuery,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
button#language-button__btn {
  height: 100%;
  background: #fff;
  color: var(--provider-color);
  text-transform: capitalize;
  font-family: Muli;
  font-weight: 400;
  font-size: 1em;
  min-width: 50px;
  padding: 0.8em;
  width:115px;
  @include Tablet--Large {
    padding: 0.75em;
    font-size: 1em;
  }
}
</style>
