<template>
  <div class="vcl-date-picker__container">
    <div :id="_uid+'VpChartDatePicker'" class="vcl-date-picker" :style="bodyCSS" tabindex="0" aria-label="Event Day Selection" role="combobox" @keyup="containerKeyupHandler($event)" @keydown.left.right.prevent>
      <div class="vcl-date-picker__arrow" tabindex="0" role="button" aria-label="Previous Event Date" @click.stop="modifyOffset(-1)" @keypress.space.prevent @keyup.stop.prevent="chevronKeyup($event, -1)">
        <VpIcon icon="chevron-left" />
      </div>
      <div class="vcl-date-picker__picker">
        <date-picker ref="picker" v-model="datePickerValue" v-bind="datePickerProps">
          <template #default="{ togglePopover }">
            <span>{{ busy ? $t('loading') : formatDate(datePickerValue) }}</span>
            <VpIcon icon="calendar" title="Select Event Date" class="is-clickable" @click.stop="togglePopover()" />
          </template>
        </date-picker>
      </div>
      <div class="vcl-date-picker__arrow" tabindex="0" role="button" aria-label="Next Event Date" @click.stop="modifyOffset(1)" @keypress.space.prevent @keyup.stop.prevent="chevronKeyup($event, 1)">
        <VpIcon icon="chevron-right" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from '@virtual-peaker/v-calendar/src/components/DatePicker.vue';

const $filter = {
  Date: (value, format) => moment(value).format(format),
};

export default {
  name: 'DatePickerCustom',
  inject: ['$byodConfig'],
  components: { DatePicker },
  props: {
    attributes: { type: Array, default: () => [] },
    availableDates: { type: Array, default: () => [] },
    loadedDates: { type: Array, default: () => [] },
    busy: { type: Boolean, default: null },
    date: { type: [Date, String], default: () => new Date() },
    hasMore: { type: Boolean, default: false },
    handleLoad: { type: Function, default: null },
    totalLoaded: { type: Number, default: 0 },
  },
  emits: ['update'],
  data: () => ({ currentDateIndex: 0 }),
  computed: {
    datePickerProps() {
      return {
        attributes: this.attributes,
        availableDates: this.availableDates,
        locale: this.$i18n.locale,
      };
    },
    datePickerValue: {
      get() {
        return this.date;
      },
      set(range) {
        // Update Date if it's included in availableDates Prop
        const index = this.isIncluded(range);
        // select range if its already pre-loaded
        if (index > -1) {
          this.currentDateIndex = index;
          return this.update();
        }
        // fetch event if range is in availableDates
        const date = this.formatDate(range);
        if (this.availableDates.includes(date)) {
          return this.handleLoad(date);
        }
        return null;
      },
    },
    currentDate() {
      if (this.loadedDates.length && this.loadedDates[this.currentDateIndex]) {
        return this.loadedDates[this.currentDateIndex].startTime;
      }
      return moment().format(this.format.date);
    },
    format() {
      if (this.$i18n.locale === 'es') {
        return ({ date: 'DD MMM YYYY' });
      } return ({ date: 'MMM DD, YYYY' });
    },
    $filter: () => $filter,
    bodyCSS() {
      return {
        '--provider-color': this.$byodConfig.color,
      };
    },
  },
  watch: {
    availableDates() {
      return this.update(true);
    },
  },
  methods: {
    isIncluded(date) {
      return this.loadedDates.findIndex((event) => {
        const eventDate = moment(new Date(event.startTime), 'YYYY-MM-DD').format(
          this.format.date,
        );
        const selectedDate = moment(date).format(this.format.date);
        return eventDate === selectedDate;
      });
    },
    formatDate(value) {
      return this.$filter.Date(new Date(value) || new Date(), this.format.date);
    },
    chevronKeyup(event, direction) {
      if ((event.code === 'Space') || (event.code === 'Enter')) {
        this.modifyOffset(direction);
      }
    },
    containerKeyupHandler(event) {
      if (((event.code === 'Space') || (event.code === 'Enter')) && (event.target.className === 'vcl-date-picker')) {
        this.$refs.picker.togglePopover();
      } else if ((event.code === 'ArrowLeft') && (event.target.className === 'vcl-date-picker')) {
        this.modifyOffset(-1);
      } else if ((event.code === 'ArrowRight') && (event.target.className === 'vcl-date-picker')) {
        this.modifyOffset(1);
      }
    },
    modifyOffset(delta, force = false) {
      const isZero = this.currentDateIndex === 0;
      const isCapped = this.currentDateIndex === this.totalLoaded - 1;
      if ((delta === -1 && isZero && !this.hasMore) || (delta === 1 && isCapped)) return null;
      if ((delta === -1 && isZero && this.hasMore && !this.busy)) this.handleLoad();

      if (delta === -1 && !isZero) this.currentDateIndex -= 1;
      else if (delta === 1 && !isCapped) this.currentDateIndex += 1;
      else if (force && delta >= 0 && delta < this.totalLoaded) this.setIndex(delta);

      return this.update();
    },
    setIndex(value) {
      this.currentDateIndex = value;
    },
    update(force = false) {
      if (force) {
        if (this.availableDates && this.availableDates.length) {
          this.setIndex(this.totalLoaded);
        }
      }
      return this.$emit('update', this.currentDate);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";

.vcl-date-picker__container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  div.vcl-date-picker {
    $self: &;
    margin: 0;
    display: flex;
    padding: 0.25rem;
    border-radius: 4px;
    align-items: center;
    background: #ffffff;
    box-sizing: border-box;
    border: 0.5px solid #afafaf;
    justify-content: space-between;

    &:focus {
      border: 3px solid var(--provider-color);
      outline: none;
    }

    @include Tablet--Large {
      padding: 0.5rem;
      border-width: 1px;
      border-radius: 6px;
    }
    svg.icon {
      margin: 0;
      padding: 0;
      font-size: 0.625rem;
      color: var(--provider-color);
      @include Tablet--Large {
        font-size: 1rem;
      }
    }
    &__picker {
      width: 100%;
      margin: 0 0.25rem;
      @include Tablet--Large {
        margin: 0 1rem;
      }
      span {
        font-size: 10px;
        line-height: 100%;
        @include Tablet--Large {
          font-size: 1rem;
        }
      }
      & > span {
        gap: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__arrow {
      display: flex;
    }
    &.custom {
      padding: 11px 0;
    }
    &.disabled {
      #{ $self }__arrow {
        .icon {
          color: #afafaf;
        }
      }
      #{ $self }__picker {
        span {
          color: #afafaf;
        }
        .icon {
          color: #afafaf;
        }
      }
    }
  }

}
</style>
