import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import logonRoutes from '@/modules/authentication/routes';
import { i18n, enabledLanguageKeys } from './i18n';

Vue.use(VueRouter);

function loadRoutes() {
  const context = require.context('@/modules/', true, /routes\.js$/i);
  return context.keys()
    .map(context)
    .map((m) => m.default);
}

const routes = [
  {
    name: 'home',
    path: '/',
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      const auth = store.getters['shell/isAuth'] || { authenticated: false };
      if (auth && auth.authenticated) {
        return next({ name: 'user-home' });
      }
      return next({ name: 'logon' });
    },
  },
];

const moduleRoutes = loadRoutes();
moduleRoutes.forEach((route) => routes.push(...route));

// Set metaData on routes
routes.forEach((route) => {
  if (!route.meta) {
    // if meta hasn't been set, set default
    route.meta = { requiresAuth: true };
  } else if (route.meta.requiresAuth === undefined) {
    // if requiredAuth key hasn't set, set to true by default
    route.meta.requiresAuth = true;
  }
});

// Set fallback to home for unset routes (must go last)
routes.push({ path: '*', redirect: () => ({ name: 'home' }) });

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

function overrideLocale({ query = {} }) {
  // Take no action if "lang" query param is missing or falsy
  if (!('lang' in query && query.lang)) return;

  // Respect user's selected language
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  if (selectedLanguage) return;

  let { lang } = query;
  lang = String(lang).toLowerCase();

  // Do not override if we encounter a language that is not enabled
  if (!enabledLanguageKeys.includes(lang)) return;

  // Override the current locale
  /*
    Note: setting localStorage.selectedLanguage here will affect the same
    value for the other branded apps on the same domain (Home, BYOD).
  */
  i18n.locale = lang;
  localStorage.setItem('selectedLanguage', i18n.locale);
}

// Global Navigation Guard Settings
router.beforeEach((to, from, next) => {
  overrideLocale({ query: to.query });

  const isLogonRoute = logonRoutes.some(({ path }) => path === to.path);
  // : On App Initialization, if isAuth is undefined, Bypass for VpLogon to initialize
  const auth = store.getters['shell/isAuth'];
  if (auth === null) {
    return next();
  }

  const isAuth = auth.authenticated;
  // : Authentication Route Checker
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuth && to.name !== 'logon') {
      return router.push({ name: 'logon' }).catch(() => null);
    }
  }
  // if User authenticated and accessing authentication/routes, redirect to home
  if (isLogonRoute && isAuth) {
    return router.push({ name: 'user-home' }).catch(() => null);
  }
  // if User authenticated && accessing logout route, dispatch logout
  if (to.path === '/logout') {
    if (!isAuth) {
      return router.push({ name: 'logon' }).catch(() => null);
    }
    return store.dispatch('shell/logout');
  }
  return next();
});

export default router;
