<template>
  <div v-if="smsEnabled" id="account__phone-settings" class="row phone-settings">
    <div class="columns">
      <label v-if="!showPhoneForm" for="">{{ $t('phoneNumbers') }}</label>
      <br v-if="!showPhoneForm" />
      <PhoneList v-if="showPhoneList"
                 :list="maskedPhoneNumbers"
                 @delete-number="deleteNumber"
                 @verify-number="verifyNumber"
      />
      <br v-if="!showPhoneForm" />
      <vp-button v-show="!showPhoneForm" type="success" class="addButton" :style="buttonCSS" @click="addNumber">{{ $t('addNumber') }}</vp-button>
      <PhoneForm v-if="showPhoneForm"
                 :localization="localization"
                 :error-response="errorResponse"
                 ref="phoneForm"
                 @send-code="sendCode"
                 @cancel-add="cancelAdd"
      />
      <div v-if="showCode" class="code-form">
        <vp-input v-model="code" type="tel" :class="{ error : codeError === true}" maxlength="6" :placeholder="$t('codePlaceHolder')">
          <template #label>{{ $t('enterPhoneVerificationCode', { phoneNumber: phoneNumber }) }}</template>
        </vp-input>
        <p v-if="codeError" class="error-message">{{ $t('codeError') }}.</p>
        <div class="verify-button">
          <span v-show="!resending" @click="resendVerify(); resending=true"><a>{{ $t(resendCode) }}</a></span>
          <span><i v-if="codeMessage === 'Sent'" class="fa fa-check-circle-o  " /> {{ codeMessage }}</span>
          <vp-button
            class="codeButton"
            type="success"
            :disabled="!code || code.length<6"
            @click="verifyCode()"
            :style="buttonCSS"
          >
            {{ $t('verifyNumber') }}
          </vp-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneForm from './PhoneForm.vue';
import PhoneList from './PhoneList.vue';

export default {
  name: 'AccountSettings',
  inject: ['$byodConfig', '$proSettings'],
  components: {
    PhoneForm, PhoneList,
  },
  data: () => {
    return {
      redisCode: '',
      phoneNumber: '',
      showCode: false,
      showPhoneForm: false,
      codeMessage: '',
      code: '',
      codeError: false,
      resending: false,
      errorResponse: '',
      busyState: '',
      resendCode: false,
    };
  },
  computed: {
    accountId() {
      return this.$store.state.user.id;
    },
    buttonCSS() {
      return {
        'background-color': this.providerColor,
        border: `solid 2px ${this.providerColor}`,
      };
    },
    localization() {
      return this.$store.getters['account/localization'];
    },
    PHONE_LENGTH() {
      if (this.localization === 'AU') {
        return 12;
      }
      return 14;
    },
    maskedPhoneNumbers() {
      return this.$store.getters.phoneNumbers.map((num) => {
        return { ...num, maskedPhone: this.maskPhone(num.phone) };
      });
    },
    phoneNumbers: {
      get() {
        return this.$store.getters.phoneNumbers;
      },
      set(newNumbers) {
        this.$store.commit('setPhoneNumbers', newNumbers);
      },
    },
    providerColor() { return this.$byodConfig.color; },
    providerId() {
      return this.$byodConfig.id;
    },
    showPhoneList() {
      if (this.phoneNumbers.length && !this.showPhoneForm) { return true; }
      return false;
    },
    smsEnabled() {
      if (this.$proSettings && this.$proSettings.settings && this.$proSettings.settings.enableSms) {
        return this.$proSettings.settings.enableSms;
      } return false;
    },
  },
  watch: {
    '$route.path': {
      handler() {
        if (this.$route.fullPath === '/account/profile?addPhoneNumber') {
          this.addNumber();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    cancelAdd() {
      this.showPhoneForm = false;
    },
    maskPhone(phone) {
      let masked = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
      if (this.localization === 'AU') {
        masked = `0${phone.slice(0, 3)} ${phone.slice(3, 6)}${phone.length > 6 ? ` ${phone.slice(6, 9)}` : ''}`;
      }
      return masked;
    },
    unmaskPhone(phone) {
      let unmasked = phone.replace(/\D/g, '');
      if (this.localization === 'AU') {
        unmasked = unmasked.slice(1);
      }
      return unmasked;
    },
    sendCode(phoneNumber) {
      const { providerId } = this;
      if (phoneNumber.length === this.PHONE_LENGTH) {
        this.busyState = 'busy';
        let countryCode = '1';
        if (this.localization === 'AU') {
          countryCode = '61';
        }
        const phone = this.unmaskPhone(phoneNumber);
        const actionParams = {
          accountId: this.accountId, phone, countryCode, providerId,
        };
        this.$store.dispatch('account/addPhoneNumber', actionParams)
          .then(() => {
            this.phoneNumber = phoneNumber;
            this.showPhoneForm = false;
            this.showCode = true;
            this.busyState = '!busy';
          })
          .catch((reply) => {
            if (reply.status === 400 && this.phoneList.length > 0) {
              this.errorResponse = this.$i18n.t('phoneErrorDuplicate');
              this.busyState = '!busy';
              setTimeout(() => {
                this.showPhoneForm = false;
                this.errorResponse = '';
              }, 5000);
            } else if (reply.status === 400) {
              this.errorResponse = this.$i18n.t('phoneError');
              this.busyState = '!busy';
              setTimeout(() => {
                this.errorResponse = '';
              }, 5000);
            } else {
              this.errorResponse = this.$i18n.t('phoneError');
              this.busyState = '!busy';
              setTimeout(() => {
                this.errorResponse = '';
              }, 5000);
            }
          });
      }
    },
    resendVerify() {
      const { providerId, accountId } = this;
      this.resendCode = true;
      this.codeMessage = this.$i18n.t('sending');
      const phone = this.unmaskPhone(this.phoneNumber);
      let countryCode = '1';
      if (this.localization === 'AU') {
        countryCode = '61';
      }
      this.$store.dispatch('account/resendPhoneCode', {
        accountId, phone, countryCode, providerId,
      })
        .then(() => {
          this.codeError = false;
          this.code = '';
          this.codeMessage = this.$i18n.t('sent');
          setTimeout(() => {
            this.codeMessage = '';
            this.resendCode = false;
          }, 40000);
        });
    },

    verifyCode() {
      this.busyState = 'busy';
      const phone = this.unmaskPhone(this.phoneNumber);
      let countryCode = '1';
      if (this.localization === 'AU') {
        countryCode = '61';
      }

      const params = { phone, countryCode, code: this.code, providerId: this.providerId };
      this.$store.dispatch('account/confirmPhoneVerificationCode', params)
        .then((data) => {
          if (data.verified === false) {
            this.codeError = true;
            this.code = '';
          } else {
            this.showPhoneForm = false;
            this.showCode = false;
          }
        })
        .catch((reply) => {
          this.codeError = true;
          this.busyState = '!busy';
        });
    },
    addNumber() {
      this.showPhoneForm = true;
      this.phoneNumber = '';
      this.$nextTick(() => this.$refs.phoneForm.$refs.phoneInput.focus());
    },
    deleteNumber(phone, countryCode) {
      return this.$store.dispatch('deletePhoneNumber', { accountId: this.accountId, phone, countryCode });
    },
    verifyNumber(phone, countryCode) {
      const { providerId, accountId } = this;
      const actionParams = {
        accountId, phone, countryCode, providerId,
      };
      this.$store.dispatch('account/resendPhoneCode', actionParams)
        .then(() => {
          this.showCode = true;
          this.phoneNumber = this.maskPhone(phone);
        })
        .catch(() => {
          this.codeMessage = this.$i18n.t('unableToSend');
          this.showCode = true;
          this.phoneNumber = this.maskPhone(phone);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixins.scss';

.code-form {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 24px;
  @include Desktop {
    padding-left: 24px;
  }
}

.verify-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 24px;
}

#account__phone-settings {
  padding-top: 24px;
  @include Desktop {
    padding-top: 0;
    padding-left: 24px;
  }
}

</style>
