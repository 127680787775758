<template>
  <div v-if="ready" class="lower-dashboard">
    <DeviceControl v-for="device in devices" :key="`device-${device.id}`" :device="device" :brand="{ color: providerColor }" />
    <LeaderBoard v-if="enableLeaderboard" />
    <EventCalendar v-if="enableEventCalendar" />
    <template v-if="enableMarketing">
      <MarketingMessage v-for="msg in marketingMessages" :key="`msg-${msg.id}`" :message="msg" :branding="{ color: providerColor }" />
    </template>
  </div>
</template>

<script>
import DeviceControl from '@/modules/device/DeviceControl.vue';
import EventCalendar from '@/components/EventCalendar/EventCalendar.vue';
import LeaderBoard from '@/components/LeaderBoard.vue';
import MarketingMessage from '@/components/MarketingMessage.vue';

export default {
  name: 'LowerDashboard',
  components: {
    DeviceControl, EventCalendar, LeaderBoard, MarketingMessage,
  },
  inject: ['$byodConfig', '$proSettings', '$toast', '$busy', '$log'],
  data() {
    return {
      ready: false,
      reconcileTimeout: {
        id: null,
        delay: 3000, // ms
        enable: true,
      },
      refreshTimeout: {
        id: null,
        delay: 10000, // ms
        enable: true,
      },
    };
  },
  computed: {
    devices() {
      return this.$store.getters['device/devices'];
    },
    enableEventCalendar() {
      return this.$proSettings.settings.enableHomeEventCalendar;
    },
    enableLeaderboard() {
      return this.$proSettings.settings.enableHomeLeaderboard;
    },
    enableMarketing() {
      return this.$proSettings.settings.enableHomeMarketing;
    },
    marketingMessages() {
      return this.$store.getters['dashboard/marketingMessages'];
    },
    providerColor() {
      return this.$byodConfig.color;
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    // When upgrading to Vue 3, convert this to unmounted.
    clearTimeout(this.refreshTimeout.id);
    clearTimeout(this.reconcileTimeout.id);
  },
  methods: {
    init() {
      this.$busy.toggle(true);
      this.fetchData({ repeat: true })
        .finally(() => {
          this.ready = true;
          this.$busy.toggle(false);
        });
      this.reconcilePending({ repeat: true });
    },
    fetchData(options) {
      const { repeat } = options;
      const promises = [];
      if (this.enableMarketing) {
        promises.push(this.$store.dispatch('dashboard/fetchMarketingMessages'));
      }
      return Promise.all(promises)
        .catch((err) => {
          this.$log.error(err);
        })
        .finally(() => {
          if (repeat && this.refreshTimeout.enable) {
            this.refreshTimeout.id = setTimeout(this.fetchData, this.refreshTimeout.delay, { repeat });
          }
        });
    },
    reconcilePending(options) {
      const { repeat } = options;
      return this.$store.dispatch('device/reconcilePending')
        .catch(err => this.$log.error(err))
        .finally(() => {
          if (repeat && this.reconcileTimeout.enable) {
            this.reconcileTimeout.id = setTimeout(this.reconcilePending, this.reconcileTimeout.delay, { repeat });
          }
        })
        .catch(err => this.$log.error(err));
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.lower-dashboard {
  margin: 24px;
  padding-bottom:160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 16px;

  & > div {
    flex: 1 1 100%;
    min-width: 327px;
    max-width: 893px;
  }

  @include Mobile--Only {
    padding-bottom:80px;
  }

  @include Tablet {
    padding-bottom:80px;
  }

  @include Desktop {
    justify-content: flex-start;
    padding-bottom:120px;

    & > div {
      max-width: 880px;
    }
  }

  @include Desktop--Large {
    padding-bottom:160px;
    gap: 24px;
    & > div {
      max-width: 612px;
    }
  }
}
</style>
