export default function (i18n) {
  const self = this;

  this.hwh = {};

  this.hwh.default = {
    mode: 'HYBRID',
    setpoint: 140,
  };

  this.hwh.elementList = [
    {
      name: 'None',
      setting: 'NONE',
    },
    {
      name: 'Upper',
      setting: 'UPPER_ELEMENT',
    },
    {
      name: 'Lower',
      setting: 'LOWER_ELEMENT',
    },
    {
      name: 'Compressor',
      setting: 'COMPRESSOR',
    },
    {
      name: 'Comp and Lower',
      setting: 'COMPRESSOR_AND_LOWER',
    },
  ];

  this.hwh.modeList = [
    {
      name: i18n.t('hybrid'),
      setting: 'HYBRID',
    },
    {
      name: i18n.t('standard'),
      setting: 'STANDARD_ELECTRIC',
    },
    {
      name: i18n.t('efficiency'),
      setting: 'E_HEAT',
    },
    {
      name: i18n.t('highDemand'),
      setting: 'HIGH_DEMAND',
    },
    {
      name: i18n.t('vacation'),
      setting: 'VACATION',
    },
  ];

  this.ge = {
    hwh: {},
    tstat: {},
  };

  this.ge.hwh.default = {
    mode: 'HYBRID',
    setpoint: 140,
  };

  this.ge.hwh.elementList = [
    {
      name: 'None',
      setting: 'NONE',
    },
    {
      name: 'Upper',
      setting: 'UPPER_ELEMENT',
    },
    {
      name: 'Lower',
      setting: 'LOWER_ELEMENT',
    },
    {
      name: 'Compressor',
      setting: 'COMPRESSOR',
    },
    {
      name: 'Comp and Lower',
      setting: 'COMPRESSOR_AND_LOWER',
    },
  ];

  this.ge.hwh.modeList = [
    {
      name: i18n.t('hybrid'),
      setting: 'HYBRID',
    },
    {
      name: i18n.t('standard'),
      setting: 'STANDARD_ELECTRIC',
    },
    {
      name: i18n.t('efficiency'),
      setting: 'E_HEAT',
    },
    {
      name: i18n.t('highDemand'),
      setting: 'HIGH_DEMAND',
    },
    {
      name: i18n.t('vacation'),
      setting: 'VACATION',
    },
  ];

  this.ge.tstat = {};
  this.ge.tstat.modeList = [
    {
      name: 'Off',
      setting: 'off',
    },
    {
      name: 'Cool',
      setting: 'cool',
    },
    {
      name: 'Fan Only',
      setting: 'fan',
    },
    {
      name: 'Energy Saver',
      setting: 'energy-saver',
    },
  ];
  this.ge.tstat.fanModeList = [
    {
      name: 'Auto',
      setting: 'auto',
    },
    {
      name: 'Low',
      setting: 'low',
    },
    {
      name: 'Medium',
      setting: 'medium',
    },
    {
      name: 'High',
      setting: 'high',
    },
  ];
  this.ge.tstat.setpointRange = [64, 86];

  this.ge.rac = {};
  this.ge.rac.modeList = [
    { name: 'Off', setting: 'off' },
    { name: 'Cool', setting: 'cool' },
    { name: 'Fan Only', setting: 'fan' },
    { name: 'Energy Saver', setting: 'energy-saver' },
  ];
  this.ge.rac.fanModeList = [
    { name: 'Auto', setting: 'auto' },
    { name: 'Low', setting: 'low' },
    { name: 'Medium', setting: 'medium' },
    { name: 'High', setting: 'high' },
  ];
  this.ge.rac.setpointRange = [64, 86];

  this.ecobee = {
    tstat: {},
  };
  this.ecobee.tstat.default = {
    absolute: true,
    temperature: {},
  };
  this.ecobee.tstat.setpointTypes = [
    {
      value: true,
      name: 'Absolute',
    },
    {
      value: false,
      name: 'Relative',
    },
  ];

  this.ecobee.tstat.modeList = [
    {
      name: 'Off',
      setting: 'off',
    },
    {
      name: 'Cool',
      setting: 'cool',
    },
    {
      name: 'Heat',
      setting: 'heat',
    },
    {
      name: 'Auto',
      setting: 'auto',
    },
    {
      name: 'Aux Heat Only',
      setting: 'auxHeatOnly',
    },
  ];

  this.ecobee.tstat.equipmentStatusList = [
    {
      name: 'Heat Pump',
      setting: 'heatPump',
    },
    {
      name: 'Heat Pump 2',
      setting: 'heatPump2',
    },
    {
      name: 'Heat Pump 3',
      setting: 'heatPump3',
    },
    {
      name: 'AC Compressor',
      setting: 'compCool1',
    },
    {
      name: 'AC Compressor 2',
      setting: 'compCool2',
    },
    {
      name: 'Aux Heat 1',
      setting: 'auxHeat1',
    },
    {
      name: 'Aux Heat 2',
      setting: 'auxHeat2',
    },
    {
      name: 'Aux Heat 3',
      setting: 'auxHeat3',
    },
    {
      name: 'Fan',
      setting: 'fan',
    },
    {
      name: 'Humidifier',
      setting: 'humidifier',
    },
    {
      name: 'Dehumidifier',
      setting: 'dehumidifier',
    },
    {
      name: 'Ventilator',
      setting: 'ventilator',
    },
    {
      name: 'Economizer',
      setting: 'economizer',
    },
    {
      name: 'Hot Water',
      setting: 'compHotWater',
    },
    {
      name: 'Aux Hot Water',
      setting: 'auxHotWater',
    },
  ];

  this.nest = {
    tstat: {},
  };
  this.nest.tstat.default = {
    absolute: true,
    temperature: {},
  };
  this.nest.tstat.setpointTypes = [];

  this.nest.tstat.modeList = [
    {
      name: 'Off',
      setting: 'off',
    },
    {
      name: 'Cool',
      setting: 'cool',
    },
    {
      name: 'Heat',
      setting: 'heat',
    },
    {
      name: 'Heat/Cool',
      setting: 'auto',
    },
    {
      name: 'Eco',
      setting: 'eco',
    },
  ];

  this.nest.tstat.equipmentStatusList = [
    {
      name: 'Heating',
      setting: 'heating',
    },
    {
      name: 'Cooling',
      setting: 'cooling',
    },
    {
      name: 'Off',
      setting: 'off',
    },
  ];

  this.honeywell = {
    tstat: {},
  };

  this.honeywell.tstat.default = {
    absolute: true,
    temperature: {},
  };
  this.honeywell.tstat.setpointTypes = [];

  this.honeywell.tstat.modeList = [
    {
      name: i18n.t('off'),
      setting: 'off',
    },
    {
      name: i18n.t('cool'),
      setting: 'cool',
    },
    {
      name: i18n.t('heat'),
      setting: 'heat',
    },
  ];

  this.honeywell.tstat.equipmentStatusList = [
    {
      name: 'Heating',
      setting: 'heating',
    },
    {
      name: 'Cooling',
      setting: 'cooling',
    },
    {
      name: 'Off',
      setting: 'off',
    },
  ];

  this.honeywellTcc = {
    tstat: {},
  };

  this.honeywellTcc.tstat.default = {
    absolute: true,
    temperature: {},
  };
  this.honeywellTcc.tstat.setpointTypes = [];

  this.honeywellTcc.tstat.modeList = [
    {
      name: 'Off',
      setting: 'off',
    },
    {
      name: 'Cool',
      setting: 'cool',
    },
    {
      name: 'Heat',
      setting: 'heat',
    },
  ];

  this.honeywellTcc.tstat.equipmentStatusList = [
    {
      name: 'Heating',
      setting: 'heating',
    },
    {
      name: 'Cooling',
      setting: 'cooling',
    },
    {
      name: 'Off',
      setting: 'off',
    },
  ];

  this.sis = {
    battery: {},
  };

  this.sis.battery.default = {
    energyControlState: '',
    loadSupportEnabled: true,
    pvEnabled: false,
    efficiencyMode: 'NORMAL',
  };

  this.sis.battery.modeList = [
    {
      name: 'Standby',
      setting: 'STANDBY',
      opts: {
        fields: [
          {
            name: 'Rate (%)',
            key: 'ratePercent',
            required: true,
            type: 'NUMBER',
            default: 0,
          },
        ],
      },
    },
    {
      name: 'Sleep',
      setting: 'SLEEP',
      opts: {
        fields: [
          {
            name: 'Rate (%)',
            key: 'ratePercent',
            required: true,
            type: 'NUMBER',
            default: 0,
          },
        ],
      },
    },
    {
      name: 'Dispatch By Power',
      setting: 'DISPATCH_BYPOWER',
      opts: {
        fields: [
          {
            key: 'dispatchByPowerSettings',
            required: true,
            type: 'CONTAINER',
            default: 0,
            fields: [
              {
                required: true,
                type: 'OR',
                names: ['Watts', 'Percent'],
                default: 'watts',
                fields: [
                  {
                    key: 'watts',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                  {
                    key: 'percent',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Dispatch By Current',
      setting: 'DISPATCH_BYCURRENT',
      opts: {
        fields: [
          {
            key: 'dispatchByCurrentSettings',
            required: true,
            type: 'CONTAINER',
            default: 0,
            fields: [
              {
                required: true,
                type: 'OR',
                names: ['Amps', 'Percent'],
                default: 'amps',
                fields: [
                  {
                    key: 'amps',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                  {
                    key: 'percent',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                ],
              },
              {
                name: 'Volt/Var Control',
                key: 'enableVoltVArControl',
                required: true,
                type: 'BOOLEAN',
                default: false,
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Dispatch By PV',
      setting: 'DISPATCH_BYPV',
      opts: {
        fields: [
          {
            key: 'dispatchByPVSettings',
            required: true,
            type: 'CONTAINER',
            default: 0,
            fields: [
              {
                name: 'Offset',
                key: 'offset',
                required: true,
                type: 'NUMBER',
                default: 0,
              },
              {
                name: 'PV Smoothing',
                key: 'pvSmoothingEnabled',
                required: true,
                type: 'BOOLEAN',
                default: false,
              },
              {
                name: 'PV Smoothing Rate',
                key: 'pvSmoothingRate',
                required: true,
                type: 'NUMBER',
                default: 0,
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Dispatch By Site Demand',
      setting: 'DISPATCH_BYSITEDEMAND',
      opts: {
        fields: [
          {
            key: 'dispatchBySiteDemandSettings',
            required: true,
            type: 'CONTAINER',
            fields: [
              {
                name: 'Desired Site Meter Reading',
                key: 'desiredSiteMeterReading',
                required: true,
                type: 'NUMBER',
                default: 0,
              },
              {
                name: 'Limit Dispatch',
                type: 'CONTAINER',
                required: false,
                fields: [
                  {
                    names: ['By Power', 'By PV'],
                    type: 'OR',
                    default: 'limitDispatchByPower',
                    fields: [
                      {
                        key: 'limitDispatchByPower',
                        required: true,
                        type: 'CONTAINER',
                        default: false,
                        fields: [
                          {
                            required: true,
                            type: 'OR',
                            names: ['Watts', 'Percent'],
                            default: 'watts',
                            fields: [
                              {
                                key: 'watts',
                                required: true,
                                type: 'NUMBER',
                                default: 0,
                              },
                              {
                                key: 'percent',
                                required: true,
                                type: 'NUMBER',
                                default: 0,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        // name: "By PV",
                        key: 'limitDispatchByPv',
                        required: true,
                        type: 'CONTAINER',
                        default: false,
                        fields: [
                          {
                            name: 'Offset',
                            key: 'offset',
                            required: true,
                            type: 'NUMBER',
                            default: 0,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Charge From Grid by Power',
      setting: 'CHARGEFROMGRID_BYPOWER',
      opts: {
        fields: [
          {
            key: 'chargeFromGridByPowerSettings',
            required: true,
            type: 'CONTAINER',
            default: 'watts',
            fields: [
              {
                required: true,
                type: 'OR',
                names: ['Watts', 'Percent'],
                default: 'watts',
                fields: [
                  {
                    key: 'watts',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                  {
                    key: 'percent',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Charge From Grid By Current',
      setting: 'CHARGEFROMGRID_BYCURRENT',
      opts: {
        fields: [
          {
            key: 'chargeFromGridByCurrentSettings',
            required: true,
            type: 'CONTAINER',
            default: 0,
            fields: [
              {
                required: true,
                type: 'OR',
                names: ['Amps', 'Percent'],
                default: 'amps',
                fields: [
                  {
                    key: 'amps',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                  {
                    key: 'percent',
                    required: true,
                    type: 'NUMBER',
                    default: 0,
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Charge From PV',
      setting: 'CHARGEFROMPV',
      opts: {
        fields: [
          {
            key: 'chargeFromPvSettings',
            required: true,
            type: 'CONTAINER',
            default: null,
          },
        ],
      },
    },
    {
      name: 'Disable Grid',
      setting: 'DISABLEGRID',
      opts: {
        fields: [
          {
            name: 'Rate (%)',
            key: 'ratePercent',
            required: true,
            type: 'NUMBER',
            default: 0,
          },
        ],
      },
    },
  ];

  this.sunverge = this.sis;
  this.tesla = {
    battery: {
      modeList: [
        { name: 'Off', setting: 'off' },
        { name: 'Direct', setting: 'direct' },
        { name: 'Autonomous', setting: 'autonomous' },
        { name: 'Scheduler', setting: 'scheduler' },
        { name: 'Backup', setting: 'backup' },
        { name: 'Self-Consumption', setting: 'self-consumption' },
      ],
    },
  };
  this.sonnen = {
    battery: [
      {
        type: 'KD-072',
        modeList: [
          { name: 'Automatic', setting: 'AUTO' },
          { name: 'Charge -> Auto', setting: 'CHARGE_THEN_AUTO' },
          { name: 'Discharge -> Auto', setting: 'DISCHARGE_THEN_AUTO' },
          { name: 'Standby', setting: 'STANDBY' },
          { name: 'Charge -> Standby', setting: 'CHARGE_THEN_STANDBY' },
          { name: 'Discharge -> Standby', setting: 'DISCHARGE_THEN_STANDBY' },
          { name: 'Slave', setting: 'SLAVE' },
          { name: 'Bypass', setting: 'BYPASS' },
          { name: 'Auto LL Charge', setting: 'AUTO_LL_CHARGE' },
          { name: 'Auto-Charge', setting: 'AUTO_CHARGE' },
          { name: 'Auto-Discharge', setting: 'AUTO_DISCHARGE' },
          { name: 'Auto Final Charge 1', setting: 'AUTO_FINALCHARGE1' },
          { name: 'Auto Final Charge 2', setting: 'AUTO_FINALCHARGE2' },
          { name: 'Standby LL Charge', setting: 'STANDBY_LL_CHARGE' },
          { name: 'Charge Final Charge 1', setting: 'CHARGE_FINALCHARGE1' },
          { name: 'Charge Final Charge 2', setting: 'CHARGE_FINALCHARGE2' },
          { name: 'Charge Trickle', setting: 'CHARGE_TRICKLE' },
        ],
      },
      {
        type: 'SA-063',
        modeList: [
          { name: 'Manual', setting: 'MANUAL' },
          {
            name: 'Automatic Self-Consumption',
            setting: 'AUTOMATIC_SELF_CONSUMPTION',
          },
          { name: 'Peak Shaving', setting: 'PEAK_SHAVING_US_COMMERCIAL' },
          { name: 'Automatic Max', setting: 'AUTOMATIC_MAX' },
          { name: 'Automatic Simulated PV', setting: 'AUTOMATIC_SIMULATED_PV' },
          { name: 'Extension', setting: 'EXTENSION' },
          { name: 'Backup', setting: 'BACKUP' },
          { name: 'Self-Consumption', setting: 'SELF_CONSUMPTION_US' },
          { name: 'Unknown', setting: 'UNKONWN_MODE_9' },
          { name: 'Unknown', setting: 'UNKONWN_MODE_10' },
        ],
      },
    ],
  };

  this.solaredge = { battery: {} };

  this.solaredge.battery.modeList = [
    {
      name: 'Backup Only',
      setting: 'backupOnly',
    },
    {
      name: 'Self Consumption',
      setting: 'maximizeSelfConsumption',
    },
    {
      name: 'Disable',
      setting: 'disable',
    },
    {
      name: 'User Defined',
      setting: 'userDefinedStorageProfile',
    },
  ];

  this.pika = { battery: {} };

  this.pika.battery.modeList = [
    {
      name: 'Safety Shutdown',
      setting: 'SAFETY_SHUTDOWN',
    },
    {
      name: 'Grid Tie',
      setting: 'GRID_TIE',
    },
    {
      name: 'Self Supply',
      setting: 'SELF_SUPPLY',
    },
    {
      name: 'Clean Backup',
      setting: 'CLEAN_BACKUP',
    },
    {
      name: 'Priority Backup',
      setting: 'PRIORITY_BACKUP',
    },
    {
      name: 'Sell',
      setting: 'SELL',
    },
    {
      name: 'Remote Arbitrage',
      setting: 'REMOTE_ARBITRAGE',
    },
  ];

  this.sensibo = {
    tstat: {},
  };
  this.sensibo.tstat.fields = [
    'heatSetpoint',
    'coolSetpoint',
    'mode',
    'fanMode',
  ];
  this.sensibo.tstat.default = {
    heatSetpoint: 60,
    coolSetpoing: 80,
    mode: 'cool',
    fanMode: 'auto',
  };
  this.sensibo.tstat.setpointRange = [(16 * 9) / 5 + 32, (30 * 9) / 5 + 32];
  this.sensibo.tstat.modeList = [
    {
      name: 'Off',
      setting: 'off',
    },
    {
      name: 'Cool',
      setting: 'cool',
    },
    {
      name: 'Heat',
      setting: 'heat',
    },
    {
      name: 'Fan Only',
      setting: 'fan',
    },
  ];
  this.sensibo.tstat.fanModeList = [
    {
      name: 'Auto',
      setting: 'auto',
    },
    {
      name: 'Low',
      setting: 'low',
    },
    {
      name: 'Medium',
      setting: 'medium',
    },
    {
      name: 'High',
      setting: 'high',
    },
  ];

  this.aquanta = {
    hwh: {},
  };
  this.aquanta.hwh.default = {
    mode: 'manual',
    setpoint: 120,
  };
  this.aquanta.hwh.setpointRange = [(10 * 9) / 5 + 32, (80 * 9) / 5 + 32];
  this.aquanta.hwh.modeList = [
    {
      setting: 'NONE',
      name: 'None',
    },
    {
      setting: 'LEARNING',
      name: 'Learning',
    },
    {
      setting: 'INTELLIGENCE',
      name: 'Intelligence',
    },
    {
      setting: 'SETPOINT',
      name: 'Setpoint Control',
    },
    {
      setting: 'AWAY',
      name: 'Vacation',
    },
    {
      setting: 'BOOST',
      name: 'Boost',
    },
    {
      setting: 'TIMER',
      name: 'Timer',
    },
    {
      setting: 'BASELINE',
      name: 'Baseline',
    },
    {
      setting: 'DR_LOADSHIFT',
      name: 'DR Loadshifting',
    },
    {
      setting: 'DR_CURTAILMENT',
      name: 'DR Curtailment',
    },
    {
      setting: 'DEVICE_TOU',
      name: 'Device TOU',
    },
  ];

  this.intesis = { tstat: {} };
  this.intesis.tstat.modeList = [
    {
      name: 'Off',
      setting: 'off',
    },
    {
      name: 'Cool',
      setting: 'cool',
    },
    {
      name: 'Heat',
      setting: 'heat',
    },
    {
      name: 'Auto',
      setting: 'auto',
    },
    {
      name: 'Fan Only',
      setting: 'fan',
    },
    {
      name: 'Dry',
      setting: 'dry',
    },
  ];
  this.intesis.tstat.fanModeList = [
    {
      name: 'Auto',
      setting: 'auto',
    },
    {
      name: 'Low',
      setting: 'position-one',
    },
    {
      name: 'Medium',
      setting: 'position-three',
    },
    {
      name: 'High',
      setting: 'position-five',
    },
  ];

  this.evse = {};
  this.evse.modeList = [
    {
      name: 'Unreachable',
      setting: 'UNREACHABLE',
    },
    {
      name: 'Available',
      setting: 'AVAILABLE',
    },
    {
      name: 'In Use',
      setting: 'INUSE',
    },
    {
      name: 'Charging',
      setting: 'INUSE_CHARGING',
    },
    {
      name: 'Error',
      setting: 'ERROR',
    },
  ];

  this.rheem = {
    hwh: [
      {
        type: 'Heat Pump Water Heater Gen 4',
        modeList: [
          { name: i18n.t('energySaver'), setting: 'Energy Saver' },
          { name: i18n.t('electric'), setting: 'Electric' },
          { name: i18n.t('heatPump'), setting: 'Heat Pump' },
          { name: i18n.t('highDemand'), setting: 'High Demand' },
          { name: i18n.t('off'), setting: 'Off' },
        ],
        tempLimits: [110, 140],
        vacationSetting: 'Off',
      },
      {
        type: 'Heat Pump Water Heater Gen 5',
        modeList: [
          { name: i18n.t('energySaver'), setting: 'Energy Saver' },
          { name: i18n.t('electric'), setting: 'Electric' },
          { name: i18n.t('heatPump'), setting: 'Heat Pump' },
          { name: i18n.t('highDemand'), setting: 'High Demand' },
          { name: i18n.t('off'), setting: 'Off' },
        ],
        tempLimits: [110, 140],
        vacationSetting: 'Off',
      },
      {
        type: 'Heat Pump Water Heater',
        modeList: [
          { name: i18n.t('energySaver'), setting: 'Energy Saver' },
          { name: i18n.t('electric'), setting: 'Electric-Only' },
          { name: i18n.t('heatPump'), setting: 'Heat Pump Only' },
          { name: i18n.t('highDemand'), setting: 'High Demand' },
          { name: i18n.t('vacation'), setting: 'Vacation' },
        ],
        tempLimits: [85, 140],
        vacationSetting: 'Vacation',
      },
      {
        type: 'Electric Water Heater',
        modeList: [
          { name: i18n.t('energySaver'), setting: 'Energy Saver' },
          { name: i18n.t('performance'), setting: 'Performance' },
        ],
        tempLimits: [110, 130],
        vacationSetting: null,
      },
      {
        type: 'Gas Water Heater',
        modeList: [],
        tempLimits: [89, 140],
        vacationSetting: null,
      },
      {
        type: 'Commercial Hi Eff. Water Heater',
        modeList: [
          { name: 'Enabled', setting: 'Enabled' },
          { name: 'Disabled', setting: 'Disabled' },
        ],
        tempLimits: [85, 185],
        vacationSetting: 'Disabled',
      },
      {
        type: 'Tankless Water Heater',
        modeLIst: [],
        tempLimits: [85, 140],
        vacationSetting: null,
      },
    ],
  };

  this.getVacationSetting = function getVacationSetting(device) {
    if (device.mfg === 'rheem') {
      const deviceSpec = self[device.mfg][device.kind]
        .find(({ type }) => type === device.type);
      if (deviceSpec) {
        return deviceSpec.vacationSetting;
      }
      return null;
    }
    return 'VACATION';
  };

  this.getModeList = function getModeList(device) {
    if (device.mfg === 'rheem') {
      const deviceSpec = self[device.mfg][device.kind]
        .find(({ type }) => type === device.type);
      if (deviceSpec) {
        return deviceSpec.modeList;
      }
      return null;
    }
    if (self[device.mfg]) {
      if (self[device.mfg][device.kind] && self[device.mfg][device.kind].modeList) {
        return self[device.mfg][device.kind].modeList;
      } else if (self[device.mfg].modeList) {
        return self[device.mfg].modeList;
      }
      return null;
    }
    return null;
  };

  this.getFanModeList = function getFanModeList(device) {
    if (device.kind === 'tstat' || device.kind === 'rac') {
      if (self[device.mfg] && self[device.mfg][device.kind]) {
        return self[device.mfg][device.kind].fanModeList;
      } else {
        return null;
      }
    }
    return null;
  };

  this.getTempLimits = function getModeList(device) {
    if (device.mfg === 'rheem') {
      const deviceSpec = self[device.mfg][device.kind]
        .find(({ type }) => type === device.type);
      if (deviceSpec) {
        return deviceSpec.tempLimits;
      }
    }
    switch (device.kind) {
      case 'hwh':
        return [100, 140];
      case 'tstat':
        return [64, 86];
      default:
        return [];
    }
  };
}
