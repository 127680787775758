<template>
  <div class="device-limit-notice" :style="style">
    <div class="device-limit-notice__icon">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 30C6.70391 30 0 23.2961 0 15C0 6.70391 6.70391 0 15 0C23.2961 0 30 6.70391 30 15C30 23.2961 23.2961 30 15 30ZM3.01676 14.7905C3.01676 21.4106 8.37989 26.7737 15 26.7737C21.6201 26.7737 26.9832 21.4106 26.9832 14.7905C26.9832 8.17039 21.6201 2.80726 15 2.80726C8.37989 2.80726 3.01676 8.17039 3.01676 14.7905Z" :fill="providerColor" />
        <path d="M10.5165 18.9804H8.54724L11.8573 12.905V13.4078H8.37964V11.9413H13.6171V13.324L10.5165 18.9804Z" :fill="providerColor" />
        <path d="M14.497 18.9804V17.5978L16.8015 15.2095C17.011 15 17.1786 14.7486 17.2624 14.581C17.3881 14.3715 17.43 14.162 17.43 13.9525C17.43 13.7011 17.3462 13.5335 17.2205 13.4497C17.0948 13.324 16.8853 13.2821 16.6339 13.2821C16.3406 13.2821 16.0473 13.324 15.754 13.4497C15.4607 13.5754 15.1255 13.7011 14.8322 13.9106L14.2456 12.6117C14.5389 12.3603 14.916 12.1927 15.335 12.067C15.754 11.9413 16.2568 11.8575 16.7177 11.8575C17.2205 11.8575 17.6814 11.9413 18.0585 12.1089C18.4355 12.2765 18.7288 12.486 18.9383 12.8212C19.1478 13.1145 19.2316 13.4916 19.2316 13.8687C19.2316 14.2877 19.1478 14.6648 18.9802 15C18.8126 15.3352 18.5612 15.7123 18.1423 16.1313L16.3406 17.9749L16.0892 17.514H19.5249V18.9804H14.497Z" :fill="providerColor" />
        <path d="M21.7456 14.9581C21.4523 14.9581 21.2009 14.8743 20.9495 14.7486C20.6981 14.6229 20.5305 14.4134 20.4048 14.2039C20.2791 13.9525 20.1953 13.7011 20.1953 13.4078C20.1953 13.1145 20.2791 12.8631 20.4048 12.6117C20.5305 12.3603 20.74 12.1927 20.9495 12.067C21.2009 11.9413 21.4523 11.8575 21.7456 11.8575C22.0389 11.8575 22.2903 11.9413 22.5417 12.067C22.7931 12.1927 22.9607 12.3603 23.0864 12.6117C23.2121 12.8631 23.2959 13.1145 23.2959 13.4078C23.2959 13.7011 23.2121 13.9525 23.0864 14.2039C22.9607 14.4553 22.7512 14.6229 22.5417 14.7486C22.3322 14.8743 22.0389 14.9581 21.7456 14.9581ZM21.7456 14.0782C21.9132 14.0782 22.0808 13.9944 22.2065 13.9106C22.3322 13.7849 22.416 13.6173 22.416 13.4078C22.416 13.1983 22.3322 13.0307 22.2065 12.9469C22.0808 12.8212 21.9132 12.7793 21.7456 12.7793C21.578 12.7793 21.4104 12.8212 21.2847 12.9469C21.159 13.0726 21.1171 13.2402 21.1171 13.4078C21.1171 13.6173 21.159 13.7849 21.2847 13.9106C21.4104 14.0363 21.578 14.0782 21.7456 14.0782Z" :fill="providerColor" />
      </svg>
    </div>
    <div class="device-limit-notice__message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'DeviceLimitNotice',
  props: {
    brand: {
      type: Object,
      default: () => ({
        color: '#000000',
      }),
    },
    limit: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messages: {
        noAccess: 'This device manufacturer carefully protects your thermostat\'s information. You can view or control your device manually or from the manufacturer\'s recommended device application.',
        noControl: 'This device manufacturer limits your ability to control your device from our application. You can make adjustments manually or from the manufacturer\'s recommended device application.',
      },
    };
  },
  computed: {
    message() {
      if (this.limit === 'noAccess') {
        return this.$i18n.t('deviceLimitNoAccess');
      } else if (this.limit === 'noControl') {
        return this.$i18n.t('deviceLimitNoControl');
      }
      return '';
    },
    providerColor() {
      return this.brand.color;
    },
    style() {
      return {
        '--default-padding': this.styleConfig.default.padding,
        '--default-column-gap': this.styleConfig.default.columnGap,
        '--default-border': this.styleConfig.default.border,
        '--default-icon-size': this.styleConfig.default.iconSize,
        '--default-font-size': this.styleConfig.default.fontSize,
        '--default-line-height': this.styleConfig.default.lineHeight,
        '--default-color': this.styleConfig.default.color,
        '--tablet-padding': this.styleConfig.tablet.padding,
        '--tablet-icon-size': this.styleConfig.tablet.iconSize,
        '--tablet-font-size': this.styleConfig.tablet.fontSize,
        '--tablet-line-height': this.styleConfig.tablet.lineHeight,
      };
    },
    styleConfig() {
      if (this.mode === 'subpanel') {
        return {
          default: {
            padding: '4px 0 0',
            columnGap: '8px',
            border: 'none',
            iconSize: '22px',
            fontSize: '8px',
            lineHeight: '9px',
            color: '#AFAFAF',
          },
          tablet: {
            padding: '16px 0 0',
            iconSize: '30px',
            fontSize: '12px',
            lineHeight: '15.06px',
          },
        };
      }
      return {
        default: {
          padding: '13px 16px',
          columnGap: '16px',
          border: '1px solid #DBDBDB',
          iconSize: '30px',
          fontSize: '11px',
          lineHeight: '16px',
          color: '#4F4F4F',
        },
        tablet: {
          padding: '40px 41px',
          iconSize: '80px',
          fontSize: '16px',
          lineHeight: '24px',
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.device-limit-notice {
  padding: var(--default-padding);

  display: flex;
  column-gap: var(--default-column-gap);
  align-items: center;

  border: var(--default-border);
  box-sizing: border-box;
  border-radius: 6px;

  &__icon {
    display: flex;
    svg {
      width: var(--default-icon-size);
      height: var(--default-icon-size);
    }
  }
  &__message {
    color: var(--default-color);
    font-size: var(--default-font-size);
    line-height: var(--default-line-height);
  }

  @include Tablet--Large {
    padding: var(--tablet-padding);

    &__icon {
      svg {
        width: var(--tablet-icon-size);
        height: var(--tablet-icon-size);
      }
    }
    &__message {
      font-size: var(--tablet-font-size);
      line-height: var(--tablet-line-height);
    }
  }
}
</style>
