<template>
  <div class="device-settings-list">
    <template v-for="setting in settingsList">
      <VpInput v-if="setting.element === 'input'" :key="setting.key" v-model="setting.source[setting.key]" class="device-settings-list__input" :type="setting.inputType || false" :disabled="setting.disabled">
        <template #label>
          <span>{{ settingTranslationLabel(setting) }}</span>
        </template>
        <template #affix>
         <VpTooltip v-if="setting.tooltip" class="device-settings-list__tooltip" :body="setting.tooltip.body" :icon="setting.tooltip.icon || 'question-circle'" :style="{ color: brand.color }" />
        </template>
        <template v-if="setting.inputSuffix" #suffix>{{ setting.inputSuffix }}</template>
      </VpInput>
      <VpCheckbox v-if="setting.element === 'checkbox'" :key="setting.key" v-model="setting.source[setting.key]" class="device-settings-list__checkbox" :disabled="setting.disabled" :styles="{ color: brand.color }">
        <template #label>
          <span>{{ settingTranslationLabel(setting) }}</span>
          <VpTooltip v-if="setting.tooltip" class="device-settings-list__tooltip" :body="setting.tooltip.body" :style="{ color: brand.color }" />
        </template>
      </VpCheckbox>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DeviceSettingsList',
  inject: ['$filter'],
  props: {
    brand: {
      type: Object,
      default: () => ({ color: '#000000' }),
    },
    settingsList: {
      type: Array,
      default: () => ([
        // {
        //   element: '', // 'input | 'checkbox'
        //   label: '',
        //   source: {},
        //   key: '',
        //   resource: '',
        //   disabled: false,
        //   tooltip: {},
        //   inputSuffix: '',
        //   inputType: '',
        // },
      ]),
    },
  },
  methods: {
    settingTranslationLabel(setting) {
      let translationKey = this.$filter.camelize(setting.label);
      if (translationKey === 'iDNumber') { translationKey = 'deviceUid'; }
      if (translationKey === 'deviceManufacturer' && this.settingsList[1].source.kind === 'ev') { translationKey = 'evDeviceMfg'; }
      if (translationKey === 'deviceName' && this.settingsList[1].source.kind === 'ev') { translationKey = 'evDeviceName'; }
      return this.$i18n.t(translationKey);
    },
  },
};
</script>

<style lang="scss">
.device-settings-list {
  display: flex;
  flex-flow: row wrap;
  gap: 32px 26px;

  padding: 32px 24px;

  &__input {
    max-width: 300px !important;

    // Vertical alignment for VpTooltip in "affix" slot
    .vcl-input__label-row svg.icon {
      margin-bottom: 6px !important;
    }
  }
  &__checkbox {
    padding-top: 22px;
  }
  &__tooltip {
    margin-left: 8px;
  }
}
</style>
