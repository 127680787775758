<template>
  <div class="weather-forecast-card">
    <div class="weather-forecast-card__date">{{ date }}</div>
    <i class="weather-forecast-card__icon" :class="icon" />
    <div class="weather-forecast-card__desc">{{ desc }}</div>
    <div class="weather-forecast-card__max">{{ max }}°</div>
    <div class="weather-forecast-card__min">{{ min }}°</div>
    <div class="weather-forecast-card__precip">{{ $t('precipitation') }}: {{ precip }}%</div>
    <div class="weather-forecast-card__clouds">{{ $t('cloudCoverage') }}: {{ clouds }}%</div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'WeatherForecastCard',
  inject: ['$filter'],
  props: {
    forecast: {
      type: Object,
      required: true,
    },
    screenSize: {
      type: String,
      default: () => ('small'),
    },
  },
  computed: {
    date() {
      let dateFormat = 'dddd, MMM D';
      if (['medium', 'mediumLarge', 'large'].includes(this.screenSize)) {
        dateFormat = 'dddd, MMMM D';
      }
      const dateStr = this.$filter.date(this.forecast.date, dateFormat, this.$i18n.locale);
      const isToday = moment(this.forecast.date).isSame(moment(), 'day');
      if (isToday) {
        const splitDate = dateStr.split(', ');
        splitDate[0] = this.$i18n.t('forecastToday');
        return splitDate.join(', ');
      }
      return dateStr;
    },
    icon() {
      return this.forecast.weather.icon;
    },
    min() {
      return this.forecast.temp.min;
    },
    max() {
      return this.forecast.temp.max;
    },
    desc() {
      return this.$filter.truncateString(this.forecast.weather.description, { maxChars: 20, maxWords: 4 });
    },
    precip() {
      return this.forecast.precip;
    },
    clouds() {
      return this.forecast.clouds;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
.weather-forecast-card {
  width: 100%;
  height: 77.12px;

  border: 1px solid #AFAFAF;
  border-radius: 6px;

  padding: 6px;

  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas:
    "date date date date date date .    .    .    .    .    .   "
    "date date date date date date max_ max_ max_ min_ min_ min_"
    ".    .    .    .    .    .    max_ max_ max_ min_ min_ min_"
    "icon icon icon icon icon icon max_ max_ max_ min_ min_ min_"
    "icon icon icon icon icon icon max_ max_ max_ min_ min_ min_"
    "icon icon icon icon icon icon .    .    .    .    .    .   "
    "icon icon icon icon icon icon .    .    .    .    .    .   "
    "icon icon icon icon icon icon .    .    .    .    .    .   "
    "icon icon icon icon icon icon prcp prcp prcp prcp prcp prcp"
    ".    .    .    .    .    .    prcp prcp prcp prcp prcp prcp"
    "desc desc desc desc desc desc clds clds clds clds clds clds"
    "desc desc desc desc desc desc clds clds clds clds clds clds";
    gap: 4px;
    justify-items: center;
    align-items: center;

    font-size: 10px;
    line-height: 10px;
    font-weight: 300px;
    text-align: center;

  &__date {
    grid-area: date;

    width: 100%;
    height: max-content;

    font-size: 10px;
    line-height: 14px;

    text-transform: uppercase;

    border: 1px solid #DBDBDB;
    border-radius: 4px;
  }
  &__icon {
    grid-area: icon;

    font-size: 24px;
    line-height: 24px;
  }
  &__desc {
    grid-area: desc;

    width: 100%;
    height: max-content;

    font-size: 12px;
    line-height: 12px;

    text-transform: capitalize;
  }
  &__max {
    grid-area: max_;

    font-size: 24px;
    line-height: 24px;
  }
  &__min {
    grid-area: min_;

    font-size: 24px;
    line-height: 24px;
  }
  &__precip {
    grid-area: prcp;

    font-size: 11px;
    line-height: 11px;

    text-transform: capitalize;
  }
  &__clouds {
    grid-area: clds;

    font-size: 11px;
    line-height: 11px;

    text-transform: capitalize;
  }

  @include Tablet--XLarge {
    height: 142px;

    padding: 8px;

    grid-template-areas:
    "date date date date date date date date date date date date"
    "date date date date date date date date date date date date"
    "date date date date date date date date date date date date"
    "icon icon icon icon max_ max_ max_ max_ min_ min_ min_ min_"
    "icon icon icon icon max_ max_ max_ max_ min_ min_ min_ min_"
    "icon icon icon icon max_ max_ max_ max_ min_ min_ min_ min_"
    "icon icon icon icon max_ max_ max_ max_ min_ min_ min_ min_"
    "desc desc desc desc desc desc desc desc desc desc desc desc"
    "desc desc desc desc desc desc desc desc desc desc desc desc"
    "desc desc desc desc desc desc desc desc desc desc desc desc"
    "prcp prcp prcp prcp prcp prcp clds clds clds clds clds clds"
    "prcp prcp prcp prcp prcp prcp clds clds clds clds clds clds";

    &__date {
      font-size: 14px;
      line-height: 19.5px;

      align-self: baseline;

      text-transform: capitalize;
    }
    &__icon {
      font-size: 32px;
    }
    // &__max {}
    // &__min {}
    &__desc {
      padding-bottom: 12px;
      border-bottom: 1px solid #AFAFAF;
      font-size: 14px;
      line-height: 14px;
    }
    &__precip {
      font-size: 12px;
      line-height: 12px;
    }
    &__clouds {
      font-size: 12px;
      line-height: 12px;
    }
  }

  @include Desktop {
    height: 190px;
    grid-template-areas:
    "date date date date date date date date date date date date"
    "date date date date date date date date date date date date"
    "icon icon icon icon icon icon icon icon icon icon icon icon"
    "icon icon icon icon icon icon icon icon icon icon icon icon"
    "icon icon icon icon icon icon icon icon icon icon icon icon"
    "max_ max_ max_ max_ max_ max_ min_ min_ min_ min_ min_ min_"
    "max_ max_ max_ max_ max_ max_ min_ min_ min_ min_ min_ min_"
    "desc desc desc desc desc desc desc desc desc desc desc desc"
    "desc desc desc desc desc desc desc desc desc desc desc desc"
    ".    .    .    .    .    .    .    .    .    .    .    .   "
    "prcp prcp prcp prcp prcp prcp prcp prcp prcp prcp prcp prcp"
    "clds clds clds clds clds clds clds clds clds clds clds clds";

    // &__date {}
    // &__icon {}
    // &__max {}
    // &__min {}
    &__desc {
      padding-top: 4px;
    }
    &__precip {
      margin-top: -14px;
      font-size: 14px;
      line-height: 14px;
    }
    &__clouds {
      margin-top: -6px;
      font-size: 14px;
      line-height: 14px;
    }
  }

  @include Desktop--Large {
    height: 275px;
    grid-template-areas:
    "date date date date date date date date date date date date"
    "icon icon icon icon icon icon icon icon icon icon icon icon"
    "icon icon icon icon icon icon icon icon icon icon icon icon"
    "icon icon icon icon icon icon icon icon icon icon icon icon"
    "icon icon icon icon icon icon icon icon icon icon icon icon"
    ".    max_ max_ max_ max_ max_ min_ min_ min_ min_ min_ .   "
    ".    max_ max_ max_ max_ max_ min_ min_ min_ min_ min_ .   "
    "desc desc desc desc desc desc desc desc desc desc desc desc"
    "desc desc desc desc desc desc desc desc desc desc desc desc"
    ".    .    .    .    .    .    .    .    .    .    .    .   "
    "prcp prcp prcp prcp prcp prcp prcp prcp prcp prcp prcp prcp"
    "clds clds clds clds clds clds clds clds clds clds clds clds";

    &__date {
      line-height: 24px;
    }
    &__icon {
      font-size: 40px;
      line-height: 40px;
    }
    &__max {
      margin-top: -14px;
      font-size: 32px;
      line-height: 32px;
    }
    &__min {
      margin-top: -14px;
      font-size: 32px;
      line-height: 32px;
    }
    &__desc {
      margin-top: -8px;
      padding-bottom: 18px;
      font-size: 16px;
      line-height: 16px;
    }
    &__precip {
      margin-top: -24px;
      font-size: 16px;
      line-height: 16px;
    }
    &__clouds {
      margin-top: -14px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
