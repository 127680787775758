import DeviceTabs from './DeviceTabs/DeviceTabs.vue';

export default [
  {
    path: '/device/:deviceId',
    component: DeviceTabs,
    props: { tab: 'status' },
  },
  {
    path: '/device/:deviceId/schedule',
    component: DeviceTabs,
    props: { tab: 'schedule' },
  },
  {
    path: '/device/:deviceId/settings',
    component: DeviceTabs,
    props: { tab: 'settings' },
  },
];
