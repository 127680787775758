<template>
  <div class="app-authentication">
    <VpAuthentication
      :styles="styles"
      :logo="logo"
      :name="name"
      @is-authenticated="onAuthenticated"
    >
      <template #controls>
        <div v-if="multipleLanguages" class="app-authentication__language-control">
          <LanguageButton />
        </div>
      </template>
    </VpAuthentication>
  </div>
</template>

<script>
export default {
  name: 'AppAuthentication',
  inject: ['$busy', '$byodConfig'],
  emits: ['auth'],
  data() {
    return {
      logo: this.$byodConfig.logoLight,
      name: this.$byodConfig.name,
      styles: {
        tabInactiveColor: '#4f4f4f',
        tabBackgroundColor: '#EFF1F2',
        primaryColor: this.$byodConfig.color,
      },
    };
  },
  computed: {
    multipleLanguages() {
      const multiLanguageConfig = this.$byodConfig.commonSettings.find(({ key }) => key === 'byodMultipleLanguages');
      if (multiLanguageConfig !== undefined) {
        return multiLanguageConfig.value;
      } else {
        return false;
      }
    },
  },
  methods: {
    onAuthenticated(isAuthenticated) {
      this.$emit('auth', isAuthenticated);
    },
  },
};
</script>

<style lang="scss">
.app-authentication {
  &__language-control {
    margin-bottom: 32px;
  }
}
</style>
