<template>
  <div>
    <div id="mcContainer" :style="bodyCSS">
      <div id="mcHeader" :style="mcHeaderStyle">
        {{ $t('messageCenter') }}
        <vp-select id="messageType" v-model="messageCenterMode" :options="messageTypeOptions" width="160px" aria-label="Select Message Type" />
      </div>
      <vp-tab-menu id="mc" v-show="!isMobileView" v-model="messageCenterMode" :tabs="messageModeTabs" :active-tab-under-line-color="providerColor">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <template #inbox>{{ $t('inbox') }}<span v-html="inboxTabUnreadCount" /></template>
        <template #favorites>{{ $t('favorites') }}</template>
        <template #archived>{{ $t('archived') }}</template>
      </vp-tab-menu>
      <div id="mcLayout" :style="mcLayoutStyle">
        <div id="leftColumn" :style="leftColumnStyle">
          <div id="messageSearchContainerOuter">
            <div id="messageSearchContainer">
              <vp-input id="message-search" v-model="filterText" :placeholder="$t('search')" :clear-on-click="1">
                <template #suffix>
                  <vp-icon icon="magnify_gray" />
                </template>
              </vp-input>
            </div>
          </div>
          <MessagePreviewList :messages="messages" @message-selected="messageSelected" />
        </div>
        <div id="rightColumn" :style="rightColumnStyle">
          <MessageBody :message="selectedMessage" />
          <div id="mailboxInitialView" :style="mailboxInitialViewStyle">
            <img :src="require('@/assets/icons/mailbox.svg')">
            <div id="mailboxInitialViewLineOne">{{ $t('messageCenterWelcome') }}</div>
            <div id="mailboxInitialViewLineTwo">{{ $t('getStarted') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { VpKebabMenu } from '@virtual-peaker/vue-component-library';
import MessagePreviewList from './MessagePreviewList.vue';
import MessageBody from './MessageBody.vue';

export default {
  name: 'MessageCenter',
  components: {
    MessagePreviewList,
    MessageBody,
  },
  inject: ['$byodConfig', '$toast'],
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data: function data() {
    return {
      filterText: '',
      unreadCount: '',
      triggerMessageReload: 0,
    };
  },
  computed: {
    bodyCSS() {
      return {
        '--provider-color': this.$byodConfig.color,
      };
    },
    breakpoint() {
      return this.$store.getters.breakpoint;
    },
    inboxTabUnreadCount: function inboxTabUnreadCount() {
      if (this.unreadCount > 0) {
        return ` <div id="inboxTabUnreadCount">${this.unreadCount}</div>`;
      }
      return '';
    },
    isMobileView() {
      if ((this.breakpoint.substring(0, 6) === 'mobile') ||
          (this.breakpoint.substring(0, 6) === 'tablet')) {
        return true;
      } return false;
    },
    leftColumnStyle() {
      const style = {};
      if (this.isMobileView) {
        style.display = 'inline-block';
        if (this.selectedMessageId !== '') {
          style.display = 'none';
        }
      } else {
        style.display = 'flex';
      }
      return style;
    },
    mailboxInitialViewStyle() {
      const style = {};
      if (this.isMobileView) {
        style.display = 'none';
      }
      if (!this.isMobileView && this.selectedMessageId === '') {
        style.display = 'block';
        style['padding-bottom'] = '40px';
        if (this.windowHeight < 1300) {
          style['padding-top'] = '0px';
        } else {
          style['padding-top'] = '100px';
        }
      }
      return style;
    },
    mcHeaderStyle() {
      const style = {};
      if (this.isMobileView) {
        if (this.selectedMessageId !== '') {
          style.display = 'none';
        } else {
          style.display = 'block';
        }
      } else {
        style.display = 'block';
      }
      return style;
    },
    mcLayoutStyle() {
      const style = {};
      if (this.isMobileView) {
        style['overflow-y'] = '';
        style.height = '';
      } else {
        style['overflow-y'] = 'clip';
        style.height = `${this.windowHeight - 205}px`;
      }
      return style;
    },
    messageModeTabs: function messageModeTabs() {
      return [{ value: 'inbox', path: '/message-center/inbox' },
        { value: 'favorites', path: '/message-center/favorites' },
        { value: 'archived', path: '/message-center/archived' }];
    },
    messageTypeOptions: function messageTypeOptions() {
      return [{ name: this.$i18n.t('inbox'), value: 'inbox' },
        { name: this.$i18n.t('favorites'), value: 'favorites' },
        { name: this.$i18n.t('archived'), value: 'archived' }];
    },
    messages: {
      get() {
        return this.$store.getters['messageCenter/messages'];
      },
      set(newMessages) {
        this.$store.commit('messageCenter/updateMessages', newMessages);
      },
    },
    messageCenterMode: {
      get() {
        return this.$store.getters['messageCenter/messageCenterMode'];
      },
      set(newMode) {
        this.$store.commit('messageCenter/updateMessageCenterMode', newMode);
      },
    },
    modeLabel() {
      if (this.messageCenterMode === 'inbox') {
        return 'Inbox';
      }
      if (this.messageCenterMode === 'favorites') {
        return 'Favorites';
      }
      if (this.messageCenterMode === 'archived') {
        return 'Archived';
      }
      return '';
    },
    providerColor() { return this.$byodConfig.color; },
    rightColumnStyle() {
      const style = {};
      if (this.isMobileView) {
        style.display = 'none';
        style['overflow-y'] = 'auto';
        if (this.selectedMessageId !== '') {
          style.display = 'inline-block';
        }
      } else {
        style.display = 'inline-block';
      }
      return style;
    },
    selectedMessage: {
      get() {
        return this.$store.getters['messageCenter/selectedMessage'];
      },
      set(newMsg) {
        this.$store.commit('messageCenter/updateSelectedMessage', newMsg);
      },
    },
    selectedMessageId: {
      get() {
        return this.$store.getters['messageCenter/selectedMessageId'];
      },
      set(newMsgId) {
        this.$store.commit('messageCenter/updateSelectedMessageId', newMsgId);
      },
    },
    windowWidth() {
      return this.$store.getters.windowWidth;
    },
    windowHeight() {
      return this.$store.getters.windowHeight;
    },
  },
  watch: {
    filterText() {
      this.messageFilter();
    },
    isMobileView() {
      this.updateHeaderView();
    },
    messageCenterMode(mode) {
      let path = '';
      if (mode === 'inbox') {
        path = '/message-center/inbox';
      } else if (mode === 'favorites') {
        path = '/message-center/favorites';
      } else if (mode === 'archived') {
        path = '/message-center/archived';
      }
      if (path !== '') {
        this.$router.push({ path }).catch(() => {});
        this.triggerMessageReload = 1;
      }
    },
    mode(newMode) {
      this.messageCenterMode = newMode;
    },
    selectedMessageId(newId) {
      if (newId === '') {
        document.getElementById('topBarLeftDiv').innerHTML = '';
        document.getElementById('topBarCenterDiv').innerHTML = '';
      } else {
        const leftArrowSrc = require('@/assets/icons/left_arrow.svg');
        document.getElementById('topBarLeftDiv').innerHTML = `<img class="messageHeaderBackBtn" src="${leftArrowSrc}">`;
        document.getElementById('topBarLeftDiv').addEventListener('click', this.returnToMessageSelection);

        // build content for center of header bar in mobile view
        const created = new Date(this.selectedMessage.createdAt);
        const month = created.toLocaleString('default', { month: 'long' });
        const hours = created.getHours();
        const createdDisplay = `${month} ${created.getDate()}, ${created.getFullYear()} ${
          hours > 12 ? (hours - 12) : hours}:${created.getMinutes()} ${
          hours >= 12 ? 'PM' : 'AM'}`;
        const centerHTML = `<div class="headerMessageSubject">${this.selectedMessage.subject}</div>` +
          `<div class="headerMessageDate">${createdDisplay}</div>`;
        document.getElementById('topBarCenterDiv').innerHTML = centerHTML;

        // build kebab menu for right side of header bar in mobile view
        if (!document.getElementById('kebab-mobile-message')) {
          const MobileKebab = Vue.extend(VpKebabMenu);
          const instance = new MobileKebab();
          instance.id = 'kebab-mobile-message';

          let menuOptions = [];
          if (this.messageCenterMode === 'inbox') {
            menuOptions = [{ label: 'Favorite', value: 'favorite' }, { label: 'Delete', value: 'delete' }];
          } else if (this.messageCenterMode === 'favorites') {
            menuOptions = [{ label: 'Delete', value: 'delete' }];
          }
          instance.$props.menuOptions = menuOptions;
          instance.$props.menuPosition = 'down-left';
          instance.$mount();
          const context = this;

          if (this.messageCenterMode === 'inbox') {
            instance._self.$el.children[1].children[0].children[0].addEventListener('click', () => {
              context.$store.dispatch('messageCenter/setFavorite', { id: context.selectedMessageId, value: 1 }).then(() => {});
            });
            instance._self.$el.children[1].children[0].children[1].addEventListener('click', () => {
              context.$store.dispatch('messageCenter/deleteMessage', context.selectedMessageId).then(() => {});
            });
          } else {
            instance._self.$el.children[1].children[0].children[0].addEventListener('click', () => {
              context.$store.dispatch('messageCenter/deleteMessage', context.selectedMessageId).then(() => {});
            });
          }
          document.getElementById('topBarRightDiv').appendChild(instance.$el);
        }
      }
      this.updateHeaderView();
    },
    triggerMessageReload() {
      if (this.triggerMessageReload === 1) {
        this.$store.dispatch('messageCenter/getAccountMessages', this.messageCenterMode).then((results) => {
          const newMsgs = {};
          if (results !== undefined) {
            results.forEach((result) => {
              newMsgs[result.uuid] = result;
            });
          }
          this.messages = newMsgs;
          const context = this;
          if (this.$route.params.messageId !== undefined) {
            Object.keys(this.messages).forEach((msgId) => {
              if (msgId === context.$route.params.messageId) {
                context.selectedMessageId = msgId;
                context.selectedMessage = context.messages[msgId];
              }
            });
          }
          this.triggerMessageReload = 0;
        });
      }
    },
  },
  created() {
    this.messageReplyEnabled = 0;
    this.unreadCount = parseInt(localStorage.getItem('unreadCount'), 10);

    this.messageCenterMode = this.mode;
    this.selectedMessageId = '';
  },

  beforeDestroy() {
    document.getElementById('topBarCenterDiv').innerHTML = '';
    document.getElementById('topBarCenterDiv').style.display = 'none';
    document.getElementById('topBarLeftDiv').style.display = 'none';
    document.getElementById('topBarRightDiv').innerHTML = '';
    document.getElementById('topBarRightDiv').style.display = 'none';
    localStorage.setItem('unreadCount', 0);
    if (this.isMobileView) {
      document.getElementById('topBarProviderLogo').style.display = 'inline-block';
    }
  },

  mounted() {
    this.$store.dispatch('messageCenter/countUnread').then((results) => {
      localStorage.setItem('unreadCount', results);
      this.unreadCount = results;
    });

    window.addEventListener('click', () => {
      if (window.messageActionsDisplayed) {
        const messageActions = document.getElementById('messageActions');
        messageActions.style.display = 'none';
        window.messageActionsDisplayed = 0;
      }
    });

    this.triggerMessageReload = 1;
  },
  methods: {
    messageFilter: function messageFilter() {
      const filterFields = ['subject', 'plainTextBody'];
      if (this.messages === undefined) { return; }
      const updatedMsgs = {};
      Object.values(this.messages).forEach((message) => {
        let matches = 0;
        filterFields.forEach((key) => {
          if ((message[key] !== undefined) && (message[key] !== null)) {
            if (message[key].toString().toLowerCase().indexOf(this.filterText.toLowerCase()) !== -1) {
              matches = 1;
            }
          }
        });
        if (matches) {
          message.hidden = '';
        } else {
          message.hidden = 'hidden';
        }
        updatedMsgs[message.uuid] = message;
      });
      this.$store.commit('messageCenter/updateMessages', updatedMsgs);
    },

    messageSearchClicked: function messageSearchClicked() {
      const messageSearch = document.getElementById('message-search');
      if (messageSearch.value === 'Search All Messages') {
        messageSearch.value = '';
        messageSearch.style.color = '#555';
      }
    },

    messageSelected: function messageSelected(id) {
      this.selectedMessageId = id;
      this.selectedMessage = this.messages[id];
      if (this.selectedMessage.read == null) {
        this.$store.dispatch('messageCenter/markAsRead', id).then(() => {
          this.unreadCount -= 1;
          localStorage.setItem('unreadCount', this.unreadCount);
        });
      }

      const newPath = `/message-center/${this.messageCenterMode}/message/${this.selectedMessage.uuid}`;
      this.$router.push({ path: newPath }).catch(() => {});
    },

    messageFavoriteToggle: function messageFavoriteToggle(messageId) {
      this.$store.dispatch('messageCenter/toggleFavorite', (messageId));
    },

    returnToMessageSelection: function returnToMessageSelection() {
      this.selectedMessageId = '';
      this.selectedMessage = null;
    },

    deleteMessage: function deleteMessage(id) {
      this.$store.dispatch('messageCenter/deleteMessage', id).then(() => {
        this.returnToMessageSelection();
      });
    },

    updateHeaderView: function updateHeaderView() {
      if (this.isMobileView) {
        document.getElementById('utilityLogoContainer').style.display = 'none';
        if (this.selectedMessageId === '') {
          document.getElementById('topBarProviderLogo').style.display = 'inline-block';
          document.getElementById('topBarLeftDiv').style.display = 'none';
          document.getElementById('topBarCenterDiv').style.display = 'none';
          document.getElementById('topBarRightDiv').style.display = 'none';
          document.getElementById('kebab-mobile-message').style.display = 'none';
          document.getElementById('mobileHeader').style.display = 'flex';
          document.getElementById('mc').style.display = 'block';
        } else {
          document.getElementById('topBarProviderLogo').style.display = 'none';
          document.getElementById('mobileHeader').style.display = 'none';
          document.getElementById('topBarLeftDiv').style.display = 'block';
          document.getElementById('topBarCenterDiv').style.display = 'inline-block';
          document.getElementById('topBarRightDiv').style.display = 'inline-block';
          document.getElementById('kebab-mobile-message').style.display = 'inline-block';
          document.getElementById('mc').style.display = 'none';
        }
      } else {
        // hide all of the mobile top bar components, show the desktop utility logo
        document.getElementById('topBarProviderLogo').style.display = 'none';
        document.getElementById('topBarLeftDiv').style.display = 'none';
        document.getElementById('topBarCenterDiv').style.display = 'none';
        document.getElementById('topBarRightDiv').style.display = 'none';
        document.getElementById('kebab-mobile-message').style.display = 'none';
        document.getElementById('utilityLogoContainer').style.display = 'inline-block';
        document.getElementById('mobileHeader').style.display = 'none';
        document.getElementById('mc').style.display = 'block';
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles';

.vue-app-container {
 background-color: $verylightgray;
 font-family: Muli;
}

#topBar {
 user-select:none;
}

#mcContainer {
 margin-top:32px;
 margin-right:20px;
 padding-left:32px;
}

#mcHeader {
 background-color: var(--provider-color);
 border-top-left-radius: 4px;
 border-top-right-radius: 4px;
 color: $white;
 font-size:24px;
 height:60px;
 line-height:60px;
 padding-left:24px;
 user-select:none;
 width:100%;
}

#mcMobileHeader {
 display:none;
}

#mcTabMenu {
 box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.05) inset;
 height:45px;
 padding-left:24px;
}

#inboxTabUnreadCount {
 background-color: var(--provider-color);
 border-radius:20px;
 color:$white;
 display:inline-block;
 font-size:12px;
 font-weight:700;
 height:18px;
 line-height:18px;
 margin-left:4px;
 text-align:center;
 width:30px;
}

#mcTabMenu a {
 color: $gray;
 display:inline-block;
 height:35px;
 line-height:28px;
 user-select:none;
}

a#inboxLink {}
a#favoritesLink { margin-left:32px; }
a#archivedLink { margin-left:32px; }

#mcTabMenu a.selectedPage {
 background: linear-gradient(0deg, var(--provider-color) 4px, $verylightgray 0px);
 color: var(--provider-color);
 cursor:default;
}

#mcLayout {
 background-color: $offwhite;
 display:flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: flex-start;
}

div#leftColumn {
 background-color:$white;
 border-right: 1px solid #DBDBDB;
 box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
 flex-flow: column;
 height: 100%;
 order:1;
 min-width:400px;
 overflow-y: hidden;
 width:400px;
}

div#rightColumn {
 background:$offwhite;
 min-width:500px;
 order:2;
 height:100%;
 width:100%;
}

#inboxIcon, #favoriteIcon, #archivedIcon {
 display:inline-block;
 float:left;
}

#inboxIcon {
 background: url(/assets/icons/inbox.svg);
 height:22px;
 width:26px;
}

#favoriteIcon {
 background: url(/assets/icons/star_big.svg);
 height:24px;
 width:26px;
}

#archivedIcon {
 background: url(/assets/icons/archive.svg);
 color:$charcoal;
 height:24px;
 width:23px;
}

#unreadCount {
 background-color:$lightblue;
 border-radius:20px;
 color:$darkblue;
 float:left;
 font-size:14px;
 font-weight:700;
 height:20px;
 line-height:20px;
 margin-left:8px;
 margin-top:1px;
 text-align:center;
 width:33px;
}

#messageSearchContainerOuter {
 border-bottom: 1px solid #DBDBDB;
 flex: 0 0 auto;
 height:104px;
}

#messageSearchContainer {
 margin:0 auto;
 padding-top:24px;
 text-align:center;
 width:340px;
}

#messageSearchContainer div {
 margin:0 auto;
}

div#mailboxInitialView {
 height:100%;
 overflow-y:scroll;
 padding-top:100px;
 text-align:center;
 user-select:none;
}

#mailboxInitialViewLineOne {
 color: $charcoal;
 font-size:20px;
 font-weight:400;
}
#mailboxInitialViewLineTwo {
 color: $charcoal;
 font-size:16px;
 font-weight:400;
 margin-top:20px;
}

h2 {
 font-size:32px;
}

#mcHeader div.vcl-select {
 display:none;
}

#new-message {
  display:none;
  float:right;
  margin-top:4px;
  width:140px;
}

div#messageListing {
 background-color: $offwhite;
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

div.messageFavorite {
 background: url(/assets/icons/star_empty.svg);
 cursor:pointer;
 height:15px;
 width:15px;
}

div.messageFavorite:hover {
 background: url(/assets/icons/star_full.svg);
 opacity:0.5;
}

div.messageFavorited {
 background: url(/assets/icons/star_full.svg);
}

div.messageFavorited:hover {
 background: url(/assets/icons/star_empty.svg);
}

@media (max-width:992px) {
 body {
  background-color: $verylightgray;
 }

 .vp-widget {
  margin-top:0px !important;
 }

 #topBarLeftDiv {
  cursor:pointer;
  float:left;
  height:100px;
  padding-left:30px;
  padding-top:25px;
  width:70px;
 }

 #topBarCenterDiv {
  flex-flow: column;
  height:100px;
  width: calc(100vw - 170px);
 }

 #topBarRightDiv {
  height:100px;
  margin-right:0px;
  margin-top:0px;
  padding-top:0px;
  width:60px;
 }

 .messageHeaderBackBtn {
  color:$offwhite;
 }

 #kebab-mobile-message {
  height: 100px;
  padding-top: 20px;
 }

 #kebab-mobile-messageActions {
  margin-top:42px;
 }

 #kebab-mobile-messageActions .c-kebab-menu__list li {
  background-color:#fff;
 }

 #kebab-mobile-messageActions .c-kebab-menu__list li:hover {
  background-color:#036699;
 }

 #kebab-mobile-messageActions {
  right:-25px;
 }

 #kebab-mobile-messageActions i {
  right:23px;
 }

 #mcLayout {
  margin-top:0px;
  padding:0px;
  width:100%;
 }

 #mcHeader {
  background-color:$verylightgray;
  color:$charcoal;
  display:block;
  font-size: 20px;
 }

 #mcHeader div.vcl-select {
   display:inline-block;
   float:right;
   margin-right:20px;
   margin-top:10px;
   width:113px;
 }
 #messageType {
   width:160px;
 }

 #messageHeader {
  display:none;
 }

 .headerMessageSubject {
  color:$offwhite;
  font-size:18px;
  height:72px;
  overflow: hidden;
  padding-top:16px;
  text-overflow:ellipsis;
  user-select:none;
  white-space: nowrap;
 }

 .headerMessageDate {
  color: $offwhite;
  font-size:10px;
  height:28px;
  line-height: 10px;
  text-transform:uppercase;
 }

 div#leftColumn {
  padding-left:16px;
  padding-right:16px;
  padding-bottom:120px;
  padding-top:0px;
  width:100%;
  min-width: 200px;
  height: 100%;
 }

 div#rightColumn {
  background-color: $verylightgray;
  box-shadow:none;
  margin-left:0px;
  padding-bottom:80px;
  width:100%;
  min-width: 200px;
 }

 div.logoBubble {
  display:none;
 }

 #mcMobileHeader { display:block; }

 div.messagePreview {
  padding-left:20px;
 }

 select#messageType {
  display:inline-block;
  float:right;
 }

 #new-message {
   margin-right:30px;
   margin-top:11px;
 }

 div#mcContainer {
  margin-top:0px;
  margin-right:0px;
  padding-left:0px;
  width:auto;
 }

 div#leftColumn {
  background-color:#f1f1f1;
  box-shadow:none;
 }

 div#mcTabMenu { display:none; }

 #messageSearchContainer {
  border:0px;
  height:75px;
  width:100%;
  padding: 16px 0px;
 }

 #messageSearchContainerOuter {
  border-bottom:0px;
  height:75px;
 }

 #messageSearchContainer div.vcl-input {
   margin-top:0px;
 }
 #message-search { width:100%; }

 #messageListing { border-radius:6px; }

 #messageType { display:inline-block; }

 .c-kebab-menu__icon {
   height: 100px;
   padding-top:16px;
 }

 #kebab-mobile-message .c-kebab-menu__icon svg {
   color:#FFF !important;
 }
}
</style>
