<template>
  <div id="phoneVerificationModal" :style="{ '--primary-color': primaryColor }">
    <VpModal ref="modal">
      <template #header>{{ $t('phoneVerifyHead') }}</template>
      <div id="phoneVerificationIntro">
        {{ $t('phoneVerifyIntro') }}
      </div>
      <div id="phoneVerificationForm">
        <div id="phoneVerificationInnerForm">
          <vp-input id="phoneNumber" v-model="phoneNumberDisplay" readonly>
            <template #label>
              {{ $t('phoneNumber') }}&nbsp;
              <vp-tooltip placement="right" width="250">
                <template #target>
                  <VpIcon id="phoneTooltip" icon="question-circle" />
                </template>
                <p class="tooltip__content">{{ $t('phoneVerifyTooltip') }}</p>
              </vp-tooltip>
            </template>
          </vp-input>
          <vp-input id="verificationCode" v-model="verificationCode" :placeholder="$t('codePlaceholder')">
            <template #label>{{ $t('verificationCode') }}</template>
          </vp-input>
        </div><!-- end phoneVerificationInnerForm -->
        <vp-button id="sendVerificationCodeButton" class="primary medium blue no-fill" @click="sendVerificationCode()">{{ $t('sendCode') }}</vp-button>
        <div id="phoneVerficationCodeSentMessage">{{ $t('codeSent') }}</div>
      </div><!-- end phoneVerificationForm -->
      <template #footer>
        <vp-button class="modal__button medium" @click="close">{{ $t('cancel') }}</vp-button>
        <vp-button class="modal__button medium" :disabled="verificationCode.length < 6" @click="submit">{{ $t('verifyNumber') }}</vp-button>
      </template>
    </VpModal>
  </div><!-- end phoneVerificationModal -->
</template>
<script>
import phoneNumberLocalization from '../../../common/mixins/PhoneNumberLocalization';

export default {
  name: 'PhoneVerificationModal',
  mixins: [phoneNumberLocalization],
  inject: ['$byodConfig', '$modal', '$toast'],
  data() {
    return {
      primaryColor: '#016699',
      phoneNumber: '',
      phoneNumberDisplay: '',
      verificationCode: '',
    };
  },
  computed: {
    phoneNumbers() {
      return this.$store.getters.phoneNumbers;
    },
  },
  created() {
    this.primaryColor = this.$byodConfig.color;
    let locale = this.$byodConfig.localization;
    if (locale === undefined) { locale = 'US'; }

    if (this.phoneNumbers) {
      const firstUnverifiedNumber = this.phoneNumbers.find(p => p.verified === false);
      if (firstUnverifiedNumber !== undefined) {
        this.phoneNumber = firstUnverifiedNumber.phone;
        this.countryCode = firstUnverifiedNumber.countryCode;
        this.phoneNumberDisplay = this.applyPhoneNumberLocalization(locale, this.phoneNumber);
      }
    }
  },
  mounted() {
    localStorage.setItem('phoneVerificationModalLastDisplayed', Date.now());
  },
  methods: {
    close() {
      this.$modal.close();
    },
    sendVerificationCode() {
      const params = { number: this.phoneNumber, countryCode: this.countryCode };
      this.$store.dispatch('account/sendPhoneVerificationCode', params);
      document.getElementById('phoneVerficationCodeSentMessage').style.display = 'inline-block';

      // temporarily disable button to prevent double clicks
      const btn = document.getElementById('sendVerificationCodeButton');
      btn.disabled = true;
      btn.innerHTML = this.$i18n.t('resendCode');
      setTimeout(() => {
        btn.disabled = false;
      }, 5000);
    },
    submit() {
      const { locale } = this.$i18n;
      const successToast = {
        title: this.$i18n.messages[locale].verifiedToast.title,
        body: this.$i18n.messages[locale].verifiedToast.body,
      };
      const failToast = {
        title: this.$i18n.messages[locale].verifyFailToast.title,
        body: this.$i18n.messages[locale].verifyFailToast.body,
      };
      const params = {
        number: this.phoneNumber, countryCode: this.countryCode, code: this.verificationCode,
      };
      this.$store.dispatch('account/confirmPhoneVerificationCode', params).then((results) => {
        if (results.verified) {
          this.$store.commit('setPhoneNumberVerified', this.phoneNumber);
          this.$toast.show('success', successToast);
        } else {
          this.$toast.show('fail', failToast);
        }
        this.$modal.close();
      })
        .catch(() => {
          this.$toast.show('fail', failToast);
          this.$modal.close();
        });
    },
  },
};
</script>
<style lang="scss">
#phoneVerificationModal {
  .vcl-modal__window .vcl-modal__body {
   padding:0px !important;
  }
}

#phoneVerificationIntro {
 color: #4F4F4F;
 line-height:24px;
 padding: 27px 48px 0;
 user-select:none;
}

#phoneVerificationForm {
 background: #F4F7F8;
 box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05) inset;
 margin-top:33px;
 padding:18px 48px;
}

#phoneVerificationInnerForm {
 display:flex;
 flex-direction:row;
 gap:30px;
}

#phoneVerificationForm .vcl-input {
 width:400px;
}

@media only screen and (max-width: 992px) {
 #phoneVerificationInnerForm {
  flex-direction:column;
 }

 #phoneVerificationForm .vcl-input {
   width:unset;
   max-width:90%;
 }
}

#phoneTooltip {
 font-size:14px;
 margin-top:-4px;
 color: var(--primary-color);
}

.tooltip__content {
 font-size:10px;
 padding:8px;
}

#sendVerificationCodeButton {
 background-color:#fff;
 color: var(--primary-color);
 border: solid 2px var(--primary-color);
 height:40px;
 margin-top:34px;
 width:160px;
}

#phoneVerficationCodeSentMessage {
 background: #E5F0F6;
 border: 1px solid rgba(167, 204, 225, 1);
 border-radius:4px;
 color: #1B3664;
 display:none;
 font-size:13px;
 line-height:20px;
 margin-left:20px;
 margin-top:0px;
 padding:10px 8px;
 width:290px;
}

@media (max-width: 992px) {
  #phoneVerificationInnerForm {
    flex-direction:column;
  }

  #phoneVerficationCodeSentMessage {
    margin-left:unset;
    margin-top:16px;
  }
}

@media (max-width: 500px) {
  #phoneVerificationForm .vcl-input {
    width: 300px
  }
}
</style>
