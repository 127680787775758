<template>
  <widget class="marketing-message" :style="[brandStyles]" :styles="widgetCSS">
    <p class="marketing-message__title">{{ message.title }}</p>
    <p class="marketing-message__text">{{ message.body }}</p>
    <div class="marketing-message__controller">
      <vp-button id="marketing-message__btn" class="large" :style="buttonCSS" :ga-track-event="analyticsString" @click="handleClick">
        {{ message.linkTitle }}
      </vp-button>
    </div>
  </widget>
</template>

<script>
import Widget from './Widget.vue';

export default {
  name: 'MarketingMessage',
  components: { Widget },
  props: {
    message: { type: Object, required: true },
    branding: { type: Object, required: false, default: () => ({}) },
  },
  computed: {
    brandStyles() {
      return {
        '--brand-color': this.branding.color || '#40454a',
      };
    },
    buttonCSS() {
      return {
        'background-color': this.branding.color,
        border: 'none',
      };
    },
    widgetCSS: () => ({ innerPadding: '1.5rem 2rem' }),
    analyticsString() {
      return `['marketing-message', 'click', 'id=${this.message.id}']`;
    },
  },
  methods: {
    handleClick() {
      window.location.assign(this.message.link);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
.marketing-message {
  @include Tablet--Large {
    gap: 2rem;
  }
  div.widget__inner {
    height: 100%;
    display: flex;
    padding: 1rem;
    border-radius: 6px;
    align-items: center;
    @include Tablet--Large {
      padding: 2rem;
    }
    &-container {
      gap: 14px;
      display: flex;
      text-align: left;
      flex-direction: column;
      justify-content: flex-start;
      @include Tablet--Large {
        gap: 2rem;
      }
    }
  }

  &__title {
    margin: 0;
    font-size: 20px;
    text-align: left;
    line-height: 100%;
    color: var(--brand-color);
    @include Tablet--Large {
      font-size: 40px;
      line-height: 40px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 100%;
    @include Tablet--Large {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__controller {
    font-size: 14px;
    text-transform: uppercase;
    #marketing-message__btn {
      height: 100%;
      padding: 0.5rem;
      min-width: 30px;
      line-height: 100%;
    }
  }
  h2,
  p {
    margin: 0;
    padding: 0;
  }
}

div.marketing-message__controller button {
 padding:12px !important;
 user-select:none;
}
</style>
