<template>
  <div class="setting-panel">
    <VpSelect v-model="upperSetting" class="setting-panel__selector" :disabled="upper.disabled" :options="upper.list" :styles="selectStyles">
      <template #label>{{ translatedLabel(upper) }}</template>
    </VpSelect>
    <VpSelect v-if="(lower.render !== false) && lower.list.length" v-model="lowerSetting" class="setting-panel__selector" :disabled="lower.disabled" :options="lower.list" :styles="selectStyles">
      <template #label>{{ translatedLabel(lower) }}</template>
    </VpSelect>
  </div>
</template>

<script>
export default {
  name: 'SettingPanel',
  inject: ['$filter', '$toast', '$log'],
  props: {
    args: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectStyles: {
        width: '100%',
      },
    };
  },
  computed: {
    deviceId() {
      return this.args.deviceId;
    },
    lower() {
      return this.args.lower;
    },
    lowerSetting: {
      get() {
        return this.lower.value;
      },
      set(value) {
        return this.enqueueCommand(this.lower.key, value);
      },
    },
    upper() {
      return this.args.upper;
    },
    upperSetting: {
      get() {
        return this.upper.value;
      },
      set(value) {
        return this.enqueueCommand(this.upper.key, value);
      },
    },
  },
  methods: {
    enqueueCommand(key, value) {
      return this.$store.dispatch('device/enqueueCommand', { deviceId: this.deviceId, key, value })
        .catch((err) => {
          this.$log.error(err);
          this.$toast.show('fail', {
            title: 'Failed to make change to device',
            body: err.message,
          });
        });
    },
    translatedLabel(item) {
      return this.$i18n.t(this.$filter.camelize(item.label));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
.setting-panel {
  width: 100%;
  height: 100%;

  padding: 0 8px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 12px;

  &__selector {
    max-width: 222px;
    .vcl-input__label {
      font-size: 10px;
      line-height: 1;
      margin-bottom: 3px;
    }
    .vcl-input__input {
      height: 100% !important;

      input.vcl-input__element {
        padding: 0.25rem 0.5rem;
        font-size: 12px;
        line-height: 13px;
        margin: 0 !important;
      }
    }
  }

  @include Tablet--Large {
    gap: 20px;

    &__selector {
      label.vcl-input__label {
        margin-bottom: 3px;
        font-size: 16px;
      }
      .vcl-input__input {
        input.vcl-input__element {
          font-size: 16px;
          height: 40px !important;
        }
      }
    }
  }
}
</style>
