import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

let defaultLanguage = '';
const config = window.cConfig;
let allowMultipleLanguages = false;
let enabledLanguages = [];
let enabledLanguageKeys = [];
if (config && config.commonSettings) {
  const byodMultipleLanguages = config.commonSettings.find(setting => setting.key === 'byodMultipleLanguages') || {};
  allowMultipleLanguages = !!byodMultipleLanguages.value;
  enabledLanguages = byodMultipleLanguages.languages || [];
  enabledLanguageKeys = enabledLanguages.map(({ key }) => key);
}
const selectedLanguage = localStorage.getItem('selectedLanguage');

if (allowMultipleLanguages && selectedLanguage) {
  defaultLanguage = selectedLanguage;
} else if (allowMultipleLanguages && navigator.language.includes('es')) {
  defaultLanguage = 'es';
} else {
  defaultLanguage = 'en';
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });

  const vclLocales = require.context('../node_modules/@virtual-peaker/vue-component-library/src/locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const vclMessages = {};
  const combinedMessages = {};
  vclLocales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      vclMessages[locale] = vclLocales(key).default;
      combinedMessages[locale] = { ...messages[locale], ...vclMessages[locale] };
    }
  });
  return combinedMessages;
}

const i18n = new VueI18n({
  locale: defaultLanguage || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: loadLocaleMessages(),
});

export default i18n;
export {
  i18n,
  enabledLanguages,
  enabledLanguageKeys,
};
