import shellMutations from './mutations';
import shellActions from './actions';
import shellGetters from './getters';

export default {
  namespaced: true,
  name: 'shell',
  state: () => ({
    isAuth: null,
  }),
  mutations: shellMutations,
  actions: shellActions,
  getters: shellGetters,
};
