<template>
  <div v-if="status === 'ONLINE'" title="Device Status ON" class="device-status-indicator">
    <span class="device-status-indicator__text">{{ $t('online') }}</span>
  </div>
  <div v-else-if="status === 'OFFLINE'" title="Device Status OFF" class="device-status-indicator--off">
    <span class="device-status-indicator__text">{{ $t('offline') }}</span>
  </div>
  <div v-else title="Device Status UNKNOWN" class="device-status-indicator--unknown">
    <span class="device-status-indicator__text">{{ $t('unknown') }}</span>
  </div>
</template>

<script>
export default {
  name: 'DeviceStatusIndicator',
  props: {
    brand: {
      type: Object,
      default: () => ({ color: '#00FF00' }),
    },
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      return {
        '--provider-color': this.brand.color,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.device-status-indicator, .device-status-indicator--off, .device-status-indicator--unknown {
  display: flex;
  height: 28px;
  font-size: 14px;
  color: #004E4C;
  line-height: 24px;
  background-color: #E5F9F8;
  border: 1px solid #5BBFBD;
  border-radius: 4px;
  align-items: center;
  padding: 0 4px;
  user-select:none;
  &--off {
    background-color: #FBF4F3;
    border: 1px solid #CE3C31;
    color: #4A1814;
  }
  &--unknown {
    background-color: #F5F5F5;
    border: 1px solid #AFAFAF;
    color: #4F4F4F;
  }
}
</style>
