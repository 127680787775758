import Vue from 'vue';
import api from '@/common/api';

export default {
  namespaced: true,
  name: 'messageCenter',
  state: {
    messages: {},
    messageCenterMode: 'inbox',
    providerConfig: {},
    selectedMessageId: '',
    selectedMessage: null,
    triggerMessageReload: 0,
  },
  actions: {
    getAccountMessages(store, mode) {
      const user = JSON.parse(localStorage.getItem('user') || {});
      const accountId = user.id;
      const houseId = localStorage.getItem('houseId');
      let url = `/account/${accountId}/house/${houseId}/message`;
      if (mode === 'favorites') {
        url += '?favorite=true';
      } else if (mode === 'archived') {
        url += '?archived=true';
      } else {
        url += '?archived=false';
      }
      return api.get(url);
    },
    deleteMessage(store, messageId) {
      const user = JSON.parse(localStorage.getItem('user') || {});
      const accountId = user.id;
      const houseId = localStorage.getItem('houseId');
      const url = `/account/${accountId}/house/${houseId}/message/${messageId}`;
      return api.delete(url).then(() => {
        store.commit('deleteMessage', messageId);
        return true;
      }).catch(() => {
        return false;
      });
    },
    countUnread() {
      const user = JSON.parse(localStorage.getItem('user') || {});
      const accountId = user.id;
      const houseId = localStorage.getItem('houseId');
      const url = `/account/${accountId}/house/${houseId}/message?read=false&archived=false&countOnly=1`;
      return api.get(url);
    },
    markAsRead(store, messageId) {
      const user = JSON.parse(localStorage.getItem('user') || {});
      const accountId = user.id;
      const houseId = localStorage.getItem('houseId');
      const url = `/account/${accountId}/house/${houseId}/message/${messageId}`;
      const params = { read: true };
      return api.put(url, params).then(() => {
        store.commit('markMessageRead', messageId);
        return true;
      }).catch(() => {
        return false;
      });
    },
    sendNewMessage(store, payload) {
      const user = JSON.parse(localStorage.getItem('user') || {});
      const accountId = user.id;
      const houseId = localStorage.getItem('houseId');
      const url = `/account/${accountId}/house/${houseId}/message`;
      const params = { subject: payload.subject, body: payload.body };
      return api.post(url, params);
    },
    sendReply(store, payload) {
      const user = JSON.parse(localStorage.getItem('user') || {});
      const accountId = user.id;
      const houseId = localStorage.getItem('houseId');
      const url = `/account/${accountId}/house/${houseId}/message/reply`;
      const params = { parentMessageId: payload.messageId, body: payload.body };
      return api.put(url, params);
    },
    setFavorite(store, payload) {
      const user = JSON.parse(localStorage.getItem('user') || {});
      const accountId = user.id;
      const houseId = localStorage.getItem('houseId');
      const url = `/account/${accountId}/house/${houseId}/message/${payload.id}`;
      const params = { favorite: payload.value };
      return api.put(url, params).then(() => {
        store.commit('setFavorite', payload);
        return true;
      }).catch(() => {
        return false;
      });
    },
  },
  mutations: {
    deleteMessage: (state, messageId) => {
      Vue.delete(state.messages, messageId);
      state.selectedMessageId = '';
      state.selectedMessage = null;
    },
    markMessageRead: (state) => {
      state.selectedMessage.read = Date.now();
    },
    setFavorite: (state, payload) => {
      Object.values(state.messages).forEach((message) => {
        if (message.uuid === payload.id) {
          state.messages[message.uuid].favorite = payload.value;
        }
      });
    },
    updateMessages: (state, newMessages) => {
      state.messages = newMessages;
    },
    updateMessageCenterMode: (state, newMode) => {
      state.messageCenterMode = newMode;
    },
    updateProviderConfig: (state, newConfig) => {
      state.providerConfig = newConfig;
    },
    updateSelectedMessageId: (state, newMessageId) => {
      state.selectedMessageId = newMessageId;
    },
    updateSelectedMessage: (state, newSelectedMessage) => {
      state.selectedMessage = newSelectedMessage;
    },
    updateTriggerMessageReload: (state, newFlag) => {
      state.triggerMessageReload = newFlag;
    },
  },
  getters: {
    messages: (state) => {
      return state.messages;
    },
    messageCenterMode: (state) => {
      return state.messageCenterMode;
    },
    providerConfig: (state) => {
      return state.providerConfig;
    },
    selectedMessageId: (state) => {
      return state.selectedMessageId;
    },
    selectedMessage: (state) => {
      return state.selectedMessage;
    },
    triggerMessageReload: (state) => {
      return state.triggerMessageReload;
    },
  },
};
