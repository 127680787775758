<template>
  <div id="leftMenu">
    <div id="leftMenuLinks">
      <a class="desktopMenuItem" tabindex="0" @click="goHome" @keyup.enter.space="goHome" :class="{ 'selected': homePageSelected }">
        <img class="leftMenuIcon" alt="Home Page" v-if="!homePageSelected" :src="require('@/assets/icons/home.svg')">
        <img class="leftMenuIcon" alt="Home Page" v-if="homePageSelected" :src="require('@/assets/icons/home_selected.svg')">
      </a>
      <a class="desktopMenuItem" tabindex="0" @click="goToMcInbox" @keyup.enter.space="goToMcInbox" v-if="mcEnabled" :class="{ 'selected': mcSelected }">
        <img class="leftMenuIcon" alt="Message Center" v-if="!mcSelected" :src="require('@/assets/icons/mail_no_msgs.svg')">
        <img class="leftMenuIcon" alt="Message Center" v-if="mcSelected" :src="require('@/assets/icons/mail_no_msgs_selected.svg')">
        </a>
      </div>
    </div>
</template>
<script>
export default {
  name: 'LeftMenu',
  inject: ['$proSettings'],
  computed: {
    homePageSelected() {
      if (this.$route.name === 'user-home') {
        return true;
      }
      return false;
    },
    mcEnabled() {
      if (this.$proSettings.settings.enableHomeMessageCenterPage) {
        return this.$proSettings.settings.enableHomeMessageCenterPage;
      } return false;
    },
    mcSelected() {
      if (this.$route.name && this.$route.name.startsWith('message-center')) {
        return true;
      }
      return false;
    },
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' }).catch(() => {});
    },
    goToMcInbox() {
      this.$router.push({ path: '/message-center/inbox' }).catch(() => {});
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/mixins.scss';

div#leftMenu {
 @include Mobile--Only {
   display:none;
 }

 @include Tablet {
   display:none;
 }

 @include Desktop {
  display:inline-block;
 }
 background-color: #4F4F4F;
 height: 100vh;
 padding-top: 100px;
 position:fixed;
 text-align: center;
 width: 80px;
 z-index: 900;
}

div#leftMenuLinks a img {
  margin-top: -10px;
}

div#leftMenuLinks a.selected {
  background: linear-gradient(90deg, #fff 5px, rgba(255, 255, 255, 0.1) 0px);
}

img.leftMenuIcon {
 height:24px;
 width:24px;
}

a.desktopMenuItem {
 cursor: pointer;
 display: block;
 height: 55px;
 line-height: 55px;
 margin-top: 10px;
}

a.desktopMenuItem:focus-visible {
 outline:2px solid white;
}
</style>
