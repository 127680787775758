<template>
  <div class="phone-form">
    <p>{{ $t('phoneInstructions') }}</p>
    <div name="phoneNumber">
      <vp-input ref="phoneInput" v-model="phone" type="tel" :placeholder="placeholder" @keyup="keyChanged($event)">
        <template #label>{{ $t('phoneNumber') }}</template>
      </vp-input>
      <div class="phone-row">
        <span>{{ errorResponse }}</span>
      </div>
      <div class="button-row">
        <vp-button class="blue no-fill" @click="cancel">{{ $t('cancel') }}</vp-button>&nbsp;
        <vp-button
          class="phoneButton"
          type="success"
          :disabled="!phone || phone.length < validLength"
          @click="sendCode()"
          :style="buttonCSS"
        >
          {{ $t('sendCode') }}
        </vp-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PhoneForm',
  inject: ['$byodConfig'],
  props: {
    localization: {
      type: String,
      default: 'US',
    },
    errorResponse: {
      type: String,
      default: '',
    },
  },
  emits: ['cancel-add', 'send-code'],
  data() {
    return {
      phone: '',
      response: '',
      validLength: this.localization === 'AU' ? 12 : 14,
      busyState: '',
    };
  },
  computed: {
    buttonCSS() {
      return {
        'background-color': this.providerColor,
        border: `solid 2px ${this.providerColor}`,
      };
    },
    providerColor() { return this.$byodConfig.color; },
    placeholder() {
      let placeholder = this.$i18n.t('phonePlaceholder.america');
      if (this.localization === 'AU') {
        placeholder = this.$i18n.t('phonePlaceholder.australia');
      }
      return placeholder;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel-add', {});
    },
    maskedNumber(number) {
      if (!number) { return number; }
      const phoneNumber = number.replace(/\D/g, '');

      // If we end up supporting more localizations we should consider using a telephone number formatting library
      if (this.localization === 'US') {
        if (phoneNumber.length < 4) {
          return phoneNumber;
        } else {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}${phoneNumber.length > 6 ? `-${phoneNumber.slice(6, 10)}` : ''}`;
        }
      } else if (this.localization === 'AU') {
        let masked = `${phoneNumber.substring(0, 4)}`;
        if (phoneNumber.length > 4) { masked += ` ${phoneNumber.substring(4, 7)}`; }
        if (phoneNumber.length > 7) { masked += ` ${phoneNumber.substring(7, 10)}`; }
        return masked;
      }
      return number;
    },
    keyChanged(event) {
      if (event.key === 'Backspace') {
        let lastChar = this.phone.slice(-1);
        while ((lastChar === '-') || (lastChar === ')') || (lastChar === '(') || (lastChar === ' ')) {
          this.phone = this.phone.slice(0, -1);
          lastChar = this.phone.slice(-1);
        }
      }
      this.phone = this.maskedNumber(this.phone);
    },
    sendCode() {
      this.$emit('send-code', this.phone);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixins.scss';

.phone-form {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
}

.phone-row {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.button-row {
  text-align:right;
}

</style>
