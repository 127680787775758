<template>
  <div id="mobileMenu">
    <div id="mobileMenuLinks">
      <div id="mobileHomeLink" :class="{ 'selected' : homePageSelected }">
        <a href="./">
          <img width=32 height=32 alt="Home Page" v-if="!homePageSelected" :src="require('@/assets/icons/home.svg')">
          <img width=32 height=32 alt="Home Page" v-if="homePageSelected" :src="require('@/assets/icons/home_selected.svg')">
        </a>
      </div>
      <div id="mobileInboxLink" v-if="mcEnabled" :class="{ 'selected' : mcSelected }">
        <a href="#/message-center/inbox">
          <img width=32 height=32 alt="Message Center" v-if="!mcSelected" :src="require('@/assets/icons/mail_no_msgs.svg')">
          <img width=32 height=32 alt="Message Center" v-if="mcSelected" :src="require('@/assets/icons/mail_no_msgs_selected.svg')">
        </a>
      </div>
      <div id="mobileAvatar" :class="{ 'selected' : userProfileSelected }">
        <a href="#/account/profile">
          <img width=28 height=28 v-if="!userProfileSelected" :src="require('@/assets/icons/user.svg')">
          <img width=28 height=28 v-if="userProfileSelected" :src="require('@/assets/icons/user_selected.svg')">
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MobileMenu',
  inject: ['$proSettings'],
  computed: {
    homePageSelected() {
      if (this.$route.name === 'user-home') {
        return true;
      }
      return false;
    },
    mcEnabled() {
      if (this.$proSettings.settings.enableHomeMessageCenterPage) {
        return this.$proSettings.settings.enableHomeMessageCenterPage;
      } return false;
    },
    mcSelected() {
      if (this.$route.name && this.$route.name.startsWith('message-center')) {
        return true;
      }
      return false;
    },
    userProfileSelected() {
      if (this.$route.name && this.$route.name.startsWith('account-')) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/mixins.scss';

div#mobileMenu {
  background-color: #30619C;
  bottom: 0;
  position: fixed;
  height: 80px;
  top: unset;
  text-align: center;
  width: 100%;
  z-index: 901;

  @include Mobile--Only {
    display:block;
  }
  @include Tablet {
    display:block;
  }
  @include Desktop {
    display:none;
  }
}

div#mobileMenuLinks div {
  cursor:pointer;
  display: inline-block;
  height: 80px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 72px;
}

div#mobileMenuLinks div a {
 display:inline-block;
 height:100%;
 padding-top:20px;
 width:100%;
}

div#mobileMenuLinks div.selected {
  background: linear-gradient(0deg, white 5px, #085278 0px);
  height: 77px;
}
</style>
