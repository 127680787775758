<template>
  <div
    class="device-schedule"
    :class="{ 'device-schedule--complex-active': showComplex }"
    :style="styles"
  >
    <div
      v-if="mode === 'header'"
      role="row"
      class="device-schedule__row device-schedule__row--header"
    >
      <div
        v-for="({label, text}, index) in columns"
        :key="`header-${index}-${label}`"
        tabindex="0"
        role="columnheader button"
        class="device-schedule__row__cell"
        @click="handleHeaderInteraction(label)"
        @keydown.enter="handleHeaderInteraction(label)"
        @keydown.space.prevent="handleHeaderInteraction(label)"
      >
        <span>{{ text }}</span>
        <span><VpIcon :icon="getSortIcon(label)" :title="`Sort by ${text}`" /></span>
      </div>
    </div>
    <div
      v-if="mode === 'row'" class="device-schedule__row"
      role="row"
    >
      <div role="cell" class="device-schedule__row__cell">
        <span v-if="isComplex"><VpIcon icon="list" :color="providerColor" title="Complex Schedule" /></span>
        <span>{{ scheduleName }}</span>
        <span v-if="isActive"><VpIcon icon="active" :color="providerColor" title="Active Schedule" /></span>
        <div
          v-if="isComplex"
          tabindex="0"
          role="button"
          class="device-schedule__toggle"
          @click="toggleComplex"
          @keydown.enter="toggleComplex"
          @keydown.space.prevent="toggleComplex"
        >
          <VpIcon v-if="showComplex" icon="up-arrow" title="Close Details" />
          <VpIcon v-else icon="down-arrow" title="Open Details" />
        </div>
      </div>
      <div
        role="cell"
        class="device-schedule__row__cell"
        :aria-hidden="isComplex"
      >
        <ScheduleWeekDisplay
          v-if="!isComplex"
          :days="scheduleDays"
          :brand="{ color: providerColor }"
        />
        <template v-else>{{ scheduleDays }}</template>
      </div>
      <div role="cell" class="device-schedule__row__cell" :aria-hidden="isComplex">{{ scheduleStartTime }}</div>
      <div role="cell" class="device-schedule__row__cell" :aria-hidden="isComplex">{{ scheduleDeviceMode }}</div>
      <div role="cell" class="device-schedule__row__cell" :aria-hidden="isComplex">{{ scheduleSetpoint }}</div>
    </div>
    <div
      v-if="isComplex && showComplex"
      role="row"
    >
      <div role="cell" class="device-schedule__complex">
        <ScheduleEventDisplay
          v-for="(event, index) in scheduleEvents"
          :key="`schedule-${scheduleId}-event-${index}`"
          :event="event"
          :event-index="index"
          :is-active="isEventActive({ scheduleEvents, event })"
        />
      </div>
    </div>
    <div
      v-if="mode === 'mobile'" class="device-schedule__mobile"
    >
      <div class="device-schedule__mobile__title">
        {{ deviceSchedule.name }}
        <VpIcon icon="active" :color="providerColor" title="Active Schedule" v-if="isActive" />
      </div>
      <div class="device-schedule__mobile__days" v-if="!isComplex">
        <ScheduleWeekDisplay
          v-if="!isComplex"
          :days="scheduleDays"
          :brand="{ color: providerColor }"
        />
      </div>
      <div v-if="!isComplex" class="device-schedule__mobile__settingsRow">
        <div class="device-schedule__mobile__setting">
          <div class="device-schedule__mobile__setting__value">
            {{ scheduleStartTime }}
          </div>
          <div class="device-schedule__mobile__setting__label">
            {{ $t('scheduleStartTime') }}
          </div>
        </div>
        <div class="device-schedule__mobile__setting">
          <div class="device-schedule__mobile__setting__value">
            {{ scheduleSetpoint }}
          </div>
          <div class="device-schedule__mobile__setting__label">
            {{ $t('scheduleSetpoint') }}
          </div>
        </div>
        <div class="device-schedule__mobile__setting">
          <div class="device-schedule__mobile__setting__value">
            {{ scheduleDeviceMode }}
          </div>
          <div class="device-schedule__mobile__setting__label">
            {{ $t('scheduleDeviceMode') }}
          </div>
        </div>
      </div>
      <div v-if="isComplex">
        <div v-for="(event, index) in scheduleEvents"
          :key="`schedule-${scheduleId}-event-${index}`"
          class="device-schedule__mobile__complex__container">
          <div class="device-schedule__mobile__complex">
            <div class="schedule-event-display__event-number device-schedule__mobile__complex__eventNumber">
              {{ index + 1 }}
            </div>
            <div class="device-schedule__mobile__complex__schedule">
              <ScheduleWeekDisplay :days="complexEventDays(event)" :brand="{ color: providerColor }" />
            </div>
          </div>
          <div class="device-schedule__mobile__complex__settingsRow">
            <div class="device-schedule__mobile__setting">
              <div class="device-schedule__mobile__setting__value">
                {{ complexScheduleStartTime(event) }}
              </div>
              <div class="device-schedule__mobile__setting__label">
                {{ $t('scheduleStartTime') }}
              </div>
            </div>
            <div class="device-schedule__mobile__setting">
              <div class="device-schedule__mobile__setting__value">
                {{ complexScheduleSetpoint(event) }}
              </div>
              <div class="device-schedule__mobile__setting__label">
                {{ $t('scheduleSetpoint') }}
              </div>
            </div>
            <div class="device-schedule__mobile__setting">
              <div class="device-schedule__mobile__setting__value">
                {{ complexScheduleDeviceMode(event) }}
              </div>
              <div class="device-schedule__mobile__setting__label">
                {{ $t('scheduleDeviceMode') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import moment from 'moment';
import ScheduleWeekDisplay from './ScheduleWeekDisplay.vue';
import ScheduleEventDisplay from './ScheduleEventDisplay.vue';

export default {
  name: 'DeviceSchedule',
  components: { ScheduleWeekDisplay, ScheduleEventDisplay },
  provide() {
    const { formatDecimalTime, formatConvertSetpoint } = this;
    return {
      formatDecimalTime, formatConvertSetpoint,
    };
  },
  inject: ['$byodConfig', '$filter', 'deviceModes'],
  props: {
    deviceSchedule: {
      type: Object,
      default: null,
    },
    activeScheduleUid: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    sortColumn: {
      type: String,
      default: null,
    },
    sortDirection: {
      type: String,
      default: null,
    },
  },
  emits: ['sortBy'],
  data() {
    return {
      showComplex: false,
    };
  },
  computed: {
    scheduleId() {
      return this.deviceSchedule.id;
    },
    scheduleUid() {
      return this.deviceSchedule.uid;
    },
    scheduleName() {
      return this.deviceSchedule.name;
    },
    scheduleEvents() {
      let scheduleEvents = [];
      if (this.deviceSchedule && 'Event' in this.deviceSchedule) {
        scheduleEvents = this.deviceSchedule.Event;
      }
      return scheduleEvents;
    },
    scheduleDays() {
      let scheduleDays = '--';
      if (this.scheduleEvents.length === 1) {
        scheduleDays = this.scheduleEvents[0].DayOfWeek;
        scheduleDays = scheduleDays.map(item => item.day);
      }
      return scheduleDays;
    },
    scheduleStartTime() {
      let scheduleStartTime = '--';
      if (this.scheduleEvents.length === 1) {
        scheduleStartTime = this.formatDecimalTime(this.scheduleEvents[0].timeOfDay);
      }
      return scheduleStartTime;
    },
    scheduleDeviceMode() {
      let scheduleDeviceMode = '--';
      if (this.scheduleEvents.length === 1) {
        const mode = this.deviceModes.find(item => item.setting === this.scheduleEvents[0].command.mode);
        scheduleDeviceMode = mode ? mode.name : this.$filter.humanReadableCommandMode(this.scheduleEvents[0].command.mode);
      }
      return scheduleDeviceMode;
    },
    scheduleSetpoint() {
      let scheduleSetpoint = '--';
      if (this.scheduleEvents.length === 1) {
        scheduleSetpoint = this.scheduleEvents[0].command.setpoint;
        scheduleSetpoint = this.formatConvertSetpoint(scheduleSetpoint);
      }
      return scheduleSetpoint;
    },
    columns() {
      return [
        {
          label: 'scheduleName',
          text: this.$i18n.t('scheduleName'),
        },
        {
          label: 'scheduleDays',
          text: this.$i18n.t('scheduleDays'),
        },
        {
          label: 'scheduleStartTime',
          text: this.$i18n.t('scheduleStartTime'),
        },
        {
          label: 'scheduleDeviceMode',
          text: this.$i18n.t('scheduleDeviceMode'),
        },
        {
          label: 'scheduleSetpoint',
          text: this.$i18n.t('scheduleSetpoint'),
        },
      ];
    },
    isActive() {
      return this.activeScheduleUid === this.scheduleUid;
    },
    isComplex() {
      return this.scheduleEvents.length > 1;
    },
    providerColor() {
      return this.$byodConfig.color;
    },
    styles() {
      return {
        '--provider-color': this.providerColor,
      };
    },
    localization() {
      return this.$store.getters.localization;
    },
  },
  methods: {
    complexEventDays(event) {
      return event.DayOfWeek.map(item => item.day);
    },
    complexScheduleStartTime(event) {
      return this.formatDecimalTime(event.timeOfDay);
    },
    complexScheduleSetpoint(event) {
      return this.formatConvertSetpoint(event.command.setpoint);
    },
    complexScheduleDeviceMode(event) {
      const mode = this.deviceModes.find(item => item.setting === event.command.mode);
      return mode ? mode.name : this.$filter.humanReadableCommandMode(event.command.mode);
    },
    toggleComplex() {
      this.showComplex = !this.showComplex;
    },
    getSortIcon(columnLabel) {
      let sortIcon = 'table-sorter';
      if (this.sortColumn === columnLabel) {
        switch (this.sortDirection) {
          case 'ascending':
            sortIcon = 'table-sorter-ascending';
            break;
          case 'descending':
            sortIcon = 'table-sorter-descending';
            break;
          default:
            break;
        }
      }
      return sortIcon;
    },
    handleHeaderInteraction(columnLabel) {
      this.$emit('sortBy', { column: columnLabel });
    },
    formatDecimalTime(decimalTime) {
      const time = moment();

      const hour = parseInt(decimalTime, 10) || 0;
      const minute = Math.round((parseInt(decimalTime, 10) - hour) * 60) || 0;
      time.set('hour', hour).set('minute', minute);

      return this.$filter.date(time, 'h:mm A');
    },
    formatConvertSetpoint(rawSetpoint) {
      const { localTemp, tempUnit } = this.localization;
      return String(localTemp(rawSetpoint)).concat(`° ${tempUnit}`);
    },
    isEventActive({ scheduleEvents, event }) {
      let isEventActive = false;

      if (this.isActive) {
        const now = moment();
        const eventIsToday = event.DayOfWeek.find(dayItem => dayItem.day === now.day());

        if (eventIsToday) {
          let todaysEvents = [];

          // Filter for events that are today
          todaysEvents = todaysEvents.concat(scheduleEvents.filter((eventItem) => {
            return eventItem.DayOfWeek.find(dayItem => dayItem.day === now.day());
          }));

          if (todaysEvents.length) {
            let mostCurrentEventBeforeNow;

            // Find the most current event that should already have run in this schedule
            todaysEvents.forEach((eventItem) => {
              const hour = parseInt(eventItem.timeOfDay, 10);
              const minute = Math.round(parseFloat(eventItem.timeOfDay - hour) * 60);

              const eventItemStartTime = moment()
                .set('hour', hour)
                .set('minute', minute);

              if (now.isAfter(eventItemStartTime)) {
                mostCurrentEventBeforeNow = eventItem;
              }
            });

            // Deep compare the result to the present event
            if (isEqual(event, mostCurrentEventBeforeNow)) {
              isEventActive = true;
            }
          }
        }
      }

      return isEventActive;
    },
  },
};
</script>

<style lang="scss">
.device-schedule {
  &--complex-active {
    border-left: 4px solid var(--provider-color);

    .device-schedule__row {
      margin-left: -4px;
    }
  }

  &__row {
    min-height: 56px;
    height: max-content;

    padding: 0 8px;

    display: grid;
    grid-template-columns: minmax(200px, 300px) minmax(260px, 280px) repeat(3, minmax(110px, 150px));
    grid-auto-rows: 100%;

    align-content: center;
    justify-items: left;

    border-bottom: 1px solid #DBDBDB;

    &--header {
      svg {
        width: 10px !important;
        height: 10px !important;
      }
    }

    &__cell {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      border-right: 1px solid #DBDBDB;

      display: flex;
      justify-content: left;
      align-items: center;
      gap: 0 8px;
    }
    &__cell:last-of-type {
      border-right: none;
    }
  }
  &__complex {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;

    padding: 16px 24px;

    border-bottom: 1px solid #DBDBDB;

    background: #FBFBFB;

    &__row {
      display: flex;
      flex-flow: row nowrap;
    }
  }
  &__toggle {
    width: 22px;
    height: 22px;

    margin-left: auto;
    padding-top: 2px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 2px;

    svg {
      width: 8px !important;
      height: 8px !important;
    }
  }

  &__mobile {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius:6px;
    margin:16px;
    padding-bottom:8px;

    &__title {
      border-bottom:1px solid #eee;
      color: #016699;
      font-size:16px;
      line-height:24px;
      padding:4px 8px;
      width:100%;
    }

    &__days {
      border:1px solid #eee;
      border-radius:3px;
      margin:8px;
      padding:8px;
    }

    &__settingsRow {
      border-spacing: 10px;
      display:flex;
    }

    &__setting {
      border:1px solid #eee;
      border-radius:3px;
      display:flex;
      flex-basis:33.33%;
      flex-direction: column;
      margin:4px;
      justify-content: space-between;
      padding:4px 14px;
      text-align:center;
    }

    &__setting__value {
      color: #016699;
      font-size:18px;
      font-weight:400;
      line-height:24px;
    }

    &__setting__label {
      color: #4F4F4F;
      font-size:10px;
    }

    &__complex__container {
      margin-bottom:8px;
    }

    &__complex {
      padding:4px;
      vertical-align:middle;
    }

    &__complex__eventNumber {
      display:inline-block;
      padding-top:2px;
      text-align:center;
      vertical-align:middle;
    }

    &__complex__schedule {
      border:1px solid #eee;
      border-radius:3px;
      display:inline-block;
      margin-left:8px;
      padding:8px;
      vertical-align:middle;
    }

    &__complex__settingsRow {
      border-spacing: 10px;
      display:flex;
    }

  }
}
</style>
