<template>
  <div role="table" class="device-schedule-list">
    <div v-show="isDesktop">
      <DeviceSchedule
        class="device-schedule-list__row"
        mode="header"
        :sort-column="sortColumn"
        :sort-direction="sortDirection"
        @sortBy="updateSorting"
      />
      <DeviceSchedule
        v-for="deviceSchedule in sortedDeviceSchedules"
        :key="`schedule-${deviceSchedule.id}`"
        class="device-schedule-list__row"
        mode="row"
        :device-schedule="deviceSchedule"
        :active-schedule-uid="activeScheduleUid"
      />
    </div>
    <div v-show="!isDesktop">
      <DeviceSchedule
        v-for="deviceSchedule in sortedDeviceSchedules"
        :key="`schedule-${deviceSchedule.id}`"
        class="device-schedule-list__mobile__item"
        mode="mobile"
        :device-schedule="deviceSchedule"
        :active-schedule-uid="activeScheduleUid"
      />
    </div>
  </div>
</template>

<script>
import DeviceSchedule from './DeviceSchedule.vue';

export default {
  name: 'DeviceScheduleList',
  components: { DeviceSchedule },
  inject: ['deviceModes'],
  props: {
    deviceSchedules: {
      type: Array,
      default: () => ([]),
    },
    activeScheduleUid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sortColumn: 'default',
      sortDirection: 'default',
      defaultSortColumn: 'scheduleName',
      defaultSortDirection: 'ascending',
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.breakpoint;
    },
    isDesktop() {
      if ((this.breakpoint.substring(0, 7) === 'desktop')) { return true; }
      return false;
    },
    sortedDeviceSchedules() {
      return [...this.deviceSchedules].sort(this.compareFunction);
    },
    compareFunction() {
      const sortMultipliers = {
        ascending: 1,
        descending: -1,
      };

      let sortOrder;
      switch (this.sortDirection) {
        case 'default':
          sortOrder = sortMultipliers[this.defaultSortDirection];
          break;
        case 'ascending':
          sortOrder = sortMultipliers.ascending;
          break;
        case 'descending':
          sortOrder = sortMultipliers.descending;
          break;
        default:
          sortOrder = 0;
          break;
      }

      return (itemA, itemB) => {
        let result = 0;
        if (this.targetOfComparison(itemA) < this.targetOfComparison(itemB)) {
          result = -1;
        }
        if (this.targetOfComparison(itemA) > this.targetOfComparison(itemB)) {
          result = 1;
        }
        return result * sortOrder;
      };
    },

  },
  methods: {
    targetOfComparison(item) {
      const column = this.sortColumn === 'default' ? this.defaultSortColumn : this.sortColumn;
      let weekString;
      let mode;
      switch (column) {
        case 'scheduleName':
          return item.name;
        case 'scheduleDays':
          // Prepare for sorting by charCode starting with Monday
          weekString = item.Event[0].DayOfWeek.map(dayObj => dayObj.day).sort().join('');
          if (weekString.charAt(0) === '0') {
            weekString = weekString.slice(1).concat('0');
          }
          return weekString;
        case 'scheduleStartTime':
          return item.Event[0].timeOfDay;
        case 'scheduleDeviceMode':
          mode = this.deviceModes.find(item => item.setting === this.scheduleEvents[0].command.mode);
          return mode ? mode.name : this.$filter.humanReadableCommandMode(item.Event[0].command.mode);
        case 'scheduleSetpoint':
          return item.Event[0].command.setpoint;
        default:
          return item;
      }
    },
    updateSorting({ column }) {
      const isCurrentColumn = this.sortColumn === column;
      if (isCurrentColumn) {
        if (this.sortDirection === 'ascending') {
          this.sortDirection = 'descending';
        } else {
          this.sortDirection = 'ascending';
        }
      } else {
        this.sortColumn = column;
        this.sortDirection = 'ascending';
      }
    },
  },
};
</script>

<style lang="scss">
.device-schedule-list {
  &__row:first-of-type {
    border-top: 1px solid #DBDBDB;
  }
  &__row:nth-of-type(2n) {
    background: #F4F7F8;
  }
}

</style>
