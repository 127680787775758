<template>
  <div class="status-tab">
    <DeviceStatus />
    <DevicePlotList />
  </div>
</template>

<script>
import DevicePlotList from '../DevicePlotList.vue';
import DeviceStatus from '../DeviceStatus.vue';

export default {
  name: 'StatusTab',
  components: { DeviceStatus, DevicePlotList },
};
</script>

<style lang="scss">
.status-tab {
  display: flex;
  flex-flow: column;
  row-gap: 24px;
}
</style>
