<template>
  <widget class="leader-board" :style="bodyCSS">
    <template #title>
      {{ $t('leaderboard') }}
    </template>
    <template #controller>
      <vp-select id="leader-board_standingsType" v-model="standingsTypes" :options="[{ name: $t('overallStandings'), value: 'overall' }, { name: $t('userStandings'), value: 'homeowner' }]" width="180px" aria-label="Select Ranking Type" />
    </template>
    <div class="leader-board__body">
      <div class="leader-board__left-panel">
        <div v-if="standingsTypes === 'overall'" class="leader-board__overall-stats">
          <div class="leader-board__energy">
            <p class="leader-board__panel__title" :style="panelTitleStyle">{{ $t('totalEnergy') }}</p>
            <div class="leader-board__panel__icon">
              <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0134 6.24809C12.3948 6.24809 12.6809 6.40122 12.8716 6.7075C13.0623 7.01378 13.0623 7.32006 12.8716 7.65697L5.71997 19.5406C5.52926 19.8469 5.2432 20 4.86178 20C4.54393 20 4.28965 19.8775 4.09894 19.6631C3.90823 19.4181 3.84466 19.1424 3.94002 18.8361L5.81532 11.2404H0.984029C0.697965 11.2404 0.443687 11.1485 0.252978 10.9341C0.0622687 10.7198 -0.0330858 10.4747 -0.00130098 10.1991L1.30188 0.826953C1.33366 0.58193 1.42902 0.398162 1.61973 0.245023C1.81043 0.0918836 2.03293 0 2.28721 0H7.81777C8.13562 0 8.3899 0.122511 8.58061 0.367534C8.77131 0.612557 8.83488 0.888208 8.73953 1.16386L7.37278 6.24809H12.0134Z" :fill="providerColor" />
              </svg>
              <span class="leader-board__panel__icon-text">{{ currentSeasonalSavings }}</span>
              <span class="leader-board__panel__icon-text--sub">{{ currentSeasonalSavingsType }}</span>
            </div>
          </div>
          <div class="leader-board__participants">
            <p class="leader-board__panel__title">{{ $t('participants') }}</p>
            <div class="leader-board__panel__icon">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0274 13.3636C15.7587 13.7482 16.1243 14.0979 16.1243 14.4126V15.986C16.1243 16.2657 16.0146 16.5455 15.7952 16.7203C15.5759 16.9301 15.3199 17.035 15.0274 17.035H1.09689C0.804388 17.035 0.548446 16.9301 0.329068 16.7203C0.109689 16.5105 0 16.2657 0 15.986V14.4126C0 14.1329 0.365631 13.7832 1.09689 13.3636L5.26508 11.1958C4.49726 10.6364 3.94881 9.86713 3.65631 8.88811C3.3638 7.90908 3.21755 7.17482 3.21755 6.58041V3.993C3.21755 3.57342 3.3638 3.11887 3.65631 2.69929C3.94881 2.27971 4.31444 1.8951 4.7532 1.54545C5.19196 1.1958 5.7404 0.916077 6.32541 0.706287C6.91042 0.496497 7.49543 0.391602 8.08044 0.391602C8.70201 0.391602 9.28702 0.496497 9.87203 0.706287C10.457 0.916077 10.9689 1.1958 11.4077 1.54545C11.8464 1.8951 12.2121 2.27971 12.5046 2.69929C12.7971 3.11887 12.9068 3.57342 12.9068 3.993V6.58041C12.9068 7.20978 12.7605 7.97901 12.5046 8.92307C12.2486 9.86713 11.7367 10.6364 10.9689 11.1958L15.0274 13.3636Z" :fill="providerColor" />
              </svg>
              <span class="leader-board__panel__icon-text">{{ participantCount }}</span>
            </div>
          </div>
        </div>
        <div v-if="standingsTypes === 'homeowner'" class="leader-board__individual-stats">
          <p class="leader-board__panel__title">{{ $t('userParticipation') }}</p>
          <div class="leader-board__panel__icon">
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6063 18.6063C18.6063 19.3728 17.9791 20.0697 17.1429 20.0697H1.46341C0.696864 20.0697 0 19.4425 0 18.6063V4.32056C0 3.48432 0.627178 2.85714 1.46341 2.85714H2.92683V1.81185C2.85714 0.836237 3.69338 0 4.66899 0H5.36585C6.34146 0 7.1777 0.836237 7.1777 1.81185V2.85714H11.4983V1.81185C11.4286 0.836237 12.2648 0 13.2404 0H13.9373C14.9129 0 15.7491 0.836237 15.7491 1.81185V2.85714H17.2125C17.9791 2.85714 18.676 3.48432 18.676 4.32056V18.6063H18.6063ZM4.66899 10.3833V7.1777H1.46341V10.3833H4.66899ZM4.66899 14.6341V11.0801H1.46341V14.6341H4.66899ZM4.66899 18.6063V15.4007H1.46341V18.6063H4.66899ZM5.71429 1.81185C5.71429 1.60279 5.57491 1.46341 5.36585 1.46341H4.66899C4.45993 1.46341 4.32056 1.60279 4.32056 1.81185V5.01742C4.32056 5.22648 4.45993 5.36585 4.66899 5.36585H5.36585C5.57491 5.36585 5.71429 5.22648 5.71429 5.01742V1.81185ZM8.91986 10.3833V7.1777H5.36585V10.3833H8.91986ZM8.91986 14.6341V11.0801H5.36585V14.6341H8.91986ZM8.91986 18.6063V15.4007H5.36585V18.6063H8.91986ZM13.2404 10.3833V7.1777H9.68641V10.3833H13.2404ZM13.2404 14.6341V11.0801H9.68641V14.6341H13.2404ZM13.2404 18.6063V15.4007H9.68641V18.6063H13.2404ZM14.2857 1.81185C14.2857 1.60279 14.1463 1.46341 13.9373 1.46341H13.2404C13.0314 1.46341 12.892 1.60279 12.892 1.81185V5.01742C12.892 5.22648 13.0314 5.36585 13.2404 5.36585H13.9373C14.1463 5.36585 14.2857 5.22648 14.2857 5.01742V1.81185ZM17.1429 10.3833V7.1777H13.9373V10.3833H17.1429ZM17.1429 14.6341V11.0801H13.9373V14.6341H17.1429ZM17.1429 18.6063V15.4007H13.9373V18.6063H17.1429Z" :fill="providerColor" />
            </svg>
            <span class="leader-board__panel__icon-text">{{ participantEvents }}</span>
            <span class="leader-board__panel__icon-text--sub"> of {{ totalEvents }}</span>
          </div>
        </div>
      </div>
      <div class="leader-board__right-panel">
        <LeaderBoardList />
      </div>
    </div>
  </widget>
</template>

<script>
import LeaderBoardList from './LeaderBoardList.vue';
import Widget from './Widget.vue';

const myHouseId = localStorage.getItem('houseId');

export default {
  name: 'LeaderBoard',
  components: { LeaderBoardList, Widget },
  inject: ['$byodConfig', '$proSettings'],
  computed: {
    bodyCSS() {
      return { '--provider-color': this.providerColor };
    },
    currentSeasonalSavings() {
      const savings = this.$store.getters['device/events']
        .filter(item => item.startTime >= (this.$proSettings.leaderboardStart || new Date()))
        .reduce((prev, item) => {
          const { reductionEnergy } = item.performance;
          if (reductionEnergy >= 0) {
            return prev + reductionEnergy;
          }
          return prev;
        }, 0);
      return savings ? savings.toFixed(3) : 0;
    },
    currentSeasonalSavingsType() {
      return this.currentSeasonalSavings >= 1000 ? 'MWH' : 'KWH';
    },
    panelTitleStyle() {
      const style = {};
      if (this.$i18n.locale === 'es') {
        style['font-size'] = '0.55rem';
      } else {
        style['font-size'] = '0.625rem';
      }
      return style;
    },
    participantList() {
      return this.$store.getters['dashboard/leaderboardParticipantList'];
    },
    participantCount() {
      return this.participantList.length;
    },
    participantEvents() {
      return this.myStats ? (this.myStats.responseCount - this.myStats.optOutCount) : 0;
    },
    providerColor() {
      return this.$byodConfig.color;
    },
    standingsTypes: {
      get() {
        return this.$store.getters['dashboard/leaderboardStandingsTypes'];
      },
      set(newType) {
        this.$store.commit('dashboard/leaderboardStandingsTypes', newType);
      },
    },
    myStats() {
      return this.participantList.find(({ houseId }) => houseId === Number(myHouseId));
    },
    totalEvents() {
      return this.myStats ? this.myStats.eventCount : 0;
    },
  },
  mounted() {
    return this.init();
  },
  methods: {
    init() {
      return this.$store.dispatch('dashboard/getProgramLeaderboard');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";
#leader-board_standingsType {
  div.vcl-input__input {
    height: 100% !important;
  }
  div.vcl-select__selected {
    @include Tablet--Large {
    }
  }
  input.vcl-input__element {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    line-height: 13px;
    margin: 0 !important;
    @include Tablet--Large {
      font-size: 1rem;
    }
  }
}
.leader-board {
  .widget {
    &__header-container {
      gap: 1rem;
    }
    &__inner {
      padding: 0;
    }
  }
  .leader-board__panel {
    &__title {
      font-size: 0.625rem;
      line-height: 0.8125rem;
      font-weight: normal;
      margin-bottom: 0.25rem;
      @include Tablet--Large {
        font-size: 0.875rem;
      }
    }
    &__icon {
      display: flex;
      font-size: 1rem;
      justify-content: center;
      align-items: center;
      max-height: 30px;
      gap: 0.25rem;
      &-text {
        color: var(--provider-color);
        line-height: 24px;
      }
      &-text--sub {
        color: #afafaf;
        font-weight: bold;
        line-height: 13px;
        text-transform: uppercase;
      }
      @include Tablet {
          font-size: 1.5rem;
        }
    }
  }
  &__header {
    display: flex;
    padding: 1.25rem;
    border-radius: 6px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    font-size: 20px;
  }
  &__body {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    background: #fbfbfb;
  }
  &__left-panel {
    flex: 1 1 36%;
    padding: 0.5rem;
    background: #fff;
    @include Tablet--Large {
      padding: 1.5rem;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #dbdbdb;
      padding: 0.75rem 0.25rem;
      border-radius: 6px;
      height: 100%;
      gap: 0.75rem;
      @include Tablet--Large {
        gap: 2rem;
      }
    }
  }
  &__right-panel {
    flex: 2 1 50%;
    padding: 0 0.5rem;
    box-shadow: 0px 1px 3px 0px #0000001a inset;
    @include Tablet--Large {
      padding: 0 1rem;
    }
  }
  &__energy {
    .leader-board__panel__icon-text--sub {
      font-size: 0.5rem;
      align-self: end;
    }
  }
}
</style>
