export default {
  activeDevice: state => state.activeDevice,
  activeSchedule: state => (deviceId) => {
    const target = state.activeSchedules.find(item => item.deviceId === deviceId);
    return (target === undefined) ? {} : target;
  },
  changesPendingReconciliation: state => state.changesPendingReconciliation,
  device: state => (deviceId) => {
    const target = state.devices.find(device => device.id === deviceId);
    return (target === undefined) ? null : target;
  },
  deviceData: state => state.deviceData,
  devicePending: state => (deviceId) => {
    const target = state.devicesRealtime.find(item => item.deviceId === deviceId);
    return (target === undefined) ? {} : target.pending;
  },
  deviceRealtime: state => (deviceId) => {
    const target = state.devicesRealtime.find(item => item.deviceId === deviceId);
    return (target === undefined) ? {} : target.current;
  },
  devicesRealtime: state => state.devicesRealtime,
  deviceSettings: state => (settingKey) => {
    if (settingKey) {
      return state.deviceSettings.find(setting => setting.key === settingKey);
    }
    return state.deviceSettings;
  },
  energySavedBySeason: state => state.energySavedBySeason,
  eventLog: state => state.eventLog,
  events: state => state.events,
  devices: state => state.devices,
  plotData: state => (deviceKind, plotType) => {
    if (deviceKind in state.plotData && plotType in state.plotData[deviceKind]) {
      return state.plotData[deviceKind][plotType];
    }
    return {
      datasets: [],
      metadata: {},
    };
  },
  deviceSchedules: state => ({ deviceId, getDetails = false }) => {
    if (getDetails) {
      if (deviceId != null) {
        return state.deviceScheduleDetails.filter(deviceSchedule => deviceSchedule.deviceId === deviceId);
      }
      return state.deviceScheduleDetails;
    } else {
      if (deviceId != null) {
        return state.deviceSchedules.filter(deviceSchedule => deviceSchedule.deviceId === deviceId);
      }
      return state.deviceSchedules;
    }
  },
};
