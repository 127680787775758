/*
  munging.js
*/

const getIconClass = (iconStr) => {
  // Converts Open Weather API icon strings to Weather Icon class names
  const time = iconStr.slice(-1) === 'd' ? 'day' : 'night';
  const iconNum = iconStr.slice(0, 2);
  let details = 'clear';
  switch (iconNum) {
    case '01':
    case '02':
      if (time === 'day') {
        details = 'sunny';
      } else {
        details = 'clear';
      }
      break;
    case '03':
      details = 'cloudy';
      break;
    case '04':
      details = 'cloudy-high';
      break;
    case '09':
    case '10':
      details = 'rain';
      break;
    case '11':
      details = 'thunderstorm';
      break;
    case '13':
      details = 'snow';
      break;
    case '50':
      details = 'fog';
      break;
    default:
      details = '';
      break;
  }
  return `wi wi-fw wi-${time}-${details}`;
};

export const map = fn => dataArr => dataArr.map(fn); // Map given function against data array
export const pass = data => data; // Do nothing to given data
export const pipe = (...fns) => data => fns.reduce((v, fn) => fn(v), data); // Run data through given functions

export const evolveCurrent = ({ pop, precision, units }) => {
  // Strips and transforms Open Weather API: 5 Day, 3 Hour Forecast
  return (dataObj) => {
    const {
      main: { temp },
      weather: [{ icon }],
      wind: { speed: windSpeed },
    } = dataObj;

    return {
      temp: temp.toFixed(precision),
      icon: getIconClass(icon),
      windSpeed: windSpeed.toFixed(precision),
      precip: Math.round(pop * 100 < 10 ? 0 : pop * 100), // convert probability to percentage with floor
      units,
    };
  };
};

export const evolveForecast = ({ precision, units }) => {
  // Strips and transforms Open Weather API: Daily Forecast 16 days
  return (dataObj) => {
    const {
      dt,
      temp: { min, max },
      weather: [{ description, icon }],
      clouds,
      pop,
    } = dataObj;

    return {
      date: new Date(dt * 1000),
      temp: {
        min: min.toFixed(precision),
        max: max.toFixed(precision),
      },
      weather: {
        description,
        icon: getIconClass(icon),
      },
      clouds,
      precip: Math.round(pop * 100 < 10 ? 0 : pop * 100), // convert probability to percentage with floor
      units,
    };
  };
};
