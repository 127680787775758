<template>
  <div class="device-energy-datepicker" :style="[brandStyles]">
    <div class="device-energy-day-select">
      <VpSelect v-model="timeType" :styles="{ height: '40px' }" :options="[{ name: $t('datePickerDay'), value: 'day' }, { name: $t('datePickerWeek'), value: 'week' }, { name: $t('datePickerMonth'), value: 'month' }]" width="100%" height="40px" aria-label="Select Timeframe" empty-option="" />
    </div>
    <div id="datePickerContainer">
      <VpChartDatePicker ref="picker" :locale="locale" :type="timeType" aria-label="Date Picker" @update="updateDates($event)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceEnergyDatepicker',
  inject: ['$byodConfig'],
  computed: {
    brandStyles() {
      return {
        '--brand-color': this.$byodConfig.color || '#016699',
      };
    },
    timeType: {
      get() {
        return this.$store.getters['dashboard/energyGraphTimeType'];
      },
      set(type) {
        this.$store.dispatch('dashboard/setEnergyGraphTimeType', { timeType: type });
      },
    },
    locale() {
      return this.$store.getters['dashboard/locale'];
    },
  },
  methods: {
    updateDates({ start, end }) {
      this.$store.dispatch('dashboard/setEnergyGraphDateRange', { start, end });
    },
  },
};

</script>

<style lang="scss">
  .device-energy-datepicker {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    .vcl-input__label, .vcl-date-picker__label {
      color: #fff;
    }
  }
  ::v-deep .vcl-select__menu-item {
    &:hover, &.has-focus, &.is-selected, &:focus {
      background-color: var(--brand-color) !important;
      &__name {
        color:#fff !important;
      }
    }
  }
</style>
