<template>
  <div class="weather-current-card">
    <div class="weather-current-card__contents">
      <div class="weather-current-card__contents__header">{{ header }}</div>
      <div class="weather-current-card__contents__body">
        <i v-if="icon" class="weather-current-card__contents__body__icon" :class="icon" />
        <div v-else>
          <span class="weather-current-card__contents__body__measurement">{{ measurement }}</span>
          <span class="weather-current-card__contents__body__symbol">{{ symbol }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeatherCurrentCard',
  props: {
    card: {
      type: Object,
      default: () => ({
        key: '',
        icon: '',
        value: '',
        symbol: '',
      }),
    },
  },
  computed: {
    header() { return this.card.key; },
    icon() { return this.card.icon; },
    measurement() { return this.card.key === 'temperature' ? String(this.card.value).concat('°') : this.card.value; },
    symbol() { return this.card.symbol; },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.weather-current-card {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 57px;

  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 6px;

  &__contents {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    &__header {
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      color: #E3E3E3;
      text-transform: uppercase;
    }
    &__body {
      &__icon {
        font-size: 30px;
        line-height: 30px;
        color: #FFFFFF;
      }
      &__measurement {
        font-size: 30px;
        line-height: 30px;
        color: #FFFFFF;
      }
      &__symbol {
        font-size: 14px;
        line-height: 14px;
        color: #E3E3E3;
      }
    }
  }

  @include Tablet--XLarge {
    height: 142px;
    padding: 16px 0;

    &__contents {
      height: 100%;
      row-gap: 22px;

      &__header {
        font-size: 12px;
        line-height: 16px;
      }
      &__body {
        &__icon {
          font-size: 48px;
          line-height: 48px;
        }
        &__measurement {
          font-size: 48px;
          line-height: 48px;
        }
        &__symbol {
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  @include Desktop {
    width: 132px;
    height: 95px;
    padding: 8px 0;

    &__contents {
      height: 100%;
      row-gap: 15px;
      &__header {
        font-size: 14px;
        line-height: 16px;
      }
      &__body {
        &__icon {
          font-size: 30px;
          line-height: 30px;
        }
        &__measurement {
          font-size: 30px;
          line-height: 30px;
        }
        &__symbol {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }

  @include Desktop--Large {
    width: 210px;
    height: 138px;
    &__contents {
      row-gap: 22px;
      &__header {
        font-size: 14px;
        line-height: 16px;
      }
      &__body {
        &__icon {
          font-size: 60px;
          line-height: 60px;
        }
        &__measurement {
          font-size: 60px;
          line-height: 60px;
        }
        &__symbol {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
