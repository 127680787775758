<template>
  <div id="accountContainer" :style="bodyCSS">
    <div id="accountHeader">
      <p class="account__title" v-if="accountSelectedTab === 'profile'">{{ $t('accountProfile') }}</p>
      <p class="account__title" v-if="accountSelectedTab === 'security'">{{ $t('accountSecurity') }}</p>
      <p class="account__title" v-if="accountSelectedTab === 'preferences'">{{ $t('notificationPreferences') }}</p>
      <vp-select id="accountPageSelect" v-model="accountSelectedTab"
                 :options="accountPageOptions"
                 :styles="{
                   width: '150px',
                   menu: {
                     width: '150px',
                     itemHeight: '40px',
                   }}"
                 aria-label="Account Page"
      />
    </div>
    <vp-tab-menu id="accountTabMenu" v-model="accountSelectedTab" :tabs="accountPageOptions" :styles="{ activeColor: this.providerColor, inactiveColor: '#4f4f4f', backgroundColor: '#EFF1F2', activeUnderlineColor: this.providerColor }">
      <template #profile>{{ $t('profile') }}</template>
      <template #security>{{ $t('security') }}</template>
      <template #preferences>{{ $t('notifications') }}</template>
    </vp-tab-menu>
    <AccountProfile v-if="accountSelectedTab === 'profile'" />
    <AccountSecurity v-if="accountSelectedTab === 'security'" />
    <AccountPreferences v-if="accountSelectedTab === 'preferences'" />
  </div>
</template>

<script>
import AccountProfile from './AccountProfile.vue';
import AccountSecurity from './AccountSecurity.vue';
import AccountPreferences from './AccountPreferences.vue';

export default {
  name: 'Account',
  components: {
    AccountProfile, AccountSecurity, AccountPreferences,
  },
  methods: {
    mobileLogout() {
      this.$store.dispatch('shell/logout');
    },
  },
  inject: ['$byodConfig', '$toast'],
  props: { tab: { type: String, default: '' } },
  data() {
    return {
      accountSelectedTab: '',
    };
  },
  computed: {
    accountPageOptions() {
      const options = [
        {
          name: this.$i18n.t('profile'), value: 'profile', path: '/account/profile',
        },
        {
          name: this.$i18n.t('security'), value: 'security', path: '/account/security',
        },
        {
          name: this.$i18n.t('preferences'), value: 'preferences', path: '/account/preferences',
        },
      ];
      if (this.windowWidth < 1024) { // Only show in mobile view
        options.push({ name: this.$i18n.t('logout'), value: 'logout' });
      }
      return options;
    },
    bodyCSS() {
      return {
        '--provider-color': this.$byodConfig.color,
      };
    },
    providerColor() {
      return this.$byodConfig.color;
    },
    user() {
      return this.$store.getters.user;
    },
    windowWidth() {
      return this.$store.getters.windowWidth;
    },
  },
  watch: {
    accountSelectedTab(newValue) {
      const tab = this.accountPageOptions.find((opt) => { return (opt.value === newValue); });
      if ((this.$route.fullPath !== '/account/profile?addPhoneNumber') || (newValue !== 'profile')) {
        this.$router.push({ path: tab.path }).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            this.$toast.show('fail', { title: `Failed to change path to ${tab.path}`, body: err.message });
          }
          if (tab.value === 'logout') {
            this.mobileLogout();
          }
        });
      }
    },
    '$route.path': {
      handler(newRoute) {
        const tab = this.accountPageOptions.find((opt) => { return (opt.path === newRoute); });
        this.accountSelectedTab = tab.value;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.accountSelectedTab = this.tab || 'profile';
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';

.vue-app-container {
 background-color: $verylightgray;
 font-family: Muli;
 margin-top:0px;
}

#accountContainer {
  color: #4F4F4F;
}

#accountHeader {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height:60px;
  justify-content: space-between;
  line-height: 32px;
  letter-spacing: 0em;
  padding: 0px;
  padding-left: 24px;
  text-align: left;
}

.account__title {
  font-size:1rem;
  line-height:1.62;
  text-transform: capitalize;
}

#accountTabMenu {
  display: none;
}

#accountPageSelect {
  margin-top:12px;
  margin-right:24px;

  .vcl-input__input{
    &.has-focus {
      border: 2px solid var(--provider-color);
    }
  }
  .vcl-select__menu-item {
    &-name {
      color: #4F4F4F;
    }
    &:hover, &.has-focus, &.is-selected, &:focus {
      background-color: var(--provider-color);
      .vcl-select__menu-item-name {
        color:#fff;
      }
    }
  }
}

@include Desktop {
  #accountContainer {
    padding: 0px 32px;
    margin-top: 20px;
    width:90vw;
  }
  #accountHeader {
    background-color: var(--provider-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: $white;
    font-size:24px;
    height:60px;
    line-height:60px;
    padding-left:24px;
    user-select:none;
  }
  #accountTabMenu {
    display: block;
    box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.05) inset;
    height:45px;
  }
  #accountPageSelect {
    display: none;
  }
}
</style>
