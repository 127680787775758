<template>
  <div id="preferences">
    <p class="preferences__intro">{{ $t('preferenceInstructions') }}</p>
    <a tabindex="0" class="preferences__phone-link" :styles="{color: providerColor}" @click="addPhoneNumber" @keyup.enter="addPhoneNumber">{{ $t('addPhoneNumber') }}</a>
    <div class="header row" v-show="!isMobile">
      <div id="mediums-header">
        <div class="header-label">{{ $t('email') }}</div>
        <div class="header-label option--tooltip" v-if="smsEnabled">
          <span v-if="smsEnabled">{{ $t('SMS') }}</span>
          <vp-tooltip>
            <p class="tooltip__content">{{ $t('phoneToolTip') }}</p>
          </vp-tooltip>
        </div>
        <div v-if="voiceNotificationsEnabled" class="header-label">{{ $t('voice') }}</div>
      </div>
    </div>
    <div v-for="item in notificationList" :key="item.key" class="row" v-show="!isMobile">
      <p class="setting-desc">
        {{ $t(item.translationKey) }}
        <span v-if="item.tooltipKey">
          <vp-tooltip>
            <p class="tooltip__content">{{ $t(item.tooltipKey) }}</p>
          </vp-tooltip>
        </span>
      </p>
      <div class="checkboxes">
        <div class="option">
          <vp-checkbox
          :ref="item.key+'_email'"
          v-model="item.email"
          :disabled="checkboxLocked"
          :styles="{ size: 16, color: providerColor }"
          @input="updatePreferences(item.key, 'email')"
        />
        </div>
        <div class="option" v-if="smsEnabled">
          <vp-checkbox
          v-if="smsEnabled"
          :ref="item.key+'_sms'"
          v-model="item.sms"
          :disabled="checkboxLocked"
          :styles="{ size: 16, color: providerColor }"
          @input="updatePreferences(item.key, 'sms')"
        />
        </div>
        <div class="option" v-if="voiceNotificationsEnabled">
          <vp-checkbox
          v-if="voiceNotificationsEnabled"
          :ref="item.key+'voice'"
          v-model="item.voice"
          :disabled="voiceDisabled"
          :styles="{ size: 16, color: providerColor, 'margin-left': 'auto', 'margin-right': 'auto' }"
          @input="updatePreferences(item.key, 'voice')"
          />
        </div>
      </div>
      <div v-show="optedOut" class="opt-out">
        <div class="opted-out">
          <span>{{ $t('phoneOptOut', { supportEmail }) }}</span>
        </div>
      </div>
    </div>
    <div v-show="isMobile">
      <div v-for="item in notificationList" :key="item.key">
        <b>{{ $t(item.translationKey) }}</b><br>
        <div>
          <vp-checkbox
            :ref="item.key+'_email'"
            v-model="item.email"
            :disabled="checkboxLocked"
            :styles="{ size: 16, color: providerColor }"
            @input="updatePreferences(item.key, 'email')"
          >
            <template #label>{{ $t('email') }}</template>
          </vp-checkbox>
        </div>
        <div v-if="smsEnabled">
          <vp-checkbox
            v-if="smsEnabled"
            :ref="item.key+'_sms'"
            v-model="item.sms"
            :disabled="checkboxLocked"
            :styles="{ size: 16, color: providerColor }"
            @input="updatePreferences(item.key, 'sms')"
          >
            <template #label>{{ $t('SMS') }}</template>
          </vp-checkbox>
        </div>
        <div v-if="voiceNotificationsEnabled">
          <vp-checkbox
            v-if="voiceNotificationsEnabled"
            :ref="item.key+'voice'"
            v-model="item.voice"
            :disabled="voiceDisabled"
            :styles="{ size: 16, color: providerColor, 'margin-left': 'auto', 'margin-right': 'auto' }"
            @input="updatePreferences(item.key, 'voice')"
          >
            <template #label>{{ $t('voice') }}</template>
          </vp-checkbox>
        </div>
        <br>
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AccountPreferences',
  inject: ['$byodConfig', '$proSettings'],
  data() {
    return {
      checkboxLocked: false,
      notificationList: [],
      voiceDisabled: true,
    };
  },
  computed: {
    ...mapState('account', {
    }),
    breakpoint() {
      return this.$store.getters.breakpoint;
    },
    isMobile() {
      if ((this.breakpoint.substring(0, 6) === 'mobile')) { return true; }
      return false;
    },
    optedOut() {
      const optedOut = this.phoneNumbers.find(item => item.optout === true);
      if (optedOut !== undefined) {
        return true;
      } return false;
    },
    phoneNumbers() {
      return this.$store.getters.phoneNumbers;
    },
    providerColor() { return this.$byodConfig.color; },
    smsEnabled() {
      return this.$proSettings.settings.enableSms;
    },
    supportEmail() {
      return this.$byodConfig.supportEmail;
    },
    voiceNotificationsEnabled() {
      return this.$proSettings.settings.enableVoiceNotifications;
    },
  },
  created() {
    this.getPreferences();
  },
  methods: {
    getPreferences() {
      return this.$store.dispatch('account/getMessageSettings')
        .then((data) => {
          const keyMap = {
            offline_devices: 'offlineDeviceNotification',
            peak_demand: 'peakDemandNotification',
            general_notification: 'generalNotification',
            direct_message: 'directMessage',
          };
          for (let i = 0; i < data.length; i++) {
            data[i].translationKey = keyMap[data[i].key];
            if (data[i].key === 'direct_message') {
              data[i].tooltipKey = 'directMessageTooltip';
            }
          }
          this.notificationList = data;
        });
    },
    addPhoneNumber() {
      this.$router.push({ path: '/account/profile?addPhoneNumber' }).catch(() => { });
    },
    updatePreferences(key, type) {
      const setting = this.notificationList.find(item => (item.key === key));
      const bool = setting[type];
      this.checkboxLocked = true;
      const data = { type, bool };
      // Send preferences to api to update the database entry
      return this.$store.dispatch('account/updateMessageSettings', {
        key, data,
      })
        .then((item) => {
          this.checkboxLocked = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixins.scss';

#preferences {
  background-color:#FBFBFB;
  display: flex;
  flex-flow: column nowrap;
  padding: 24px 24px 24px 24px;
  &__list {
    padding-right: 0;
    justify-content: flex-end;
  }
}

.preferences__intro {
  user-select:none;
}

.preferences__phone-link {
  align-self: flex-end;
  cursor: pointer;
}

.preferences__phone-link:focus {
 outline: 1px dotted var(--provider-color);
}

.row {
 border-bottom: 1px solid #DBDBDB;
 display:flex;
 height:54px;
}

.header {
 align-items: center;
}

#mediums-header {
 margin-left: auto;
}

.option {
 display:flex;
 justify-content:center;
 text-align:center;

 @include Mobile--Only {
   width:60px;
 }

 @include Tablet {
   width:80px;
 }

 @include Desktop {
  width:120px;
 }
}

.header-label {
 display:inline-block;
 text-align:center;
 user-select:none;

 @include Mobile--Only {
   width:60px;
 }

 @include Tablet {
   width:80px;
 }

 @include Desktop {
  width:120px;
 }
}

.setting-desc {
 margin-right:auto;
 width:320px;
}

.checkboxes {
 align-items:center;
 display:flex;
 margin-left:auto;
}

div.vcl-tooltip {
 display:inline-block;
 padding-left:6px;
}

.vcl-tooltip__target .vcl-tooltip__body {
 padding:8px !important;
}

.vcl-checkbox__box {
 margin-left:auto;
 margin-right:auto;
}

</style>
