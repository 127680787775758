import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  name: 'dashboard',
  state() {
    return {
      leaderboard: {
        standingsTypes: 'overall',
        participantList: [],
      },
      energyGraph: {
        dateRange: {
          start: null,
          end: null,
        },
        timeType: 'day',
      },
      house: {},
      marketingMessages: {},
      locale: 'en',
    };
  },
  actions,
  getters,
  mutations,
};
