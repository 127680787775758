<template>
  <div id="messageListing">
    <div v-for="message in messages" :key="message.id" :class="[message['hidden'],'messagePreview']"
         @click="messageRowClicked($event, message)"
    >
      <div v-if="(message.read == null)" class="unreadHighlight">
        &nbsp;
      </div>
      <div class="previewFavorite messageFavorite"
           role="img" :alt="(message.favorite) ? $t('unsetFavorite') : $t('setFavorite')"
           :class="(message.favorite) ? 'messageFavorited' : ''"
           @click="favoriteMessage(message)"
      />
      <span class="messagePreviewDate" :title="formatPreviewDateTitle(message.createdAt)">{{ formatPreviewDate(message.createdAt) }}</span>
      <span class="messagePreviewSender">{{ providerConfig.name }}</span>
      <div class="messagePreviewSubject" @click="messageSubjectClicked($event, message)">
        {{ message.subject ? message.subject : $t('noSubject') }}
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'MessagePreviewList',
  components: {},
  props: {},
  emits: ['message-selected'],
  data: function data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  computed: {
    hasTouchScreen: {
      get() {
        return this.$store.getters.hasTouchScreen;
      },
    },
    messageCenterMode: {
      get() {
        return this.$store.getters['messageCenter/messageCenterMode'];
      },
    },
    messages: {
      get() {
        return this.$store.getters['messageCenter/messages'];
      },
      set(newMessages) {
        this.$store.commit('messageCenter/updateMessages', newMessages);
      },
    },
    providerConfig: {
      get() {
        return this.$store.getters['messageCenter/providerConfig'];
      },
      set(newConfig) {
        this.$store.commit('messageCenter/updateProviderConfig', newConfig);
      },
    },
  },
  methods: {
    favoriteMessage: function favoriteMessage(msg) {
      this.$store.dispatch('messageCenter/setFavorite', { id: msg.uuid, value: !msg.favorite });
    },
    formatPreviewDate: function formatPreviewDate(createdAt) {
      const created = new Date(createdAt);
      const month = created.toLocaleString(this.$i18n.locale, { month: 'long' });
      if (this.$i18n.locale === 'es') {
        return `${created.getDate()} de ${month} de ${created.getFullYear()}`;
      }
      return `${month} ${created.getDate()}, ${created.getFullYear()}`;
    },
    formatPreviewDateTitle: function formatPreviewDateTitle(createdAt) {
      const date = moment(createdAt).format('dddd, MMMM Do, YYYY');
      const time = moment(createdAt).format('h:mm a');
      return `${date} at ${time}`;
    },
    messageSubjectClicked: function messageSubjectClicked(evt, msg) {
      this.$emit('message-selected', msg.uuid);
    },
    messageRowClicked: function messageRowClicked(evt, msg) {
      if (this.hasTouchScreen) {
        this.$emit('message-selected', msg.uuid);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles';

div#messageListing {
 overflow-y:auto;
}

div.hidden { display:none; }

div.logoBubble {
  background: $lightgray;
  border-radius:20px;
  height:40px;
  width:40px;
  position:absolute;
  margin-left:-49px;
  margin-top:0px;
  text-align:center;
}

div.logoBubble img {
 margin-left:2px;
 margin-top:8px;
}

div.messagePreview {
 border-bottom:1px solid #e3e3e3;
 padding-left:24px;
 padding-right:14px;
 padding-top:20px;
 padding-bottom:55px;
 position:relative;
 user-select:none;
}

div.unreadHighlight {
 background: var(--provider-color);
 height:calc(100% - 45px);
 margin-left:-24px;
 top:22px;
 position:absolute;
 width:5px;
}

div.previewFavorite {
 position:absolute;
 right:18px;
 bottom:24px;
}

span.messagePreviewSender {
 color: $graytext;
 display:block;
 font-size:12px;
 width:75%;
}

span.messagePreviewDate {
 color: $graytext;
 float:right;
 font-size:12px;
 text-transform:uppercase;
}

div.messagePreviewSubject {
 color: var(--provider-color);
 cursor:pointer;
 display:-webkit-box;
 font-size:16px;
 line-height:24px;
 margin-top:10px;
 max-height:70px;
 overflow: hidden;
 text-decoration:underline;
 text-overflow: ellipsis;
 -webkit-box-orient: vertical;
 -webkit-line-clamp: 3;
}

@media (max-width:992px) {
 span.messagePreviewDate {
  margin-right:-16px;
 }

 div.messagePreview { cursor:pointer; }

 div.previewFavorite {
   float:right;
   margin-left:0px;
   margin-top:80px;
   position:unset;
 }

 div.unreadHighlight {
   margin-left:-20px;
 }
}
</style>
