import { render, staticRenderFns } from "./LocationPanel.vue?vue&type=template&id=52ba4ab8&"
import script from "./LocationPanel.vue?vue&type=script&lang=js&"
export * from "./LocationPanel.vue?vue&type=script&lang=js&"
import style0 from "./LocationPanel.vue?vue&type=style&index=0&id=52ba4ab8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports