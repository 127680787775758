<template>
  <div class="weather-forecast">
    <h4 class="dashboard-header">{{ $t('weatherForecast') }}</h4>
    <div class="weather-forecast__deck">
      <WeatherForecastCard v-for="item of forecast" :key="item.date.toISOString()" :forecast="item" :screen-size="screenSize" />
    </div>
  </div>
</template>

<script>
import WeatherForecastCard from './WeatherForecastCard.vue';

export default {
  name: 'WeatherForecast',
  components: { WeatherForecastCard },
  inject: ['$busy', '$toast'],
  props: {
    house: {
      type: Object,
      required: true,
    },
    screenSize: {
      type: String,
      default: () => ('small'),
    },
  },
  computed: {
    forecast() {
      return this.$store.getters['weather/forecast'];
    },
    locale() {
      return this.$store.getters['dashboard/locale'];
    },
  },
  watch: {
    house() {
      this.$busy.toggle(true);
      this.fetchForecast()
        .finally(() => {
          this.$busy.toggle(false);
        });
    },
    locale() {
      this.$busy.toggle(true);
      this.fetchForecast()
        .finally(() => {
          this.$busy.toggle(false);
        });
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$busy.toggle(true);
      this.fetchForecast()
        .finally(() => {
          this.$busy.toggle(false);
        });
    },
    fetchForecast() {
      const { house } = this;
      const i18n = this.$i18n;
      return this.$store.dispatch('weather/fetchForecast', { house, i18n })
        .catch((err) => {
          this.$toast.show('fail', { title: err.name, body: err.message });
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';
.weather-forecast {
  padding: 0 0 24px 0;

  &__deck {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;
    color: #FFFFFF;
  }

  @include Tablet--XLarge {
    &__deck {
      flex-flow: row nowrap;
      gap: 0 16px;
    }
  }

  @include Desktop {
    &__deck {
      width: 576px;
      height: 206px;

      border: 1px solid #AFAFAF;
      border-radius: 6px;

      padding: 8px;

      gap: 0 12px;
    }
  }

  @include Desktop--Large {
    &__deck {
      width: 780px;
      height: 291px;
      gap: 0 8px;
    }
  }
}
</style>
