<template>
  <div class="settings-tab">
    <div v-if="!evLayout" class="settings-tab__header">{{ $t('deviceSettings') }}</div>
    <div v-if="evLayout" class="settings-tab__header">{{ $t('evDeviceSettings') }}</div>
    <div v-if="evLayout" class="settings-tab__subHeader">{{ $t('vehicleDetails') }}</div>
    <DeviceSettingsList v-if="upperSettings.length" class="settings-tab__upper" :settings-list="upperSettings" :brand="brand" />
    <div v-if="evLayout" class="settings-tab__subHeader">{{ $t('vehicleData') }}</div>
    <DeviceSettingsList v-if="lowerSettings.length" class="settings-tab__lower" :settings-list="lowerSettings" :brand="brand" />
    <div class="settings-tab__footer">
      <VpButton class="medium" :styles="{ backgroundColor: brand.color, border: `2px solid ${brand.color}` }" @click="updateDevice" @keydown.enter="updateDevice">{{ $t('updateDevice') }}</VpButton>
    </div>
  </div>
</template>

<script>
import DeviceSettingsList from '../DeviceSettingsList.vue';
import MixingValveModal from '../MixingValveModal.vue';

export default {
  name: 'SettingsTab',
  components: { DeviceSettingsList },
  inject: ['$byodConfig', '$busy', '$filter', '$toast', '$modal', '$proSettings'],
  data() {
    return {
      deviceProperties: {},
      deviceSettings: {},
      deviceData: {},
      mixingValvePriorValue: null,
    };
  },
  computed: {
    brand() {
      return {
        color: this.providerColor,
      };
    },
    device() {
      return this.$store.getters['device/activeDevice'];
    },
    deviceId() {
      return this.device.id;
    },
    deviceKind() {
      return this.device.kind;
    },
    deviceMfg() {
      return this.device.mfg;
    },
    evLayout() {
      return this.device.kind === 'ev';
    },
    locale() {
      return this.$i18n.locale;
    },
    localization() {
      return this.$store.getters.localization;
    },
    localTempUnitSymbol() {
      return '°'.concat(this.localization.tempUnit);
    },
    providerColor() {
      return this.$byodConfig.color;
    },
    upperSettings() {
      let upperSettings = [
        {
          element: 'input',
          label: 'Device Type',
          source: this.deviceProperties,
          key: 'kind',
          resource: '',
          disabled: true,
          tooltip: null,
        },
        {
          element: 'input',
          label: 'Device Manufacturer',
          source: this.deviceProperties,
          key: 'mfg',
          resource: '',
          disabled: true,
          tooltip: null,
        },
        {
          element: 'input',
          label: 'ID Number',
          source: this.deviceProperties,
          key: 'uid',
          resource: '',
          disabled: true,
          tooltip: null,
        },
        {
          element: 'input',
          label: 'Device Name',
          source: this.deviceProperties,
          key: 'name',
          resource: '',
          disabled: false,
          tooltip: {
            body: this.$i18n.t('deviceInstructions'),
          },
        },
      ];

      if (this.deviceKind === 'hwh') {
        upperSettings = upperSettings.concat([
          {
            element: 'input',
            label: 'Default Mode',
            source: this.deviceSettings,
            key: 'hwh-default-set-mode',
            resource: 'hwh-default-set-mode',
            disabled: true,
            tooltip: null,
          },
          {
            element: 'input',
            label: 'Default Setpoint',
            source: this.deviceSettings,
            key: 'hwh-default-set-temperature',
            resource: 'hwh-default-set-temperature',
            disabled: true,
            tooltip: null,
            inputSuffix: this.localTempUnitSymbol,
            inputType: 'number',
          },
          {
            element: 'checkbox',
            label: 'Mixing Valve',
            source: this.deviceSettings,
            key: 'hwh-mixing-valve',
            resource: 'hwh-mixing-valve',
            disabled: false,
            tooltip: null,
          },
        ]);
      }

      if (this.deviceKind === 'ev') {
        upperSettings.shift();
        upperSettings = upperSettings.concat([
          {
            element: 'input',
            label: 'Ev Vehicle Model',
            source: this.deviceSettings,
            key: 'vehicle-model',
            resource: 'vehicle-model',
            disabled: true,
            tooltip: null,
          },
          {
            element: 'input',
            label: 'Ev Vehicle Year',
            source: this.deviceSettings,
            key: 'vehicle-year',
            resource: 'vehicle-year',
            disabled: true,
            tooltip: null,
          },
        ]);
      };

      return upperSettings;
    },
    lowerSettings() {
      let lowerSettings = [];
      if (this.deviceKind === 'hwh' && this.$proSettings.settings.enableAutoSchedule) {
        if (!['eradio', 'armada', 'aosmith'].includes(this.deviceMfg)) {
          lowerSettings = lowerSettings.concat([
            {
              element: 'input',
              label: 'Max Temperature',
              source: this.deviceSettings,
              key: 'hwh-max-setpoint',
              resource: 'hwh-max-setpoint',
              disabled: false,
              tooltip: {
                body: this.$i18n.t('maxTempDisclaimer'),
              },
              inputSuffix: this.localTempUnitSymbol,
              inputType: 'number',
            },
            {
              element: 'input',
              label: 'Min Temperature',
              source: this.deviceSettings,
              key: 'hwh-min-set-temperature',
              resource: 'hwh-min-set-temperature',
              disabled: false,
              tooltip: {
                body: this.$i18n.t('minTempDisclaimer'),
              },
              inputSuffix: this.localTempUnitSymbol,
              inputType: 'number',
            },
          ]);
        }
      }

      if (this.deviceKind === 'ev') {
        lowerSettings = lowerSettings.concat([
          {
            element: 'input',
            label: 'Ev Battery Capacity',
            source: this.deviceData,
            key: 'ev-battery-capacity',
            resource: 'ev-battery-capacity',
            inputSuffix: 'kWh',
            disabled: true,
            tooltip: null,
          },
          {
            element: 'input',
            label: 'Ev Charging Status',
            source: this.deviceData,
            key: 'ev-charging-status',
            resource: 'ev-charging-status',
            disabled: true,
            tooltip: null,
          },
          {
            element: 'checkbox',
            label: 'Ev Location Status',
            source: this.deviceData,
            key: 'ev-location-status',
            resource: 'ev-location-status',
            disabled: true,
            tooltip: null,
          },
          {
            element: 'checkbox',
            label: 'Ev Plugged In Status',
            source: this.deviceData,
            key: 'ev-plugged-in-status',
            resource: 'ev-plugged-in-status',
            disabled: true,
            tooltip: null,
          },
        ]);
      }
      return lowerSettings;
    },
    fetchableSettings() {
      let fetchableSettings = [];
      [
        this.upperSettings,
        this.lowerSettings,
      ].forEach((settingsList) => {
        fetchableSettings = fetchableSettings.concat(
          settingsList
            .filter(setting => !!setting.resource)
            .map(setting => setting.resource),
        );
      });
      return fetchableSettings;
    },
    editableSettings() {
      let editableSettings = [];

      [
        this.upperSettings,
        this.lowerSettings,
      ].forEach((settingsList) => {
        editableSettings = editableSettings.concat(
          settingsList
            .filter(setting => 'disabled' in setting && !setting.disabled)
            .map(setting => ({
              key: setting.key,
              source: setting.source,
            })),
        );
      });
      return editableSettings;
    },
  },
  watch: {
    locale() {
      this.refreshData();
    },
    deviceSettings: {
      deep: true,
      handler(newValue) {
        const mixingValveNewValue = newValue['hwh-mixing-valve'];

        if (mixingValveNewValue === true && this.mixingValvePriorValue === false) {
          this.openMixingValveModal();
        }
        this.mixingValvePriorValue = mixingValveNewValue;
      },
    },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    openMixingValveModal() {
      const target = this;
      this.$modal.open(MixingValveModal, { target });
    },
    refreshData() {
      this.$busy.toggle(true);
      const { deviceId } = this;
      this.$store.dispatch('device/fetchDevice', { deviceId })
        .then((device) => {
          const promises = [];

          this.deviceProperties = {};
          this.deviceSettings = {};
          this.deviceData = {};

          let deviceMfg = '';
          let subBrand;

          device.deviceSettings.forEach((setting) => {
            if (setting.key === 'sub-brand') {
              subBrand = setting.value;
            }
          });

          if ((device.mfg === 'aosmith' || device.mfg === 'rheem') && device.kind === 'hwh' && subBrand !== undefined) {
            deviceMfg = this.$filter.deviceSubBrand(subBrand);
          } else if (device.kind === 'ev') {
            deviceMfg = this.$filter.vehicleMake(device.type);
          } else {
            deviceMfg = this.$filter.deviceMfg(device.mfg);
          }

          this.deviceProperties = {
            ...device,
            kind: this.$filter.deviceKind(device.kind),
            mfg: deviceMfg,
          };

          this.fetchableSettings.forEach((key) => {
            if (this.deviceKind === 'ev') { this.fetchableSettings.splice(2, 4); }; // removes lowerSettings for EVs since they're handled differently below
            promises.push(this.$store.dispatch('device/fetchDeviceSetting', { deviceId, resource: { key } })
              .then((deviceSetting) => {
                if (deviceSetting.key === 'hwh-default-set-temperature' && !deviceSetting.value) {
                  deviceSetting.value = 'Unset';
                }
                this.$set(this.deviceSettings, deviceSetting.key, deviceSetting.value);
              }));
          });

          if (this.deviceKind === 'ev') {
            this.lowerSettings.forEach((setting) => {
              promises.push(this.$store.dispatch('device/fetchDeviceDataByType', { deviceId, dataType: setting.key })
                .then((data) => {
                  // for checkbox in lowerSettings
                  if (setting.key === 'ev-location-status' && data.value === 'HOME') {
                    data.value = true;
                  }
                  if (setting.key === 'ev-location-status' && data.value === 'AWAY') {
                    data.value = false;
                  }
                  // for internationalization
                  if (setting.key === 'ev-charging-status' && data.value === 'CHARGING') {
                    data.value = this.$i18n.t('charging');
                  }
                  if (setting.key === 'ev-charging-status' && data.value === 'NOT_CHARGING') {
                    data.value = this.$i18n.t('notCharging');
                  }
                  if (setting.key === 'ev-charging-status' && data.value === 'FULLY_CHARGED') {
                    data.value = this.$i18n.t('fullyCharged');
                  }
                  this.$set(this.deviceData, setting.key, data.value);
                }));
            });
          };

          return Promise.all(promises);
        })
        .catch((err) => {
          this.$toast.show('fail', { title: err.name, body: err.message });
        })
        .finally(() => {
          this.$busy.toggle(false);
        });
    },
    setMixingValveSetting(value = false) {
      // Called inside MixingValveModal
      if ('hwh-mixing-valve' in this.deviceSettings) {
        this.deviceSettings['hwh-mixing-valve'] = value;
      }
    },
    updateDevice() {
      const { deviceId } = this;
      const promises = [];

      this.editableSettings.forEach((setting) => {
        const { key, source } = setting;
        if (source === this.deviceSettings) {
          const value = this.deviceSettings[key];
          const existingValue = this.$store.getters['device/deviceSettings'](key).value;
          if (value !== existingValue) {
            promises.push(this.$store.dispatch('device/updateDeviceSetting', { deviceId, resource: { key, value } }));
          }
        }
        if (source === this.deviceProperties) {
          const value = this.deviceProperties[key];
          const existingValue = this.device[key];
          if (value !== existingValue) {
            promises.push(this.$store.dispatch('device/updateDevice', { deviceId, deviceObject: { [key]: value } }));
          }
        }
      });

      Promise.all(promises)
        .then(() => {
          return this.refreshData();
        });
    },
  },
};
</script>

<style lang="scss">
.settings-tab {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 24px 0 32px;

  &__header {
    padding-left: 24px;
    font-size: 24px;
    border-bottom: 1px solid #DBDBDB;
  }
  &__subHeader {
    padding: 24px 0 0 24px;
    font-size: 18px;
  }
  &__upper {
    border-bottom: 1px solid #DBDBDB;
  }
  &__lower {
    border-bottom: 1px solid #DBDBDB;
  }
  &__footer {
    display: flex;
    justify-content: center;
    padding-top: 32px;
  }
}
</style>
<style scoped>
.settings-tab__lower :deep(span) {
  color: #4F4F4F;
}
</style>
